<script setup lang="ts">
import {computed, watch} from "vue"
import {useHelperStore} from "@/stores/HelperStore"
import {useModalStore} from "@/stores/ModalStore"
import {useHead} from "@unhead/vue"
import {useRoute} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {isProd, isDev} from "@/main"
import Header from "@/components/partials/Header.vue"
import Footer from "@/components/partials/Footer.vue"
import FFModalWindow from "@/components/FFUI/FFModalWindow.vue"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

const helperStore = useHelperStore()
const {openModal} = useModalStore()
const route = useRoute()
const {currentLocale} = useHelpers()

useHead({
  title: () => helperStore.pageTitle || "FFIns | АО «СК «Freedom Finance Insurance»",
  meta: [
    {
      name: "description",
      content:
        "Freedom Insurance – первая в Казахстане страховая компания, которая работает для удобства автовладельцев. Входит в структуру холдинга Freedom Holding Corp. – американскую холдинговую компанию со штаб-квартирой в Алматы, Казахстан. АО «СК «Freedom Finance Insurance»."
    }
  ]
})

const isNotMstBoarding = computed(() => route.name !== "MSTBoarding")

//Jivo
if (isProd) {
  const injectJivoScript = (): void => {
    const existingScript = document.getElementById("jivo-widget")
    if (existingScript) {
      existingScript.remove()
    }

    let scriptUrl = ""

    if (currentLocale.value === "kk") {
      scriptUrl = "//code.jivo.ru/widget/DnJtPKmAfM"
    } else {
      scriptUrl = "//code.jivo.ru/widget/wPHqpxFJiR"
    }

    if (scriptUrl) {
      const script = document.createElement("script")
      script.src = scriptUrl
      script.async = true
      script.id = "jivo-widget"
      document.head.appendChild(script)
    }
  }

  injectJivoScript()

  watch(currentLocale, (newLocale) => {
    if (newLocale) {
      injectJivoScript()
      location.reload()
    }
  })
}
</script>

<template>
  <div class="app">
    <div>
      <Header v-if="isNotMstBoarding"></Header>
      <router-view />
      <FFModalWindow />
    </div>
    <Footer v-if="isNotMstBoarding"></Footer>
  </div>
</template>

<style scoped lang="scss">
.app {
  background: var(--color-black);
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
}
</style>
