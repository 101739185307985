import {ref, watch} from "vue"

import {useRoute} from "vue-router"

export function useHelpers() {
  const route = useRoute()
  const currentLocale = ref(route.params["lang"] || window.localStorage.getItem("locale") || "ru")

  watch(route, () => {
    currentLocale.value = route.params["lang"] || "ru"
  })

  const openLink = (link: string, target: string) => {
    if (link === "") return
    window.open(link, target)
  }

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"})
  }

  const scrollTo = (name: string) => {
    const element = document.querySelector(name)
    if (element) {
      element.scrollIntoView({behavior: "smooth"})
    }
  }

  const scrollToPoint = (y: number) => {
    window.scrollTo({
      top: y,
      behavior: 'smooth',
    });
  };

  const toUpperCase = (value: string) => {
    return value.toUpperCase()
  }

  return {
    currentLocale,
    openLink,
    scrollToTop,
    scrollTo,
    scrollToPoint,
    toUpperCase
  }
}
