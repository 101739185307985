<script setup lang="ts">
import {storeToRefs} from "pinia"
import {onBeforeUnmount, computed} from "vue"
import {useI18n} from "vue-i18n"
import {useRouter} from "vue-router"
import {useHelperStore} from "@/stores/HelperStore"
import {useHelpers} from "@/composables/useHelpers"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useFormatters} from "@/utils/formatters"
import {pushGtagEvent} from "@/utils/pushGtagEvent"
import {externalUrls} from "@/constants/urls"
import CashbackMessage from "@/components/SuccessPay/CashbackMessage.vue"
import ProductBankCashbackAmount from "@/components/shared/ProductBankCashbackAmount.vue"
import FFBackdropSheet from "@/components/FFUI/FFBackdropSheet.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const helperStore = useHelperStore()
const {isSheetVisible} = storeToRefs(helperStore)
const {openLink, currentLocale} = useHelpers()
const {thousandSeparator} = useFormatters()
const {isDesktop, isTablet, isMobileDevice} = useDeviceSize()
const {t} = useI18n()

const router = useRouter()
const paymentType = localStorage.getItem("paymentType")
const cashbackBalance = JSON.parse(localStorage.getItem("cashbackBalance"))
const cashbackAmount = JSON.parse(localStorage.getItem("cashbackAmount"))
const isClientBank = JSON.parse(localStorage.getItem("isClientBank"))
const isScoring = JSON.parse(localStorage.getItem("isScoring"))
const productType = JSON.parse(localStorage.getItem("productType"))

const superAppLink = "https://loyalty.bankffin.kz/ru?utm_source=site&amp;utm_medium=banner&amp;utm_campaign=loyalty&amp;utm_content=ru"

const removePaymentTypeFromStorage = () => {
  localStorage.removeItem("paymentType")
  localStorage.removeItem("cashbackAmount")
  localStorage.removeItem("cashbackBalance")
  localStorage.removeItem("isClientBank")
  localStorage.removeItem("isScoring")
  localStorage.removeItem("productType")
}

if (paymentType !== "KaspiPay" || paymentType !== "FreedomSuperApp") {
  pushGtagEvent("ogpo_thankyou_page")
}

const descriptionNotBankClient = computed(() => {
  if (currentLocale.value === "kk") {
    return `
  ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
  ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}
  <span id="link" class="underline cursor-pointer">${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-3")}</span>
  ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-4")}
`
  } else {
    return `
  ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
  <span id="link" class="underline cursor-pointer">${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}</span>
`
  }
})

onBeforeUnmount(() => {
  removePaymentTypeFromStorage()
})
</script>

<template>
  <div class="bg-image">
    <div class="container">
      <div class="sucess-wrapper">
        <div class="success">
          <img src="/success-pay-front/images/success-check.png" alt="" />
          <template v-if="paymentType === 'KaspiPay'">
            <p class="success__title">{{ $t("app.successPayPage.kaspiPayTitle") }}</p>
            <p class="success__subtitle">{{ $t("app.successPayPage.kaspiPaySubtitle") }}</p>
          </template>
          <template v-else-if="paymentType === 'FreedomSuperApp'">
            <p class="success__title">{{ $t("app.successPayPage.freedomSuperAppTitle") }}</p>
            <p class="success__subtitle">{{ $t("app.successPayPage.freedomSuperAppSubtitle") }}</p>
          </template>
          <template v-else>
            <p class="success__title">{{ $t("app.successPayPage.title") }}</p>
            <p class="success__subtitle">{{ $t("app.successPayPage.subtitle") }}</p>
          </template>

          <ProductBankCashbackAmount
            v-if="!isClientBank"
            :isLoyaltyCalled="true"
            :isClientBank="false"
            :cashbackBalance="cashbackBalance"
            :paymentType="paymentType"
            :width="isDesktop || isTablet ? '370px' : '100%'"
            :url="externalUrls.superAppUrl"
            :productType="productType" />

          <FFBackdropSheet
            v-model:isVisible="isSheetVisible"
            :isSheet="!isDesktop"
            :descriptionNotBankClient="descriptionNotBankClient"
            :url="externalUrls.superAppUrl">
            <template #title>{{ $t("app.freedomBank.cashback.notBankClient.bottomSheet.title") }}</template>
          </FFBackdropSheet>

          <FFButton
            @click="router.push('/pages/personal-profile')"
            class="sucess-message__button"
            :title="$t('app.thankYou.login')"
            :uppercase="false"
            color="#4F9D3A"
            paddingx="15px"
            paddingy="30px"
            width="300px"
            border-pixel="1px solid #fff"
            background="#FFF" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-image {
  background: url("/success-pay-front/images/success-pay-bg.png") no-repeat top;
  height: 100%;
}
.sucess-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 107px 0;

  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    .success__title {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
      background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .success__subtitle {
      display: inline;
      position: relative;
      text-align: center;
      color: #fff;
      width: fit-content;
      font-weight: 700;
      font-size: 21px;
      line-height: normal;
      color: #4fe8b0;

      @media (max-width: $lg) {
        font-size: 19px;
      }
    }
  }
}

@media (max-width: $lg) {
  .thank-you {
    padding: 50px 0;
    gap: 50px;
  }
}
</style>
