import zod from "zod"

export const juridicalPageFormSchema = zod.object({
  companyName: zod.string().min(1),
  bin: zod.string().nonempty().length(12),
  fio: zod.string().min(1),
  phone: zod
    .string()
    .nonempty()
    .regex(/^\+7 \(\d{3}\) \d{3} \d{4}$/),
  email: zod.string().email(),
  iAgreeWithAgreement: zod.boolean().refine((value) => value === true)
})
