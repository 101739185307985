<script setup lang="ts">
import {onMounted} from "vue"
import {storeToRefs} from "pinia"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import {useTerminationSubscriptionAgreementStore} from "@/stores/TerminationSubscriptionAgreementStore"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"
import ProfileHero from "@/components/PersonalProfile/ProfileHero.vue"
import UserNameBonus from "@/components/PersonalProfile/UserNameBonus.vue"
import ProfileServiceCards from "@/components/PersonalProfile/ProfileServiceCards.vue"
import ProfileMyPolises from "@/components/PersonalProfile/ProfileMyPolises.vue"
import ProfileMessages from "@/components/PersonalProfile/ProfileMessages.vue"
import ProfileOffers from "@/components/PersonalProfile/ProfileOffers.vue"
import ProfileMyTerminatedPolicies from "@/components/PersonalProfile/ProfileMyTerminatedPolicies.vue"
import ProfileMySubscriptionPolicies from "@/components/PersonalProfile/ProfileMySubscriptionPolicies.vue"

const personalProfileStore = usePersonalProfileStore()
const terminationSubscriptionAgreementStore = useTerminationSubscriptionAgreementStore()
const {loading} = storeToRefs(personalProfileStore)
const {loading: terminationSubscriptionAgreementLoading} = storeToRefs(terminationSubscriptionAgreementStore)

onMounted(async () => {
  await personalProfileStore.getAllUserPolicies()
  await personalProfileStore.getAllUserSubscriptionPolicies()
})
</script>

<template>
  <Modal style="z-index: 1003" :isOpen="loading || terminationSubscriptionAgreementLoading">
    <Loader />
  </Modal>
  <div class="bg-black personal-profile">
    <ProfileHero />
    <UserNameBonus />
    <ProfileServiceCards />
    <ProfileMyPolises />
    <ProfileMySubscriptionPolicies />
    <ProfileMyTerminatedPolicies />
  </div>
</template>

<style scoped lang="scss">
.personal-profile {
  @include max-responsive(lg) {
    background: url("/user-profile-front/images/mobile-gradient.png") no-repeat top;
    background-size: cover;
  }
}
</style>
