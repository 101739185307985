<script setup lang="ts">
import {useToast} from "vue-toastification"
import {useHelpers} from "@/composables/useHelpers"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {useRoute} from "vue-router"
import {vMaska} from "maska"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFCheckerInput from "@/components/FFUI/FFCheckerInput.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import AddPersonIcon from "@/components/icons/AddPersonIcon.vue"
import Skeleton from "primevue/skeleton"

const toast = useToast()
const {currentLocale} = useHelpers()
const {t} = useI18n()
const addDriverAutoStore = useAddDriverAutoStore()
const {
  vehicles,
  pushedPersons,
  persons,
  userByIIN,
  insurant,
  insureds,
  policyHolder,
  isDriverUnchanged,
  addDriverCheckboxDisabled,
  personsForCalculation,
  addDriverAutoByGlobalId,
  iin,
  isIinLoadingState,
  iinPassed,
  isDriverAutoSumCalculated,
  isIINExistsInInitialState,
  initialPersonsState,
  initialVehiclesState
} = storeToRefs(addDriverAutoStore)
const route = useRoute()

const addPerson = async (iinValue: string) => {
  isDriverAutoSumCalculated.value = false
  isIinLoadingState.value = false
  if (!iinValue || iinValue.length !== 12) {
    toast.clear()
    toast.error(t("app.toasterMessages.iinNumCheck.inputValidIIN"))
    return
  }
  if (vehicles.value.length > 1 && persons.value.length === 1) {
    iin.value = ""
    toast.clear()
    toast.error(t("app.toasterMessages.iinNumCheck.cantAddMoreIIN"))
    return
  }
  if (persons.value?.length >= 1 && vehicles.value.length === 0) {
    iin.value = ""
    toast.clear()
    toast.error(t("app.toasterMessages.iinNumCheck.cantAddMoreIINIfVehicleIsZero"))
    return
  }
  const response = await addDriverAutoStore.iinCheck(iinValue)
  if (response) {
    pushedPersons.value.push({
      iin: iinValue,
      fullName: userByIIN.value.client,
      class: userByIIN.value.class,
      isMain: false,
      isDriver: true,
      isPrivilege: false,
      isNew: isIINExistsInInitialState.value
    })
    iin.value = ""
  } else {
    return
  }
}

const deletePerson = (iinValue: string) => {
  isDriverAutoSumCalculated.value = false
  pushedPersons.value = pushedPersons.value.filter((person) => person.iin !== iinValue)

  if (addDriverAutoByGlobalId.value && addDriverAutoByGlobalId.value.insureds) {
    addDriverAutoByGlobalId.value.insureds = addDriverAutoByGlobalId.value.insureds.filter(
      (person) => person.iin !== iinValue
    )
  }
}
</script>
<template>
  <div class="add-driver">
    <p class="add-driver__insurant-title">{{ $t("app.addDriverAuto.insurant") }}</p>
    <hr />
    <div class="add-driver__insurant">
      <div class="add-driver__insurant--info">
        <div class="add-driver__insurant--info__data">
          <div class="add-driver__insurant__data--name">
            <Skeleton v-if="!addDriverAutoByGlobalId?.holder_fio" width="10rem" height="1.1rem" class="mb-1" />
            <p v-else>{{ addDriverAutoByGlobalId?.holder_fio }}</p>
            <p v-if="addDriverAutoByGlobalId && personsForCalculation[0].isPrivilege">
              {{ $t("app.addDriverAuto.beneficiary") }}
            </p>
          </div>
          <Skeleton v-if="!addDriverAutoByGlobalId?.holder_iin" width="10rem" height="1.1rem" />
          <p class="add-driver__insurant--info__data--iin">{{ addDriverAutoByGlobalId?.holder_iin }}</p>
        </div>
      </div>
    </div>
    <div class="add-driver__insurant-checkboxes">
      <template v-if="addDriverAutoByGlobalId !== undefined">
        <FFCheckbox
          :title="$t('app.addDriverAuto.perks')"
          font-weight="400"
          v-model="personsForCalculation[0].isPrivilege" />
        <FFCheckbox :title="$t('app.addDriverAuto.willDriveCar')" font-weight="400" v-model="policyHolder" />
      </template>
    </div>
    <p class="add-driver__insured-title">{{ $t("app.addDriverAuto.insured") }}</p>
    <hr />
    <div v-if="persons?.length !== 0" class="add-driver__persons">
      <div v-for="(person, index) in persons" :key="index">
        <div class="add-driver__person">
          <div class="add-driver__person--info">
            <div class="add-driver__person--info__data">
              <div class="add-driver__person--info__data--name">
                <p>{{ person.fullName }}</p>
                <p v-if="person.isPrivilege">{{ $t("app.addDriverAuto.beneficiary") }}</p>
              </div>
              <div class="add-driver__person--info__data--iin-class">
                <p>{{ person.iin }}</p>
                <p v-if="Boolean(person.class)">{{ person.class }} {{ $t("app.addDriverAuto.class") }}</p>
              </div>
            </div>
          </div>
          <img
            v-if="person.iin !== addDriverAutoByGlobalId?.holder_iin"
            @click="deletePerson(person.iin)"
            src="/auth-front/images/clear-input-big.svg"
            class="cursor-pointer"
            alt="icon" />
        </div>
        <FFCheckbox
          v-if="person.iin !== addDriverAutoByGlobalId?.holder_iin"
          :title="$t('app.addDriverAuto.perks')"
          font-weight="400"
          v-model="person.isPrivilege" />
      </div>
    </div>
    <div class="add-driver__add">
      <div class="add-driver__add--form">
        <FFFieldset :title="$t('app.addDriverAuto.iinLabel')">
          <FFInput
            v-maska
            data-maska="############"
            v-model="iin"
            id="iin"
            type="text"
            inputmode="numeric"
            placeholder="123412341234" />
        </FFFieldset>
        <button class="add-mobile" @click="addPerson(iin)" :disabled="!iinPassed">
          <AddPersonIcon />
        </button>
        <FFButton
          class="add-desktop"
          @click="addPerson(iin)"
          :disabled="!iinPassed"
          style="width: fit-content"
          :title="$t('app.buttons.add')"
          :uppercase="false"
          color="#4F9D3A"
          paddingx="12.5px"
          paddingy="20px"
          width="100%"
          background="#fff"
          border="5px" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.add-driver {
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  .add-driver__insurant-title {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 10px;
  }
  .add-driver__insurant {
    display: flex;
    align-items: center;
    margin: 30px 0;
    justify-content: space-between;
    .add-driver__insurant--info {
      display: flex;
      align-items: center;
      gap: 20px;
      .add-driver__insurant--info__data {
        display: flex;
        flex-direction: column;
        .add-driver__insurant__data--name {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          :nth-child(1) {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          :nth-child(2) {
            color: #fff;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 6px 8px;
            text-wrap: nowrap;
            border-radius: 10px;
            background: #383838;
          }
        }
        .add-driver__insurant--info__data--iin {
          color: rgba(255, 255, 255, 0.5);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .add-driver__insurant-checkboxes {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    gap: 10px;
  }
  .add-driver__insured-title {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 10px;
  }
  .add-driver__add {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    .add-driver__add--form {
      display: flex;
      align-items: flex-end;
      gap: 20px;
      .add-mobile {
        display: none;
        border-radius: 5px;
        border: 2px solid #fff;
        box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
        padding: 7.2px 8px;
      }
    }
  }
  .add-driver__persons {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 30px 0 10px;
    .add-driver__person {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      .add-driver__person--info {
        display: flex;
        align-items: center;
        gap: 20px;
        .add-driver__person--info__data {
          display: flex;
          flex-direction: column;
          .add-driver__person--info__data--name {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            :nth-child(1) {
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            :nth-child(2) {
              color: #fff;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 6px 8px;
              text-wrap: nowrap;
              border-radius: 10px;
              background: #383838;
            }
          }
          .add-driver__person--info__data--iin-class {
            display: flex;
            gap: 10px;
            p {
              color: rgba(255, 255, 255, 0.5);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .add-driver {
    padding: 30px 16px;
    .add-driver__add {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .add-driver__add--form {
        gap: 10px;
        .input-fieldset {
          input::placeholder {
            font-size: 15px;
          }
        }
        .add-desktop {
          display: none;
        }
        .add-mobile {
          display: block;
        }
      }
    }
    .add-driver__persons {
      .add-driver__person {
        flex-direction: row;
        gap: 10px;
        align-items: center;
        margin-bottom: 0;

        .add-driver--info {
          width: 100%;
          .add-driver--info__data {
            width: 100%;
            .add-driver--info__data--name {
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
</style>
