export const whatsAppOgpoMessageRu = "Здравствуйте!%20Обращаюсь%20к%20вам%20касательно%20продукта%20ОГПО."
export const whatsAppOgpoMessageKk = "Сәлеметсіз%20бе!%20Мен%20сізге%20МҚЖМ%20өнімі%20бойынша%20хабарласып%20тұрмын."
export const whatsAppOgpoMessageEn = "Hello!%20I%20am%20contacting%20you%20regarding%20the%20OGPO%20product."

export const whatsAppOgpoPlusMessageRu = "Здравствуйте!%20Обращаюсь%20к%20вам%20касательно%20продукта%20ОГПО+."
export const whatsAppOgpoPlusMessageKk = "Сәлеметсіз%20бе!%20Мен%20сізге%20МҚЖМ+%20өнімі%20бойынша%20хабарласып%20тұрмын."
export const whatsAppOgpoPlusMessageEn = "Hello!%20I%20am%20contacting%20you%20regarding%20the%20OGPO+%20product."

export const whatsAppKaskoMessageRu = "Здравствуйте!%20Обращаюсь%20к%20вам%20касательно%20продукта%20КАСКО."
export const whatsAppKaskoMessageKk = "Сәлеметсіз%20бе!%20Мен%20сізге%20КАСКО%20өнімі%20бойынша%20хабарласып%20тұрмын."
export const whatsAppKaskoMessageEn = "Hello!%20I%20am%20contacting%20you%20regarding%20the%20KASKO%20product."

export const whatsAppComfortMessageRu =
  "Здравствуйте!%20Обращаюсь%20к%20вам%20касательно%20продукта%20Freedom%20Comfort."
export const whatsAppComfortMessageKk =
  "Сәлеметсіз%20бе!%20Мен%20сізге%20Freedom%20Comfort%20өнімі%20бойынша%20хабарласып%20тұрмын."
export const whatsAppComfortMessageEn =
  "Hello!%20I%20am%20contacting%20you%20regarding%20the%Freedom%20Comfort%20product."

export const whatsAppAddDriverAutoMessageRu =
  "Здравствуйте!%20Обращаюсь%20к%20вам%20касательно%20переоформления%20моего%20полиса."
export const whatsAppAddDriverAutoMessageKk =
  "Сәлеметсіз%20бе!%20Мен%20сізге%20саясатымды%20жаңартуға%20байланысты%20хабарласып%20тұрмын."
export const whatsAppAddDriverAutoMessageEn =
  "Hello!%20I%20am%20contacting%20you%20regarding%20the%20renewal%20of%20my%20policy."

export const whatsAppTerminationMessageRu =
  "Здравствуйте!%20Обращаюсь%20к%20вам%20касательно%20расторжения%20моего%20полиса."
export const whatsAppTerminationMessageKk =
  "Сәлеметсіз%20бе!%20Мен%20сізге%20саясатымның%20тоқтатылуына%20байланысты%20хабарласып%20тұрмын."
export const whatsAppTerminationMessageEn =
  "Hello!%20I%20am%20contacting%20you%20regarding%20the%20termination%20of%20my%20policy."
