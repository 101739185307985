export const driverLicense: string = "DriverLicense"
export const identityCard: string = "IdentityCard"
export const foreignerResidencePermit: string = "ForeignerResidencePermit"

export const kaskoDocsRules = {
  kaskoExpressRu: "https://ffins.kz/report-file/214",
  kaskoExpressKk: "https://ffins.kz/kk/report-file/214",

  kaskoClassicRu: "https://ffins.kz/report-file/269",
  kaskoClassicKk: "https://ffins.kz/kk/report-file/269",

  kaskoVipRu: "https://ffins.kz/report-file/268",
  kaskoVipKk: "https://ffins.kz/kk/report-file/268"
}

export const homeProtectPulicOffer = "https://ffins.kz/en/report-file/270"

export const mstDocsRules = {
  mstDocRu: "https://ffins.kz/report-file/240",
  mstDocKk: "https://ffins.kz/report-file/240"
}

