<script setup lang="ts"></script>

<template>
  <section class="ogpo-plus-amortization">
    <div class="ogpo-plus-amortization__orange-block">
      {{ $t("app.ogpoPlusPage.amortization.usefulToKnow") }}
    </div>
    <p class="ogpo-plus-amortization__title">{{ $t("app.ogpoPlusPage.amortization.title") }}</p>
    <div class="ogpo-plus-amortization__descriptions">
      <p class="ogpo-plus-amortization__paragraph">{{ $t("app.ogpoPlusPage.amortization.paragraph-1") }}</p>
      <p class="ogpo-plus-amortization__paragraph">{{ $t("app.ogpoPlusPage.amortization.paragraph-2") }}</p>
      <p class="ogpo-plus-amortization__paragraph">{{ $t("app.ogpoPlusPage.amortization.paragraph-3") }}</p>
      <p class="ogpo-plus-amortization__paragraph">{{ $t("app.ogpoPlusPage.amortization.paragraph-4") }}</p>
    </div>
  </section>
</template>

<style scoped lang="scss">
.ogpo-plus-amortization {
  position: relative;
  z-index: 1;
  padding: 50px 30px;
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.4);
  backdrop-filter: blur(8px);
  margin-bottom: 200px;

  @media (max-width: $lg) {
    margin-bottom: 100px;
  }

  .ogpo-plus-amortization__orange-block {
    display: inline;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #f2994a;
    color: #ffffff;
  }
  .ogpo-plus-amortization__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40.4px;
    background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 30px 0 35px;

    @media (max-width: $lg) {
      font-size: 30px;
      line-height: normal;
    }
  }
  .ogpo-plus-amortization__descriptions {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
    .ogpo-plus-amortization__paragraph {
      color: #a9a9a9;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
