<script setup></script>

<template>
  <div class="mst-assistance">
    <div class="flex justify-center md-max:justify-start">
      <svg fill="none" height="115" viewBox="0 0 115 115" width="115" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_25765_36343)" opacity="0.2">
          <path
            d="M49.9541 3.12656C54.1228 -1.04219 60.8791 -1.04219 65.0479 3.12656L111.874 49.9603C116.043 54.1291 116.043 60.8781 111.874 65.0397L65.0479 111.873C60.8791 116.042 54.13 116.042 49.9685 111.873L3.12754 65.0469C2.13612 64.0575 1.34957 62.8823 0.812905 61.5885C0.276239 60.2947 0 58.9078 0 57.5072C0 56.1065 0.276239 54.7196 0.812905 53.4259C1.34957 52.1321 2.13612 50.9569 3.12754 49.9675L49.9541 3.12656ZM60.0166 8.15781C59.3494 7.49067 58.4445 7.11588 57.501 7.11588C56.5575 7.11588 55.6526 7.49067 54.9854 8.15781L8.15161 54.9844C7.48447 55.6516 7.10968 56.5565 7.10968 57.5C7.10968 58.4435 7.48447 59.3484 8.15161 60.0156L54.9854 106.849C55.6526 107.517 56.5575 107.891 57.501 107.891C58.4445 107.891 59.3494 107.517 60.0166 106.849L106.85 60.0156C107.517 59.3484 107.892 58.4435 107.892 57.5C107.892 56.5565 107.517 55.6516 106.85 54.9844L60.0166 8.15062V8.15781Z"
            fill="white" />
          <path
            d="M50.3281 79.0626C50.3281 78.1187 50.514 77.184 50.8752 76.312C51.2364 75.44 51.7659 74.6476 52.4333 73.9802C53.1007 73.3128 53.8931 72.7834 54.7651 72.4222C55.6371 72.061 56.5717 71.8751 57.5156 71.8751C58.4595 71.8751 59.3941 72.061 60.2662 72.4222C61.1382 72.7834 61.9305 73.3128 62.598 73.9802C63.2654 74.6476 63.7948 75.44 64.156 76.312C64.5172 77.184 64.7031 78.1187 64.7031 79.0626C64.7031 80.9688 63.9459 82.797 62.598 84.1449C61.25 85.4928 59.4219 86.2501 57.5156 86.2501C55.6094 86.2501 53.7812 85.4928 52.4333 84.1449C51.0854 82.797 50.3281 80.9688 50.3281 79.0626ZM51.0325 35.9016C50.9368 34.9948 51.0328 34.0781 51.3143 33.2108C51.5957 32.3435 52.0564 31.5451 52.6664 30.8674C53.2763 30.1897 54.022 29.6477 54.8549 29.2768C55.6878 28.9058 56.5894 28.7141 57.5013 28.7141C58.4131 28.7141 59.3147 28.9058 60.1476 29.2768C60.9805 29.6477 61.7262 30.1897 62.3361 30.8674C62.9461 31.5451 63.4068 32.3435 63.6882 33.2108C63.9697 34.0781 64.0657 34.9948 63.97 35.9016L61.4544 61.1082C61.3699 62.0984 60.9168 63.0209 60.1847 63.6931C59.4527 64.3653 58.4951 64.7382 57.5013 64.7382C56.5074 64.7382 55.5498 64.3653 54.8178 63.6931C54.0857 63.0209 53.6326 62.0984 53.5481 61.1082L51.0325 35.9016Z"
            fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_25765_36343">
            <rect fill="white" height="115" width="115" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="mst-assistance-title">{{ $t('app.mstPage.contact.title') }}</div>
    <div class="mst-assistance-info">
      <p>«FIRST ASSIST MEDIKAL LTD. ŞTI.»</p>
      <p>{{ $t('app.mstPage.contact.telephone_list') }}</p>
      <p>{{ $t('app.mstPage.contact.telephone') }} <a href="tel:+902423104444">+902423104444</a></p>
      <p>Whatsapp: <a href="whatsapp://send?phone=+905445068088">+905445068088</a></p>
      <p>Telegram: <a href="https://t.me/firstassistance">firstassistance</a></p>
      <p>Email: <a href="mailto:info@firstassist.com.tr">info@firstassist.com.tr</a></p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mst-assistance {
  display: block;
  border-radius: 20px;
  background: #1c1c1c;
  padding: 40px 30px;
  height: fit-content;
}

.mst-assistance-title {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 40px 0;
}

.mst-assistance-info {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  a {
    text-decoration: underline;
  }
}

@media (max-width: $lg) {
  .mst-assistance-title {
    font-size: 30px;
    font-weight: 900;
  }

  .mst-assistance-info p, a {
    font-weight: 300;
  }
}
</style>
