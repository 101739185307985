<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"

const {currentLocale} = useHelpers()
</script>

<template>
  <section class="ogpo-juridical-statistics container">
    <p class="ogpo-juridical-statistics__title">{{ $t("app.ogpoPage.statistics.mainTitle") }}</p>
    <div class="ogpo-juridical-statistics__cards">
      <div class="ogpo-juridical-statistics__card">
        <p>{{ $t("app.ogpoJuridicalPage.statistics.title-1") }}</p>
        <p>202 307</p>
        <p>{{ $t("app.ogpoJuridicalPage.statistics.subtitle-1") }}</p>
      </div>
      <div class="ogpo-juridical-statistics__card">
        <p>{{ $t("app.ogpoJuridicalPage.statistics.title-2") }}</p>
        <p>28 446</p>
        <p>{{ $t("app.ogpoJuridicalPage.statistics.subtitle-2") }}</p>
      </div>
      <div class="ogpo-juridical-statistics__card">
        <p>{{ $t("app.ogpoJuridicalPage.statistics.title-3") }}</p>
        <div>
          <p>6,5</p>
          <div class="mlrd">
            <template v-if="currentLocale === 'en'">
              <p>{{ $t("app.ogpoJuridicalPage.statistics.billion") }}</p>
              <p>{{ $t("app.ogpoJuridicalPage.statistics.tenge") }}</p>
            </template>
            <template v-else>
              <p>млрд</p>
              <p>тг.</p>
            </template>
          </div>
        </div>
        <p>{{ $t("app.ogpoPage.statistics.subtitle-3") }}</p>
      </div>
    </div>
  </section>
  <img
    src="/ogpo-juridical-front/images/megapolis-car-roads.png"
    alt=""
    class="ogpo-juridical__megapolis-car-roads-img" />
</template>

<style scoped lang="scss">
.ogpo-juridical-statistics {
  position: relative;
  background: rgba(74, 74, 74, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border-radius: 30px;
  margin-bottom: 588px;

  @include min-responsive(xxlWide) {
    padding: 60px 0;
  }

  @include max-responsive(xxlWide) {
    padding: 60px 0;
  }

  @include max-responsive(xxl) {
    padding: 50px 30px;
  }

  @include max-responsive(xl) {
    padding: 50px 0 40px;
  }

  @include max-responsive(lg) {
    padding: 50px 30px;
    margin-bottom: 511px;
  }

  @include max-responsive(md) {
    padding: 30px 5px;
  }

  .ogpo-juridical-statistics__title {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @include main-green-gradient;

    @include max-responsive(lg) {
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }

  .ogpo-juridical-statistics__cards {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @include max-responsive(xl) {
      @include column;
    }

    .ogpo-juridical-statistics__card {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 330px;

      @include max-responsive(xl) {
        width: 100%;
      }

      &:nth-child(1) {
        @include max-responsive(xl) {
          padding-bottom: 30px;
        }
      }

      &:nth-child(2) {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        padding: 0 30px;

        @include max-responsive(xl) {
          border-right: 0;
          border-left: 0;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          padding: 30px 0 30px;
        }
      }

      &:last-of-type {
        justify-content: start;

        @include max-responsive(xl) {
          padding-top: 30px;
        }
      }
      p:first-of-type {
        color: #ffffff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
      }
      p:nth-child(2) {
        text-align: center;
        font-size: 50px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        @include main-green-gradient;

        height: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p:last-of-type {
        color: #d6d6d6;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        height: fit-content;

        @include max-responsive(lg) {
          font-weight: 500;
        }
      }
      div {
        display: flex;
        align-items: center;
        margin: 0 auto;
        p:first-of-type {
          text-align: center;
          font-size: 70px !important;
          font-style: normal;
          font-weight: 900 !important;
          line-height: normal;
          @include main-green-gradient;
        }

        .mlrd {
          display: flex;
          flex-direction: column;
          p {
            font-size: 30px !important;
            font-style: normal;
            font-weight: 900 !important;
            line-height: normal;
            text-transform: lowercase;
          }
        }
      }
    }
  }
}

.ogpo-juridical__megapolis-car-roads-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 2271px;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: auto;
  object-fit: cover;

  @include max-responsive(lg) {
    top: 3308px;
  }
}
</style>
