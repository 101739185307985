<script setup lang="ts">
import {ref, onMounted, watch} from "vue"
import {useRouter} from "vue-router"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {type IGroups} from "@/interfaces/Documents/IDocument"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"

const router = useRouter()
const {getData, loading} = useGatewayFetcher()
const {currentLocale, openLink} = useHelpers()
const data = ref<IGroups>()

const emit = defineEmits(["update:loading"])

const fetchDocuments = async () => {
  const config = {
    headers: {
      "Accept-Language": currentLocale.value
    }
  }
  const response = await getData("/company/documents", config)
  if (response) {
    data.value = response
  }
}

watch(loading, (newLoadingState) => {
  emit("update:loading", newLoadingState)
})

fetchDocuments()
</script>

<template>
  <section class="documents-list container">
    <div v-if="data?.groups.length !== 0" class="documents-list__content">
      <Accordion :multiple="false" class="documents-list__accordion">
        <AccordionTab
          v-for="(document, index) in data?.groups"
          :key="index"
          :header="document.title"
          headerClass="documents-list__content-accordion-tab">
          <template v-if="document.title !== 'Правила'">
            <ul class="documents-list__content-accordion-list">
              <li v-for="(doc, docIndex) in document.documents" :key="docIndex">
                <a :href="doc.file" target="_blank">
                  <span>
                    {{ doc.title }}
                  </span>
                  <span> {{ doc.reportDate }} </span>
                </a>
              </li>
            </ul>
          </template>

          <template v-else>
            <Accordion :multiple="false" class="documents-list__nested-accordion">
              <AccordionTab
                v-for="(category, categoryIndex) in document.types"
                :key="categoryIndex"
                headerClass="documents-list__content-nested-accordion-tab">
                <template #header>
                  <span class="p-accordion-header-text">{{ category.title }}</span>
                  <img :src="category.icon" alt="icon" />
                </template>
                <ul class="documents-list__content-nested-accordion-list">
                  <li v-for="(doc, docIndex) in category.documents" :key="docIndex">
                    <a :href="doc.file" target="_blank">
                      <span>{{ doc.title }}</span> <span> {{ doc.reportDate }} </span>
                    </a>
                  </li>
                </ul>
              </AccordionTab>
              <AccordionTab headerClass="documents-list__not-active-content-nested-accordion-tab">
                <template #header>
                  <span class="documents-list__not-active-content-nested-accordion-header-text">{{
                    $t("app.documentsPage.rulesBecomeInvalid")
                  }}</span>
                  <img src="https://ffins.kz//img/report-rule-types/7-overdue.svg" alt="icon" />
                </template>
                <ul class="documents-list__not-active-content-nested-accordion-list">
                  <li v-for="(doc, docIndex) in document.documents" :key="docIndex">
                    <a
                      :href="doc.file"
                      target="_blank"
                      class="documents-list__not-active-content-nested-accordion-link">
                      <div class="documents-list__not-active-content-nested-accordion-names">
                        <span>{{ doc.title }}</span>
                        <span>{{ $t("app.documentsPage.productDeactivated") }} {{ doc.endedDate }}</span>
                      </div>
                      <span> {{ doc.reportDate }} </span>
                    </a>
                  </li>
                </ul>
              </AccordionTab>
            </Accordion>
          </template>
        </AccordionTab>
      </Accordion>
      <div
        v-if="data?.groups.length !== 0"
        @click="router.push('/pages/polodgenie-ob-obrabotke-personalnykh-dannykh')"
        class="documents-list__regulation-processing-of-personal-data">
        <p>{{ $t("app.documentsPage.regulationProcessingOfPersonalData") }}</p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.documents-list {
  @include column;
  .documents-list__content {
    padding: 50px 0;
    .documents-list__accordion {
      @include column;
      gap: 16px;
      margin-bottom: 16px;
      ::v-deep(.documents-list__content-accordion-tab a) {
        background: transparent;
        color: var(--color-white);
        font-family: inherit;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;

        .p-icon {
          width: 22px;
          height: 22px;
        }
      }
      .documents-list__content-accordion-list {
        margin-top: 16px;
      }
      .documents-list__content-accordion-list li {
        width: 100%;
        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
        a {
          @include between;
        }
      }
      ::v-deep(.p-toggleable-content .p-accordion-content) {
        background: transparent;
        color: var(--color-white);
        margin-left: 20px;
      }
    }
    .documents-list__nested-accordion {
      margin-top: 16px 0;
      ::v-deep(.documents-list__content-nested-accordion-tab) {
        margin-top: 16px;
        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
      }
      ::v-deep(.documents-list__content-nested-accordion-tab) {
        .p-accordion-header-text {
          margin: 0 auto 0 5px;
        }
      }
      ::v-deep(.documents-list__content-nested-accordion-tab a) {
        background: transparent;
        color: var(--color-white);
        font-family: inherit;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;

        .p-accordion-header-text {
          color: #4f9d3a;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: none;
        }
        .p-icon {
          width: 22px;
          height: 22px;
          path {
            fill: #4f9d3a;
          }
        }
      }
      ::v-deep(.documents-list__not-active-content-nested-accordion-tab) {
        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
      }
      ::v-deep(.documents-list__not-active-content-nested-accordion-tab a) {
        background: transparent;
        color: var(--color-white);
        font-family: inherit;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;

        .p-accordion-header-text {
          color: #4f9d3a;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: none;
        }
        .p-icon {
          width: 22px;
          height: 22px;
          path {
            fill: #4f9d3a;
          }
        }
      }
      ::v-deep(.documents-list__not-active-content-nested-accordion-tab .p-accordion-header-link .p-icon path) {
        fill: #848484;
      }
      .documents-list__not-active-content-nested-accordion-link {
        @include between;
        margin-bottom: 16px;
      }
      .documents-list__not-active-content-nested-accordion-names {
        @include column;
        span:first-child {
          margin-bottom: 3px;
        }
        span:last-child {
          color: #515151;
        }
      }
      .documents-list__not-active-content-nested-accordion-header-text {
        color: #848484;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: none;
        margin: 0 auto 0 5px;
      }
      ::v-deep(.p-accordion-tab) {
        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
      }
      ::v-deep(.p-toggleable-content .p-accordion-content) {
        margin-left: 20px;
      }
      ::v-deep(.p-toggleable-content .p-accordion-content .documents-list__content-nested-accordion-list li) {
        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
        a {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .documents-list__regulation-processing-of-personal-data {
      p {
        background: transparent;
        color: var(--color-white);
        font-family: inherit;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
}
</style>
