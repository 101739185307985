<script setup lang="ts">
import {ref} from "vue"
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import type {IProductCard} from "@/interfaces/Main/IProductCard"
import {comfortUrl, kaskoUrl} from "@/constants/urls"

const router = useRouter()
const {openLink} = useHelpers()

interface Props {
  data: IProductCard[]
}

const props = defineProps<Props>()
</script>

<template>
  <div>
    <div class="cards container">
      <template v-for="(i, index) in props.data" :key="index">
        <div
          class="cards__card"
          :class="{
            'backdrop-filter': i?.backdropFilter,
            'set-border': i?.backdropFilter
          }"
          :style="{background: i?.background}"
          @click="router.push(i.to)">
          <div class="flex flex-col">
            <p v-if="i.title" class="cards__card-title" :class="{'gradient-text': i?.gradientTitle}">
              {{ $t(i.title) }}
            </p>
            <p v-if="i.subTitle" class="cards__card-subtitle">{{ $t(i.subTitle) }}</p>
            <ul v-if="i.list?.length !== 0" class="cards__card-list">
              <li v-for="(item, index) in i.list" :key="index">
                {{ $t(item) }}
              </li>
            </ul>
          </div>
          <img v-show="i.image" :src="i.image" alt="card" />
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cards {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  position: relative;
  z-index: 0;

  @include max-responsive(xxl) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
  }

  @include max-responsive(xl) {
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    z-index: 1;
  }

  @include max-responsive(lg) {
    grid-template-columns: repeat(1, 1fr);
  }

  .skeleton-card {
    width: 270px;
    height: 118px;
    border-radius: 10px;

    @include max-responsive(xxl) {
      width: 225px;
    }

    @include max-responsive(xl) {
      width: 165px;
    }

    @include max-responsive(lg) {
      width: 166.5px;
    }

    @include max-responsive(md) {
      width: 134px;
    }
  }
  .cards__card {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    max-height: 118px;
    cursor: pointer;
    .cards__card-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      color: var(--color-white);
      width: fit-content;
      max-width: 420px;
      z-index: 10;

      @include max-responsive(md) {
        word-break: break-all;
      }
    }
    .cards__card-subtitle {
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      padding-left: 20px;
      color: var(--color-white);
      z-index: 10;
      max-width: 280px;
      margin-top: 8px;
      opacity: 0.6;
    }
    .cards__card-list {
      padding-left: 20px;
      li {
        position: relative;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        color: var(--color-white);
        padding-left: 15px;
        z-index: 10;
        max-width: 280px;
        margin-top: 8px;
        opacity: 0.6;

        &::before {
          content: "•";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
    img {
      object-fit: cover;
      margin-left: -115px;
    }
  }
  .cards__card:hover {
    box-shadow: 0 0 5px var(--color-white);
  }
  .backdrop-filter {
    backdrop-filter: blur(8px);
  }
  .set-border {
    border: 1px solid #3f4c3b;
  }
  .gradient-text {
    background: var(--FF-gradient, linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
