<script setup lang="ts">
import {ref, onUnmounted} from "vue"
import {useI18n} from "vue-i18n"
import FFButton from "@/components/FFUI/FFButton.vue"
// import VOtpInput from "vue3-otp-input"
import FFVue3OtpInput from "@/components/FFUI/FFOtpInput/FFVue3OtpInput.vue"
import CloseIcon from "@/components/icons/CloseIcon.vue"

interface IProps {
  codeIsSent: boolean
  shouldAutoFocus?: boolean
  shouldFocusOrder?: boolean
  conditionalClass?: string[]
  numInputs?: number
  placeholder?: string[]
  buttonTitle?: string
  smsText?: string
  needWrapper?: boolean
  needCloseIcon?: boolean
  confirmSmsText?: string
  needSubText?: boolean
  subText?: string

  sendOtp: () => void
  checkOtp: (code: string) => void
  closeOtp?: () => void
  handleSubTextFunc?: () => void
}

const {t} = useI18n()

const props = withDefaults(defineProps<IProps>(), {
  shouldAutoFocus: true,
  shouldFocusOrder: true,
  conditionalClass: () => ["one", "two", "three", "four"],
  numInputs: 4,
  placeholder: () => ["", "", "", ""],
  needWrapper: true,
  needCloseIcon: true,
  confirmSmsText: "app.auth.confirmSms"
})

const otpInput = ref<InstanceType<typeof FFVue3OtpInput> | null>(null)
let inputValue = defineModel<string>()
const bindValue = ref("")

const clearInput = () => {
  otpInput.value?.clearInput()
}

onUnmounted(() => {
  clearInput()
})
</script>

<template>
  <div v-if="needWrapper" class="otp-content">
    <div class="otp">
      <CloseIcon v-if="needCloseIcon" @click="closeOtp" />
      <p>{{ $t(confirmSmsText) }}</p>
      <FFVue3OtpInput
        v-if="codeIsSent"
        ref="otpInput"
        input-classes="otp-input"
        :conditionalClass="conditionalClass"
        separator=""
        inputType="number"
        inputmode="numeric"
        :num-inputs="numInputs"
        v-model:value="inputValue"
        :should-auto-focus="shouldAutoFocus"
        :should-focus-order="shouldFocusOrder"
        :placeholder="placeholder" />

      <div class="otp__buttons">
        <FFButton
          :title="buttonTitle"
          @click="sendOtp()"
          :disabled="codeIsSent"
          :uppercase="false"
          maxWidthMobile="100%" />
        <FFButton
          :title="$t('app.buttons.send')"
          @click="() => checkOtp(inputValue)"
          :disabled="!codeIsSent || (codeIsSent && inputValue?.length !== 4)"
          :uppercase="false"
          maxWidthMobile="100%" />
      </div>

      <template v-if="needSubText">
        <p @click="handleSubTextFunc" class="otp__subtext">{{ subText }}</p>
      </template>
    </div>
  </div>
  <template v-else>
    <p class="otp-confirmSms-title">{{ $t(confirmSmsText) }}</p>
    <FFVue3OtpInput
      v-if="codeIsSent"
      ref="otpInput"
      input-classes="otp-input"
      :conditionalClass="conditionalClass"
      separator=""
      inputType="number"
      inputmode="numeric"
      :num-inputs="numInputs"
      v-model:value="inputValue"
      :should-auto-focus="shouldAutoFocus"
      :should-focus-order="shouldFocusOrder"
      :placeholder="placeholder" />

    <div class="otp__buttons">
      <FFButton :title="buttonTitle" @click="sendOtp" :disabled="codeIsSent" :uppercase="false" maxWidthMobile="100%" />
      <FFButton
        :title="$t('app.buttons.send')"
        @click="() => checkOtp(inputValue)"
        :disabled="!codeIsSent || (codeIsSent && inputValue?.length !== 4)"
        :uppercase="false"
        maxWidthMobile="100%" />
    </div>
  </template>
</template>

<style scoped lang="scss">
.otp-content {
  margin: 0 16px;
  max-width: 408px;
  .otp {
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 10px;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    width: 100%;
    max-width: 400px;
    svg {
      position: absolute;
      top: 13px;
      right: 13px;
      cursor: pointer;
    }
    .otp-input-container {
      gap: 10px;
    }
    p {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      width: 100%;
    }
    .otp__buttons {
      display: flex;
      gap: 13px;
      width: 100%;
    }
    .otp__subtext {
      color: var(--color-white);
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      width: fit-content;
      margin-top: 20px;
      border-bottom: 1px solid var(--color-white);
      cursor: pointer;
    }
  }
}

.otp-confirmSms-title {
  color: var(--color-white);
  font-size: 18px;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  width: 100%;
}
.otp-input-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
.otp__buttons {
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;
  margin-top: 20px;
}

@media (max-width: $lg) {
  .otp-content {
    max-width: 400px;
    .otp {
      .otp__buttons {
        @include column;
        width: 100%;
      }
    }
  }
  .otp__buttons {
    @include column;
  }
}
</style>
