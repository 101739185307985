import {passwordRegex} from "@/constants/regex"
import zod from "zod"

export const iinAndPhoneSchema = zod.object({
  resetIin: zod.string().nonempty().length(12),
  resetPhoneNumber: zod
    .string()
    .nonempty()
    .regex(/^\+7 \(\d{3}\) \d{3} \d{4}$/)
})

export const passwordsSchema = zod
  .object({
    email: zod.string().email().optional().or(zod.literal("")),
    password: zod.string().nonempty().regex(passwordRegex),
    confirmPassword: zod.string().nonempty().min(8)
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"]
  })
