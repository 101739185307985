<script setup lang="ts">
import {ref, computed, watch} from "vue"
import zod from "zod"
import {vMaska} from "maska"
import {useToast} from "vue-toastification"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useKaskoStore} from "@/stores/KaskoStore"
import {useValidation} from "@/composables/useValidation"
import {useRules} from "@/utils/rules"
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import {filterEmailInput} from "@/utils/inputEvents"
import {kaskoExecutionFormSchema} from "@/schemas"
import {kaskoDocsRules} from "@/constants/documents"
import VueDatePicker from "@vuepic/vue-datepicker"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFRadiobutton from "@/components/FFUI/FFRadiobutton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"
import ProductBankCashbackAmount from "@/components/shared/ProductBankCashbackAmount.vue"

const toast = useToast()
const {t} = useI18n()
const kaskoStore = useKaskoStore()
const {
  currentStep,
  kaskoTariffs,
  currentTariffName,
  currentTariff,
  executionForm,
  amountOfCoverage,
  payMonthly,
  cashbackData,
  clientCardData,
  clientCardResponse,
  selectedCardType
} = storeToRefs(kaskoStore)
const router = useRouter()
const {currentLocale, openLink} = useHelpers()
const {thousandSeparator, formatCardHash} = useFormatters()

const emit = defineEmits(["nextStep"])

const currentCardTypeVariants = ref<number[]>([1, 2])
const currentCardTypeVariantTitles = ref<any>([
  {
    title: computed(
      () => `${t("app.kaskoPage.forms.execution.card")} ${formatCardHash(clientCardData.value?.cardHash)}`
    )
  },
  {
    title: computed(() => t("app.kaskoPage.forms.execution.anotherCard"))
  }
])

const {isValid, getError, validate} = useValidation(kaskoExecutionFormSchema, executionForm.value, {
  mode: "lazy"
})

const handleNextStep = async () => {
  try {
    await validate()
    if (!executionForm.value.email && !executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTermsAndEmail"))
      return
    }
    if (!executionForm.value.email && executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTermsEmail"))
      return
    }
    if (executionForm.value.email && !executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTerms"))
      return
    }
    if (isValid.value) {
      if (payMonthly.value) {
        kaskoStore.subscriptionFinalPay()
      } else {
        currentStep.value = 4
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const handleCurrentPaymentCardType = (type: number) => {
  selectedCardType.value = type
}

const kaskoRulesLink = computed(() => {
  if (currentTariff.value.kasko_type === "KASKO_EXPRESS") {
    console.log(currentLocale.value)
    return currentLocale.value === "kk" ? kaskoDocsRules.kaskoExpressKk : kaskoDocsRules.kaskoExpressRu
  } else if (currentTariff.value.kasko_type === "KASKO_CLASSIC") {
    console.log(currentLocale.value)
    return currentLocale.value === "kk" ? kaskoDocsRules.kaskoClassicKk : kaskoDocsRules.kaskoClassicRu
  } else {
    console.log(currentLocale.value)
    return currentLocale.value === "kk" ? kaskoDocsRules.kaskoVipKk : kaskoDocsRules.kaskoVipRu
  }
})

const currentTariffTypeForQuestionnaire = computed(() => {
  if (currentTariff.value.kasko_type === "KASKO_EXPRESS") {
    return "express"
  } else if (currentTariff.value.kasko_type === "KASKO_CLASSIC") {
    return "classic"
  } else {
    return "vip"
  }
})
</script>

<template>
  <div class="exec-form">
    <template v-if="payMonthly && clientCardResponse?.length !== 0">
      <div class="exec-form__payment-type-wrapper">
        <div
          @click="handleCurrentPaymentCardType(variant)"
          v-for="(variant, index) in currentCardTypeVariants"
          :key="index"
          class="payment-method"
          :class="{'payment-method--selected': selectedCardType === variant}">
          <FFRadiobutton id="payment-type" v-model="selectedCardType" name="payment" :value="variant" />
          <p>{{ currentCardTypeVariantTitles[variant - 1].title }}</p>
        </div>
      </div>
      <hr />
    </template>
    <template v-if="currentTariffTypeForQuestionnaire !== 'express'">
      <div>
        <FFFieldset :title="$t('form.inputTitles.inspectionDate')">
          <VueDatePicker
            :enable-time-picker="false"
            :teleport="true"
            auto-apply
            v-model="executionForm.inspectionDate"
            format="dd.MM.yyyy"
            :locale="currentLocale"
            :start-date="new Date()"
            :min-date="new Date()"
            :max-date="new Date(new Date().setDate(new Date().getDate() + 3))">
            <template #input-icon>
              <img class="date-time-picker-icon" src="/declare-insured-event/images/calendar-input.svg" alt="icon" />
            </template>
            <template #clear-icon> </template>
          </VueDatePicker>
        </FFFieldset>
      </div>
      <p class="exec-form__policy-activates-text">{{ $t("app.kaskoPage.forms.execution.policyActivatesNextDay") }}</p>
    </template>
    <div>
      <FFFieldset :isError="!!getError('email')" :title="$t('form.inputTitles.email')">
        <FFInput
          id="email"
          name="email"
          type="email"
          v-model="executionForm.email"
          :placeholder="t('app.ogpoPage.forms.willSendPolisOnEmail')"
          inputmode="email"
          :required="true"
          @input="filterEmailInput" />
      </FFFieldset>
      <template v-if="!!getError('email')">
        <FFErrorMessage
          v-if="executionForm.email.length === 0"
          :darkMode="true"
          :errorMessage="t('form.validationErrors.emailFieldIsRequired')" />
        <FFErrorMessage
          v-if="executionForm.email.length > 0 && getError('email')"
          :darkMode="true"
          :errorMessage="t('form.validationErrors.emailIsNotValid')" />
      </template>
    </div>
    <FFCheckbox v-if="currentLocale === 'kk'" v-model="executionForm.iAgreeWithAgreement">
      <template #title>
        {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
        <span
          @click="() => kaskoStore.openKaskoQuestionnairePdf(currentTariffTypeForQuestionnaire, currentTariff.calcId)"
          class="underline cursor-pointer">
          {{ $t("form.checkboxTitles.iAgree.iAgree-2") }}</span
        >
        {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
        <span @click="openLink(kaskoRulesLink, '_blank')" class="underline cursor-pointer">
          {{ $t("form.checkboxTitles.iAgree.iAgree-4") }}
        </span>
        {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}
      </template>
    </FFCheckbox>
    <FFCheckbox v-else v-model="executionForm.iAgreeWithAgreement">
      <template #title>
        {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
        <span
          @click="() => kaskoStore.openKaskoQuestionnairePdf(currentTariffTypeForQuestionnaire, currentTariff.calcId)"
          class="underline cursor-pointer">
          {{ $t("form.checkboxTitles.iAgree.iAgree-2") }}</span
        >
        {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
        <span @click="openLink(kaskoRulesLink, '_blank')" class="underline cursor-pointer"
          >{{ $t("form.checkboxTitles.iAgree.iAgree-4") }} {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}</span
        >
      </template>
    </FFCheckbox>
  </div>
  <div class="relative">
    <div class="exex-form__dark-bg"></div>
    <div class="exec-form__payment-sum-wrapper">
      <div class="exec-form__payment-sum-info">
        <div class="exec-form__payment-sum-info-row">
          <p>{{ $t("app.kaskoPage.forms.execution.tariff") }}:</p>
          <p class="capitalize">
            {{ $t("app.kaskoPage.kasko") }}
            {{
              currentTariff.kasko_type === "KASKO_VIP"
                ? "VIP"
                : currentTariff.kasko_type === "KASKO_CLASSIC"
                  ? "CLASSIC"
                  : "EXPRESS"
            }}
          </p>
        </div>
        <div class="exec-form__payment-sum-info-row">
          <p>{{ $t("app.kaskoPage.forms.execution.cost") }}:</p>
          <p>
            {{
              payMonthly
                ? thousandSeparator(currentTariff.subscriptionPremium)
                : thousandSeparator(currentTariff.premium)
            }}
            {{ payMonthly ? $t("form.formAbbreviations.inMonth") : $t("form.formAbbreviations.tg") }}
          </p>
        </div>
      </div>
      <hr class="exec-form__payment-sum-line" />
      <div class="exec-form__payment-sum-to-pay">
        <p>{{ $t("app.ogpoPage.forms.toPay") }}</p>
        <p>
          {{
            payMonthly ? thousandSeparator(currentTariff.subscriptionPremium) : thousandSeparator(currentTariff.premium)
          }}
          <span class="tenge">
            {{ payMonthly ? $t("form.formAbbreviations.inMonth") : $t("form.formAbbreviations.tg") }}</span
          >
        </p>
      </div>
    </div>
    <p v-if="payMonthly" class="exec-form__monthly-payment-info">
      {{ $t("app.kaskoPage.forms.execution.monthlyPaymentInfo") }}
    </p>
    <ProductBankCashbackAmount
      productType="Kasko"
      :isLoyaltyCalled="cashbackData?.isLoyaltyCalled"
      :isClientBank="cashbackData?.isClientBank"
      :cashbackPrice="cashbackData?.cashbackUpToAmount"
      :cashbackPercent="cashbackData?.isClientBank ? cashbackData?.cashbackUpToAmount : cashbackData?.cashbackPercent"
      :currentStep="currentStep"
      marginTop="20px" />
    <div class="exec-form__pay-button">
      <FFButton @click="handleNextStep" :title="$t('app.buttons.pay')" :uppercase="false" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.exec-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 24px 0 20px;

  ::v-deep(.dp__input_icon) {
    inset-inline-start: unset;
    right: 6px;
    top: 45%;
  }
  ::v-deep(.ff-form .dp__input_icon) {
    inset-inline-start: unset;
    right: 0px;
    top: 45%;
  }
  ::v-deep(.date-time-picker-icon) {
    width: 27px;
    height: 27px;
  }
  ::v-deep(.dp__input_icon_pad) {
    padding-inline-start: unset;
    padding-left: 15px;
    font-size: 16px !important;
  }
  ::v-deep(.dp__pointer .db__input_readonly .dp__input_icon_pad) {
    padding-inline-start: unset !important;
  }

  .exec-form__payment-type-wrapper {
    @include column;
    gap: 8px;
    .payment-method {
      @include flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      background: rgba(226, 226, 226, 0.17);
      border: 1px solid transparent;
      cursor: pointer;

      &--selected {
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: rgba(0, 0, 0, 0.25);
      }

      p {
        color: var(--color-white);
        text-align: start;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .exec-form__policy-activates-text {
    color: var(--color-white);
    font-size: 13px;
    font-weight: 500;
    margin-top: -15px;
  }
}
.exec-form__payment-sum-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  .exec-form__payment-sum-info {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    .exec-form__payment-sum-info-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
      p {
        color: #ffffff;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }

  .exec-form__payment-sum-line {
    margin: 10px 0;
  }

  .exec-form__payment-sum-to-pay {
    display: flex;
    justify-content: space-between;

    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}

.exec-form__monthly-payment-info {
  color: var(--color-white);
  opacity: 0.5;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 4px;
}

hr {
  z-index: 1;
  position: relative;
  border-color: white;
}
.exec-form__pay-button {
  position: relative;
  z-index: 1;
  padding-top: 20px;
}

.exex-form__dark-bg {
  background-color: rgba(0, 0, 0, 0.5);
  height: calc(100% + 80px);
  width: calc(100% + 60px);
  position: absolute;
  top: 0;
  left: -10%;
  right: 0;
  bottom: 0;
}

.tenge {
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: lowercase;
}
</style>
