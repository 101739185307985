import {ref} from "vue"
import {defineStore} from "pinia"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"

interface IVmsData {
  global_id: string
  product_type: string
  from_date: string
  till_date: string
  holder_fio: string
  insureds: Array<{
    fullName: string
  }>
  status: string
}

export const useVmsStore = defineStore("vmsStore", () => {
  const {loading, postData} = useGatewayFetcher()
  const {currentLocale} = useHelpers()

  const vmsData = ref<IVmsData | null>(null)

  const getMigrantPolicy = async (hash: string | unknown) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/get-migrant-policy",
        {
          hash
        },
        config
      )
      if(response && response.status === 200) {
        vmsData.value = response?.data?.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    vmsData,
    getMigrantPolicy
  }
})
