<script setup lang="ts">
import {onBeforeMount} from "vue"
import {useRouter, onBeforeRouteLeave} from "vue-router"
import {storeToRefs} from "pinia"
import {useWearAndMileageStore} from "@/stores/CalculatorStore"
import {vMaska} from "maska"
import VehicleMileageCalculatorResult from "@/components/VehicleMileageCalculator/VehicleMileageCalculatorResult.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import Dropdown from "primevue/dropdown"
import FFButton from "@/components/FFUI/FFButton.vue"

const router = useRouter()

const wearAndMileageStore = useWearAndMileageStore()
const {vehicleMileageObject, vehicleMileageCategories, isVehicleMileageObjectComplete, vehicleMileageCalculateResult} =
  storeToRefs(wearAndMileageStore)

onBeforeMount(() => {
  wearAndMileageStore.fetchVehicleMileageCategories()
})

onBeforeRouteLeave(() => {
  wearAndMileageStore.resetStates()
})
</script>

<template>
  <section class="vehicle-mileage-calculator-form container">
    <p class="vehicle-mileage-calculator-form__title">{{ $t("app.calculatorPage.vehicleMileage.title") }}</p>
    <div class="vehicle-mileage-calculator-form__row-inputs">
      <FFFieldset :title="$t('form.inputTitles.category')">
        <Dropdown
          v-model="vehicleMileageObject.category"
          :options="vehicleMileageCategories"
          optionLabel="name"
          :panelStyle="{maxWidth: '246px', minWidth: '246px'}" />
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.yearOfRelease')">
        <FFInput
          v-model="vehicleMileageObject.yearOfRelease"
          v-maska
          data-maska="####"
          type="text"
          inputmode="numeric" />
      </FFFieldset>
    </div>
    <div class="vehicle-mileage-calculator-form__buttons">
      <FFButton
        @click="wearAndMileageStore.vehicleMileageCalculate"
        :title="$t('app.buttons.calculateMileage')"
        color="var(--color-white)"
        colorMobile="var(--color-white)"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        :uppercase="false"
        :disabled="!isVehicleMileageObjectComplete" />
      <VehicleMileageCalculatorResult v-if="vehicleMileageCalculateResult?.result_mileage" :resultMileage="vehicleMileageCalculateResult?.result_mileage" />
      <FFButton
        @click="router.push('/pages/wear-vehicle')"
        :title="$t('app.buttons.wearCalculator')"
        color="var(--color-white)"
        colorMobile="var(--color-white)"
        background="transparent"
        backgroundMobile="transparent"
        borderPixel="1px solid var(--color-white)"
        borderPixelMobile="1px solid var(--color-white)"
        :uppercase="false" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.vehicle-mileage-calculator-form {
  max-width: 540px;
  padding-top: 200px;
  padding-bottom: 200px;
  @include max-responsive(lg) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .vehicle-mileage-calculator-form__title {
    font-size: 38px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @include main-green-gradient;
    text-align: center;
  }
  .vehicle-mileage-calculator-form__row-inputs {
    @include flex;
    gap: 20px;
    &:first-of-type {
      margin: 50px 0 20px;
    }

    @include max-responsive(lg) {
      @include column;
    }
  }
  .vehicle-mileage-calculator-form__buttons {
    @include column;
    gap: 20px;
    margin-top: 20px;
  }
}
</style>
