<script setup lang="ts">
import {ref, onUnmounted } from "vue"
import CloseIcon from "@/components/icons/CloseIcon.vue"

interface IProps {
  errorMessage: string
  darkMode: boolean
}
withDefaults(defineProps<IProps>(), {
  darkMode: false
})

const errorMessageIsClosed = ref<boolean>(false)

const handleCloseDarkModeMessage = () => {
  errorMessageIsClosed.value = true
}

onUnmounted(() => {
  errorMessageIsClosed.value = false;
});
</script>

<template>
  <template v-if="darkMode">
    <div v-if="!errorMessageIsClosed" class="dark-mode-error__wrapper">
      <p class="dark-mode-error__message">{{ errorMessage }}</p>
      <CloseIcon @click="handleCloseDarkModeMessage" class="dark-mode__close-icon" />
    </div>
  </template>
  <template v-else>
    <div class="validation-error-color">{{ errorMessage }}</div>
  </template>
</template>

<style scoped lang="scss">
.dark-mode-error__wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #282828;
  border-radius: 5px;
  padding: 10px 6px 10px 12px;
  margin-top: 4px;

  .dark-mode-error__message {
    color: #fff;
    width: 238px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dark-mode__close-icon {
    cursor: pointer;
  }
}
</style>
