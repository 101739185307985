<script setup lang="ts">
import {ref, computed, onUnmounted} from "vue"
import {storeToRefs} from "pinia"
import {useOgpoJuridicalStore} from "@/stores/OgpoJuridicalStore"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import FFFileInput from "@/components/FFUI/FFFileInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const {getData} = useGatewayFetcher()

const ogpoJuridicalStore = useOgpoJuridicalStore()
const {currentStep, documents, documentTypes, verificationStatuses} = storeToRefs(ogpoJuridicalStore)

const pollingIntervals = ref<Record<string, number>>({})

const handleFileChange = async (file: File | null, documentType: string, key: string) => {
  if (!file) return

  const response = await ogpoJuridicalStore.verifyDocument(file, documentType, key)

  if (response && !pollingIntervals.value[key]) {
    pollingIntervals.value[key] = setInterval(async () => {
      try {
        const response = await ogpoJuridicalStore.getDocumentStatus()
        const status = response?.data?.original?.verify_status
        if (status) {
          verificationStatuses.value[key] = status === "CONFIRMED" ? 1 : status === "PENDING" ? 2 : 3
        }

        if (status === "CONFIRMED" || status === "REJECTED") {
          clearInterval(pollingIntervals.value[key])
          delete pollingIntervals.value[key]
        }
      } catch (error) {
        console.error(`Error while checking document status (${key}):`, error)
      }
    }, 5000)
  } else {
    return
  }
}

const handleFileDeletion = (fileKey: string) => {
  if (pollingIntervals.value[fileKey]) {
    clearInterval(pollingIntervals.value[fileKey])
    delete pollingIntervals.value[fileKey]
  }

  documents.value[fileKey] = null
  verificationStatuses.value[fileKey] = null
}

const isInputDisabled = (fileKey: string) => {
  if (verificationStatuses.value[fileKey] === 2) {
    return true
  }

  return Object.values(verificationStatuses.value).some((status) => status === 2)
}

const isHandleNextStepDisabled = computed(() => {
  return !Object.values(verificationStatuses.value).every((status) => status === 1)
})

const handleNextStep = async () => {
  const [countriesResponse, documentTypesResponse] = await Promise.all([
    await ogpoJuridicalStore.getBeneficiariesCountries(),
    await ogpoJuridicalStore.getBeneficiariesDocumentTypes()
  ])
  if (countriesResponse && documentTypesResponse) {
    currentStep.value = 4
  } else {
    return
  }
}

onUnmounted(() => {
  Object.keys(pollingIntervals.value).forEach((key) => {
    clearInterval(pollingIntervals.value[key])
  })
  pollingIntervals.value = {}
})
</script>

<template>
  <div class="ogpo-juridical-documents-form__wrapper">
    <div class="ogpo-juridical-documents-form__row" v-for="(fileKey, index) in Object.keys(documents)" :key="fileKey">
      <p class="ogpo-juridical-documents-form__text">
        {{ $t(`app.ogpoJuridicalPage.forms.documents.text-${index + 1}`) }}
      </p>
      <FFFileInput
        v-model="documents[fileKey]"
        :inputId="fileKey"
        :isComponentForVerification="true"
        :verificationStatus="verificationStatuses[fileKey]"
        :disabled="isInputDisabled(fileKey)"
        @change="() => handleFileChange(documents[fileKey], documentTypes[index].guid, fileKey)"
        @fileDeleted="handleFileDeletion(fileKey)" />
    </div>
    <FFButton @click="handleNextStep" :disabled="isHandleNextStepDisabled" :title="$t('app.buttons.continue')" :uppercase="false" />
  </div>
</template>

<style scoped lang="scss">
.ogpo-juridical-documents-form__wrapper {
  @include column;
  gap: 24px;
  margin-top: 24px;
  .ogpo-juridical-documents-form__row {
    @include column;
    .ogpo-juridical-documents-form__text {
      color: var(--color-white);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px;
      opacity: 0.7;
    }
  }
}
</style>
