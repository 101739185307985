<script setup lang="ts">
import {useI18n} from "vue-i18n"
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

const {scrollToTop} = useHelpers()
const {t} = useI18n()
</script>

<template>
  <section class="ogpo-juridical-arrange container">
    <div class="ogpo-juridical-arrange__content">
      <div class="ogpo-juridical-arrange__autumn-road-bg-mobile"></div>

      <p>{{ $t("app.ogpoPage.arrangeCarInsurance.mainTitle") }}</p>
    </div>
    <div class="ogpo-juridical-arrange__button-container">
      <FFButton
        @click="scrollToTop"
        :title="t('app.buttons.calculateCost')"
        maxWidth="234px"
        color="var(--color-white)"
        colorMobile="var(--color-white)"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        :uppercase="false" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.ogpo-juridical-arrange {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 147px;
  position: relative;
  z-index: 0;

  @include max-responsive(xl) {
    @include column;
  }

  @include max-responsive(md) {
    margin-bottom: 100px;
  }

  .ogpo-juridical-arrange__content {
    position: relative;
    width: 70%;

    @include max-responsive(xl) {
      width: 100%;
    }

    .ogpo-juridical-arrange__autumn-road-bg-mobile {
      display: none;

      @include max-responsive(md) {
        @include flex;
        position: absolute;
        background-image: url(/ogpo-front/images/autumn-road-bg-mobile.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        top: -588px;
        right: 0;
        bottom: 0;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
      }
    }
    p:first-of-type {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      @include main-green-gradient;
      margin-bottom: 20px;

      @include max-responsive(xl) {
        text-align: center;
      }

      @include max-responsive(lg) {
        font-size: 30px;
      }

      @include max-responsive(md) {
        font-size: 30px;
        position: relative;
        z-index: 1;
      }
    }
  }
  .ogpo-juridical-arrange__button-container {
    width: 30%;
    @include center;

    @include max-responsive(xl) {
      width: 100%;
      margin-top: 30px;
    }
  }
}
</style>
