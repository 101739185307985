<script lang="ts" setup>
import {computed} from "vue"
import {storeToRefs} from "pinia"
import {vMaska} from "maska"
import zod from "zod"
import {useOgpoPlusStore} from "@/stores/OgpoPlusStore"
import {onPhoneFocus} from "@/utils/inputEvents"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const ogpoStore = useOgpoPlusStore()
const {kaspiPayPhoneNumber} = storeToRefs(ogpoStore)

const phoneNumberFormSchema = zod.object({
  phone: zod
    .string()
    .nonempty()
    .regex(/^\+7 \(\d{3}\) \d{3} \d{4}$/)
})

const isPhoneFormFilled = computed(() => {
  return !phoneNumberFormSchema.safeParse({phone: kaspiPayPhoneNumber.value}).success
})
</script>

<template>
  <div class="kaspi-payment-form">
    <FFFieldset :title="$t('app.mstPage.form.paymentForm.linkedPhoneNumberToKaspi')">
      <FFInput
        v-maska
        data-maska="+7 (###) ### ####"
        placeholder="+7 (___) ___ ____"
        id="phone"
        v-model="kaspiPayPhoneNumber"
        name="phone"
        @focus="onPhoneFocus"
        type="tel" />
    </FFFieldset>
    <FFButton
      @click="ogpoStore.finalPay"
      :disabled="isPhoneFormFilled"
      :title="$t('app.mstPage.form.paymentForm.sendBillToKaspi')"
      :uppercase="false" />
  </div>
</template>

<style scoped lang="scss">
.kaspi-payment-form {
  margin-top: 20px;
  .input-fieldset {
    margin-bottom: 20px;
  }
}
</style>
