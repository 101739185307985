<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
    <rect
      x="0.5"
      y="50"
      width="50"
      height="50"
      rx="10"
      transform="rotate(-90 0.5 50)"
      fill="white"
      fill-opacity="0.44" />
    <path d="M31.5 17.5L24 25L31.5 32.5L30 35.5L19.5 25L30 14.5L31.5 17.5Z" fill="black" />
  </svg>
</template>

<style scoped>
svg {
  cursor: pointer;
}
svg:hover rect {
  fill: #4f9d3a;
}
</style>
