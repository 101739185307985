<script setup lang="ts">
import {useI18n} from "vue-i18n"
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

const {scrollToTop} = useHelpers()
const {t} = useI18n()
</script>

<template>
  <section class="kasko-arrange">
    <div class="kasko-arrange__content">
      <div class="kasko-arrange__autumn-road-bg-mobile"></div>

      <p>{{ $t("app.kaskoPage.arrangeCarInsurance.mainTitle") }}</p>
      <p>{{ $t("app.kaskoPage.arrangeCarInsurance.description") }}</p>
    </div>
    <div class="kasko-arrange__button-container">
      <FFButton
        @click="scrollToTop"
        :title="t('app.buttons.getNow')"
        maxWidth="234px"
        color="#ffffff"
        colorMobile="#ffffff"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        :uppercase="true" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.kasko-arrange {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 147px;
  position: relative;
  z-index: 1;
  .kasko-arrange__content {
    position: relative;
    width: 70%;

    .kasko-arrange__autumn-road-bg-mobile {
      display: none;
    }
    p:first-of-type {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;

      background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      margin-bottom: 20px;
    }

    p:last-of-type {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      opacity: 0.8;
    }
  }
  .kasko-arrange__button-container {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: $xxlWide) {
  .kasko-arrange {
    .kasko-arrange__content {
      .kasko-arrange__autumn-road-bg-mobile {
        display: none;
      }
    }
  }
}

@media (max-width: $wide) {
  .kasko-arrange {
    .kasko-arrange__content {
      .kasko-arrange__autumn-road-bg-mobile {
        display: none;
      }
    }
  }
}

@media (max-width: $xxl) {
  .kasko-arrange {
    .kasko-arrange__content {
      .kasko-arrange__autumn-road-bg-mobile {
        display: none;
      }
    }
  }
}

@media (max-width: $xl) {
  .kasko-arrange {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    .kasko-arrange__content {
      width: 100%;
      .kasko-arrange__autumn-road-bg-mobile {
        display: none;
      }
      p {
        text-align: center;
      }
    }

    .kasko-arrange__button-container {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: $lg) {
  .kasko-arrange {
    .kasko-arrange__content {
      p:first-of-type {
        font-size: 40px;
      }
    }
  }
}

@media (max-width: $md) {
  .kasko-arrange {
    margin-bottom: 100px;
    .kasko-arrange__content {
      .kasko-arrange__autumn-road-bg-mobile {
        display: flex;
        position: absolute;
        background-image: url(/kasko-front/images/autumn-road-bg-mobile.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        top: -588px;
        right: 0;
        bottom: 0;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
      }
      p:first-of-type {
        font-size: 40px;
        position: relative;
        z-index: 1;
      }
      p:last-of-type {
        position: relative;
        z-index: 1;
      }
    }
  }
}
</style>
