<script setup lang="ts"></script>

<template>
  <section class="ogpo-juridical-unique-abilities container">
    <p class="ogpo-juridical-unique-abilities__title"></p>
    <div class="ogpo-juridical-unique-abilities__boxes">
      <div class="ogpo-juridical-unique-abilities__box">
        <p class="ogpo-juridical-unique-abilities__box-title">
          {{ $t("app.ogpoJuridicalPage.uniqueOpportunities.uniqueOpportunitiesTitle-1") }}
        </p>
        <p class="ogpo-juridical-unique-abilities__box-description">
          {{ $t("app.ogpoJuridicalPage.uniqueOpportunities.uniqueOpportunitiesDescription-1") }}
        </p>
        <img
          class="ogpo-juridical-unique-abilities__box-img"
          src="/ogpo-juridical-front/images/safe.png"
          alt="safe image" />
      </div>
      <div class="ogpo-juridical-unique-abilities__box">
        <p class="ogpo-juridical-unique-abilities__box-title">
          {{ $t("app.ogpoJuridicalPage.uniqueOpportunities.uniqueOpportunitiesTitle-2") }}
        </p>
        <p class="ogpo-juridical-unique-abilities__box-description">
          {{ $t("app.ogpoJuridicalPage.uniqueOpportunities.uniqueOpportunitiesDescription-2") }}
        </p>
        <img
          class="ogpo-juridical-unique-abilities__box-img"
          src="/ogpo-juridical-front/images/seat.png"
          alt="seat image" />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.ogpo-juridical-unique-abilities {
  padding: 0 0 150px;
  .ogpo-juridical-unique-abilities__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    @include main-green-gradient;
  }
  .ogpo-juridical-unique-abilities__boxes {
    @include between;
    gap: 20px;
    @include max-responsive(lg) {
      @include column;
    }
    .ogpo-juridical-unique-abilities__box {
      position: relative;
      overflow: hidden;
      padding: 24px;
      height: 274px;
      width: 100%;
      border-radius: 24px;
      background: radial-gradient(54.59% 70.24% at 0% 100%, rgba(26, 46, 34, 0.6) 0%, rgba(27, 27, 27, 0.6) 100%);
      backdrop-filter: blur(8px);
      @include column;
      gap: 16px;
      .ogpo-juridical-unique-abilities__box-title {
        color: var(--color-white);
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
      }
      .ogpo-juridical-unique-abilities__box-description {
        color: #dbdbdb;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 22.4px;
        max-width: 400px;
      }
      .ogpo-juridical-unique-abilities__box-img {
        position: absolute;
        bottom: 0;
        right: -20px;

        @include max-responsive(xxl) {
          right: -47px;
        }

        @include max-responsive(xl) {
          right: 0;
          height: 120px;
          width: 205px;
        }
      }
    }
  }
}
</style>
