<script setup lang="ts">
import {computed, ref, watch} from "vue"
import i18n from "@/i18n"
import {useRouter, useRoute} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {kaskoUrl, comfortUrl} from "@/constants/urls"

const router = useRouter()
const route = useRoute()

const {openLink} = useHelpers()

const emit = defineEmits(["close"])

const changeLocale = (locale: string) => {
  i18n.global.locale = locale
  localStorage.setItem("locale", locale)
  router.push({name: route.name as string, params: {lang: locale}})
}

const currentLocale = computed(() => i18n.global.locale)

const productsShown = ref<boolean>(false)
const aboutShown = ref<boolean>(false)
const insuranceShown = ref<boolean>(false)
const contactsShown = ref<boolean>(false)
const propertyInsurance = ref<boolean>(false)
const motorInsurance = ref<boolean>(false)
const liabilityInsurance = ref<boolean>(false)
const specializedInsurance = ref<boolean>(false)

const closeMenu = () => {
  emit("close")
  productsShown.value = false
  aboutShown.value = false
  insuranceShown.value = false
  contactsShown.value = false
  propertyInsurance.value = false
  motorInsurance.value = false
  liabilityInsurance.value = false
  specializedInsurance.value = false
}

watch(
  () => route.path,
  () => {
    closeMenu()
  }
)

const waitOpenLink = (link: string, target: string) => {
  closeMenu()
  setTimeout(() => {
    openLink(link, target)
  }, 200)
}

const routerPush = (path: string) => {
  closeMenu()
  router.push(path)
}
</script>
<template>
  <div class="mobile-menu">
    <div class="mobile-menu__header">
      <div class="mobile-menu__header--lang">
        <p :class="{active: currentLocale === 'kk'}" @click="changeLocale('kk')">ҚАЗ</p>
        <p :class="{active: currentLocale === 'ru'}" @click="changeLocale('ru')">РУС</p>
        <p :class="{active: currentLocale === 'en'}" @click="changeLocale('en')">ENG</p>
      </div>
      <svg @click="closeMenu" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.4099 12L17.7099 7.71C17.8982 7.5217 18.004 7.2663 18.004 7C18.004 6.7337 17.8982 6.47831 17.7099 6.29C17.5216 6.1017 17.2662 5.99591 16.9999 5.99591C16.7336 5.99591 16.4782 6.1017 16.2899 6.29L11.9999 10.59L7.70994 6.29C7.52164 6.1017 7.26624 5.99591 6.99994 5.99591C6.73364 5.99591 6.47824 6.1017 6.28994 6.29C6.10164 6.47831 5.99585 6.7337 5.99585 7C5.99585 7.2663 6.10164 7.5217 6.28994 7.71L10.5899 12L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L11.9999 13.41L16.2899 17.71C16.3829 17.8037 16.4935 17.8781 16.6154 17.9289C16.7372 17.9797 16.8679 18.0058 16.9999 18.0058C17.132 18.0058 17.2627 17.9797 17.3845 17.9289C17.5064 17.8781 17.617 17.8037 17.7099 17.71C17.8037 17.617 17.8781 17.5064 17.9288 17.3846C17.9796 17.2627 18.0057 17.132 18.0057 17C18.0057 16.868 17.9796 16.7373 17.9288 16.6154C17.8781 16.4936 17.8037 16.383 17.7099 16.29L13.4099 12Z"
          fill="white" />
      </svg>
    </div>
    <div class="mobile-menu__body">
      <div>
        <div class="mobile-menu__body--item" @click="productsShown = !productsShown">
          <div class="flex items-center gap-[10px]">
            <img src="/mobile-menu-icons-front/products.svg" alt="icon" />
            <p class="item-title">{{ $t("app.productsServices") }}</p>
          </div>
          <img src="/mobile-menu-icons-front/arrow.svg" :class="{'rotate-180': productsShown}" alt="arrow" />
        </div>
        <Transition>
          <div v-show="productsShown" class="mobile-menu__body--categories">
            <div class="flex flex-col gap-[20px]">
              <p class="physical-title">{{ $t("app.forPhysicalPersons") }}</p>
              <div class="products-child">
                <p @click="routerPush('/pages/ogpo')">
                  {{ $t("app.requiredAutoInsuranceOgpo") }}
                </p>
                <p @click="routerPush('/pages/kasko')">{{ $t("app.CASCO") }}</p>
                <p @click="routerPush('/pages/ogpo-plus')">
                  {{ $t("app.ogpoPlus") }}
                </p>
                <p @click="routerPush('/pages/home-protect')" class="alone">Home Protect</p>
                <p @click="routerPush('/pages/comfort')">
                  {{ $t("app.apartmentInsurance") }}
                </p>
                <p @click="routerPush('/pages/mst')" class="alone">
                  {{ $t("app.mst") }}
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-[20px]">
              <p class="physical-title legal-title">{{ $t("app.forLegalPersons") }}</p>
              <div class="products-child" style="gap: 20px">
                <div
                  @click="routerPush('/pages/juridical-ogpo')"
                  class="flex items-center justify-between cursor-pointer">
                  <p>{{ $t("app.requiredAutoInsuranceOgpo") }}</p>
                </div>
                <div
                  @click="propertyInsurance = !propertyInsurance"
                  class="flex justify-between gap-[15px] cursor-pointer">
                  <p>{{ $t("app.propertyCargoInsurance") }}</p>
                  <img
                    src="/mobile-menu-icons-front/arrow.svg"
                    :class="{'rotate-180': propertyInsurance}"
                    class="w-[14px] h-[14px]"
                    alt="arrow" />
                </div>
                <Transition>
                  <div v-show="propertyInsurance" class="mt-[-10px]">
                    <ul class="flex flex-col gap-[10px] ml-[22px]">
                      <li @click="router.push('/pages/strakhovanie-imuschestva')">
                        {{ $t("app.propertyInsurance") }}
                      </li>
                      <li
                        @click="
                          router.push(
                            '/pages/strakhovanie-imuschestva-i-finansovykh-ubytkov-v-rezultate-pereryva-v-proizvodst'
                          )
                        ">
                        {{ $t("app.propertyInsuranceNote") }}
                      </li>
                      <li @click="router.push('/pages/strakhovanie-stroitelno-montadgnykh-riskov-smr')">
                        {{ $t("app.buildInsurance") }}
                      </li>
                      <li @click="router.push('/pages/strakhovanie-gruzov')">
                        {{ $t("app.cargoInsurance") }}
                      </li>
                    </ul>
                  </div>
                </Transition>
                <div @click="motorInsurance = !motorInsurance" class="flex justify-between gap-[15px] cursor-pointer">
                  <p>{{ $t("app.motorInsurance") }}</p>
                  <img
                    src="/mobile-menu-icons-front/arrow.svg"
                    :class="{'rotate-180': motorInsurance}"
                    class="w-[14px] h-[14px]"
                    alt="arrow" />
                </div>
                <Transition>
                  <div v-show="motorInsurance" class="mt-[-10px]">
                    <ul class="flex flex-col gap-[10px] ml-[22px]">
                      <li @click="router.push('/pages/strakhovanie-avtomobilnogo-transporta')">
                        {{ $t("app.autoInsurance") }}
                      </li>
                      <li @click="router.push('/pages/strakhovanie-aviatransporta-i-otvetstvennosti-ego-vladeltsev')">
                        {{ $t("app.aviaInsurance") }}
                      </li>
                      <li
                        @click="router.push('/pages/strakhovanie-vodnogo-transporta-i-otvetstvennosti-ego-vladeltsev')">
                        {{ $t("app.seaInsurance") }}
                      </li>
                      <li @click="router.push('/pages/strakhovanie-dgeleznodorodgnogo-transporta')">
                        {{ $t("app.railwayInsurance") }}
                      </li>
                    </ul>
                  </div>
                </Transition>
                <div
                  @click="liabilityInsurance = !liabilityInsurance"
                  class="flex justify-between gap-[15px] cursor-pointer">
                  <p>{{ $t("app.liabilityInsurance") }}</p>
                  <img
                    src="/mobile-menu-icons-front/arrow.svg"
                    :class="{'rotate-180': liabilityInsurance}"
                    class="w-[14px] h-[14px]"
                    alt="arrow" />
                </div>
                <Transition>
                  <div v-show="liabilityInsurance" class="mt-[-10px]">
                    <ul class="flex flex-col gap-[10px] ml-[22px]">
                      <li
                        @click="
                          router.push(
                            '/pages/dobrovolnoe-strakhovanie-otvetstvennosti-vladeltsev-transportnogo-sredstva-dgpo'
                          )
                        ">
                        {{ $t("app.optionalInsurance") }}
                      </li>
                      <li @click="router.push('/pages/strakhovanie-professionalnoy-otvetstvennosti')">
                        {{ $t("app.proInsurance") }}
                      </li>
                      <li @click="router.push('/pages/strakhovanie-perevozchikov')">
                        {{ $t("app.shippingInsurance") }}
                      </li>
                      <li @click="router.push('/pages/strakhovanie-otvetstvennosti-arendatorov')">
                        {{ $t("app.rentInsurance") }}
                      </li>
                      <li @click="router.push('/pages/ekologicheskoe-strakhovanie')">
                        {{ $t("app.ecoInsurance") }}
                      </li>
                      <li @click="router.push('/pages/obyazatelnoe-strakhovanie-gpo-vladeltsev-opasnykh-obektov')">
                        {{ $t("app.requiredGPOInsurance") }}
                      </li>
                      <li
                        @click="
                          router.push(
                            '/pages/strakhovanie-neispolneniya-nenadledgaschego-ispolneniya-obyazatelstv-po-likvidat'
                          )
                        ">
                        {{ $t("app.liquidInsurance") }}
                      </li>
                    </ul>
                  </div>
                </Transition>
                <div
                  @click="specializedInsurance = !specializedInsurance"
                  class="flex justify-between gap-[15px] cursor-pointer">
                  <p>{{ $t("app.specialInsurance") }}</p>
                  <img
                    src="/mobile-menu-icons-front/arrow.svg"
                    :class="{'rotate-180': specializedInsurance}"
                    class="w-[14px] h-[14px]"
                    alt="arrow" />
                </div>
                <Transition>
                  <div v-show="specializedInsurance" class="mt-[-10px]">
                    <ul class="flex flex-col gap-[10px] ml-[22px]">
                      <li @click="router.push('/pages/strakhovanie-neftyanykh-operatsiy')">
                        {{ $t("app.oilInsurance") }}
                      </li>
                      <li @click="router.push('/pages/meditsinskoe-strakhovanie-turistov')">
                        {{ $t("app.medicalTouristsInsurance") }}
                      </li>
                    </ul>
                  </div>
                </Transition>
              </div>
            </div>
            <div class="flex flex-col gap-[20px]">
              <p class="physical-title services">{{ $t("app.services") }}</p>
              <div class="products-child">
                <p @click="waitOpenLink(`https://id.mkb.kz/#/esbd/checkInsurancePolicy`, '_self')">
                  {{ $t("app.checkPolis") }}
                </p>
                <p @click="waitOpenLink(`https://dtp.kz`, '_self')" class="alone">{{ $t("app.dtpKz") }}</p>
                <p @click="router.push('/pages/earn-money-with-dtp-app')" class="alone">{{ $t("app.dtpApp") }}</p>
                <p @click="router.push('/pages/wear-vehicle')">
                  {{ $t("app.depreciationCalculator") }}
                </p>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <div>
        <div class="mobile-menu__body--item" @click="aboutShown = !aboutShown">
          <div class="flex items-center gap-[10px]">
            <img src="/mobile-menu-icons-front/about.svg" alt="icon" />
            <p class="item-title">{{ $t("app.aboutUs") }}</p>
          </div>
          <img src="/mobile-menu-icons-front/arrow.svg" :class="{'rotate-180': aboutShown}" alt="arrow" />
        </div>
        <Transition>
          <div v-show="aboutShown" class="mobile-menu__body--alone-categories">
            <div @click="router.push('/pages/about-company')" class="flex items-center gap-[10px] cursor-pointer">
              <img src="/mobile-menu-icons-front/about.svg" alt="icon" />
              <p>{{ $t("app.aboutCompany") }}</p>
            </div>
            <div @click="router.push('/pages/our-team')" class="flex items-center gap-[10px] cursor-pointer">
              <img src="/mobile-menu-icons-front/ourTeam.svg" alt="icon" />
              <p>{{ $t("app.ourTeamOne") }}</p>
            </div>
            <div @click="router.push('/pages/documents')" class="flex items-center gap-[10px] cursor-pointer">
              <img src="/mobile-menu-icons-front/documents.svg" alt="icon" />
              <p>{{ $t("app.documents") }}</p>
            </div>
            <div @click="router.push('/pages/news')" class="flex items-center gap-[10px] cursor-pointer">
              <img src="/mobile-menu-icons-front/news.svg" alt="icon" />
              <p>{{ $t("app.news") }}</p>
            </div>
          </div>
        </Transition>
      </div>
      <div>
        <div class="mobile-menu__body--item" @click="insuranceShown = !insuranceShown">
          <div class="flex items-center gap-[10px]">
            <img src="/mobile-menu-icons-front/insuranceCase.svg" alt="icon" />
            <p class="item-title">{{ $t("app.insuranceCase") }}</p>
          </div>
          <img src="/mobile-menu-icons-front/arrow.svg" :class="{'rotate-180': insuranceShown}" alt="arrow" />
        </div>
        <Transition>
          <div v-show="insuranceShown" class="mobile-menu__body--alone-categories">
            <div @click="waitOpenLink(`https://dtp.kz`, '_self')" class="flex items-start gap-[10px] cursor-pointer">
              <img src="/mobile-menu-icons-front/dtp.svg" alt="icon" />
              <div>
                <p>{{ $t("app.applyDtpkz") }}</p>
                <p style="color: #8c8c8c; font-weight: 400; letter-spacing: unset">{{ $t("app.ifDtp") }}</p>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <div>
        <div class="mobile-menu__body--item" @click="router.push('/pages/contacts')">
          <div class="flex items-center gap-[10px]">
            <img src="/mobile-menu-icons-front/contacts.svg" alt="icon" />
            <p class="item-title">{{ $t("app.contacts") }}</p>
          </div>
        </div>
      </div>
      <div @click="router.push('/pages/personal-profile')" class="mobile-menu__body--item">
        <div class="flex items-center gap-[10px]">
          <img src="/mobile-menu-icons-front/profile.svg" alt="icon" />
          <p class="item-title">{{ $t("app.profileApp") }}</p>
        </div>
      </div>

      <div class="flex justify-center gap-[20px] mt-auto pb-[20px] pt-[16px]">
        <a href="tel:5777"><img src="/mobile-menu-icons-front/phone.png" class="cursor-pointer" alt="phone" /></a>
        <a href="https://www.instagram.com/" target="_blank"
          ><img src="/mobile-menu-icons-front/insta.png" class="cursor-pointer" alt="insta"
        /></a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
::-webkit-scrollbar {
  display: none;
}
.mobile-menu {
  background: var(--Dark-green, linear-gradient(132deg, #2f4d3b -0.69%, #162522 103.63%));
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &__header {
    &--lang {
      display: flex;
      align-items: center;
      gap: 26px;
      justify-content: center;
      margin-top: 10px;
      p {
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 0 1px 4px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
      }
      .active {
        border-bottom: 2px solid #53ec29;
      }
    }
    svg {
      position: absolute;
      right: 8px;
      top: 10px;
      cursor: pointer;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
    padding: 0 26px;
    height: 85%;
    &--item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .item-title {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
      }
    }
    &--categories {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .physical-title {
        border-radius: 4px;
        background: #f2994a;
        padding: 4px 0;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
        max-width: 250px;
      }
      .legal-title {
        background: #72bf44;
      }
      .services {
        background: #797979;
      }
      .products-child {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 10px;
        border-left: 1px solid #fff;
        p,
        li {
          width: fit-content;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.5px;
          cursor: pointer;
          max-width: 265px;
        }
        li {
          max-width: unset;
        }
      }
    }
    &--alone-categories {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-left: 1px solid #fff;
      margin-top: 10px;
      padding-left: 30px;
      p {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
      }
    }
  }
}

ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}

@media (max-width: 450px) {
  .mobile-menu {
    .physical-title {
      max-width: unset;
    }
  }
}
</style>
