<script setup lang="ts"></script>

<template>
  <div class="bg-image">
    <section class="regulation-processing-personal-data container">
      <p class="regulation-processing-personal-data__main-title">
        {{ $t("app.regulationProcessingPersonalDataPage.title") }}
      </p>
      <!-- 1 -->
      <ul class="regulation-processing-personal-data__list">
        <template v-for="i in 4" :key="i">
          <li v-if="i !== 4" class="regulation-processing-personal-data__list-item">
            {{ i }}. {{ $t(`app.regulationProcessingPersonalDataPage.firstPart.row-${i}`) }}
          </li>
          <li v-else class="regulation-processing-personal-data__list-item">
            {{ i }}. {{ $t(`app.regulationProcessingPersonalDataPage.firstPart.row-4.name`) }}
            <ul class="regulation-processing-personal-data__sublist">
              <template v-for="j in 17" :key="j">
                <li class="regulation-processing-personal-data__sublist-item">
                  {{ j }}. {{ $t(`app.regulationProcessingPersonalDataPage.firstPart.row-4.subrows[${j - 1}]`) }}
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>

      <!-- 2 -->
      <div class="regulation-processing-personal-data__wrapper">
        <p class="regulation-processing-personal-data__title">
          {{ $t("app.regulationProcessingPersonalDataPage.secondPart.title") }}
        </p>
        <ul class="regulation-processing-personal-data__list">
          <template v-for="i in 2" :key="i">
            <li class="regulation-processing-personal-data__list-item">
              {{ i }}. {{ $t(`app.regulationProcessingPersonalDataPage.secondPart.row-${i}.name`) }}
              <ul class="regulation-processing-personal-data__sublist">
                <template v-if="i === 1">
                  <li v-for="j in 1" :key="j" class="regulation-processing-personal-data__sublist-item">
                    {{ j }}. {{ $t(`app.regulationProcessingPersonalDataPage.secondPart.row-${i}.subrows[${j - 1}]`) }}
                  </li>
                </template>
                <template v-else>
                  <li v-for="j in 2" :key="j" class="regulation-processing-personal-data__sublist-item">
                    {{ j }}. {{ $t(`app.regulationProcessingPersonalDataPage.secondPart.row-${i}.subrows[${j - 1}]`) }}
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>
      </div>

      <!-- 3 -->
      <div class="regulation-processing-personal-data__wrapper">
        <p class="regulation-processing-personal-data__title">
          {{ $t("app.regulationProcessingPersonalDataPage.thirdPart.title") }}
        </p>
        <ul class="regulation-processing-personal-data__list">
          <template v-for="i in 6" :key="i">
            <li class="regulation-processing-personal-data__list-item">
              {{ i }}. {{ $t(`app.regulationProcessingPersonalDataPage.thirdPart.row-${i}.name`) }}
              <ul v-if="[1, 4, 6].includes(i)" class="regulation-processing-personal-data__sublist">
                <template v-if="i === 1">
                  <li v-for="j in 5" :key="j" class="regulation-processing-personal-data__sublist-item">
                    {{ j }}. {{ $t(`app.regulationProcessingPersonalDataPage.thirdPart.row-1.subrows[${j - 1}]`) }}
                  </li>
                </template>
                <template v-if="i === 4">
                  <li v-for="j in 4" :key="j" class="regulation-processing-personal-data__sublist-item">
                    {{ j }}. {{ $t(`app.regulationProcessingPersonalDataPage.thirdPart.row-4.subrows[${j - 1}]`) }}
                  </li>
                </template>
                <template v-if="i === 6">
                  <li v-for="j in 2" :key="j" class="regulation-processing-personal-data__sublist-item">
                    {{ j }}. {{ $t(`app.regulationProcessingPersonalDataPage.thirdPart.row-6.subrows[${j - 1}]`) }}
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>
      </div>

      <!-- 4 -->
      <div class="regulation-processing-personal-data__wrapper">
        <p class="regulation-processing-personal-data__title">
          {{ $t("app.regulationProcessingPersonalDataPage.fourthPart.title") }}
        </p>
        <ul class="regulation-processing-personal-data__list">
          <template v-for="i in 3" :key="i">
            <li class="regulation-processing-personal-data__list-item">
              {{ i }}. {{ $t(`app.regulationProcessingPersonalDataPage.fourthPart.row-${i}.name`) }}
            </li>
          </template>
        </ul>
      </div>

      <!-- 5 -->
      <div class="regulation-processing-personal-data__wrapper">
        <p class="regulation-processing-personal-data__title">
          {{ $t("app.regulationProcessingPersonalDataPage.fifthPart.title") }}
        </p>
        <ul class="regulation-processing-personal-data__list">
          <template v-for="i in 4" :key="i">
            <li class="regulation-processing-personal-data__list-item">
              {{ i }}. {{ $t(`app.regulationProcessingPersonalDataPage.fifthPart.row-${i}.name`) }}
              <ul v-if="i === 2" class="regulation-processing-personal-data__sublist">
                <li v-for="j in 3" :key="j" class="regulation-processing-personal-data__sublist-item">
                  {{ j }}. {{ $t(`app.regulationProcessingPersonalDataPage.fifthPart.row-2.subrows[${j - 1}]`) }}
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>

      <!-- 6 -->
      <div class="regulation-processing-personal-data__wrapper">
        <p class="regulation-processing-personal-data__title">
          {{ $t("app.regulationProcessingPersonalDataPage.sixthPart.title") }}
        </p>
        <ul class="regulation-processing-personal-data__list">
          <template v-for="i in 1" :key="i">
            <li class="regulation-processing-personal-data__list-item">
              {{ i }}. {{ $t(`app.regulationProcessingPersonalDataPage.sixthPart.row-${i}.name`) }}
            </li>
          </template>
        </ul>
      </div>

      <!-- 7 -->
      <div class="regulation-processing-personal-data__wrapper">
        <p class="regulation-processing-personal-data__title">
          {{ $t("app.regulationProcessingPersonalDataPage.seventhPart.title") }}
        </p>
        <ul class="regulation-processing-personal-data__list">
          <template v-for="i in 6" :key="i">
            <li class="regulation-processing-personal-data__list-item">
              {{ i }}. {{ $t(`app.regulationProcessingPersonalDataPage.seventhPart.row-${i}.name`) }}
            </li>
          </template>
        </ul>
      </div>

      <!-- Consent to the collection, processing and storage of personal data -->
      <p class="regulation-processing-personal-data__second-title">
        {{ $t("app.regulationProcessingPersonalDataPage.secondTitle") }}
      </p>
      <p class="regulation-processing-personal-data__second-description">
        {{ $t("app.regulationProcessingPersonalDataPage.secondDescription") }}
      </p>
      <div class="regulation-processing-personal-data__wrapper">
        <ul class="regulation-processing-personal-data__list">
          <template v-for="i in 6" :key="i">
            <li class="regulation-processing-personal-data__list-item">
              {{ i }}. {{ $t(`app.regulationProcessingPersonalDataPage.agreementRows.row-${i}.title`) }}
              <ul v-if="i === 1" class="regulation-processing-personal-data__sublist">
                <li v-for="j in 3" :key="j" class="regulation-processing-personal-data__sublist-item">
                  {{ j }}.
                  {{ $t(`app.regulationProcessingPersonalDataPage.agreementRows.row-1.subrows[${j - 1}].title`) }}
                  <ul v-if="j === 1" class="regulation-processing-personal-data__sublist">
                    <li v-for="k in 5" :key="k" class="regulation-processing-personal-data__sublist-item">
                      {{ k }}.
                      {{
                        $t(
                          `app.regulationProcessingPersonalDataPage.agreementRows.row-1.subrows[${0}].subrowsSublist[${k - 1}]`
                        )
                      }}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.bg-image {
  background: url("/thankyou-front/images/thankyou-bg.png") no-repeat top;
  .regulation-processing-personal-data {
    padding: 100px 0;

    @include max-responsive(lg) {
      padding: 50px 15px;
    }
    .regulation-processing-personal-data__main-title {
      color: var(--color-white);
      font-size: 33px;
      font-weight: 900;
      line-height: normal;
      font-style: normal;
      margin-bottom: 50px;

      @include max-responsive(lg) {
        font-size: 25px;
        margin-bottom: 30px;
      }
    }
    .regulation-processing-personal-data__list {
      @include column;
      gap: 4px;
      padding: 0 20px;
      .regulation-processing-personal-data__list-item {
        color: var(--color-white);
        font-size: 16px;
        font-weight: 300;
        line-height: normal;
        font-style: normal;
        .regulation-processing-personal-data__sublist {
          @include column;
          gap: 4px;
          margin-top: 4px;
          padding: 0 40px;

          @include max-responsive(lg) {
            padding: 0 20px;
            gap: 7px;
          }
        }
      }
    }
    .regulation-processing-personal-data__wrapper {
      margin: 15px 0;
      .regulation-processing-personal-data__title {
        color: var(--color-white);
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        font-style: normal;
        margin-bottom: 15px;
      }
    }
    .regulation-processing-personal-data__second-title {
      color: var(--color-white);
      font-size: 29px;
      font-weight: 900;
      line-height: normal;
      font-style: normal;
      margin-bottom: 20px;
      margin-top: 30px;
      text-align: center;

      @include max-responsive(lg) {
        font-size: 25px;
      }
    }
    .regulation-processing-personal-data__second-description {
      color: var(--color-white);
      font-size: 16px;
      font-weight: 300;
      line-height: normal;
      font-style: normal;
      text-indent: 30px;
    }
  }
}
</style>
