<script setup lang="ts">
import {computed, defineProps, defineEmits} from "vue"

const props = defineProps<{
  value?: string | number
  modelValue?: string | number
}>()

defineEmits(["update:modelValue"])

const isChecked = computed(() => {
  return props.modelValue === props.value
})
</script>

<template>
  <div class="radio-button__wrapper">
    <input type="radio" :value="value" v-model="isChecked" @change="$emit('update:modelValue', value)" />
    <span class="radio-button" :class="{checked: isChecked}"></span>
  </div>
</template>

<style scoped lang="scss">
input[type="radio"] {
  opacity: 0;
  z-index: 1;
  position: absolute;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.radio-button__wrapper {
  position: relative;
  width: 26px;
  height: 26px;

  .radio-button {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    background: transparent;
    border: 3px solid #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .checked {
    &::after {
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
