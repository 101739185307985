<script setup lang="ts">
import {ref, computed} from "vue"
import {useI18n} from "vue-i18n"
import type {IWhatSecureCards} from "@/interfaces/Comfort/IWhatSecureCards"

const {t} = useI18n()

const cards = ref<IWhatSecureCards[]>([
  {
    id: 1,
    imgSrc: "/comfort-front/images/apartment-1.webp",
    title: computed(() => t("app.comfortPage.interiorDecoration-title")),
    alt: computed(() => t("app.comfortPage.interiorDecoration-title")),
    description: computed(() => t("app.comfortPage.interiorDecoration-description"))
  },
  {
    id: 2,
    imgSrc: "/comfort-front/images/apartment-2.webp",
    title: computed(() => t("app.comfortPage.movableProperty-title")),
    alt: computed(() => t("app.comfortPage.movableProperty-title")),
    description: computed(() => t("app.comfortPage.movableProperty-description"))
  },
  {
    id: 3,
    imgSrc: "/comfort-front/images/apartment-3.webp",
    title: computed(() => t("app.comfortPage.responsibilityTowardsNeighbours-title")),
    alt: computed(() => t("app.comfortPage.responsibilityTowardsNeighbours-title")),
    description: computed(() => t("app.comfortPage.responsibilityTowardsNeighbours-description")),
  }
])
</script>

<template>
  <section class="comfort-whatsecure">
    <h2 class="comfort-whatsecure__title">{{ $t("app.comfortPage.whatSecure-title") }}</h2>
    <div class="comfort-whatsecure__cards scrollbar-hide">
      <div v-for="card in cards" :key="card.id" class="comfort-whatsecure__card">
        <img class="comfort-whatsecure__card-image" :src="card.imgSrc" :alt="card.alt" />
        <div class="comfort-whatsecure__card-content">
          <p class="comfort-whatsecure__card-title">
            {{ card.title }}
          </p>
          <p class="comfort-whatsecure__card-description">
            {{ card.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.comfort-whatsecure {
  margin-bottom: 150px;
  .comfort-whatsecure__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;

    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .comfort-whatsecure__cards {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;

    .comfort-whatsecure__card {
      max-height: max-content;
      border-radius: 30px;
      background: rgba(74, 74, 74, 0.2);
      backdrop-filter: blur(8px);

      .comfort-whatsecure__card-image {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
      }

      .comfort-whatsecure__card-content {
        padding: 34px 33px;

        .comfort-whatsecure__card-title {
          color: #ffffff;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .comfort-whatsecure__card-description {
          color: #d6d6d6;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .comfort-whatsecure {
    margin-bottom: 100px;
    .comfort-whatsecure__cards {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      right: -106px;
      padding-right: 12%;
      width: 390px;

      .comfort-whatsecure__card {
        flex-shrink: 0;
        width: 300px;
      }
    }
  }
}
</style>
