<script setup lang="ts">
import {onUnmounted} from "vue"
import {storeToRefs} from "pinia"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import UserProfileHero from "@/components/UserProfile/UserProfileHero.vue"
import UserProfileInfo from "@/components/UserProfile/UserProfileInfo.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const personalProfileStore = usePersonalProfileStore()
const {loading} = storeToRefs(personalProfileStore)

onUnmounted(() => {
  personalProfileStore.resetUserProfileData()
})
</script>
<template>
  <Modal style="z-index: 103" :isOpen="loading">
    <Loader />
  </Modal>
  <div class="bg-black user-profile">
    <UserProfileHero />
    <UserProfileInfo />
  </div>
</template>
<style scoped lang="scss">
.user-profile {
  background: url("/declare-insured-event/images/declare-insuranse-bg.webp") no-repeat bottom;
}

@media (max-width: $lg) {
  .user-profile {
    background: url("/declare-insured-event/images/mobile-glow-bg.webp") no-repeat center;
  }
}
</style>
