<script setup lang="ts">
import {computed, watch} from "vue"
import {useToast} from "vue-toastification"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useOgpoStore} from "@/stores/OgpoStore"
import {useOgpoPlusStore} from "@/stores/OgpoPlusStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useValidation} from "@/composables/useValidation"
import {useFormatters} from "@/utils/formatters"
import {useHelpers} from "@/composables/useHelpers"
import {filterEmailInput} from "@/utils/inputEvents"
import {pushGtagEvent} from "@/utils/pushGtagEvent"
import {emailFormSchema} from "@/schemas"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"
import ProductBankCashbackAmount from "@/components/shared/ProductBankCashbackAmount.vue"
import iAgreeWithQuestionnaireAndRules from "@/components/shared/IAgreeWithQuestionnaireAndRules.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import QuestionIcon from "@/components/icons/QuestionIcon.vue"

interface ITooltips {
  notBankClient: boolean
  bankClient: boolean
}

const toast = useToast()
const {t} = useI18n()
const helperStore = useHelperStore()
const ogpoStore = useOgpoStore()
const ogpoPlusStore = useOgpoPlusStore()
const {
  calculationObject,
  calculationObjectWhenOgpoPlus,
  addOgpoPlus,
  ogpoPlusSum,
  executionForm,
  nomadClubMember,
  calculateSumWithOgpoPlusOrWithout,
  calculateCashbackWithOgpoPlusOrWithout,
  calculateCashbackPriceWithOgpoOrWithout,
  autonumtechpassInputStates,
  iinInputStates,
  isCarOlder,
  isScoring
} = storeToRefs(ogpoStore)
const {isSheetVisible} = storeToRefs(helperStore)
const {currentLocale, openLink} = useHelpers()
const {thousandSeparator} = useFormatters()
const emit = defineEmits(["nextStep"])

const {isValid, getError, validate} = useValidation(emailFormSchema, executionForm.value, {
  mode: "lazy"
})

const handleNextStep = async () => {
  try {
    await validate()
    if (!executionForm.value.email && !executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTermsAndEmail"))
      return
    }
    if (!executionForm.value.email && executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTermsEmail"))
      return
    }
    if (executionForm.value.email && !executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTerms"))
      return
    }
    if (addOgpoPlus.value && !executionForm.value.iAgreeWithOgpoPlusAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTerms"))
      return
    }
    if (isValid.value) {
      isSheetVisible.value = false
      pushGtagEvent("valid_email_input")
      pushGtagEvent("vibor_oplata_step2")
      emit("nextStep")
    }
  } catch (error) {
    console.error(error)
  }
}

const showOgpoPlus = computed(() => {
  return autonumtechpassInputStates.value.length === 0 && iinInputStates.value.length === 0
})

watch(
  addOgpoPlus,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      if (newVal) {
        ogpoStore.calculateCostWhenOgpoPlus()
      } else {
        calculationObjectWhenOgpoPlus.value = null
        ogpoStore.calculateCostWithoutOgpoPlus()
      }
    }
  },
  {immediate: false}
)
</script>

<template>
  <template v-if="showOgpoPlus && !isCarOlder">
    <div class="exec-form__ogpo-plus">
      <p class="exec-form__ogpo-plus-subtitle">{{ $t("app.ogpoPage.forms.additionalPayIfDTP") }}</p>
      <p class="exec-form__ogpo-plus-title">{{ $t("app.ogpoPage.forms.ogpoPlus") }}</p>
      <p class="exec-form__ogpo-plus-paragraph">
        {{ $t("app.ogpoPage.forms.amortizationCause") }}
      </p>
      <div class="exec-form__ogpo-plus-additional-pay-wrapper">
        <p>{{ $t("app.ogpoPage.forms.additionalPayment") }}</p>
        <p v-if="currentLocale !== 'kk'">
          {{ $t("form.formAbbreviations.till") }} 1 107 600 {{ $t("form.formAbbreviations.tg") }}
        </p>
        <p v-else>1 107 600 {{ $t("form.formAbbreviations.tg") }} {{ $t("form.formAbbreviations.till") }}</p>
      </div>
      <iAgreeWithQuestionnaireAndRules
        v-if="addOgpoPlus"
        v-model="executionForm.iAgreeWithOgpoPlusAgreement"
        :openQuestionnairePdfFn="() => ogpoStore.openOgpoPlusQuestionnairePdf(calculationObject?.calcId)"
        :openRules="
          () =>
            openLink(
              currentLocale === 'kk' ? 'https://ffins.kz/kk/report-file/254' : 'https://ffins.kz/report-file/254',
              '_blank'
            )
        "
        :showQuestionnaire="addOgpoPlus"
        product="ogpoPlus" />
      <div class="exec-form__add-ogpo-plus">
        <FFCheckbox :title="t('app.ogpoPage.forms.addToSum')" v-model="addOgpoPlus" />
      </div>
    </div>
  </template>
  <div class="exec-form__opgo mt-[10px]">
    <div>
      <FFFieldset :isError="!!getError('email')" :title="$t('form.inputTitles.email')">
        <FFInput
          id="email"
          name="email"
          type="email"
          v-model="executionForm.email"
          :placeholder="t('app.ogpoPage.forms.willSendPolisOnEmail')"
          inputmode="email"
          :required="true"
          @input="filterEmailInput" />
      </FFFieldset>
      <template v-if="!!getError('email')">
        <FFErrorMessage
          v-if="executionForm.email.length === 0"
          :darkMode="true"
          :errorMessage="t('form.validationErrors.emailFieldIsRequired')" />
        <FFErrorMessage
          v-if="executionForm.email.length > 0 && getError('email')"
          :darkMode="true"
          :errorMessage="t('form.validationErrors.emailIsNotValid')" />
      </template>
    </div>
    <iAgreeWithQuestionnaireAndRules
      v-model="executionForm.iAgreeWithAgreement"
      :openQuestionnairePdfFn="() => ogpoStore.openOgpoQuestionnairePdf(calculationObject?.calcId)"
      :openRules="
        () =>
          openLink(
            currentLocale === 'kk' ? 'https://ffins.kz/kk/report-file/228' : 'https://ffins.kz/report-file/228',
            '_blank'
          )
      " />
  </div>
  <div class="relative">
    <div class="exec-form__dark-bg"></div>
    <div class="exec-form__payment-sum-wrapper">
      <template
        v-if="
          addOgpoPlus &&
          calculationObject &&
          calculationObjectWhenOgpoPlus &&
          calculationObjectWhenOgpoPlus.insPremium !== undefined
        ">
        <div class="exec-form__payment-sum-wrapper--ogpo-plus">
          <p>{{ $t("app.ogpoPage.forms.polisOgpo") }}:</p>
          <p>
            {{ calculationObject && calculationObject.premium ? thousandSeparator(calculationObject.premium) : "" }}
            {{ $t("form.formAbbreviations.tg") }}
          </p>
        </div>
        <div class="exec-form__payment-sum-wrapper--ogpo-plus">
          <p>{{ $t("app.ogpoPage.forms.additionalCostOgpo+") }}:</p>
          <p>
            {{ thousandSeparator(calculationObjectWhenOgpoPlus.insPremium) }}
            {{ t("form.formAbbreviations.tg") }}
          </p>
        </div>
      </template>

      <div
        class="exec-form__payment-sum-wrapper--sum"
        :class="{
          'mb-[15px]':
            (calculationObjectWhenOgpoPlus && calculationObjectWhenOgpoPlus.insPremium) ||
            !Boolean(calculationObject?.isClientBank) ||
            Boolean(calculationObject?.isClientBank)
        }">
        <p>{{ $t("app.ogpoPage.forms.toPay") }}</p>
        <p>
          {{ thousandSeparator(calculateSumWithOgpoPlusOrWithout) }}
          <span class="tenge">{{ $t("form.formAbbreviations.tg") }}</span>
        </p>

        <template v-if="isScoring">
          <p  class="text-[15px] text-white mb-[15px]">
            {{ $t("app.ogpoPage.scoringAlert") }}
          </p>
          <table class="text-white text-[12px] mb-[15px]">
            <tbody>
              <tr>
                <td>АО СК Номад Иншуранс</td>
                <td><a href="https://nomad.kz/" target="_blank" rel="noopener noreferrer">https://nomad.kz/</a></td>
              </tr>
              <tr>
                <td>АО СК Сентрас Иншуранс</td>
                <td><a href="https://www.cic.kz/" target="_blank" rel="noopener noreferrer">https://www.cic.kz/</a></td>
              </tr>
              <tr>
                <td>АО СК Jusan Garant</td>
                <td><a href="https://jgarant.kz/" target="_blank" rel="noopener noreferrer">https://jgarant.kz/</a></td>
              </tr>
              <tr>
                <td>АО СК Amanat</td>
                <td><a href="https://jgarant.kz/" target="_blank" rel="noopener noreferrer">https://jgarant.kz/</a></td>
              </tr>
              <tr>
                <td>АО СК АСКО</td>
                <td><a href="https://asko.kz/" target="_blank" rel="noopener noreferrer">https://asko.kz/</a></td>
              </tr>
              <tr>
                <td>АО СК Евразия</td>
                <td><a href="https://theeurasia.kz/" target="_blank" rel="noopener noreferrer">https://theeurasia.kz/</a></td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
      <hr
        v-if="
          (calculationObjectWhenOgpoPlus && calculationObjectWhenOgpoPlus.insPremium !== null) ||
          !Boolean(calculationObject?.isClientBank) ||
          Boolean(calculationObject?.isClientBank)
        " />
      <ProductBankCashbackAmount
        v-if="!calculationObject?.isScoring"
        productType="Ogpo"
        :isLoyaltyCalled="calculationObject?.isLoyaltyCalled"
        :isClientBank="calculationObject?.isClientBank"
        :cashbackBalance="calculateCashbackWithOgpoPlusOrWithout"
        :cashbackPercent="calculationObject?.cashbackPercent"
        :cashbackPrice="calculateCashbackPriceWithOgpoOrWithout"
        marginTop="20px" />
    </div>

    <div class="exec-form__pay-button" :class="{'exec-form__pay-button--no-margin': !addOgpoPlus}">
      <FFButton @click="handleNextStep" :title="$t('app.buttons.pay')" :uppercase="false" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.exec-form__ogpo-plus {
  border-radius: 10px;
  padding: 20px 16px;
  background-color: #1f1f1f;
  margin: 20px 0 0 0;

  .exec-form__ogpo-plus-subtitle {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.54px;
    margin-bottom: 10px;
  }

  .exec-form__ogpo-plus-title {
    color: #ffffff;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: 53.76px;

    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .exec-form__ogpo-plus-paragraph {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 20px 0;
  }

  .exec-form__ogpo-plus-additional-pay-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    p {
      color: #ffffff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      opacity: 0.8;
    }
  }

  .exec-form__add-ogpo-plus {
    border-radius: 5px;
    background: #72bf44;
    padding: 10px;
    margin-top: 20px;
  }
}

.exec-form__opgo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  .exec-fort__ogpo-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
  }
}

.exec-form__payment-sum-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  position: relative;
  z-index: 1;

  .exec-form__payment-sum-wrapper--notClientBank {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;

    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: none;

      span {
        text-decoration: underline;
        font-weight: 700;
      }
    }

    p:first-child {
      width: 165px;
    }
  }

  .exec-form__payment-sum-wrapper--ogpo-plus {
    display: flex;
    justify-content: space-between;

    p {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      opacity: 0.8;
    }

    p:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  .exec-form__payment-sum-wrapper--sum {
    display: flex;
    justify-content: space-between;

    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      opacity: 1;
    }
  }

  .exec-form__payment-cashback-wrapper {
    margin-top: 15px;
  }
}

hr {
  z-index: 1;
  position: relative;
  border-color: white;
}

.exec-form__pay-button {
  position: relative;
  padding-top: 20px;
}

.exec-form__pay-button--no-margin {
  margin-top: 0px;
}

.exec-form__dark-bg {
  background-color: rgba(0, 0, 0, 0.5);
  height: calc(100% + 80px);
  width: calc(100% + 60px);
  position: absolute;
  top: 0;
  left: -10%;
  right: 0;
  bottom: 0;
}

.tenge {
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
</style>
