import {ref, computed, watchEffect} from "vue"
import {defineStore, storeToRefs} from "pinia"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useAuthStore} from "@/stores/AuthStore"
import {useModalStore} from "@/stores/ModalStore"
import {useI18n} from "vue-i18n"
import {useFormatters} from "@/utils/formatters"
import {useHelpers} from "@/composables/useHelpers"
import {useRouter} from "vue-router"
import {useToast} from "vue-toastification"
import type {ISubscriptionPolicy} from "@/interfaces/PersonalProfile/ISubscriptionPolicy"
import type {IPaymentInfoItem} from "@/interfaces/PersonalProfile/IPaymentInfoItem"
import FFOtp from "@/components/FFUI/FFOtp.vue"

export const usePersonalProfileStore = defineStore("personalProfileStore", () => {
  const {postData, getData, putData, deleteData, loading} = useGatewayFetcher()
  const authStore = useAuthStore()
  const modalStore = useModalStore()
  const {accessToken} = storeToRefs(authStore)
  const {openLink, currentLocale} = useHelpers()
  const {phoneNumberFormatter} = useFormatters()
  const toast = useToast()
  const router = useRouter()
  const {t} = useI18n()

  //Personal profile
  const allUserPolicies = ref()
  const notActivePolicies = ref([])

  const config = {
    headers: {
      "Accept-Language": currentLocale.value,
      Authorization: `Bearer ${accessToken}`
    }
  }

  const getAllUserPolicies = async () => {
    try {
      const response = await postData("/auth/policies", {}, config)
      if (response && response?.status === 200 && response?.data) {
        allUserPolicies.value = response?.data?.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  const currentPolicie = ref()
  const getClickedPolicyObject = (globalId: string) => {
    const val = allUserPolicies.value.find((policy) => policy.global_id === globalId)
    currentPolicie.value = val
  }

  const openPoliciePdf = async (globalId: string, productCode: string) => {
    try {
      const response = await postData(
        `/auth/policy/pdf`,
        {
          globalId,
          productCode
        },
        config
      )
      if (response?.status === 200 && response?.data?.pdf) {
        const binaryData = atob(response?.data?.pdf)

        const arrayBuffer = new Uint8Array(binaryData.length)
        for (let i = 0; i < binaryData.length; i++) {
          arrayBuffer[i] = binaryData.charCodeAt(i)
        }

        const blob = new Blob([arrayBuffer], {type: "application/pdf"})

        const pdfUrl = URL.createObjectURL(blob)

        const popUpCheck = window.open(pdfUrl, "_blank")
        if (!popUpCheck) {
          toast.clear()
          toast.info(t("app.toasterMessages.turnOffPopupBlocker"))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  //User profile
  const localStorageCurrentUser = JSON.parse(localStorage.getItem("current-user"))?.data || null
  const localStorageAccessToken = localStorage.getItem("access-token")

  const originalEmail = ref(localStorageCurrentUser?.email || "")
  const originalPhone = ref(localStorageCurrentUser?.phone || "")
  const emailPhoneProfileData = ref({
    email: originalEmail.value,
    phone: originalPhone.value
  })

  const passwordProfileData = ref({
    password: null,
    repeatPassword: null
  })
  const passwordType = ref<string>("password")
  const repeatPasswordType = ref<string>("password")

  //OTP
  const otpCode = ref<string | number>()
  const showOtp = ref<boolean>(false)
  const codeIsSent = ref<boolean>(false)
  const sentOtpResponse = ref<{message: string; status: boolean}>()
  const timer = ref(0)

  const buttonTitle = computed(() => {
    if (timer.value > 0) {
      const minutes = Math.floor(timer.value / 60)
      const seconds = timer.value % 60
      return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
    }
    return t("app.buttons.sendCode")
  })

  const startTimer = () => {
    timer.value = 90
    const interval = setInterval(() => {
      timer.value--
      if (timer.value <= 0) {
        clearInterval(interval)
        codeIsSent.value = false
      }
    }, 1000)
  }

  const clearOtpData = () => {
    otpCode.value = ""
    showOtp.value = false
    timer.value = 0
    codeIsSent.value = false
  }

  const handleSendOtp = async () => {
    try {
      const response = await postData(
        "/auth/send-otp",
        {phone: localStorageCurrentUser.phone, type: "security_purposes_reset_password"},
        config
      )
      if (response?.status === 200) {
        startTimer()
        codeIsSent.value = true
        showOtp.value = true
        sentOtpResponse.value = response?.data?.data
      } else {
        otpCode.value = ""
        codeIsSent.value = false
        showOtp.value = false
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkOtpResponse = ref()
  const checkOtpCode = async (code: string | number) => {
    try {
      otpCode.value = code
      const response = await postData(
        "/auth/check-otp",
        {
          phone: localStorageCurrentUser.phone,
          otpCode: otpCode.value
        },
        config
      )
      if (response?.status === 200 && response?.data) {
        clearOtpData()
        checkOtpResponse.value = response?.data?.data
        if (checkOtpResponse.value.status) {
          modalStore.closeModal()
          const changePasswordResponse = await changePasswordInUserProfile()
          if (changePasswordResponse) {
            router.push("personal-profile")
            toast.clear()
            toast.success(t("app.toasterMessages.auth.passwordHasChanged"))
            return true
          } else {
            return
          }
        } else {
          return
        }
      } else {
        return
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const closeOtpForm = () => {
    showOtp.value = false
    codeIsSent.value = false
    timer.value = 0
    modalStore.closeModal()
  }

  const handleOtpFormOpen = async () => {
    const otpProps = {
      codeIsSent: codeIsSent,
      buttonTitle: buttonTitle,

      sendOtp: handleSendOtp,
      checkOtp: checkOtpCode,
      closeOtp: closeOtpForm
    }
    modalStore.openModal({
      component: FFOtp,
      props: otpProps
    })
  }

  const resetUserProfileData = () => {
    const currentUser = JSON.parse(localStorage.getItem("current-user"))?.data || null
    emailPhoneProfileData.value.email = currentUser.email
    emailPhoneProfileData.value.phone = currentUser.phone
    originalEmail.value = currentUser.email
    originalPhone.value = currentUser.phone
    passwordProfileData.value.password = null
    passwordProfileData.value.repeatPassword = null
  }

  //profile data change
  const changeEmailPhoneInUserProfile = async (email: string, phone: string) => {
    try {
      const response = await postData(
        "/auth/user-edit",
        {
          email,
          phone: phoneNumberFormatter(phone),
          password: "",
          confirmPassword: ""
        },
        config
      )
      if (response && response.status === 200) {
        toast.clear()
        toast.success(t("app.toasterMessages.auth.emailHasBeenChanged"))
        await authStore.getUser(accessToken.value)
        resetUserProfileData()
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
    }
  }

  const changePasswordInUserProfile = async () => {
    try {
      const response = await postData(
        "/auth/user-edit",
        {
          email: localStorageCurrentUser.email,
          password: passwordProfileData.value.password,
          confirmPassword: passwordProfileData.value.repeatPassword
        },
        config
      )
      if (response && response.status === 200) {
        toast.clear()
        toast.success(t("app.toasterMessages.auth.passwordHasChanged"))
        resetUserProfileData()
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  //subscription card section
  const allUserSubscriptionPolicies = ref<ISubscriptionPolicy[] | null>([])
  const currentOpenedPolicySubscription = ref<ISubscriptionPolicy | null>(null)
  const subscriptionPaymentInfoData = ref<IPaymentInfoItem[] | null>(null)
  const isSubscriptionEnded = ref<boolean>(false)
  const showPaymentHistory = ref<boolean>(false)

  const getAllUserSubscriptionPolicies = async () => {
    try {
      const response = await postData("/auth/policy/subscriptions", {}, config)
      if (response && response?.status === 200 && response?.data) {
        allUserSubscriptionPolicies.value = response.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getPoliciesPaymentInfoData = async () => {
    try {
      const response = await postData(
        "/auth/policy/subscription/payment-history",
        {
          subsRegNum: currentOpenedPolicySubscription.value?.subscriptionRegNum
        },
        config
      )
      if (response && response.status === 200 && response.data) {
        subscriptionPaymentInfoData.value = response.data.items
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
    }
  }

  const lastTwoPaymentInfo = computed(() => {
    return subscriptionPaymentInfoData?.value?.slice(-2)
  })

  const groupedPaymentHistory = computed(() => {
    const result: {cardPan: string; payments: IPaymentInfoItem[]}[] = []
    let currentGroup: {cardPan: string; payments: IPaymentInfoItem[]} | null = null

    subscriptionPaymentInfoData?.value?.forEach((item) => {
      if (item.statusCode !== "Paid") {
        return
      }

      if (!currentGroup || currentGroup.cardPan !== item.cardPan) {
        if (currentGroup) {
          result.push(currentGroup)
        }
        currentGroup = {
          cardPan: item.cardPan,
          payments: [item]
        }
      } else {
        currentGroup.payments.push(item)
      }
    })

    if (currentGroup) {
      result.push(currentGroup)
    }

    return result
  })

  const totalSubscriptionPaymentInfoDataAmount = computed(() => {
    return groupedPaymentHistory.value.reduce((sum, group) => {
      const groupSum = group.payments.reduce((groupTotal, payment) => {
        return groupTotal + parseFloat(payment.writeOffAmount)
      }, 0)
      return sum + groupSum
    }, 0)
  })

  const replaceCardUrl = ref<string>("")
  const replaceCard = async () => {
    try {
      const response = await putData("/auth/card/upsert", {}, config)
      if (response) {
        openLink(response.link, "_self")
      } else {
        return
      }
    } catch (error) {
      console.error(error)
    }
  }

  const subscriptionEnd = async () => {
    try {
      const response = await postData("/auth/policy/subscription/end", {
        subsRegNum: currentOpenedPolicySubscription.value?.subscriptionRegNum
      })
      if (response && response.status === 200) {
        isSubscriptionEnded.value = true
      }
    } catch (error) {
      console.error(error)
    }
  }
  return {
    loading,
    allUserPolicies,
    notActivePolicies,
    localStorageCurrentUser,
    originalEmail,
    originalPhone,
    emailPhoneProfileData,
    passwordProfileData,
    passwordType,
    currentPolicie,
    repeatPasswordType,
    allUserSubscriptionPolicies,
    currentOpenedPolicySubscription,
    subscriptionPaymentInfoData,
    lastTwoPaymentInfo,
    groupedPaymentHistory,
    totalSubscriptionPaymentInfoDataAmount,
    replaceCardUrl,
    isSubscriptionEnded,
    showPaymentHistory,
    getAllUserPolicies,
    getAllUserSubscriptionPolicies,
    getClickedPolicyObject,
    openPoliciePdf,
    handleOtpFormOpen,
    changeEmailPhoneInUserProfile,
    changePasswordInUserProfile,
    resetUserProfileData,
    getPoliciesPaymentInfoData,
    replaceCard,
    subscriptionEnd
  }
})
