<script setup lang="ts">
import {reactive, ref} from "vue"
import {useI18n} from "vue-i18n"
import {useRoute} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {useHelperStore} from "@/stores/HelperStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useFormatters} from "@/utils/formatters"
import FFButton from "@/components/FFUI/FFButton.vue"
import InfoIconStraight from "@/components/icons/InfoIconStraight.vue"

interface IProps {
  productType: string
  isLoyaltyCalled?: boolean | null | undefined
  isClientBank?: boolean | null | undefined
  cashbackBalance?: number | null | undefined
  cashbackPercent?: number | null | undefined
  cashbackPrice?: number | null | undefined
  currentStep?: number
  paymentType?: string
  marginTop?: string
  top?: string
  width?: string
  url?: string
}

interface ITooltips {
  notBankClient: boolean
  bankClient: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  isLoyaltyCalled: false,
  isClientBank: false,
  cashbackPercent: 0,
  marginTop: "0px",
  top: "none",
  width: "100%"
})

const route = useRoute()
const {thousandSeparator} = useFormatters()
const {isMobileDevice} = useDeviceSize()
const helperStore = useHelperStore()

const {t} = useI18n()
const {currentLocale, openLink} = useHelpers()
</script>

<template>
  <template v-if="isLoyaltyCalled && productType === 'Ogpo'">
    <div v-if="!isClientBank" class="exec-form__payment-sum-wrapper--notClientBank">
      <template v-if="route.name === 'SuccessPay' && paymentType === 'KaspiPay' && cashbackBalance === 0">
        <p class="exec-form__payment-sum-text-kaspi-without-cashback-1">
          {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.paragraph-1") }}
        </p>
        <p class="exec-form__payment-sum-text-kaspi-without-cashback-2">
          {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.paragraph-2") }}
          <span @click="openLink(props?.url, '_blank')" class="exec-form__payment-sum-text-1 underline cursor-pointer">
            {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.paragraph-3") }}
          </span>
          {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.paragraph-4") }}
        </p>
        <div class="exec-form__payment-sum-green-blur-kaspi-without-cashback"></div>
        <img
          src="/freedom-bank-front/images/fsilver-coin.webp"
          alt="silver coin"
          class="exec-form__payment-sum-img-kaspi-without-cashback" />
      </template>
      <template
        v-if="
          route.name === 'SuccessPay' &&
          (paymentType === 'KaspiPay' || paymentType === 'FreedomPay' || paymentType === 'FreedomSuperApp') &&
          cashbackBalance !== 0
        ">
        <p class="exec-form__payment-sum-text-kaspi-with-cashback">
          {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.thankYouPage.paragraph") }}
        </p>
        <p class="exec-form__payment-sum-kaspi-with-cashback">{{ thousandSeparator(cashbackBalance) }} ₸</p>
        <button @click="openLink(props?.url, '_blank')" class="exec-form__payment-sum-btn-kaspi-with-cashback">
          {{ $t("app.buttons.pickUp") }}
        </button>
        <div class="exec-form__payment-sum-green-blur-kaspi-with-cashback"></div>
        <img
          src="/freedom-bank-front/images/fsilver-coin-full.webp"
          alt="silver coin"
          class="exec-form__payment-sum-img-kaspi-with-cashback" />
      </template>
      <template v-if="route.name !== 'SuccessPay'">
        <p class="exec-form__payment-sum-text-1">
          {{ $t("app.freedomBank.cashback.notBankClient.paragraph-1") }}
          <span @click="helperStore.toggleSheet" class="cursor-pointer">
            <InfoIconStraight />
          </span>
        </p>
        <div
          :class="[
            currentLocale !== 'kk' ? 'exec-form__payment-sum-gradient-box' : 'exec-form__payment-sum-gradient-box-kk'
          ]">
          <p class="exec-form__payment-sum-text-2">
            {{ cashbackBalance }} ₸ {{ $t("app.freedomBank.cashback.notBankClient.paragraph-2") }}
          </p>
        </div>
        <div class="exec-form__payment-sum-green-blur"></div>
        <img src="/freedom-bank-front/images/fsilver-coin.webp" alt="silver coin" class="exec-form__payment-sum-img" />
      </template>
    </div>
    <div v-if="isClientBank && isLoyaltyCalled" class="exec-form__payment-sum-wrapper--clientBank">
      <p class="exec-form__payment-sum-text-1">{{ $t("app.freedomBank.cashback.bankClient.paragraph-1") }}</p>
      <div
        :class="[
          currentLocale === 'ru' ? 'exec-form__payment-sum-gradient-box' : 'exec-form__payment-sum-gradient-box-kk'
        ]">
        <p class="exec-form__payment-sum-text-2">
          {{ $t("app.freedomBank.cashback.bankClient.paragraph-2") }}
          {{ cashbackPrice }}
          {{ $t("form.formAbbreviations.tg") }}
        </p>
      </div>
      <div class="exec-form__payment-sum-green-blur"></div>
      <img
        src="/freedom-bank-front/images/joker-card-and-products.webp"
        alt="joker card and products"
        :class="[currentLocale === 'ru' ? 'exec-form__payment-sum-img' : 'exec-form__payment-sum-img-kk']" />
    </div>
  </template>

  <template v-if="isLoyaltyCalled && productType === 'Kasko'">
    <div v-if="isClientBank && isLoyaltyCalled" class="exec-form__payment-sum-wrapper--clientBank">
      <p class="exec-form__payment-sum-text-1">{{ $t("app.freedomBank.cashback.bankClient.paragraph-1") }}</p>
      <div
        :class="[
          currentLocale === 'ru' ? 'exec-form__payment-sum-gradient-box' : 'exec-form__payment-sum-gradient-box-kk'
        ]">
        <p v-if="currentStep === 2" class="exec-form__payment-sum-text-2">
          {{ $t("app.freedomBank.cashback.bankClient.paragraph-2") }}
          {{ cashbackPercent }}%
        </p>
        <p v-if="currentStep === 3 || currentStep === 4" class="exec-form__payment-sum-text-2">
          {{ $t("app.freedomBank.cashback.bankClient.paragraph-2") }}
          {{ cashbackPrice }} {{ $t("form.formAbbreviations.tg") }}
        </p>
      </div>
      <div class="exec-form__payment-sum-green-blur"></div>
      <img
        src="/freedom-bank-front/images/joker-card-and-products.webp"
        alt="joker card and products"
        :class="[currentLocale === 'ru' ? 'exec-form__payment-sum-img' : 'exec-form__payment-sum-img-kk']" />
    </div>
    <div v-if="!isClientBank" class="exec-form__payment-sum-wrapper--notClientBank">
      <template v-if="route.name === 'SuccessPay' && paymentType === 'KaspiPay' && cashbackBalance === 0">
        <p class="exec-form__payment-sum-text-kaspi-without-cashback-1">
          {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.paragraph-1") }}
        </p>
        <p class="exec-form__payment-sum-text-kaspi-without-cashback-2">
          {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.paragraph-2") }}
          <span @click="openLink(props?.url, '_blank')" class="exec-form__payment-sum-text-1 underline cursor-pointer">
            {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.paragraph-3") }}
          </span>
          {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.paragraph-4") }}
        </p>
        <div class="exec-form__payment-sum-green-blur-kaspi-without-cashback"></div>
        <img
          src="/freedom-bank-front/images/fsilver-coin.webp"
          alt="silver coin"
          class="exec-form__payment-sum-img-kaspi-without-cashback" />
      </template>
      <template
        v-if="
          route.name === 'SuccessPay' &&
          (paymentType === 'KaspiPay' || paymentType === 'FreedomPay' || paymentType === 'FreedomSuperApp') &&
          cashbackBalance !== 0
        ">
        <p class="exec-form__payment-sum-text-kaspi-with-cashback">
          {{ $t("app.freedomBank.cashback.notBankClient.kaspiPay.thankYouPage.paragraph") }}
        </p>
        <p class="exec-form__payment-sum-kaspi-with-cashback">{{ thousandSeparator(cashbackBalance) }} ₸</p>
        <button @click="openLink(props?.url, '_blank')" class="exec-form__payment-sum-btn-kaspi-with-cashback">
          {{ $t("app.buttons.pickUp") }}
        </button>
        <div class="exec-form__payment-sum-green-blur-kaspi-with-cashback"></div>
        <img
          src="/freedom-bank-front/images/fsilver-coin-full.webp"
          alt="silver coin"
          class="exec-form__payment-sum-img-kaspi-with-cashback" />
      </template>
      <template v-if="route.name !== 'SuccessPay'">
        <p class="exec-form__payment-sum-text-1">
          {{ $t("app.freedomBank.cashback.notBankClient.paragraph-1") }}
          <span @click="helperStore.toggleSheet" class="cursor-pointer">
            <InfoIconStraight />
          </span>
        </p>
        <div
          :class="[
            currentLocale !== 'kk' ? 'exec-form__payment-sum-gradient-box' : 'exec-form__payment-sum-gradient-box-kk'
          ]">
          <p class="exec-form__payment-sum-text-2">
            {{ $t("app.freedomBank.cashback.bankClient.paragraph-2") }} {{ cashbackPercent }}%
          </p>
        </div>
        <div class="exec-form__payment-sum-green-blur"></div>
        <img src="/freedom-bank-front/images/fsilver-coin.webp" alt="silver coin" class="exec-form__payment-sum-img" />
      </template>
    </div>
  </template>
</template>

<style scoped lang="scss">
.exec-form__payment-sum-wrapper--notClientBank {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 14px;
  border-radius: 16px;
  background: linear-gradient(278deg, rgba(70, 200, 224, 0.1) -29.33%, rgba(82, 216, 120, 0.1) 123.73%), #fff;
  margin-top: v-bind(marginTop);
  z-index: 0;
  width: v-bind(width);

  .exec-form__payment-sum-green-blur-kaspi-without-cashback {
    position: absolute;
    right: -27px;
    top: 43px;
    bottom: 0;
    background: rgba(58, 205, 169, 0.4);
    filter: blur(22.6734790802px);
    width: 143.96px;
    height: 111.96px;
    transform: rotate(-87.33deg);
    flex-shrink: 0;

    @include max-responsive(md) {
      position: absolute;
      right: -60px;
      top: 28px;
      bottom: 0;
      background: rgba(58, 205, 169, 0.4);
      filter: blur(22.6734790802px);
      width: 143.96px;
      height: 111.96px;
      transform: rotate(-87.33deg);
      flex-shrink: 0;
    }
  }

  .exec-form__payment-sum-img-kaspi-without-cashback {
    position: absolute;
    right: 0;
    top: -5px;
    width: 89px;
    height: 104px;

    @include max-responsive(md) {
      top: 12px;
    }
  }

  .exec-form__payment-sum-text-kaspi-with-cashback {
    max-width: 210px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 178px;
  }

  .exec-form__payment-sum-kaspi-with-cashback {
    color: #000000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.69%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 50%),
      linear-gradient(262deg, #01d48a 75.88%, #006fc2 101.31%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .exec-form__payment-sum-btn-kaspi-with-cashback {
    background: #68cc6e;
    max-width: 122px;
    border-radius: 100px;
    padding: 11px 28px;
    font-size: 17px;
    color: #ffffff;
    font-weight: 700;
  }

  .exec-form__payment-sum-green-blur-kaspi-with-cashback {
    position: absolute;
    right: -3px;
    top: 43px;
    bottom: 0;
    background: rgba(58, 205, 169, 0.4);
    filter: blur(22.6734790802px);
    width: 143.96px;
    height: 111.96px;
    transform: rotate(-87.33deg);
    flex-shrink: 0;
  }

  .exec-form__payment-sum-img-kaspi-with-cashback {
    position: absolute;
    right: -4px;
    top: 19px;
    width: 138px;
    height: 127px;
  }

  .exec-form__payment-sum-text-kaspi-without-cashback-1 {
    max-width: 210px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 222px;

    @include max-responsive(lg) {
      max-width: 213px;
    }

    @include max-responsive(374px) {
      max-width: 180px;
    }
  }
  .exec-form__payment-sum-text-kaspi-without-cashback-2 {
    max-width: 250px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 200px;

    @include max-responsive(374px) {
      max-width: 180px;
    }
  }

  .exec-form__payment-sum-text-1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 183px;
    z-index: 1;

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .exec-form__payment-sum-gradient-box {
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.69%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 50%),
      linear-gradient(262deg, #01d48a -1.12%, #006fc2 101.31%);
    padding: 6px 10px;
    width: fit-content;
    .exec-form__payment-sum-text-2 {
      color: #ffffff;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .exec-form__payment-sum-gradient-box-kk {
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.69%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 50%),
      linear-gradient(262deg, #01d48a -1.12%, #006fc2 101.31%);
    padding: 6px 10px;
    max-width: 147px;
    .exec-form__payment-sum-text-2 {
      color: #ffffff;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .exec-form__payment-sum-green-blur {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(58, 205, 169, 0.4);
    filter: blur(22.673479080200195px);
    width: 82.96px;
    height: 82.96px;
    transform: rotate(-87.33deg);
    flex-shrink: 0;
  }
  .exec-form__payment-sum-img {
    position: absolute;
    right: 0;
    top: 0px;
    width: 90px;
    height: 106px;
  }
}

.exec-form__payment-sum-wrapper--clientBank {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 14px;
  border-radius: 16px;
  background: linear-gradient(278deg, rgba(70, 200, 224, 0.1) -29.33%, rgba(82, 216, 120, 0.1) 123.73%), #fff;
  margin-top: v-bind(marginTop);
  width: v-bind(width);

  .exec-form__payment-sum-text-1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 180px;
    z-index: 1;
  }

  .exec-form__payment-sum-gradient-box {
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.69%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 50%),
      linear-gradient(262deg, #01d48a -1.12%, #006fc2 101.31%);
    padding: 6px 10px;
    width: fit-content;
    .exec-form__payment-sum-text-2 {
      color: #ffffff;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .exec-form__payment-sum-gradient-box-kk {
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.69%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 50%),
      linear-gradient(262deg, #01d48a -1.12%, #006fc2 101.31%);
    padding: 6px 10px;
    width: fit-content;
    .exec-form__payment-sum-text-2 {
      color: #ffffff;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .exec-form__payment-sum-green-blur {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(58, 205, 169, 0.4);
    filter: blur(22.673479080200195px);
    width: 82.96px;
    height: 82.96px;
    transform: rotate(-87.33deg);
    flex-shrink: 0;
  }
  .exec-form__payment-sum-img {
    position: absolute;
    right: -33px;
    top: -1px;
    width: 99px;
    height: 92px;
  }
  .exec-form__payment-sum-img-kk {
    position: absolute;
    right: -4px;
    top: 6px;
    width: 99px;
    height: 92px;
  }
}
</style>
