import axios from "axios"

export const isAuth = (): boolean => {
  return !!localStorage.getItem("access-token")
}

export const setAxiosAuthHeader = (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export const clearAxiosAuthHeader = () => {
  delete axios.defaults.headers.common["Authorization"]
}
