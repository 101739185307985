<script setup lang="ts">
import {onMounted, ref, computed} from "vue"
import {vMaska} from "maska"
import {storeToRefs} from "pinia"
import {useToast} from "vue-toastification"
import {useI18n} from "vue-i18n"
import {useRouter} from "vue-router"
import {useRecaptcha} from "vue3-recaptcha-v2"
import {useAuthStore} from "@/stores/AuthStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useValidation} from "@/composables/useValidation"
import {onPhoneFocus} from "@/utils/inputEvents"
import {useHelpers} from "@/composables/useHelpers"
import {filterCyrillicInput, filterEmailInput} from "@/utils/inputEvents"
import {iinAndPhoneSchema, passwordsSchema} from "@/schemas"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFReCaptcha from "@/components/FFUI/FFReCaptcha.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"

const toast = useToast()
const {t} = useI18n()

const authStore = useAuthStore()
const helperStore = useHelperStore()
const {
  resetPhoneObj,
  resetPasswordObj,
  resetPasswordCurrentStep,
  resetPhoneNumberCheckResponse
} = storeToRefs(authStore)
const {isRecaptchaVerified, widgetId} = storeToRefs(helperStore)
const {scrollToTop} = useHelpers()
const router = useRouter()
const {handleReset} = useRecaptcha()

const passwordType = ref<string>("password")
const confirmPasswordType = ref<string>("password")

const {isValid, getError, validate, clearErrors, errors} = useValidation(passwordsSchema, resetPasswordObj, {
  mode: "lazy"
})

const handleBackStep = () => {
  authStore.clearResetPasswordData()
}

const handleNextStep = async () => {
  if (resetPasswordCurrentStep.value === 1 && isRecaptchaVerified.value) {
    isRecaptchaVerified.value = false
    handleReset(widgetId.value)
    const response = await authStore.resetPhoneNumberCheck()
    if (response) {
      scrollToTop()
      authStore.handleResetOtpFormOpen()
    } else {
      return
    }
  }
  if (resetPasswordCurrentStep.value === 2) {
    await validate()
    if (isValid.value) {
      const response = await authStore.resetPassword()
      if (response) {
        toast.clear()
        toast.success(t("app.toasterMessages.auth.passwordHasChanged"))
        authStore.goLogin()
      }
    } else {
      return
    }
  }
}

const preventPasswordCyrillicInput = () => {
  resetPasswordObj.value.password = filterCyrillicInput(resetPasswordObj.value.password)
}
const preventRepeatPasswordCyrillicInput = () => {
  resetPasswordObj.value.confirmPassword = filterCyrillicInput(resetPasswordObj.value.confirmPassword)
}

const isResetPasswordFormFilled = computed(() => {
  return passwordsSchema.safeParse(resetPasswordObj.value).success && isRecaptchaVerified.value
})

const isIinAndPhoneFormFilled = computed(() => {
  return iinAndPhoneSchema.safeParse(resetPhoneObj.value).success && isRecaptchaVerified.value
})
</script>

<template>
  <div class="form-wrapper">
    <p v-if="resetPasswordCurrentStep === 1">
      {{ $t("app.auth.resetPasswordSms") }}
    </p>
    <div class="form-wrapper__reset-password">
      <template v-if="resetPasswordCurrentStep === 1">
        <div class="form-wrapper__reset-password-row">
          <FFFieldset :title="$t('form.inputTitles.iin')">
            <FFInput
              v-maska
              data-maska="############"
              id="iin"
              v-model="resetPhoneObj.resetIin"
              name="iin"
              type="text"
              inputmode="numeric" />
          </FFFieldset>
        </div>

        <div class="form-wrapper__reset-password-row">
          <FFFieldset :title="$t('form.inputTitles.phone')">
            <FFInput
              v-maska
              data-maska="+7 (###) ### ####"
              placeholder="+7 (___) ___ ____"
              id="phone"
              v-model="resetPhoneObj.resetPhoneNumber"
              name="1"
              type="tel"
              @focus="onPhoneFocus" />
          </FFFieldset>
        </div>
        <div class="recaptcha-wrapper">
          <FFReCaptcha />
        </div>
      </template>
      <div v-if="resetPasswordCurrentStep === 2" class="form-wrapper__reset-password-row">
        <div v-if="resetPhoneNumberCheckResponse.needEmail" class="form-wrapper__reset-password-input">
          <FFFieldset :isError="getError('email')" :title="$t('form.inputTitles.email')" :clear="false">
            <FFInput id="email" v-model="resetPasswordObj.email" name="email" type="email" @input="filterEmailInput" />
          </FFFieldset>
          <template v-if="!!getError('email')">
            <FFErrorMessage
              v-if="resetPasswordObj.email.length === 0"
              :errorMessage="t('form.validationErrors.emailFieldIsRequired')" />
            <FFErrorMessage
              v-if="resetPasswordObj.email.length > 0 && getError('email')"
              :errorMessage="t('form.validationErrors.emailIsNotValid')" />
          </template>
        </div>
        <div class="form-wrapper__reset-password-input">
          <FFFieldset
            :isError="!!getError('password')"
            :title="$t('form.inputTitles.newPassword')"
            :password="true"
            :eye-opened="passwordType === 'password'"
            @text="passwordType = 'text'"
            @password="passwordType = 'password'">
            <FFInput
              id="password"
              v-model="resetPasswordObj.password"
              name="password"
              :type="passwordType"
              @input="preventPasswordCyrillicInput" />
          </FFFieldset>
          <template v-if="!!getError('password')">
            <FFErrorMessage
              v-if="resetPasswordObj?.password?.length === 0"
              :errorMessage="t('form.validationErrors.passwordFieldIsRequired')" />
            <FFErrorMessage
              v-if="resetPasswordObj?.password?.length > 0 && getError('password')"
              :errorMessage="t('form.validationErrors.passwordIsNotValid')" />
          </template>
        </div>
        <div class="form-wrapper__reset-password-input">
          <FFFieldset
            :isError="!!getError('confirmPassword')"
            :title="$t('form.inputTitles.repeatNewPassword')"
            :password="true"
            :eye-opened="confirmPasswordType === 'password'"
            @text="confirmPasswordType = 'text'"
            @password="confirmPasswordType = 'password'">
            <FFInput
              id="confirmPassword"
              v-model="resetPasswordObj.confirmPassword"
              name="confirmPassword"
              :type="confirmPasswordType"
              @input="preventRepeatPasswordCyrillicInput" />
          </FFFieldset>
          <template v-if="!!getError('confirmPassword')">
            <FFErrorMessage
              v-if="resetPasswordObj?.confirmPassword?.length === 0"
              :errorMessage="t('form.validationErrors.repeatPasswordFieldIsRequired')" />
            <FFErrorMessage
              v-if="
                resetPasswordObj?.confirmPassword.length > 0 &&
                resetPasswordObj?.password !== resetPasswordObj?.confirmPassword
              "
              :errorMessage="t('form.validationErrors.repeatPasswordNotValid')" />
          </template>
        </div>
      </div>
      <div class="form-wrapper__reset-password-buttons">
        <FFButton
          @click="handleBackStep"
          :title="$t('app.buttons.back')"
          :uppercase="false"
          color="#FFFFFF"
          colorMobile="#FFFFFF"
          paddingx="17px"
          paddingy="30px"
          width="100%"
          type="submit"
          borderPixel="1px solid #ffffff"
          background="transparent"
          backgroundMobile="transparent" />
        <FFButton
          @click="handleNextStep"
          :title="resetPasswordCurrentStep === 1 ? $t('app.buttons.next') : $t('app.buttons.change')"
          :disabled="
            resetPasswordCurrentStep === 1
              ? !isIinAndPhoneFormFilled
              : (resetPhoneNumberCheckResponse.needEmail && resetPasswordObj.email === '') ||
                resetPasswordObj.password === '' ||
                resetPasswordObj.confirmPassword === ''
          "
          :uppercase="false"
          color="#FFFFFF"
          colorMobile="#FFFFFF"
          paddingx="17px"
          paddingy="30px"
          width="100%"
          type="submit"
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  width: 100%;
  max-width: 500px;
  padding: 50px 30px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  p {
    color: #ffffff;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-align: center;
  }
  .form-wrapper__reset-password {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
    max-width: 264px;

    .form-wrapper__reset-password-row {
      width: 100%;

      .form-wrapper__reset-password-input {
        margin-bottom: 20px;
      }
    }

    .recaptcha-wrapper {
      transform: scale(0.87);
      transform-origin: 0 0;
      width: 263px;
    }

    .form-wrapper__reset-password-buttons {
      display: flex;
      gap: 20px;
      width: 100%;
    }
  }
}

@media (max-width: $lg) {
  .form-wrapper {
    padding: 40px 30px;
  }
}
</style>
