<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {useHelpers} from "@/composables/useHelpers"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFFileInput from "@/components/FFUI/FFFileInput.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import Dropdown from "primevue/dropdown"

const addDriverAutoStore = useAddDriverAutoStore()
const {identificationCard, date, month, monthes, isSeasonalOperationVehicle, showSeasonalOperationVehicle} =
  storeToRefs(addDriverAutoStore)

const {currentLocale} = useHelpers()
</script>
<template>
  <div class="documents">
    <p class="documents__title">
      {{ $t("app.addDriverAuto.registrationOfNewAgreementOgpo") }}
    </p>
    <div class="documents__deadlines">
      <div class="documents__deadlines--side">
        <FFFieldset :title="$t('app.addDriverAuto.agreementStartDate')">
          <VueDatePicker
            :enable-time-picker="false"
            :teleport="true"
            auto-apply
            v-model="date"
            format="dd.MM.yyyy"
            :locale="currentLocale"
            :start-date="new Date(new Date().setDate(new Date().getDate() + 1))"
            :min-date="new Date(new Date().setDate(new Date().getDate() + 1))"
            :max-date="new Date(new Date().setDate(new Date().getDate() + 1) + 89 * 24 * 60 * 60 * 1000)"
            :disabled="true">
            <template #input-icon>
              <img class="date-time-picker-icon" src="/declare-insured-event/images/calendar-input.svg" alt="icon" />
            </template>
            <template #clear-icon> </template>
          </VueDatePicker>
        </FFFieldset>
      </div>
      <div class="documents__deadlines--side">
        <FFFieldset :title="$t('app.addDriverAuto.policieDurationMonth')" minHeight="unset">
          <Dropdown v-model="month" :options="monthes" optionLabel="name" :disabled="true" dropdownIcon="no" />
        </FFFieldset>
      </div>
    </div>
    <FFCheckbox
      v-if="showSeasonalOperationVehicle"
      :title="$t('app.ogpoPage.forms.seasonalVehicle')"
      v-model="isSeasonalOperationVehicle"
      :locked="true"/>
  </div>
</template>
<style scoped lang="scss">
.documents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  margin: 50px 0;

  .documents__title {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .documents__deadlines {
    display: flex;
    align-items: center;
    gap: 20px;
    .documents__deadlines--side {
      display: flex;
      gap: 20px;
      width: 100%;
      .documents__deadlines--side__title {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;
      }
      .documents__deadlines--side__value {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  &__add {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &--title {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 550px;
    }
    &--uploads {
      display: flex;
      gap: 20px;
      .file-input {
        width: 100%;
      }
    }

    &--upload {
      width: 100%;

      span {
        color: #ffffff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        opacity: 0.6;
      }
    }
  }
}

@media (max-width: $lg) {
  .documents {
    padding: 30px 16px;
    &__deadlines {
      flex-direction: column;
    }
    &__add {
      &--uploads {
        flex-direction: column;
      }
    }
  }
}
</style>
