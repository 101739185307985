<template>
  <a href="https://www.tiktok.com/@ffins.kz" target="_blank">
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_25747_39772)">
        <path
          d="M32 10.3333V23.6667C32 25.8768 31.122 27.9964 29.5592 29.5592C27.9964 31.122 25.8768 32 23.6667 32H10.3333C8.1232 32 6.00358 31.122 4.44078 29.5592C2.87797 27.9964 2 25.8768 2 23.6667V10.3333C2 8.1232 2.87797 6.00358 4.44078 4.44078C6.00358 2.87797 8.1232 2 10.3333 2H23.6667C25.8768 2 27.9964 2.87797 29.5592 4.44078C31.122 6.00358 32 8.1232 32 10.3333Z"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M13.6641 17C12.6752 17 11.7085 17.2932 10.8862 17.8427C10.064 18.3921 9.42311 19.173 9.04467 20.0866C8.66623 21.0002 8.56721 22.0055 8.76014 22.9755C8.95306 23.9454 9.42927 24.8363 10.1285 25.5355C10.8278 26.2348 11.7187 26.711 12.6886 26.9039C13.6585 27.0969 14.6639 26.9978 15.5775 26.6194C16.4911 26.241 17.272 25.6001 17.8214 24.7779C18.3708 23.9556 18.6641 22.9889 18.6641 22V7C19.2191 8.66667 21.3307 12 25.3307 12"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_25747_39772">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </a>
</template>
<script setup></script>
