<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

interface IProps {
  position: string
  isVisible: boolean
  isSheet: boolean
  descriptionNotBankClient: string
  url: string
}

const emit = defineEmits(["update:isVisible"])

const closeSheet = () => {
  emit("update:isVisible", false)
}

const props = withDefaults(defineProps<IProps>(), {
  position: "fixed",
  isVisible: false,
  isSheet: true
})

const {openLink} = useHelpers()

const handleLinkOpen = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  if (target.id === "link") {
    openLink(props.url, "_blank")
  }
}
</script>

<template>
  <div v-if="isSheet" :class="['backdrop-sheet', {'backdrop-sheet--visible': isVisible}]">
    <div class="backdrop-sheet__tick"></div>
    <div class="backdrop-sheet__content">
      <p class="backdrop-sheet__title">
        <slot name="title" />
      </p>
      <p class="backdrop-sheet__description" v-html="descriptionNotBankClient" @click="handleLinkOpen"></p>
    </div>
    <div class="backdrop-sheet__bottom">
      <FFButton
        :title="$t('app.buttons.thankYou')"
        @click="closeSheet"
        :uppercase="false"
        color="#ffffff"
        colorMobile="#ffffff"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)" />
    </div>
  </div>

  <template v-if="!isSheet && isVisible">
    <div class="backdrop-sheet-modal__overlay"></div>
    <div :class="['backdrop-sheet-modal', {'backdrop-sheet-modal--visible': isVisible}]">
      <div class="backdrop-sheet-modal__content">
        <p class="backdrop-sheet-modal__title">
          <slot name="title" />
        </p>
        <p class="backdrop-sheet-modal__description" v-html="descriptionNotBankClient" @click="handleLinkOpen"></p>
      </div>
      <div class="backdrop-sheet-modal__bottom">
        <FFButton
          :title="$t('app.buttons.thankYou')"
          @click="closeSheet"
          :uppercase="false"
          color="#ffffff"
          colorMobile="#ffffff"
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)" />
      </div>
    </div>
  </template>
</template>

<style scoped lang="scss">
.backdrop-sheet {
  position: v-bind(position);
  bottom: -100%;
  left: 0;
  right: 0;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: bottom 0.3s ease-in-out;
  z-index: 9999;
  .backdrop-sheet__tick {
    display: flex;
    align-items: center;
    width: 36px;
    height: 5px;
    border-radius: 100px;
    background: rgba(60, 60, 67, 0.3);
    margin: 9px auto 0 auto;
  }
  .backdrop-sheet__content {
    display: flex;
    padding: 25px 16px 0 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 28px;
  }
  &.backdrop-sheet--visible {
    bottom: 0;
    z-index: 9999;
    .backdrop-sheet__title {
      color: #333333;
      font-size: 21px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      max-width: 400px;
    }
    .backdrop-sheet__description {
      color: #828282;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .backdrop-sheet__bottom {
    padding: 0 16px;
    margin-bottom: 20px;
  }
}

.backdrop-sheet-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: bottom 0.3s ease-in-out;
}
.backdrop-sheet-modal {
  position: v-bind(position);
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 347px;
  height: fit-content;
  margin: auto;
  z-index: 100;
  background-color: white;
  border-radius: 10px;
  transition: bottom 0.3s ease-in-out;
  z-index: 9999;
  .backdrop-sheet-modal__content {
    display: flex;
    padding: 16px 16px 0 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 28px;
  }
  &.backdrop-sheet-modal--visible {
    display: block;
    .backdrop-sheet-modal__title {
      color: #333333;
      font-size: 21px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      width: 220px;
    }
    .backdrop-sheet-modal__description {
      color: #828282;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .backdrop-sheet-modal__bottom {
    padding: 0 16px;
    margin-bottom: 20px;
  }
}
</style>
