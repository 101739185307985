<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useOgpoJuridicalStore} from "@/stores/OgpoJuridicalStore"
import OgpoJuridicalHero from "@/components/OgpoJuridical/OgpoJuridicalHero.vue"
import OgpoJuridicalInsuranceConditions from "@/components/OgpoJuridical/OgpoJuridicalInsuranceConditions.vue"
import OgpoJuridicalUniqueAbilities from "@/components/OgpoJuridical/OgpoJuridicalUniqueAbilities.vue"
import OgpoJuridicalStatistics from "@/components/OgpoJuridical/OgpoJuridicalStatistics.vue"
import OgpoJuridicalArrange from "@/components/OgpoJuridical/OgpoJuridicalArrange.vue"
import OgpoJuridicalAnyQuestions from "@/components/OgpoJuridical/OgpoJuridicalAnyQuestions.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const ogpoJuridicalStore = useOgpoJuridicalStore()
const {loading} = storeToRefs(ogpoJuridicalStore)
</script>

<template>
  <Modal style="z-index: 103" :isOpen="loading">
    <Loader />
  </Modal>
  <div class="ogpo-juridical">
    <OgpoJuridicalHero />
    <OgpoJuridicalInsuranceConditions />
    <OgpoJuridicalUniqueAbilities />
    <OgpoJuridicalStatistics />
    <OgpoJuridicalArrange />
    <OgpoJuridicalAnyQuestions />
  </div>
</template>

<style scoped lang="scss">
.ogpo-juridical {
  .ogpo-juridical__hero-img {
    background-image: url("/ogpo-juridical-front/images/black-car-hero-bg-desk.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: -99px;
    bottom: 0;
    max-width: 1920px;
    height: auto;
  }
  .ogpo-juridical__megapolis-car-roads-img {
    background-image: url(/ogpo-juridical-front/images/megapolis-car-roads.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 2200px;
    bottom: 0;
    max-width: 1920px;
    height: 618px;

    @include max-responsive(lg) {
      top: 3308px;
    }
  }
}
</style>
