<script lang="ts" setup>
import {vMaska} from "maska"
import {useRouter} from "vue-router"
import {storeToRefs} from "pinia"
import {useHomeProtectStore} from "@/stores/HomeProtectStore"
import {onPhoneFocus} from "@/utils/inputEvents"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const homeProtectStore = useHomeProtectStore()
const {kaspiPayPhoneNumber} = storeToRefs(homeProtectStore)
const router = useRouter()
</script>

<template>
  <div class="kaspi-payment-form">
    <FFFieldset :title="$t('app.mstPage.form.paymentForm.linkedPhoneNumberToKaspi')">
      <FFInput
        v-maska
        data-maska="+7 (###) ### ####"
        placeholder="+7 (___) ___ ____"
        id="phone"
        v-model="kaspiPayPhoneNumber"
        name="phone"
        @focus="onPhoneFocus"
        type="tel" />
    </FFFieldset>
    <FFButton
      @click="homeProtectStore.finalPay"
      :title="$t('app.mstPage.form.paymentForm.sendBillToKaspi')"
      :uppercase="false" />
  </div>
</template>

<style scoped lang="scss">
.kaspi-payment-form {
  margin-top: 20px;
  .input-fieldset {
    margin-bottom: 20px;
  }
}
</style>
