import {ref} from "vue"
import {defineStore} from "pinia"
import {extend} from "@vue/shared"

const component = extend({})
type VueComponent = InstanceType<typeof component>

interface IModalProps {
  component: VueComponent
  props?: object
  isOpen?: boolean
}

const basicState: IModalProps = {
  component: null,
  props: {},
  isOpen: false
}

export const useModalStore = defineStore("modalStore", () => {
  let modalState = ref<IModalProps>(basicState)
  const body = document.querySelector("body") as HTMLBodyElement

  const openModal = (payload: IModalProps) => {
    modalState.value.component = payload.component
    modalState.value.props = payload.props || {}
    modalState.value.isOpen = true
    body.style.overflow = "hidden"
  }

  const closeModal = () => {
    modalState.value = basicState
    modalState.value.isOpen = false
    body.style.overflow = "auto"
  }

  return {modalState, openModal, closeModal}
})
