<script setup lang="ts">
import {ref, onMounted, onUnmounted, watch, computed} from "vue"
import {storeToRefs} from "pinia"
import {useRoute} from "vue-router"
import {useI18n} from "vue-i18n"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import {useTerminationSubscriptionAgreementStore} from "@/stores/TerminationSubscriptionAgreementStore"
import {useModalStore} from "@/stores/ModalStore"
import {useFormatters} from "@/utils/formatters"
import TerminationWelcomeModal from "@/components/TerminationAgreement/TerminationWelcomeModal.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFSelect from "@/components/FFUI/FFSelect.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import Skeleton from "primevue/skeleton"

const terminationSubscriptionAgreementStore = useTerminationSubscriptionAgreementStore()
const {
  terminationByGlobalId,
  terminationBySubsRegNum,
  terminationReason,
  terminationInsSum,
  terminationReasons,
  selectedReason,
  withoutRefund,
  subscriptionWithoutRefund,
  isProductCodeKaskoSubscription
} = storeToRefs(terminationSubscriptionAgreementStore)
const modalStore = useModalStore()
const personalProfileStore = usePersonalProfileStore()
const {currentPolicie} = storeToRefs(personalProfileStore)
const {convertISOToDate} = useFormatters()
const route = useRoute()

const {t} = useI18n()

const reasonsList = ref([])
const subsRegNum = route.params.id
const productCodeFromParams = route.params.product
const langFromRoute = ref(route.params.lang)

const fetchTerminationReasons = async () => {
  await terminationSubscriptionAgreementStore.getTerminationReasons()
  if (terminationReasons.value.length) {
    reasonsList.value = terminationReasons.value.map((reason, index) => ({
      id: index,
      name: reason.name,
      reasonCode: reason.code,
      value: index,
      checked: false
    }))
  }
}

onMounted(async () => {
  if (subsRegNum) {
    const response = await terminationSubscriptionAgreementStore.getTerminationSubscriptionAgreementBySubsRegNum(
      subsRegNum as string
    )
    if (response) {
      await fetchTerminationReasons()
    } else {
      return
    }
  }
})

onUnmounted(() => {
  modalStore.closeModal()
})

const productCode = route.params.product
const terminationReasonTitle = computed(() => {
  if (productCode === "KASKO_CLASSIC") {
    return t("app.addDriverAuto.iAskYouToTerminatePolicie-1-kasko-classic-subscription")
  } else if (productCode === "KASKO_VIP") {
    return t("app.addDriverAuto.iAskYouToTerminatePolicie-1-kasko-vip-subscription")
  } else if (productCode === "KASKO_EXPRESS") {
    return t("app.addDriverAuto.iAskYouToTerminatePolicie-1-kasko-express-subscription")
  } else if (productCode === "367" || productCode === "Freedom Comfort" || productCode === "FFINS_COMFORT") {
    return t("app.addDriverAuto.iAskYouToTerminatePolicie-1-comfort-subscription")
  } else {
    return ""
  }
})

const isOgpoPlus = computed(() => {
  return Boolean(terminationByGlobalId?.value?.globalIdOgpoPlus)
})

watch(terminationReason, async (newVal) => {
  if (newVal && terminationBySubsRegNum.value) {
    selectedReason.value = reasonsList.value.find((reason) => reason.name === newVal.name)
    if (selectedReason) {
      const response = await terminationSubscriptionAgreementStore.calculateSubscriptionTerminateSum(
        terminationBySubsRegNum.value.subscriptionRegNum,
        selectedReason.value.reasonCode,
        subscriptionWithoutRefund.value
      )
      if (!response) {
        terminationReason.value = null
        terminationInsSum.value = null
      }
    }
  }
})

watch(subsRegNum, async (newVal) => {
  if (newVal) {
    await terminationSubscriptionAgreementStore.getTerminationSubscriptionAgreementBySubsRegNum(
      newVal as string,
      productCodeFromParams as string
    )
  }
})

watch(
  () => route.params.lang,
  async (newLang) => {
    langFromRoute.value = newLang
    await fetchTerminationReasons()
  }
)
</script>
<template>
  <div class="reason">
    <p class="reason__title">
      {{ terminationReasonTitle }}
    </p>
    <template v-for="(item, index) in terminationBySubsRegNum?.items" :key="index">
      <div v-if="item.statusCode === 'Paid' && item.policy !== null" class="reason__deadlines">
        <div class="reason__deadlines--side">
          <p class="reason__deadlines--side__title">{{ $t("app.terminationAgreementPage.agreementNumber") }}</p>
          <Skeleton v-if="!item.policy?.globalId" width="10rem" height="1.3rem" />
          <p v-else class="reason__deadlines--side__value">{{ item.policy?.globalId }}</p>
        </div>
        <div class="reason__deadlines--side">
          <p class="reason__deadlines--side__title">{{ $t("app.terminationAgreementPage.agreementStartDate") }}</p>
          <Skeleton v-if="!item.policy?.fromDate" width="7rem" height="1.3rem" />
          <p v-else class="reason__deadlines--side__value">{{ item.policy?.fromDate }}</p>
        </div>
      </div>
    </template>
    <div>
      <div class="reason__list">
        <div>
          <FFFieldset class="fixed-fieldset" :title="$t('app.terminationAgreementPage.terminationReason')">
            <FFSelect
              :multiple="false"
              :options="reasonsList"
              v-model="terminationReason"
              :title="$t('app.declareInsuredEvent.choose')" />
          </FFFieldset>
        </div>
        <FFCheckbox
          v-if="Boolean(terminationReason)"
          :title="$t('app.terminationAgreementPage.terminateAgreementWithoutRefund')"
          font-weight="400"
          v-model="subscriptionWithoutRefund" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.reason {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  gap: 50px;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  margin: 50px 0;
  &__title {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__deadlines {
    display: flex;
    gap: 20px;
    &--side {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      &__title {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;
      }
      &__value {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }
  &__add {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &--title {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &--uploads {
      display: flex;
      gap: 20px;
    }
    &--upload {
      width: 100%;

      span {
        color: #ffffff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        opacity: 0.6;
      }
    }
  }
}

@media (max-width: $lg) {
  .reason {
    padding: 30px 16px;
    gap: 30px;
    .reason__deadlines {
      flex-direction: column;
    }
    &__add {
      &--uploads {
        flex-direction: column;
      }
    }
  }
}
</style>

