<script setup lang="ts">
import {watchEffect, onMounted, onUnmounted} from "vue"
import {useRoute} from "vue-router"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useOgpoStore} from "@/stores/OgpoStore"
import {useHelperStore} from "@/stores/HelperStore"
import OgpoHero from "@/components/Ogpo/OgpoHero.vue"
import OgpoAgreement from "@/components/Ogpo/OgpoAgreement.vue"
import OgpoGuarantee from "@/components/Ogpo/OgpoGuarantee.vue"
import OgpoStatistics from "@/components/Ogpo/OgpoStatistics.vue"
import OgpoArrangeCarInsurance from "@/components/Ogpo/OgpoArrangeCarinsurance.vue"
import OgpoAnyQuestions from "@/components/Ogpo/OgpoAnyQuestions.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const helperStore = useHelperStore()
const {t} = useI18n()
const ogpoStore = useOgpoStore()
const {loading, agreementLoaderHide} = storeToRefs(ogpoStore)

const route = useRoute()

watchEffect(() => {
  helperStore.pageTitle = t("app.headerPageTitles.ogpoPage")
})

onMounted(() => {
  localStorage.removeItem("paymentType")
  localStorage.removeItem("isClientBank")
  localStorage.removeItem("cashbackAmount")
  localStorage.removeItem("cashbackBalance")
})

onUnmounted(() => {
  ogpoStore.resetStates()
})
</script>

<template>
  <template v-if="agreementLoaderHide"> </template>
  <template v-else>
    <Modal style="z-index: 103" :isOpen="loading">
      <Loader />
    </Modal>
  </template>
  <div class="ogpo container">
    <div class="ogpo__cars-bg-desk"></div>
    <div class="ogpo__cars-bg-tablet"></div>
    <div class="ogpo__cars-bg-md-mobile"></div>
    <OgpoHero />
    <OgpoAgreement />
    <OgpoGuarantee />
    <OgpoStatistics />
    <div class="ogpo__autumn-road-bg-desk"></div>
    <div class="ogpo__autumn-road-bg-tablet"></div>
    <OgpoArrangeCarInsurance />
    <OgpoAnyQuestions />
  </div>
</template>

<style scoped lang="scss">
.ogpo {
  ::v-deep(.dp__input_icon) {
    inset-inline-start: unset;
    right: 6px;
    top: 45%;
  }
  ::v-deep(.ff-form .dp__input_icon) {
    inset-inline-start: unset;
    right: 0px;
    top: 45%;
  }
  ::v-deep(.date-time-picker-icon) {
    width: 27px;
    height: 27px;
  }
  ::v-deep(.dp__input_icon_pad) {
    padding-inline-start: unset;
    padding-left: 15px;
    font-size: 16px !important;
  }
  ::v-deep(.dp__pointer .db__input_readonly .dp__input_icon_pad) {
    padding-inline-start: unset !important;
  }
  .ogpo__cars-bg-desk {
    position: absolute;
    background-image: url("/ogpo-front/images/cars-bg-desk.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 2934px;
    z-index: 0;

    @include max-responsive(xxl) {
      display: none;
    }
  }
  .ogpo__cars-bg-tablet {
    display: none;

    @include max-responsive(xxl) {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/cars-bg-tablet.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: 3534px;
      z-index: 0;
    }

    @include max-responsive(lg) {
      display: none;
    }
  }
  .ogpo__cars-bg-md-mobile {
    display: none;

    @include max-responsive(lg) {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/cars-bg-md-mobile.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: 3500px;
      z-index: 0;
    }
  }
  .ogpo__autumn-road-bg-desk {
    position: absolute;
    background-image: url("/ogpo-front/images/autumn-road-bg-desk.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 2764px;
    min-height: 820px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    @include max-responsive(xxlWide) {
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-desk.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 2606px;
      min-height: 820px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    @include max-responsive(wide) {
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-desk.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 2534px;
      min-height: 820px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    @include max-responsive(xxl) {
      display: none;
    }
  }
  .ogpo__autumn-road-bg-tablet {
    display: none;

    @include max-responsive(xxl) {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-tablet.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 2884px;
      min-height: 652px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    @include max-responsive(xl) {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-tablet.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 3706px;
      min-height: 652px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    @include max-responsive(768px) {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-sm-tablet.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 3800px;
      min-height: 652px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    @include max-responsive(lg) {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-sm-tablet.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 3800px;
      min-height: 652px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    @include max-responsive(md) {
      display: none;
    }
  }
}
</style>
