<script setup lang="ts"></script>

<template>
  <section class="juridical-unique-abilities container">
    <p class="juridical-unique-abilities__title">
      <slot name="title" />
    </p>
    <div class="juridical-unique-abilities__cards">
      <div class="juridical-unique-abilities__card">
        <slot name="icon1" />
        <p class="juridical-unique-abilities__card-name">
          <slot name="name1" />
        </p>
        <p class="juridical-unique-abilities__card-description">
          <slot name="description1" />
        </p>
      </div>

      <div class="juridical-unique-abilities__card">
        <slot name="icon2" />
        <p class="juridical-unique-abilities__card-name">
          <slot name="name2" />
        </p>
        <p class="juridical-unique-abilities__card-description">
          <slot name="description2" />
        </p>
      </div>

      <div class="juridical-unique-abilities__card">
        <slot name="icon3" />
        <p class="juridical-unique-abilities__card-name">
          <slot name="name3" />
        </p>
        <p class="juridical-unique-abilities__card-description">
          <slot name="description3" />
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.juridical-unique-abilities {
  margin-top: 150px;
  margin-bottom: 150px;

  @include max-responsive(lg) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .juridical-unique-abilities__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 50px;
    @include main-green-gradient;
  }

  .juridical-unique-abilities__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, 354px);
    grid-gap: 24px;

    @include max-responsive(xxl) {
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: auto auto; 

      .juridical-unique-abilities__card:nth-child(3) {
        grid-column: 1 / -1; 
        grid-row: 2;
      }
    }

    @include max-responsive(lg) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    .juridical-unique-abilities__card {
      gap: 20px;
      align-items: start;
      padding: 24px;
      border-radius: 6px;
      border: 1px solid rgba(27, 27, 27, 0.6) 100%;
      background: radial-gradient(54.59% 70.24% at 0% 100%, rgba(26, 46, 34, 0.6) 0%, rgba(27, 27, 27, 0.6) 100%);
      backdrop-filter: blur(8px);

      .juridical-unique-abilities__card-name {
        color: var(--color-white);
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
        margin-top: 24px;
      }

      .juridical-unique-abilities__card-description {
        color: #dbdbdb;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 22.4px;
        margin-top: 24px;
      }
    }
  }
}
</style>
