<script setup lang="ts">
import {reactive, computed, type ComputedRef} from "vue"
import {useI18n} from "vue-i18n"

interface IAgreementSteps {
  step: number
  title: ComputedRef<string>
  description: ComputedRef<string>
}

const {t} = useI18n()

const executingSteps = reactive<IAgreementSteps[]>([
  {
    step: 1,
    title: computed(() => t("app.ogpoPage.agreement.title-1")),
    description: computed(() => t("app.ogpoPage.agreement.paragraph-1"))
  },
  {
    step: 2,
    title: computed(() => t("app.ogpoPage.agreement.title-2")),
    description: computed(() => t("app.ogpoPage.agreement.paragraph-2"))
  },
  {
    step: 3,
    title: computed(() => t("app.ogpoPage.agreement.title-3")),
    description: computed(() => t("app.ogpoPage.agreement.paragraph-3"))
  },
  {
    step: 4,
    title: computed(() => t("app.ogpoPage.agreement.title-4")),
    description: computed(() => t("app.ogpoPage.agreement.paragraph-4"))
  }
])
</script>

<template>
  <section class="ogpo-agreement">
    <div class="ogpo-agreement__left-part">
      <img
        class="ogpo-agreement__insurance-polise-desk"
        src="/ogpo-front/images/insurance-polise-desk.png"
        alt=""
        draggable="false" />
      <img
        class="ogpo-agreement__insurance-polise-tablet"
        src="/ogpo-front/images/insurance-polise-tablet.png"
        alt=""
        draggable="false" />
      <img
        class="ogpo-agreement__insurance-polise-mobile"
        src="/ogpo-front/images/insurance-polise-mobile.png"
        alt="" />
    </div>
    <div class="ogpo-agreement__right-part">
      <p class="ogpo-agreement__right-part-title">{{ $t("app.ogpoPage.agreement.fiveMinutesToArrange") }}</p>
      <div class="ogpo-agreement__right-part-executation-list">
        <div v-for="(step, index) in executingSteps" :key="index" class="ogpo-agreement__right-part-executation-row">
          <div class="ogpo-agreement__executation-row-number">{{ step.step }}</div>
          <div class="ogpo-agreement__executation-row-texts">
            <p class="ogpo-agreement__executation-row-title">{{ step.title }}</p>
            <p class="ogpo-agreement__executation-row-description">{{ step.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.ogpo-agreement {
  position: relative;
  z-index: 0;
  display: flex;
  gap: 30px;

  @include max-responsive(xxl) {
    display: flex;
    flex-direction: column;
  }
  .ogpo-agreement__left-part {
    .ogpo-agreement__insurance-polise-desk {
      min-width: 445px;
      height: 579px;

      @include max-responsive(xxl) {
        display: none;
      }
    }

    .ogpo-agreement__insurance-polise-tablet {
      display: none;

      @include max-responsive(xxl) {
        display: flex;
        min-width: 690px;
        height: 481px;
      }

      @include max-responsive(721px) {
        display: none;
      }
    }

    .ogpo-agreement__insurance-polise-mobile {
      display: none;

      @include max-responsive(721px) {
        display: flex;
        height: 481px;
        max-width: 343px;
        width: 100%;
      }
    }
  }
  .ogpo-agreement__right-part {
    .ogpo-agreement__right-part-title {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 40px;

      background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 50px;

      @include max-responsive(721px) {
        font-size: 30px;
      }
    }
    .ogpo-agreement__right-part-executation-list {
      display: flex;
      flex-direction: column;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 20px;
        height: 350px;
        width: 1px;
        border-right: 1px solid #ffffff;
        border-style: dashed;
        z-index: 0;

        @include max-responsive(xxl) {
          content: "";
          height: 340px;
        }

        @include max-responsive(721px) {
          content: "";
          position: absolute;
          top: 5px;
          left: 20px;
          height: 412px;
          width: 1px;
          border-right: 1px solid #ffffff;
          border-style: dashed;
          z-index: 0;
        }

        @include max-responsive(md) {
          content: "";
          height: 440px;
        }
      }

      .ogpo-agreement__right-part-executation-row {
        display: flex;
        gap: 9px;
        z-index: 1;

        &:not(:last-of-type) {
          margin-bottom: 50px;
        }

        .ogpo-agreement__executation-row-number {
          display: flex;
          height: min-content;
          color: #ffffff;
          text-align: center;
          font-size: 15.628px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 10px 15px;
          border-radius: 100px;
          border: 1px solid #ffffff;
          background-color: #3d3d3d;
        }

        .ogpo-agreement__executation-row-texts {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .ogpo-agreement__executation-row-title {
            color: #ffffff;
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
            line-height: 18.36px;
            text-transform: uppercase;
          }
          .ogpo-agreement__executation-row-description {
            color: #ffffff;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
