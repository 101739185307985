import zod from "zod"

export const emailFormSchema = zod.object({
  email: zod.string().min(1).email(),
  iAgreeWithOgpoPlusAgreement: zod.boolean().refine((value, context) => {
    if (context?.parent?.addOgpoPlus && !value) {
      return false
    }
    return true
  }),
  iAgreeWithAgreement: zod.boolean().refine((value) => value === true)
})
