<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import {externalUrls} from "@/constants/urls"
import GooglePlayMarketDownloadIcon from "@/components/icons/socials/GooglePlayMarketDownloadIcon.vue"
import AppleStoreDownloadIcon from "@/components/icons/socials/AppleStoreDownloadIcon.vue"

const {currentLocale, openLink} = useHelpers()
</script>

<template>
  <section class="dtp-hero container">
    <div class="dtp-hero__left-part">
      <div class="dtp-hero__title-wrapper">
        <p class="dtp-hero__title">{{ $t("app.dtpLandingPage.title-1") }}</p>
        <p class="dtp-hero__title">{{ $t("app.dtpLandingPage.title-2") }}</p>
        <p class="dtp-hero__title">{{ $t("app.dtpLandingPage.title-3") }}</p>

        <img
          v-if="currentLocale === 'ru'"
          class="dtp-hero__or-more-ru"
          src="/dtp-landing-front/images/or-more-ru.webp"
          alt="" />
        <img
          v-if="currentLocale === 'kk'"
          class="dtp-hero__or-more-kk"
          src="/dtp-landing-front/images/or-more-kk.webp"
          alt="" />
        <img
          v-if="currentLocale === 'en'"
          class="dtp-hero__or-more-en"
          src="/dtp-landing-front/images/or-more-en.webp"
          alt="" />
      </div>
      <p class="dtp-hero__description">{{ $t("app.dtpLandingPage.description") }}</p>
      <div class="dtp-hero__mobile-platforms">
        <GooglePlayMarketDownloadIcon @click="openLink(externalUrls.dtpMobileAppGoogleStoreUrl, '_blank')" />
        <AppleStoreDownloadIcon @click="openLink(externalUrls.dtpMobileAppAppStoreUrl, '_blank')" />
      </div>
      <p class="dtp-hero__remark">{{ $t("app.dtpLandingPage.remark") }}</p>
    </div>
    <div class="dtp-hero__right-part">
      <img class="dtp-hero__dtp-man-image" src="/dtp-landing-front/images/dtp-man.webp" alt="Dtp.kz man" />
      <img
        @click="openLink(externalUrls.dtpMobileAppYouTubeUrl, '_blank')"
        class="dtp-hero__video-button-image"
        src="/dtp-landing-front/images/play-video-button.webp"
        alt="play video button" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.dtp-hero {
  @include row;
  padding-top: 60px;
  z-index: 1;
  position: relative;

  @include max-responsive(lg) {
    align-items: unset;
    padding-top: 350px;
  }
  .dtp-hero__left-part {
    max-width: 665px;
    .dtp-hero__title-wrapper {
      margin-bottom: 30px;
      position: relative;

      .dtp-hero__title {
        font-size: 60px;
        font-style: normal;
        font-weight: 900;
        line-height: 60px;
        @include main-green-gradient;

        @include max-responsive(lg) {
          font-size: 36px;
          font-style: normal;
          font-weight: 900;
          line-height: 36px;
        }
      }

      .dtp-hero__or-more-ru {
        position: absolute;
        top: -55px;
        right: 29px;
        transform: rotate(-11.528deg);
        width: 203px;
        height: 106px;

        @include max-responsive(xl) {
          top: -78px;
          right: 126px;
        }

        @include max-responsive(lg) {
          top: -56px;
          right: -8px;
          width: 180px;
          height: 86px;
          z-index: 1;
        }
      }

      .dtp-hero__or-more-kk {
        position: absolute;
        top: -71px;
        right: -21px;
        transform: rotate(-11.528deg);
        width: 291px;
        height: 145px;

        @include max-responsive(xl) {
          top: -78px;
          right: 126px;
        }

        @include max-responsive(lg) {
          top: -56px;
          right: 1px;
          width: 189px;
          height: 94px;
          z-index: 1;
        }
      }

      .dtp-hero__or-more-en {
        position: absolute;
        top: -18px;
        right: 29px;
        transform: rotate(-11.528deg);
        width: 134px;
        height: 90px;

        @include max-responsive(xl) {
          top: -9px;
          right: 147px;
        }

        @include max-responsive(lg) {
          top: -68px;
          right: 179px;
          width: 128px;
          height: 86px;
          z-index: 1;
        }
      }
    }
    .dtp-hero__description {
      color: var(--color-white);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22.4px;
      margin-bottom: 50px;
    }
    .dtp-hero__mobile-platforms {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;

      svg {
        width: 200px;
        height: 59.355px;
        cursor: pointer;
        transition: all 0.2s ease;

        @include min-responsive(xl) {
          &:hover {
            scale: 1.03;
            transition: scale 0.2s ease;
          }
        }

        @include max-responsive(lg) {
          width: 163.333px;
          height: 48.473px;
        }
      }
    }
    .dtp-hero__remark {
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
  }
  .dtp-hero__right-part {
    position: relative;
    max-width: 459px;
    height: 629px;

    .dtp-hero__dtp-man-image {
      position: absolute;
      top: -35px;
      max-width: 459px;
      height: 629px;

      @include max-responsive(xl) {
        opacity: 0.3;
        right: -162px;
        width: 335px;
        height: 459px;
      }

      @include max-responsive(lg) {
        opacity: 1;
        width: 280.152px;
        height: 383.85px;
        transform: rotate(0.127deg);
        right: 36px;
        top: -367px;
      }
    }
    .dtp-hero__video-button-image {
      position: absolute;
      max-width: 459px;
      height: 210px;
      z-index: 1;
      top: 245px;
      left: 126px;
      cursor: pointer;
      transition: all 0.2s ease;

      @include min-responsive(xl) {
        &:hover {
          scale: 1.1;
          transition: scale 0.2s ease;
        }
      }

      @include max-responsive(xxl) {
        z-index: 1;
        top: 371px;
        left: 64px;
      }

      @include max-responsive(xl) {
        top: 348px;
        left: -186px;
      }

      @include max-responsive(lg) {
        top: -203px;
        left: -236px;
        width: 130px;
        height: 130px;
      }
    }
  }
}
</style>
