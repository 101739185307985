<script setup lang="ts">
import {ref, computed} from "vue"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useComfortStore} from "@/stores/ComfortStore"
import {usePaymentStore} from "@/stores/Payment"
import {useFormatters} from "@/utils/formatters"
import FreedomPayWhiteIcon from "@/components/icons/payment/FreedomPayWhiteIcon.vue"
import FFRadiobutton from "@/components/FFUI/FFRadiobutton.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"

const comfortStore = useComfortStore()
const {selectedPayment, showKaspiForm, clientCardData, payMonthly, selectedCardType, clientCardResponse} =
  storeToRefs(comfortStore)
const paymentStore = usePaymentStore()
const {paymentMethodsWithoutSuperApp} = storeToRefs(paymentStore)
const {formatCardHash} = useFormatters()
const {t} = useI18n()

const emit = defineEmits(["kaspiFormNext"])
const iAgree = ref<boolean>(false)

const handleKaspiFormNext = async () => {
  if (selectedPayment.value === "KaspiPay") {
    showKaspiForm.value = true
  } else if (selectedPayment.value === "FreedomPay") {
    await comfortStore.finalPay()
  }
}

const currentCardTypeVariants = ref<number[]>([1, 2])

const currentCardTypeVariantTitles = ref<any>([
  {
    title: computed(
      () => `${t("app.kaskoPage.forms.execution.card")} ${formatCardHash(clientCardData.value?.cardHash)}`
    )
  },
  {
    title: computed(() => t("app.kaskoPage.forms.execution.anotherCard"))
  }
])

const handleCurrentPaymentCardType = (type: number) => {
  selectedCardType.value = type
}
</script>

<template>
  <div v-if="payMonthly && clientCardResponse?.length !== 0" class="payment-method-form__payment-type-wrapper">
    <div
      @click="handleCurrentPaymentCardType(variant)"
      v-for="(variant, index) in currentCardTypeVariants"
      :key="index"
      class="payment-method-form__payment-type"
      :class="{'payment-method--selected': selectedCardType === variant}">
      <FFRadiobutton id="payment-type" v-model="selectedCardType" name="payment" :value="variant" />
      <p>{{ currentCardTypeVariantTitles[variant - 1].title }}</p>
    </div>
  </div>
  <div v-else class="payment-method-form">
    <template v-for="payment in paymentMethodsWithoutSuperApp" :key="payment.id">
      <div
        @click="comfortStore.handleCurrentMethod(payment.type)"
        class="payment-method"
        :class="{'payment-method--selected': selectedPayment === payment.type}">
        <FFRadiobutton id="bank" v-model="selectedPayment" name="payment" :value="payment.type" />
        <component :is="payment.icon" class="payment-method__icon" />
        <div @click="comfortStore.handleCurrentMethod(payment.type)" class="payment-method__wrapper">
          <p>{{ payment.name }}</p>
          <p>{{ payment.subText }}</p>
        </div>
      </div>
    </template>
  </div>
  <FFButton
    @click="payMonthly && clientCardResponse?.length !== 0 ? comfortStore.subscriptionFinalPay() : handleKaspiFormNext()"
    :title="$t('app.buttons.pay')"
    :uppercase="false"
    :disabled="payMonthly ? false : selectedPayment === ''" />
</template>

<style lang="scss" scoped>
.payment-method-form__payment-type-wrapper {
  @include column;
  gap: 20px;
  margin: 20px 0 50px;
  .payment-method-form__payment-type {
    @include flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: rgba(226, 226, 226, 0.17);
    border: 1px solid transparent;
    cursor: pointer;

    &--selected {
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: rgba(0, 0, 0, 0.25);
    }

    p {
      color: var(--color-white);
      text-align: start;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.payment-method-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;
  .ff-checkbox {
    align-items: start;
  }
  .payment-method {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: rgba(226, 226, 226, 0.17);
    border: 1px solid transparent;
    cursor: pointer;

    &--selected {
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: rgba(0, 0, 0, 0.25);
    }

    .payment-method__icon {
      margin-left: 13px;
    }

    .payment-method__freedom-icon {
      margin-left: auto;
    }

    .payment-method__wrapper {
      margin-left: 7px;
      p:first-of-type {
        color: #ffffff;
        text-align: start;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p:last-of-type {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        max-width: 115px;
      }
    }
  }
}
</style>
