<script setup lang="ts">
import {ref} from "vue"
import {vMaska} from "maska"
import {storeToRefs} from "pinia"
import {useKaskoStore} from "@/stores/KaskoStore"
import {useModalStore} from "@/stores/ModalStore"
import {filterCyrillicInput, onPhoneFocus} from "@/utils/inputEvents"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFCheckerInput from "@/components/FFUI/FFCheckerInput.vue"
import AddDriverIcon from "@/components/icons/AddDriverIcon.vue"

const modalStore = useModalStore()
const kaskoStore = useKaskoStore()
const {
  phone,
  autonumtechpass,
  carName,
  isAutoNumsLoadingState,
  isPhoneFilled,
  iin,
  iinInputStates,
  iinName,
  iinNames,
  isIinLoadingState,
  isIinLoadingStates,
  isCarNumberFilled,
  isCalculateButtonDisabled,
  currentTariff
} = storeToRefs(kaskoStore)
const fieldsWrapper = ref(null)
const isExceeded = ref<boolean>(false)

const preventAutoNumCyrillicInput = () => {
  autonumtechpass.value = filterCyrillicInput(autonumtechpass.value)
}
</script>

<template>
  <p class="calc-form__title">{{ $t("form.formHeaders.costCalculation") }}</p>
  <div class="calc-form__fields" ref="fieldsWrapper" :class="{exceeded: isExceeded}">
    <div>
      <FFFieldset :title="$t('form.inputTitles.phone')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          placeholder="+7 (___) ___ ____"
          v-model="phone"
          id="phonenumber"
          type="tel"
          @focus="onPhoneFocus" />
      </FFFieldset>
    </div>
    <div>
      <FFFieldset :title="$t('form.inputTitles.autoNumTechPass')" :locked="!isPhoneFilled">
        <FFCheckerInput
          :padding="
            !isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 7
              ? '11px 5px 7px 14px'
              : '6px 5px 7px 14px'
          "
          :inputHeight="!isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 7 ? '16px' : '51px'"
          placeholder="908LFA02 or A001OMA"
          :needUnderText="!isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 7"
          :underText="!isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 7 ? carName : ''"
          v-model="autonumtechpass"
          id="auto-num-or-tech-passport"
          type="text"
          :loading="isAutoNumsLoadingState"
          :func="
            () => {
              autonumtechpass = autonumtechpass.toUpperCase()
              preventAutoNumCyrillicInput()
            }
          "
          :blur="kaskoStore.handleAutonumBlur"
          :lockedErrorText="$t('app.toasterMessages.inputPhoneNumber')"
          :locked="!isPhoneFilled" />
      </FFFieldset>
    </div>

    <div>
      <div class="calc-form__form-row">
        <FFFieldset :title="$t('form.inputTitles.iin')">
          <FFCheckerInput
            dataMaska="############"
            :padding="
              !isIinLoadingState && Boolean(iinName) && iin.length === 12 ? '11px 5px 7px 14px' : '6px 5px 7px 14px'
            "
            :inputHeight="!isIinLoadingState && Boolean(iinName) && iin.length === 12 ? '16px' : '51px'"
            placeholder="123412545654"
            :needUnderText="!isIinLoadingState && Boolean(iinName)"
            :underText="!isIinLoadingState && Boolean(iinName) && iin.length === 12 ? iinName : ''"
            v-model="iin"
            id="iin"
            type="text"
            :loading="isIinLoadingState"
            inputmode="numeric"
            :func="
              () => {
                kaskoStore.iinCheck(iin)
              }
            "
            lockedErrorText="Укажите номер авто"
            :locked="!isCarNumberFilled" />
        </FFFieldset>
        <div @click="kaskoStore.pushInputToIINArr" class="calc-form__icon-wrapper">
          <AddDriverIcon :activeTab="0" />
        </div>
      </div>
    </div>

    <template v-for="(data, index) in iinInputStates" :key="index">
      <div>
        <FFFieldset :title="$t('form.inputTitles.iin')">
          <FFCheckerInput
            dataMaska="############"
            :padding="
              !isIinLoadingStates[index] &&
              Boolean(iinNames[index]) &&
              iinInputStates[index] &&
              iinInputStates[index].iin?.length === 12
                ? '11px 5px 7px 14px'
                : '6px 5px 7px 14px'
            "
            :inputHeight="
              !isIinLoadingStates[index] &&
              Boolean(iinNames[index]) &&
              iinInputStates[index] &&
              iinInputStates[index].iin?.length === 12
                ? '16px'
                : '51px'
            "
            placeholder="123412545654"
            :needUnderText="
              !isIinLoadingStates[index] &&
              Boolean(iinNames[index]) &&
              iinInputStates[index] &&
              iinInputStates[index].iin?.length === 12
            "
            :underText="
              !isIinLoadingStates[index] &&
              Boolean(iinNames[index]) &&
              iinInputStates[index] &&
              iinInputStates[index].iin?.length === 12
                ? iinNames[index]
                : ''
            "
            v-model="iinInputStates[index].iin"
            id="iin"
            type="text"
            inputmode="numeric"
            :loading="isIinLoadingStates[index]"
            :removeInputText="true"
            :handleRemoveInput="() => kaskoStore.removeIINState(index)"
            :func="
              () => {
                kaskoStore.iinsCheck(iinInputStates[index].iin, index)
              }
            "
            lockedErrorText="Укажите номер авто"
            :locked="!isCarNumberFilled" />
        </FFFieldset>
      </div>
    </template>

    <div>
      <FFFieldset :title="$t('form.inputTitles.promocode')" borderType="dashed">
        <FFInput id="promocode" type="text" />
      </FFFieldset>
    </div>
    <FFButton :title="$t('app.buttons.calculateCost')" @click="kaskoStore.handleNextStepKdpOrTariffs" :disabled="!isCalculateButtonDisabled" />
  </div>
</template>

<style scoped lang="scss">
.calc-form__title {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.calc-form__fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 200px;
  overflow-y: scroll;
  margin-top: 30px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.63);
  }
  gap: 20px;
  .calc-form__form-row {
    display: flex;
    gap: 10px;

    .calc-form__icon-wrapper {
      cursor: pointer;
      border: 2px solid #ffffff;
      height: 46px;
      width: 60px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
    }
  }
}
</style>
