<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="67" height="55" viewBox="0 0 67 55" fill="none">
    <path
      d="M21.9375 9.4375C21.9375 11.6753 21.0485 13.8214 19.4662 15.4037C17.8839 16.9861 15.7378 17.875 13.5 17.875C11.2622 17.875 9.11613 16.9861 7.53379 15.4037C5.95145 13.8214 5.0625 11.6753 5.0625 9.4375C5.0625 7.19974 5.95145 5.05362 7.53379 3.47129C9.11613 1.88895 11.2622 1 13.5 1C15.7378 1 17.8839 1.88895 19.4662 3.47129C21.0485 5.05362 21.9375 7.19974 21.9375 9.4375ZM0 25.4688C0 23.14 1.89 21.25 4.21875 21.25H22.7812C25.11 21.25 27 23.14 27 25.4688V41.5C27 45.0804 25.5777 48.5142 23.0459 51.0459C20.5142 53.5777 17.0804 55 13.5 55C9.91958 55 6.4858 53.5777 3.95406 51.0459C1.42232 48.5142 0 45.0804 0 41.5V25.4688Z"
      fill="url(#paint0_linear_152_3845)" />
    <path
      d="M21.9375 9.4375C21.9375 11.6753 21.0485 13.8214 19.4662 15.4037C17.8839 16.9861 15.7378 17.875 13.5 17.875C11.2622 17.875 9.11613 16.9861 7.53379 15.4037C5.95145 13.8214 5.0625 11.6753 5.0625 9.4375C5.0625 7.19974 5.95145 5.05362 7.53379 3.47129C9.11613 1.88895 11.2622 1 13.5 1C15.7378 1 17.8839 1.88895 19.4662 3.47129C21.0485 5.05362 21.9375 7.19974 21.9375 9.4375ZM0 25.4688C0 23.14 1.89 21.25 4.21875 21.25H22.7812C25.11 21.25 27 23.14 27 25.4688V41.5C27 45.0804 25.5777 48.5142 23.0459 51.0459C20.5142 53.5777 17.0804 55 13.5 55C9.91958 55 6.4858 53.5777 3.95406 51.0459C1.42232 48.5142 0 45.0804 0 41.5V25.4688Z"
      fill="black"
      fill-opacity="0.2" />
    <path
      d="M41.0996 9.4375C41.0996 11.6753 40.2107 13.8214 38.6283 15.4037C37.046 16.9861 34.8999 17.875 32.6621 17.875C30.4243 17.875 28.2782 16.9861 26.6959 15.4037C25.1136 13.8214 24.2246 11.6753 24.2246 9.4375C24.2246 7.19974 25.1136 5.05362 26.6959 3.47129C28.2782 1.88895 30.4243 1 32.6621 1C34.8999 1 37.046 1.88895 38.6283 3.47129C40.2107 5.05362 41.0996 7.19974 41.0996 9.4375ZM19.1621 25.4688C19.1621 23.14 21.0521 21.25 23.3809 21.25H41.9434C44.2721 21.25 46.1621 23.14 46.1621 25.4688V41.5C46.1621 45.0804 44.7398 48.5142 42.2081 51.0459C39.6763 53.5777 36.2425 55 32.6621 55C29.0817 55 25.6479 53.5777 23.1162 51.0459C20.5844 48.5142 19.1621 45.0804 19.1621 41.5V25.4688Z"
      fill="url(#paint1_linear_152_3845)" />
    <path
      d="M41.0996 9.4375C41.0996 11.6753 40.2107 13.8214 38.6283 15.4037C37.046 16.9861 34.8999 17.875 32.6621 17.875C30.4243 17.875 28.2782 16.9861 26.6959 15.4037C25.1136 13.8214 24.2246 11.6753 24.2246 9.4375C24.2246 7.19974 25.1136 5.05362 26.6959 3.47129C28.2782 1.88895 30.4243 1 32.6621 1C34.8999 1 37.046 1.88895 38.6283 3.47129C40.2107 5.05362 41.0996 7.19974 41.0996 9.4375ZM19.1621 25.4688C19.1621 23.14 21.0521 21.25 23.3809 21.25H41.9434C44.2721 21.25 46.1621 23.14 46.1621 25.4688V41.5C46.1621 45.0804 44.7398 48.5142 42.2081 51.0459C39.6763 53.5777 36.2425 55 32.6621 55C29.0817 55 25.6479 53.5777 23.1162 51.0459C20.5844 48.5142 19.1621 45.0804 19.1621 41.5V25.4688Z"
      fill="black"
      fill-opacity="0.2" />
    <path
      d="M61.1309 9.4375C61.1309 11.6753 60.2419 13.8214 58.6596 15.4037C57.0772 16.9861 54.9311 17.875 52.6934 17.875C50.4556 17.875 48.3095 16.9861 46.7271 15.4037C45.1448 13.8214 44.2559 11.6753 44.2559 9.4375C44.2559 7.19974 45.1448 5.05362 46.7271 3.47129C48.3095 1.88895 50.4556 1 52.6934 1C54.9311 1 57.0772 1.88895 58.6596 3.47129C60.2419 5.05362 61.1309 7.19974 61.1309 9.4375ZM39.1934 25.4688C39.1934 23.14 41.0834 21.25 43.4121 21.25H61.9746C64.3034 21.25 66.1934 23.14 66.1934 25.4688V41.5C66.1934 45.0804 64.771 48.5142 62.2393 51.0459C59.7076 53.5777 56.2738 55 52.6934 55C49.1129 55 45.6792 53.5777 43.1474 51.0459C40.6157 48.5142 39.1934 45.0804 39.1934 41.5V25.4688Z"
      fill="url(#paint2_linear_152_3845)" />
    <path
      d="M61.1309 9.4375C61.1309 11.6753 60.2419 13.8214 58.6596 15.4037C57.0772 16.9861 54.9311 17.875 52.6934 17.875C50.4556 17.875 48.3095 16.9861 46.7271 15.4037C45.1448 13.8214 44.2559 11.6753 44.2559 9.4375C44.2559 7.19974 45.1448 5.05362 46.7271 3.47129C48.3095 1.88895 50.4556 1 52.6934 1C54.9311 1 57.0772 1.88895 58.6596 3.47129C60.2419 5.05362 61.1309 7.19974 61.1309 9.4375ZM39.1934 25.4688C39.1934 23.14 41.0834 21.25 43.4121 21.25H61.9746C64.3034 21.25 66.1934 23.14 66.1934 25.4688V41.5C66.1934 45.0804 64.771 48.5142 62.2393 51.0459C59.7076 53.5777 56.2738 55 52.6934 55C49.1129 55 45.6792 53.5777 43.1474 51.0459C40.6157 48.5142 39.1934 45.0804 39.1934 41.5V25.4688Z"
      fill="black"
      fill-opacity="0.2" />
    <g filter="url(#filter0_bd_152_3845)">
      <path
        d="M24.4833 5.26641C27.4098 4.39544 30.5198 3.61304 32.3809 3.61304C34.242 3.61304 37.352 4.39544 40.2785 5.26641C43.2725 6.15215 46.2907 7.20027 48.0655 7.83505C48.8076 8.10328 49.4654 8.5952 49.9635 9.25429C50.4617 9.91339 50.7798 10.7129 50.8815 11.561C52.489 24.7792 48.7587 34.5754 44.2327 41.0561C42.3134 43.8284 40.0249 46.2697 37.4437 48.2984C36.5511 49.0005 35.6054 49.6178 34.6169 50.1437C33.8617 50.5334 33.0498 50.8523 32.3809 50.8523C31.712 50.8523 30.9028 50.5334 30.1449 50.1437C29.1563 49.6178 28.2107 49.0005 27.3181 48.2984C24.7369 46.2696 22.4484 43.8283 20.5291 41.0561C16.0031 34.5754 12.2728 24.7792 13.8803 11.561C13.982 10.7129 14.3001 9.91339 14.7983 9.25429C15.2964 8.5952 15.9542 8.10328 16.6963 7.83505C19.2727 6.91036 21.8691 6.0539 24.4833 5.26641Z"
        fill="#7DC851"
        fill-opacity="0.32" />
    </g>
    <circle cx="32.4" cy="25.2999" r="5.4" fill="white" />
    <defs>
      <filter
        id="filter0_bd_152_3845"
        x="11.5"
        y="0.613037"
        width="41.7617"
        height="52.2393"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_152_3845" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect1_backgroundBlur_152_3845" result="effect2_dropShadow_152_3845" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_152_3845" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_152_3845"
        x1="-0.567227"
        y1="1.45378"
        x2="44.0043"
        y2="21.7214"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#8DD979" />
        <stop offset="1" stop-color="#2A5704" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_152_3845"
        x1="18.5949"
        y1="1.45378"
        x2="63.1664"
        y2="21.7214"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#8DD979" />
        <stop offset="1" stop-color="#2A5704" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_152_3845"
        x1="38.6261"
        y1="1.45378"
        x2="83.1977"
        y2="21.7214"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#8DD979" />
        <stop offset="1" stop-color="#2A5704" />
      </linearGradient>
    </defs>
  </svg>
</template>
