<script setup lang="ts">
import {onMounted, onUnmounted} from "vue"
import {useRoute} from "vue-router"
import {storeToRefs} from "pinia"
import {useNewsStore} from "@/stores/NewsStore"
import NewsDetailsPost from "@/components/NewsDetails/NewsDetailsPost.vue"
import NewsRecomendationList from "@/components/NewsDetails/NewsRecomendationList.vue"

const route = useRoute()
const newsStore = useNewsStore()
const {newsDetail} = storeToRefs(newsStore)

const newsIdParam = route.params.id

const fetchNewsDetails = () => {
  const randomPage = Math.floor(Math.random() * 3) + 1
  newsStore.getNewsDetails(parseInt(newsIdParam))
  newsStore.getNews(4, randomPage)
}

fetchNewsDetails()

onMounted(() => {
  document.body.style.lineHeight = "1.42857143"
})

onUnmounted(() => {
  document.body.style.lineHeight = ""
})
</script>

<template>
  <div class="news-details">
    <NewsDetailsPost :newsDetail="newsDetail" />
    <NewsRecomendationList />
  </div>
</template>

<style scoped lang="scss">
.news-details {
  background: var(--color-white);
}
</style>
