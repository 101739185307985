<script setup lang="ts">
import {ref, computed} from "vue"
import { useI18n } from "vue-i18n"
import GreenPhoneIcon from "@/components/icons/greens/GreenPhoneIcon.vue"
import GreenCardIcon from "@/components/icons/greens/GreenCardIcon.vue"
import GreenShieldIcon from "@/components/icons/greens/GreenShieldIcon.vue"
import GreenGlassesIcon from "@/components/icons/greens/GreenGlassesIcon.vue"

const {t} = useI18n()

const cards = ref([
  {
    id: 1,
    icon: GreenPhoneIcon,
    title: computed(() => t("app.comfortPage.applyForInsuranceOnline-title")),
    description: computed(() => t("app.comfortPage.applyForInsuranceOnline-description"))
  },
  {
    id: 2,
    icon: GreenCardIcon,
    title: computed(() => t("app.comfortPage.payAsItSuitsYou-title")),
    description: computed(() => t("app.comfortPage.payAsItSuitsYou-description"))
  },
  {
    id: 3,
    icon: GreenShieldIcon,
    title: computed(() => t("app.comfortPage.protectTheProperty-title")),
    description: computed(() => t("app.comfortPage.protectTheProperty-description"))
  },
  {
    id: 4,
    icon: GreenGlassesIcon,
    title: computed(() => t("app.comfortPage.noInspection-title")),
    description: computed(() => t("app.comfortPage.noInspection-description"))
  }
])
</script>

<template>
  <section class="comfort-advantages">
    <div v-for="card in cards" :key="card.id" class="comfort-advantages__card">
      <component :is="card.icon" class="comfort-advantages__card-icon" />
      <p class="comfort-advantages__card-title">
        {{ card.title }}
      </p>
      <p class="comfort-advantages__card-description">{{ card.description }}</p>
    </div>
    <img
      class="comfort-advantages__3d-plus-mob"
      src="/comfort-front/images/3d-fluency-plus-mobile.webp"
      alt="3d fluency plus" />
    <img
      class="comfort-advantages__3d-1plus-desc"
      src="/comfort-front/images/3d-1fluency-plus-desc.webp"
      alt="3d fluency plus" />
    <img
      class="comfort-advantages__3d-2plus-desc"
      src="/comfort-front/images/3d-2fluency-plus-desc.webp"
      alt="3d fluency plus" />
  </section>
</template>

<style scoped lang="scss">
.comfort-advantages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: auto;
  gap: 34px;
  margin: 150px 0;
  position: relative;

  .comfort-advantages__card {
    max-width: 250px;

    .comfort-advantages__card-title {
      color: #ffffff;
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      margin: 20px 0;
    }

    .comfort-advantages__card-description {
      color: #d6d6d6;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
  .comfort-advantages__3d-plus-mob {
    display: none;
  }
  .comfort-advantages__3d-1plus-desc {
    width: 200px;
    height: 200px;
    position: absolute;
    top: -124px;
    left: -245px;
  }
  .comfort-advantages__3d-2plus-desc {
    width: 140.414px;
    height: 140.414px;
    position: absolute;
    top: -58px;
    right: -207px;
  }
}

@media (max-width: $xl) {
  .comfort-advantages {
    margin: 99px 0 270px;

    .comfort-advantages__3d-1plus-desc {
      width: 150px;
      height: 150px;
      position: absolute;
      top: -79px;
      left: 69px;
    }

    .comfort-advantages__card {
      max-width: 100%;
    }
  }
}

@media (max-width: $lg) {
  .comfort-advantages {
    gap: 40px;
    position: relative;

    .comfort-advantages__3d-plus-mob {
      display: block;
      position: absolute;
      top: -75px;
      right: -84px;
      width: 163.743px;
      height: 163.743px;
    }

    .comfort-advantages__3d-1plus-desc,
    .comfort-advantages__3d-2plus-desc {
      display: none;
    }
  }
}
</style>
