<script setup lang="ts">
import {ref, watch, computed, onBeforeMount} from "vue"
import {useI18n} from "vue-i18n"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import type {IContactDetail} from "@/interfaces/Contacts/IContactDetail"
import GreenContactsGeolocation from "@/components/icons/greens/GreenContactsGeolocation.vue"
import GreenContactsEmail from "@/components/icons/greens/GreenContactsEmail.vue"
import GreenContactsOperationMode from "@/components/icons/greens/GreenContactsOperationMode.vue"
import GreenCallCenter from "@/components/icons/greens/GreenCallCenter.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import Dropdown from "primevue/dropdown"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

interface IProps {
  showCityOptions?: boolean
}

withDefaults(defineProps<IProps>(), {
  showCityOptions: false
})

const {loading, getData} = useGatewayFetcher()
const {t} = useI18n()
const {openLink, currentLocale} = useHelpers()

const city = ref({
  cityId: 1,
  title: ""
})
const citites = ref<{cityId: number; title: string}[]>([])
const contactDetails = ref<IContactDetail | null>(null)

const getContactsCities = async () => {
  const config = {
    headers: {
      "Accept-Language": currentLocale.value
    }
  }
  try {
    const response = await getData("/company/contacts/cities", config)
    if (response) {
      citites.value = response
      const defaultCity = citites.value.find((c) => c.cityId === city.value.cityId)
      if (defaultCity) {
        city.value.title = defaultCity.title
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const getContactDetails = async () => {
  if (!city.value.cityId) return
  const config = {
    headers: {
      "Accept-Language": currentLocale.value
    }
  }
  try {
    const response = await getData(`/company/contacts/city/${city.value.cityId}`, config)
    if (response) {
      contactDetails.value = response
    }
  } catch (error) {
    console.error(error)
  }
}

const contactData = computed(() => {
  if (!contactDetails.value) {
    return []
  }

  const {locations, phones, emails, schedule} = contactDetails.value

  return [
    ...(locations?.map((location) => ({
      icon: GreenContactsGeolocation,
      title: t("app.contactsPage.headOfficeTitle"),
      description: location.text,
      type: location.type
    })) || []),

    ...(emails?.map((email) => ({
      icon: GreenContactsEmail,
      title: "Email:",
      description: email.text,
      type: email.type
    })) || []),

    ...(phones?.map((phone) => ({
      icon: "/contacts-front/images/call-center.svg",
      title: t("app.contactsPage.callCenterTitle"),
      description: phone.text,
      type: phone.type
    })) || []),

    ...(schedule
      ? [
          {
            icon: GreenContactsOperationMode,
            title: t("app.contactsPage.officeOperationHourTitle"),
            description: schedule
          }
        ]
      : [])
  ]
})

watch(
  city,
  async () => {
    if (city.value.cityId) {
      await getContactDetails()
    }
  },
  {
    immediate: true
  }
)

watch(currentLocale, async (newVal) => {
  if (newVal) {
    await Promise.all([getContactsCities(), getContactDetails()])
  }
})

onBeforeMount(async () => {
  await getContactsCities()
})
</script>

<template>
  <section class="contacts-info container">
    <div v-if="showCityOptions" class="contacts-info-city-options">
      <Dropdown v-model="city" :options="citites" optionLabel="title" />
    </div>
    <div class="contacts-info-content">
      <div v-for="(data, index) in contactData" :key="index" class="contacts-info-content__row">
        <div class="contacts-info-content__content">
          <component v-if="data.type !== 'phone'" :is="data.icon" />
          <img v-else :src="data.icon" alt="icon" />
          <div class="contacts-info-content__content-texts">
            <p class="contacts-info-content__title">{{ data.title }}</p>
            <p class="contacts-info-content__description">{{ data.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Modal style="z-index: 103" :isOpen="loading">
    <Loader />
  </Modal>
</template>

<style scoped lang="scss">
.contacts-info {
  @include column;
  gap: 50px;
  position: relative;
  z-index: 1;
  padding: 0;
  margin-top: 100px;
  margin-bottom: 50px;
  .contacts-info-city-options {
    @include column;
    .contacts-info-city-options__which-city {
      @include between;
      margin-bottom: 30px;

      p {
        color: var(--color-white);
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        span {
          color: #4faf3b;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
    ::v-deep(.p-dropdown) {
      background-color: #3b3b3b !important;

      .p-inputtext {
        padding: 13px 14px;
      }
    }
  }
  .contacts-info-content {
    @include column;
    gap: 40px;
    padding: 50px 30px;
    border-radius: 20px;
    background: rgba(74, 74, 74, 0.2);
    backdrop-filter: blur(16px);
    position: relative;
    z-index: 0;
    .contacts-info-content__row {
      &:first-of-type {
        @include max-responsive(lg) {
          @include column;
        }
      }
      .contacts-info-content__content {
        @include row;
        gap: 10px;

        .contacts-info-content__content-texts {
          .contacts-info-content__title {
            color: #606060;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
          .contacts-info-content__description {
            color: var(--color-white);
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
          }
        }
        img {
          width: 36px;
          height: 36px;
        }
        .ff-button-wrapper {
          margin-left: auto;
        }
      }
    }
  }
}
</style>
