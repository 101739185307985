<script setup lang="ts">
import {storeToRefs} from "pinia"
import {usePaymentStore} from "@/stores/Payment"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import AddTotalPayment from "@/components/AddDriverAuto/AddTotalPayment.vue"
import FreedomPayWhiteIcon from "@/components/icons/payment/FreedomPayWhiteIcon.vue"
import FFRadiobutton from "@/components/FFUI/FFRadiobutton.vue"

const paymentStore = usePaymentStore()
const addDriverAutoStore = useAddDriverAutoStore()
const {selectedPayment, paymentMethodsWithoutSuperApp, showKaspiForm} = storeToRefs(paymentStore)
const {isBadScoring, calculationResult} = storeToRefs(addDriverAutoStore)

const emit = defineEmits(["kaspiFormNext"])

const handleKaspiFormNext = async () => {
  if (selectedPayment.value === "KaspiPay") {
    addDriverAutoStore.handleKaspiFormOpen()
  }
  if (selectedPayment.value === "FreedomPay") {
    await addDriverAutoStore.handleFinalPayment()
  }
}
</script>

<template>
  <div class="payment-method-form">
    <p class="payment-method-form__title">{{ $t("app.paymentPage.mainTitle") }}</p>
    <template v-for="payment in paymentMethodsWithoutSuperApp">
      <div
        v-if="!(isBadScoring && payment.type === 'KaspiPay')"
        :key="payment.id"
        @click="paymentStore.handleCurrentMethod(payment.type)"
        class="payment-method"
        :class="{'payment-method--selected': selectedPayment === payment.type}">
        <FFRadiobutton id="bank" v-model="selectedPayment" name="payment" :value="payment.type" />
        <component :is="payment.icon" class="payment-method__icon" />
        <div class="payment-method__wrapper">
          <p>{{ payment.name }}</p>
          <p>{{ payment.subText }}</p>
        </div>
        <FreedomPayWhiteIcon v-if="payment.type === 'Банк. карта'" class="payment-method__freedom-icon" />
      </div>
    </template>
  </div>
  <AddTotalPayment
    @payment="handleKaspiFormNext"
    :show-premium-sum="false" />
</template>

<style lang="scss" scoped>
.payment-method-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;
  .payment-method-form__title {
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .payment-method {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: rgba(226, 226, 226, 0.17);
    border: 1px solid transparent;
    cursor: pointer;

    &--selected {
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: rgba(0, 0, 0, 0.25);
    }

    .payment-method__icon {
      margin-left: 13px;
    }

    .payment-method__freedom-icon {
      margin-left: auto;
    }

    .payment-method__wrapper {
      margin-left: 7px;
      p:first-of-type {
        color: #ffffff;
        text-align: start;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p:last-of-type {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        max-width: 115px;
      }
    }
  }
}
</style>
