<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useMSTRemasteredStore} from "@/stores/MSTRemastered"
import RemoveIcon from "@/components/icons/form/removeIcon.vue"
import AddIcon from "@/components/icons/form/addIcon.vue"

const mstStore = useMSTRemasteredStore()
const {categories, isIncrementDisabled} = storeToRefs(mstStore)
</script>

<template>
  <div class="mst-who-will-fly">
    <div class="mst-who-will-fly__list">
      <div v-for="(item, index) in categories" :key="index" class="mst-who-will-fly__list-item">
        <p>{{ item.name }}</p>
        <div class="mst-who-will-fly__counter-box">
          <RemoveIcon @click="mstStore.decrementAgeCategoryCount(index)" />
          <p>{{ item.count }}</p>
          <AddIcon @click="mstStore.incrementAgeCategoryCount(index)" :disabled="isIncrementDisabled" />
        </div>
      </div>
    </div>
    <div class="mst-who-will-fly__note">
      <p>{{ $t("app.mstPage.form.calculationForm.euroInsuredCount") }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mst-who-will-fly {
  padding: 10px 10px;
  .mst-who-will-fly__list {
    @include column;
    .mst-who-will-fly__list-item {
      @include between;
      p {
        color: #666666;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &:nth-child(1) {
        padding-bottom: 15px;
      }
      &:nth-child(2) {
        padding-bottom: 15px;
        padding-top: 15px;
      }
      &:nth-child(3) {
        padding-bottom: 15px;
        padding-top: 15px;
      }
      &:nth-child(4) {
        padding-bottom: 15px;
        padding-top: 15px;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
      .mst-who-will-fly__counter-box {
        @include center;
        gap: 11px;

        svg {
          cursor: pointer;
        }
      }
    }
  }
  .mst-who-will-fly__note {
    padding: 10px 14px;
    border-radius: 10px;
    background: #f2994a;
    max-width: 220px;
    p {
      color: #232323;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
</style>
