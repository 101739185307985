<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <g filter="url(#filter0_i_3380_7465)">
      <path
        d="M20.2727 5L8 10.4545V18.6364C8 26.2045 13.2364 33.2818 20.2727 35C27.3091 33.2818 32.5455 26.2045 32.5455 18.6364V10.4545L20.2727 5ZM24.4727 25.4545L20.2727 22.9318L16.0864 25.4545L17.1909 20.6818L13.4955 17.4909L18.3773 17.0682L20.2727 12.5682L22.1682 17.0545L27.05 17.4773L23.3545 20.6818L24.4727 25.4545Z"
        fill="url(#paint0_linear_3380_7465)" />
    </g>
    <defs>
      <filter
        id="filter0_i_3380_7465"
        x="8"
        y="5"
        width="28.0115"
        height="33.4666"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="3.46658" dy="3.46658" />
        <feGaussianBlur stdDeviation="1.73329" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3380_7465" />
      </filter>
      <linearGradient
        id="paint0_linear_3380_7465"
        x1="32.5455"
        y1="-0.294115"
        x2="-1.24874"
        y2="14.1049"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
    </defs>
  </svg>
</template>
