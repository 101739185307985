<script setup lang="ts"></script>

<template>
  <section class="comfort-how-product-works">
    <h2 class="comfort-how-product-works__title">{{ $t("app.comfortPage.howProductIsSetUp") }}</h2>
    <div>
      <div class="comfort-how-product-works__first-column">
        <div>
          <p>{{ $t("app.comfortPage.whoCanTakeOutInsurance-title") }}</p>
          <p>{{ $t("app.comfortPage.whoCanTakeOutInsurance-description") }}</p>
        </div>
        <div>
          <p>{{ $t("app.comfortPage.whatCannotBeProtected-title") }}</p>
          <p>
            {{ $t("app.comfortPage.whatCannotBeProtected-description") }}
          </p>
        </div>
      </div>
      <div class="comfort-how-product-works__second-column">
        <div>
          <p>{{ $t("app.comfortPage.deductible-title") }}</p>
          <p>
            {{ $t("app.comfortPage.deductible-description") }}
          </p>
        </div>
        <div>
          <p>{{ $t("app.comfortPage.period-title") }}</p>
          <p>{{ $t("app.comfortPage.period-description") }}</p>
        </div>
        <div>
          <p>{{ $t("app.comfortPage.areaOfInsuranceCover-title") }}</p>
          <p>{{ $t("app.comfortPage.areaOfInsuranceCover-description") }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.comfort-how-product-works {
  margin-bottom: 150px;
  padding: 50px 40px;
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(8px);

  .comfort-how-product-works__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;

    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 50px;
  }
  & > div {
    display: flex;
    max-width: 100%;
    gap: 30px;

    .comfort-how-product-works__first-column {
      width: 50%;
      div {
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
        p:first-child {
          color: #ffffff;
          font-size: 22px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          margin-bottom: 10px;
        }
        p:last-of-type {
          color: #d6d6d6;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.3;
        }
      }
    }
    .comfort-how-product-works__second-column {
      width: 50%;
      div {
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
        p:first-child {
          color: #ffffff;
          font-size: 22px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          margin-bottom: 10px;
        }
        p:last-of-type {
          color: #d6d6d6;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.3;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .comfort-how-product-works {
    padding: 30px 20px;
    margin-bottom: 100px;
    .comfort-how-product-works__title {
      font-size: 30px;
      font-weight: 900;
    }
    & > div {
      display: flex;
      flex-direction: column;

      .comfort-how-product-works__first-column,
      .comfort-how-product-works__second-column {
        width: 100%;

        div {
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
          p:first-child {
            color: #ffffff;
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            margin-bottom: 10px;
          }
          p:last-of-type {
            color: #ffffff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3;
            opacity: 0.6;
          }
        }
      }
    }
  }
}
</style>
