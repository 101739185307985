<script setup lang="ts">
import {ref} from "vue"
import {storeToRefs} from "pinia"
import {useMSTRemasteredStore} from "@/stores/MSTRemastered"
import {usePaymentStore} from "@/stores/Payment"
import FreedomPayWhiteIcon from "@/components/icons/payment/FreedomPayWhiteIcon.vue"
import FFRadiobutton from "@/components/FFUI/FFRadiobutton.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"

const mstStore = useMSTRemasteredStore()
const {selectedPayment, showKaspiForm, paymentUrl} = storeToRefs(mstStore)
const paymentStore = usePaymentStore()
const {paymentMethodsWithoutSuperApp} = storeToRefs(paymentStore)

const emit = defineEmits(["kaspiFormNext"])
const iAgree = ref<boolean>(false)

const handleKaspiFormNext = async () => {
  if (selectedPayment.value === "KaspiPay") {
    showKaspiForm.value = true
  } else if (selectedPayment.value === "FreedomPay" || selectedPayment.value === "FreedomSuperApp") {
    await mstStore.finalPay()
  }
}
</script>

<template>
  <div class="payment-method-form">
    <template v-for="payment in paymentMethodsWithoutSuperApp" :key="payment.id">
      <div
        @click="mstStore.handleCurrentMethod(payment.type)"
        class="payment-method"
        :class="{'payment-method--selected': selectedPayment === payment.type}">
        <FFRadiobutton id="bank" v-model="selectedPayment" name="payment" :value="payment.type" />
        <component :is="payment.icon" class="payment-method__icon" />
        <div @click="mstStore.handleCurrentMethod(payment.type)" class="payment-method__wrapper">
          <p>{{ payment.name }}</p>
          <p>{{ payment.subText }}</p>
        </div>
      </div>
    </template>
    <FFCheckbox
      v-if="calculationObject?.isScoring"
      v-model="iAgree"
      :title="$t('app.ogpoPage.agreement.iAgreeToComission')" />
  </div>
  <FFButton
    v-if="calculationObject?.isScoring"
    @click="handleKaspiFormNext"
    :title="$t('app.buttons.pay')"
    :uppercase="false"
    :disabled="selectedPayment === '' || !iAgree" />
  <FFButton
    v-else
    @click="handleKaspiFormNext"
    :title="$t('app.buttons.pay')"
    :uppercase="false"
    :disabled="selectedPayment === ''" />
</template>

<style lang="scss" scoped>
.payment-method-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;
  .ff-checkbox {
    align-items: start;
  }
  .payment-method {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: rgba(226, 226, 226, 0.17);
    border: 1px solid transparent;
    cursor: pointer;

    &--selected {
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: rgba(0, 0, 0, 0.25);
    }

    .payment-method__icon {
      margin-left: 13px;
    }

    .payment-method__freedom-icon {
      margin-left: auto;
    }

    .payment-method__wrapper {
      margin-left: 7px;
      p:first-of-type {
        color: #ffffff;
        text-align: start;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p:last-of-type {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        max-width: 115px;
      }
    }
  }
}
</style>
