import {ref, reactive, computed} from "vue"
import {defineStore} from "pinia"

export const useMstBoardingStore = defineStore("mstBoardingStore", () => {
  const agreements = reactive({
    iAmNotPublicOfficialFace: false,
    insuredDontHaveDepositInBank: false,
    iAgreeWithFormAndWithRules: false
  })

  const selectedPaymentMethod = ref<string>("")

  const isAllAgreementsAreFilled = computed(() => {
    return !Object.values(agreements).every(Boolean)
  })

  const kaspiPhoneNumber = ref<string>("")

  return {
    agreements,
    selectedPaymentMethod,
    kaspiPhoneNumber,
    isAllAgreementsAreFilled
  }
})
