<script setup lang="ts">
import i18n from "@/i18n"
import {computed, onBeforeMount, ref} from "vue"
import {useRouter, useRoute} from "vue-router"

const router = useRouter()
const route = useRoute()

const changeLocale = (locale: string) => {
  i18n.global.locale = locale
  localStorage.setItem("locale", locale)
  router.push({name: route.name as string, params: {lang: locale}})
}

const currentLocale = computed(() => i18n.global.locale)

const lang = ref("")
const fetchData = async () => {
  return new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve(route.params.lang)
    }, 1000)
  })
}

const startTimer = async () => {
  let timer = 5
  while (!route.params.lang && timer > 0) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    timer--
    if (timer === 0) {
      timer = 5
    }
  }
}

onBeforeMount(async () => {
  await startTimer()
  lang.value = await fetchData()
  if (lang.value) {
    i18n.global.locale = lang.value
    changeLocale(lang.value)
  } else {
    i18n.global.locale = "ru"
    changeLocale("ru")
  }
})
</script>

<template>
  <div class="locale">
    <div class="locale-wrapper">
      <a
        class="locale-link"
        :class="{'locale-link-active': currentLocale === 'kk'}"
        href="#"
        @click="changeLocale('kk')"
        >ҚАЗ</a
      >
      <a
        class="locale-link"
        :class="{'locale-link-active': currentLocale === 'ru'}"
        href="#"
        @click="changeLocale('ru')"
        >РУС</a
      >
      <a
        class="locale-link"
        :class="{'locale-link-active': currentLocale === 'en'}"
        href="#"
        @click="changeLocale('en')"
        >ENG</a
      >
    </div>
  </div>
</template>

<style scoped>
.locale {
  width: fit-content;
  padding-right: 20px;
}

.locale-wrapper {
  width: auto;
  display: flex;
  justify-content: space-around;
  gap: 26px;
}

.locale-link {
  padding: 6px 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}
.locale-link:hover {
  color: #999;
}

.locale-link-active {
  border-bottom: 2px solid #f9b000;
  transition: all 0.3s ease;
}
</style>
