<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <g filter="url(#filter0_i_3411_610)">
      <path
        d="M9.06773 7.49L20.2727 5L31.4777 7.49C31.7805 7.55731 32.0514 7.72586 32.2455 7.96782C32.4396 8.20979 32.5454 8.5107 32.5455 8.82091V22.4395C32.5454 23.7865 32.2127 25.1125 31.5771 26.3C30.9415 27.4875 30.0225 28.4997 28.9018 29.2468L20.2727 35L11.6436 29.2468C10.5231 28.4999 9.60426 27.4879 8.96866 26.3006C8.33305 25.1134 8.00032 23.7876 8 22.4409V8.82091C8.00005 8.5107 8.10587 8.20979 8.29999 7.96782C8.4941 7.72586 8.76491 7.55731 9.06773 7.49ZM21.6364 17.2727V10.4545L14.8182 20H18.9091V26.8182L25.7273 17.2727H21.6364Z"
        fill="url(#paint0_linear_3411_610)" />
    </g>
    <defs>
      <filter
        id="filter0_i_3411_610"
        x="8"
        y="5"
        width="28.0115"
        height="33.4666"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="3.46658" dy="3.46658" />
        <feGaussianBlur stdDeviation="1.73329" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3411_610" />
      </filter>
      <linearGradient
        id="paint0_linear_3411_610"
        x1="32.5455"
        y1="-0.294115"
        x2="-1.24874"
        y2="14.1049"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
    </defs>
  </svg>
</template>
