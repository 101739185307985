<script setup lang="ts">
import {computed} from "vue"
import {storeToRefs} from "pinia"
import {useFormatters} from "@/utils/formatters"
import {useOgpoJuridicalStore} from "@/stores/OgpoJuridicalStore"
import {ogpoJuridicalEmailConfirmationSchema} from "@/schemas/ogpoJuridicalSchemas"
import VOtpInput from "vue3-otp-input"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import BuildingIcon from "@/components/icons/ogpo-juridical/BuildingIcon.vue"
import GeolocationIcon from "@/components/icons/ogpo-juridical/GeolocationIcon.vue"
import CarIcon from "@/components/icons/ogpo-juridical/CarIcon.vue"

const ogpoJuridicalStore = useOgpoJuridicalStore()
const {
  currentStep,
  email,
  calculationResponse,
  documentTypes,
  otpInput,
  bindModal,
  codeIsSent,
  showOtp,
  checkEmailOtpCodeResponse,
  buttonTitle
} = storeToRefs(ogpoJuridicalStore)

const {thousandSeparator} = useFormatters()

const isEmailFilled = computed(() => {
  return ogpoJuridicalEmailConfirmationSchema.safeParse({email: email.value}).success
})

const handleNextStep = async () => {
  if (documentTypes.value.length === 0) {
    const response = await ogpoJuridicalStore.getDocumentTypes()
    if (response) {
      currentStep.value = 3
    } else {
      return
    }
  } else {
    currentStep.value = 3
  }
}
</script>

<template>
  <div class="ogpo-agreement-confirmation-form__upper-part">
    <FFFieldset :title="$t('form.inputTitles.email')">
      <FFInput v-model="email" id="email" inputmode="text" aria-required="true" />
    </FFFieldset>
    <VOtpInput
      v-if="showOtp"
      ref="otpInput"
      input-classes="otp-input"
      :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
      :num-inputs="6"
      separator=""
      v-model:value="bindModal"
      @on-change="ogpoJuridicalStore.checkEmailOtpCode" />
    <p class="ogpo-agreement-confirmation-form__upper-part-text-1">
      {{ $t("app.ogpoJuridicalPage.forms.agreementConfirmation.text-1") }}
    </p>
    <FFButton
      v-if="!codeIsSent"
      @click="ogpoJuridicalStore.sendEmailOtpCode"
      :title="buttonTitle"
      :disabled="!isEmailFilled || codeIsSent"
      :uppercase="false" />
    <FFButton
      v-if="codeIsSent && !checkEmailOtpCodeResponse?.success"
      @click="ogpoJuridicalStore.sendEmailOtpCode"
      :title="buttonTitle"
      :disabled="codeIsSent"
      :uppercase="false" />
  </div>
  <div class="ogpo-agreement-confirmation-form__body-part">
    <div class="ogpo-agreement-confirmation-form__row">
      <BuildingIcon />
      <div class="ogpo-agreement-confirmation-form__row-texts">
        <p>{{ calculationResponse.legalName }}</p>
        <p>{{ calculationResponse.bin }}</p>
      </div>
    </div>
    <div class="ogpo-agreement-confirmation-form__row">
      <GeolocationIcon />
      <div class="ogpo-agreement-confirmation-form__row-texts">
        <p>{{ calculationResponse.legalAddress }}</p>
      </div>
    </div>
    <div
      v-for="(item, index) in calculationResponse?.premium_detail"
      :key="index"
      class="ogpo-agreement-confirmation-form__car-row">
      <CarIcon />
      <div class="ogpo-agreement-confirmation-form__car-row-wrapper">
        <div class="ogpo-agreement-confirmation-form__first-car-row">
          <p>Toyota Cr***</p>
          <p>{{ thousandSeparator(item.premium) }}{{ $t("form.formAbbreviations.tg") }}.</p>
        </div>
        <div class="ogpo-agreement-confirmation-form__second-car-row">
          <span>{{ item.regNum }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="ogpo-agreement-confirmation-form__bottom-part">
    <div class="ogpo-agreement-confirmation-form__premium-wrapper">
      <p class="ogpo-agreement-confirmation-form__premium-text">К оплате:</p>
      <p class="ogpo-agreement-confirmation-form__premium-sum">
        {{ thousandSeparator(calculationResponse.premium) }}{{ $t("form.formAbbreviations.tg") }}.
      </p>
    </div>
    <FFButton @click="handleNextStep" :disabled="!checkEmailOtpCodeResponse?.success" :title="$t('app.buttons.confirmAndPay')" :uppercase="false" />
  </div>
  <p class="ogpo-agreement-confirmation-form__byContinuingYouAgree-text">
    {{ $t("app.ogpoJuridicalPage.forms.byContinuingYouAgree") }}
  </p>
</template>

<style scoped lang="scss">
.ogpo-agreement-confirmation-form__upper-part {
  margin-top: 30px;
  .ogpo-agreement-confirmation-form__upper-part-text-1 {
    color: var(--color-white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 20px 0 16px;
    opacity: 0.8;
  }
  ::v-deep(.otp-input-container) {
    gap: 8px;
    margin-top: 16px;
    div {
      background-color: var(--color-white);
      border-radius: 10px;
      input {
        width: 38px;
        height: 44px;
        color: var(--color-black);
        border: none;
      }
    }
  }
  .ff-button-wrapper {
    margin-bottom: 20px;
  }
}
.ogpo-agreement-confirmation-form__body-part {
  @include column;
  padding: 0 0 15px;
  border-bottom: 1px solid var(--color-white);
  .ogpo-agreement-confirmation-form__row {
    @include flex;
    align-items: start;
    gap: 10px;
    padding: 8px 0;
    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    p {
      color: var(--color-white);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px;
    }
  }

  .ogpo-agreement-confirmation-form__car-row {
    @include flex;
    gap: 10px;
    padding: 8px 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .ogpo-agreement-confirmation-form__car-row-wrapper {
      width: 100%;

      .ogpo-agreement-confirmation-form__first-car-row {
        @include between;
        p {
          color: var(--color-white);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22.4px;
        }
      }
      .ogpo-agreement-confirmation-form__second-car-row {
        margin-top: 3px;
        span {
          color: #d5f1ff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 4px;
          border: 0.8px solid #d5f1ff;
          padding: 2px 4px;
        }
      }
    }
  }
}
.ogpo-agreement-confirmation-form__bottom-part {
  .ogpo-agreement-confirmation-form__premium-wrapper {
    @include between;
    padding: 20px 0;
    .ogpo-agreement-confirmation-form__premium-text {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
    .ogpo-agreement-confirmation-form__premium-sum {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
.ogpo-agreement-confirmation-form__byContinuingYouAgree-text {
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
}
</style>
