<script setup lang="ts">
import {onMounted, ref, computed} from "vue"
import {storeToRefs} from "pinia"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import {useTerminationAgreementStore} from "@/stores/TerminationAgreementStore"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {useHelpers} from "@/composables/useHelpers"
import {useRouter} from "vue-router"
import {useFormatters} from "@/utils/formatters"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {isTerminated, isDone} from "@/constants/policy"
import PersonalActionButtons from "@/components/PersonalProfile/PersonalActionButtons.vue"
import DarkBackground from "@/components/partials/DarkBackground.vue"
import ProfileCardPopup from "@/components/PersonalProfile/ProfileCardPopup.vue"

const router = useRouter()
const personalProfileStore = usePersonalProfileStore()
const terminationAgreementStore = useTerminationAgreementStore()
const addDriverAutoStore = useAddDriverAutoStore()
const {loading, allUserPolicies, notActivePolicies} = storeToRefs(personalProfileStore)
const {dateAndTimeApplying} = storeToRefs(addDriverAutoStore)
const {scrollToTop, openLink, currentLocale} = useHelpers()
const {convertISOToDateTime, thousandSeparator, trimString} = useFormatters()
const {isMobileDevice, isDesktop} = useDeviceSize()

const isActivePolises = ref<boolean>(false)
const isTerminatedPolises = ref<boolean>(false)
const cardPopup = ref<boolean>(false)

const openedCards = ref<Set<string>>(new Set())
const selectedGlobalId = ref<string>("")

const setPolisType = (globalId: string) => {
  selectedGlobalId.value = globalId

  if (openedCards.value.has(globalId)) {
    openedCards.value.delete(globalId)
  } else {
    openedCards.value.add(globalId)
  }
}

const getDataByGlobalId = async (globalId: string, type: string, productCode?: string) => {
  try {
    localStorage.setItem("date-time-applying", convertISOToDateTime(new Date().toISOString()))
    if (type === "termination") {
      router.push(`termination-agreement/${globalId}/${productCode}`)
    }
    if (type === "add-driver-auto") {
      router.push(`add-driver-auto/${globalId}/${productCode}`)
    }
  } catch (error) {
    console.error(error)
  }
}

const openPoliciePdf = async (globalId: string, productCode: string) => {
  try {
    await personalProfileStore.openPoliciePdf(globalId, productCode)
  } catch (error) {
    console.log(error)
  }
}

const groupedPolicies = computed(() => {
  const groups = {
    ogpo: [],
    ogpoPlus: [],
    cascoDeposit: [],
    cascoClassic: [],
    cascoExpress: [],
    cascoVip: [],
    comfort: [],
    mst: [],
    homeProtect: []
  }

  if (Array.isArray(allUserPolicies.value)) {
    allUserPolicies.value.forEach((policy: any) => {
      switch (policy.product_code) {
        case "OS_GPO_VTS":
          groups.ogpo.push(policy)
          break
        case "OGPOPLUS":
          groups.ogpoPlus.push(policy)
          break
        case "KASKO_ATZ":
          groups.cascoDeposit.push(policy)
          break
        case "KASKO_CLASSIC":
          groups.cascoClassic.push(policy)
          break
        case "301":
          groups.cascoExpress.push(policy)
          break
        case "КАСКО KASKO_EXPRESS":
          groups.cascoExpress.push(policy)
          break
        case "KASKO_EXPRESS":
          groups.cascoExpress.push(policy)
          break
        case "KASKO_VIP":
          groups.cascoVip.push(policy)
          break
        case "FFINS_COMFORT":
          groups.comfort.push(policy)
          break
        case "367":
          groups.comfort.push(policy)
          break
        case "Freedom Comfort":
          groups.comfort.push(policy)
          break
        case "TOURISM_INS":
          groups.mst.push(policy)
          break
        case "HOME_PROTECT":
          groups.homeProtect.push(policy)
      }
    })
  }

  return groups
})

onMounted(async () => {
  scrollToTop()
})
</script>

<template>
  <div class="container">
    <div class="polises">
      <div class="polises__header" @click="isActivePolises = !isActivePolises">
        <div class="polises__header--text">
          <p class="title">{{ $t("app.personalCabinetPage.terminatedPolicies") }}</p>
        </div>
        <img
          :class="{'rotate-180': isActivePolises}"
          width="24"
          height="24"
          src="/user-profile-front/images/arrow.svg"
          alt="arrow" />
      </div>
      <Transition>
        <div v-show="isActivePolises" class="polises__list">
          <!-- [OGPO]
            OGPO
            OGPO Subscription 
            OGPO Plus-->
          <template
            v-if="groupedPolicies.ogpo.length !== 0"
            v-for="policy in groupedPolicies.ogpo"
            :key="policy.global_id">
            <div
              v-if="policy.status === isTerminated || policy.status === isDone"
              class="polises__list--offer grey-card"
              :class="{
                'h-[280px]':
                  isMobileDevice && !openedCards.has(policy.global_id) && policy.installment !== 'FFIN_FACTORING',
                'h-[330px]': !isMobileDevice && !openedCards.has(policy.global_id),
                'max-h-[450px]':
                  openedCards.has(policy.global_id) && (policy.installment === null || policy.installment === ''),
                'max-h-[480px]': openedCards.has(policy.global_id) && policy.installment === 'FFIN_FACTORING'
              }">
              <div class="polises__list--offer__content">
                <div class="h-[100%]">
                  <div>
                    <div class="polises__list--offer__content--number">
                      <p>№ {{ policy.global_id }}</p>
                      <p v-if="policy.active">
                        {{
                          currentLocale !== "kk"
                            ? `${$t("form.formAbbreviations.till")} ${policy.till_date}`
                            : `${policy.till_date} ${$t("form.formAbbreviations.till")}`
                        }}
                      </p>
                      <p v-else>{{ $t("app.personalCabinetPage.hasnotEnteredInForce") }}</p>
                    </div>

                    <div class="polises__list--offer__content--title">
                      <div v-if="policy.installment === 'FFIN_FACTORING'">
                        <p class="title">
                          {{ $t("app.personalCabinetPage.ogpo") }} <br />
                          <span>{{ $t("app.personalCabinetPage.subscription") }}</span>
                        </p>
                      </div>
                      <p v-else class="title">{{ $t("app.personalCabinetPage.ogpo") }}</p>
                    </div>
                    <div class="polises__list--offer__content--info mb-[13px]">
                      <div v-if="policy.vehicles.length === 1" class="polises__list--offer__content--info__property">
                        <img src="/user-profile-front/images/polis-car.svg" alt="icon" />
                        <div class="polises__list--offer__content--info__property--texts">
                          <p>{{ policy?.vehicles[0].mark }} {{ policy?.vehicles[0].model }}</p>
                          <p>{{ policy?.vehicles[0].regNum }}</p>
                        </div>
                      </div>
                      <div v-if="policy.vehicles.length > 1" class="polises__list--offer__content--info__property">
                        <img src="/user-profile-front/images/polis-car.svg" alt="icon" />
                        <div class="polises__list--offer__content--info__property--texts">
                          <p>{{ $t("app.personalCabinetPage.severalVehicles") }}</p>
                        </div>
                      </div>

                      <template v-if="policy.installment === 'FFIN_FACTORING'">
                        <div class="polises__list--offer__content--info__property">
                          <img src="/user-profile-front/images/polis-shield.svg" alt="icon" />
                          <div class="polises__list--offer__content--info__property--texts">
                            <p>
                              {{ thousandSeparator(policy.ins_premium) }}
                              {{ $t("form.formAbbreviations.inMonth") }}
                            </p>
                          </div>
                        </div>
                        <!-- <div class="polises__list--offer__content--info__property">
                          <img src="/user-profile-front/images/polis-card.svg" alt="icon" />
                          <div class="polises__list--offer__content--info__property--texts">
                            <p>****3113</p>
                            <p>{{ $t("app.personalCabinetPage.nextPayment") }} 22.10.23</p>
                          </div>
                        </div> -->
                      </template>
                      <div v-else class="polises__list--offer__content--info__property">
                        <img src="/user-profile-front/images/polis-shield.svg" alt="icon" />
                        <div class="polises__list--offer__content--info__property--texts">
                          <p>
                            {{ thousandSeparator(policy.ins_premium) }}
                            {{ $t("form.formAbbreviations.tg") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <img class="card-image" src="/user-profile-front/images/polis-ogpo.png" alt="card" />
            </div>
          </template>

          <template
            v-if="groupedPolicies.ogpoPlus.length !== 0"
            v-for="policy in groupedPolicies.ogpoPlus"
            :key="policy.global_id">
            <div
              v-if="policy.status === isTerminated || policy.status === isDone"
              class="polises__list--offer grey-card"
              :class="{
                'h-[330px]': !isMobileDevice && !openedCards.has(policy.global_id),
                'max-h-[450px]': openedCards.has(policy.global_id)
              }">
              <div class="polises__list--offer__content">
                <div>
                  <div class="polises__list--offer__content--number">
                    <p>№ {{ policy.global_id }}</p>
                    <p v-if="policy.active">
                      {{
                        currentLocale !== "kk"
                          ? `${$t("form.formAbbreviations.till")} ${policy.till_date}`
                          : `${policy.till_date} ${$t("form.formAbbreviations.till")}`
                      }}
                    </p>
                    <p v-else>{{ $t("app.personalCabinetPage.hasnotEnteredInForce") }}</p>
                  </div>
                  <div class="polises__list--offer__content--title">
                    <p class="title">{{ $t("app.personalCabinetPage.ogpo") }}+</p>
                  </div>
                  <div class="polises__list--offer__content--info mb-[13px]">
                    <div v-if="policy.vehicles.length === 1" class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-car.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>{{ policy?.vehicles[0].mark }} {{ policy?.vehicles[0].model }}</p>
                        <p>{{ policy?.vehicles[0].regNum }}</p>
                      </div>
                    </div>
                    <div v-if="policy.vehicles.length > 1" class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-car.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>{{ $t("app.personalCabinetPage.severalVehicles") }}</p>
                      </div>
                    </div>
                    <div class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-shield.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>
                          {{ thousandSeparator(policy.ins_premium) }}
                          {{ $t("form.formAbbreviations.tg") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <img class="card-image" src="/user-profile-front/images/polis-ogpo.png" alt="card" />
            </div>
          </template>

          <!--[KASKO] 
            KASKO Classic, 
            KASKO Classic Subscription, 
            KASKO Express,
            KASKO Express Subscription, 
            KASKO Vip,
            KASKO Vip Subscription -->
          <template v-if="groupedPolicies.cascoDeposit.length !== 0">
            <template v-for="policy in groupedPolicies.cascoDeposit" :key="policy.global_id">
              <div
                v-if="policy.status === isTerminated || policy.status === isDone"
                class="polises__list--offer green-card"
                :class="{
                  'h-[280px]':
                    isMobileDevice && !openedCards.has(policy.global_id) && policy.installment !== 'FFIN_Kasko',
                  'h-[300px]': !isMobileDevice && !openedCards.has(policy.global_id),
                  'max-h-[450px]': openedCards.has(policy.global_id)
                }">
                <div class="polises__list--offer__content">
                  <div class="polises__list--offer__content--number">
                    <p>№ {{ policy.global_id }}</p>
                    <p v-if="policy.active" class="!text-white">
                      {{
                        currentLocale !== "kk"
                          ? `${$t("form.formAbbreviations.till")} ${policy.till_date}`
                          : `${policy.till_date} ${$t("form.formAbbreviations.till")}`
                      }}
                    </p>
                    <p v-else class="!text-white">{{ $t("app.personalCabinetPage.hasnotEnteredInForce") }}</p>
                  </div>

                  <div class="polises__list--offer__content--kasko-subcription-title">
                    <div class="polises__list--kasko-subcription-title">
                      <p class="title-white">{{ $t("app.personalCabinetPage.casco") }}</p>
                    </div>
                    <p class="polises__list--kasko-deposit">
                      {{ $t("app.personalCabinetPage.kaskoDeposit") }}
                    </p>
                  </div>

                  <div class="polises__list--offer__content--info">
                    <div v-if="policy.vehicles.length === 1" class="polises__list--offer__content--info__property">
                      <img class="white-icon" src="/user-profile-front/images/polis-car.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p class="text-white">{{ policy?.vehicles[0].mark }} {{ policy?.vehicles[0].model }}</p>
                        <p class="text-white">{{ policy?.vehicles[0].regNum }}</p>
                      </div>
                    </div>
                    <div v-if="policy.vehicles.length > 1" class="polises__list--offer__content--info__property">
                      <img class="white-icon" src="/user-profile-front/images/polis-car.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p class="text-white">{{ $t("app.personalCabinetPage.severalVehicles") }}</p>
                      </div>
                    </div>

                    <div class="polises__list--offer__content--info__property">
                      <img class="white-icon" src="/user-profile-front/images/polis-card.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p class="text-white">
                          {{ thousandSeparator(policy.ins_premium) }} {{ $t("form.formAbbreviations.tg") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <img class="card-image" src="/user-profile-front/images/polis-casco.png" alt="card" />
              </div>
            </template>
          </template>

          <template
            v-if="groupedPolicies.cascoClassic.length !== 0"
            v-for="policy in groupedPolicies.cascoClassic"
            :key="policy.global_id">
            <div
              v-if="policy.status === isTerminated || policy.status === isDone"
              class="polises__list--offer green-card"
              :class="{
                'h-[280px]':
                  isMobileDevice && !openedCards.has(policy.global_id) && policy.installment !== 'FFIN_Kasko',
                'h-[300px]':
                  isMobileDevice && !openedCards.has(policy.global_id) && policy.installment === 'FFIN_Kasko',
                'h-[330px]': !isMobileDevice && !openedCards.has(policy.global_id),
                'max-h-[450px]':
                  openedCards.has(policy.global_id) && (policy.installment === null || policy.installment === ''),
                'max-h-[480px]': openedCards.has(policy.global_id) && policy.installment === 'FFIN_Kasko'
              }">
              <div class="polises__list--offer__content">
                <div class="polises__list--offer__content--number">
                  <p>№ {{ policy.global_id }}</p>
                  <p v-if="policy.active" class="!text-white">
                    {{
                      currentLocale !== "kk"
                        ? `${$t("form.formAbbreviations.till")} ${policy.till_date}`
                        : `${policy.till_date} ${$t("form.formAbbreviations.till")}`
                    }}
                  </p>
                  <p v-else class="!text-white">{{ $t("app.personalCabinetPage.hasnotEnteredInForce") }}</p>
                </div>

                <div v-if="policy.installment === 'FFIN_Kasko'">
                  <div class="polises__list--offer__content--title">
                    <div>
                      <p class="title-white">{{ $t("app.personalCabinetPage.casco") }}</p>
                      <p class="casco">Classic</p>
                    </div>
                    <p class="polises__list--kasko-subcription">
                      {{ $t("app.personalCabinetPage.subscription") }}
                    </p>
                  </div>
                </div>
                <div v-else class="polises__list--offer__content--title">
                  <p class="title-white">{{ $t("app.personalCabinetPage.casco") }}</p>
                  <p class="casco">Classic</p>
                </div>

                <div class="polises__list--offer__content--info">
                  <div v-if="policy.vehicles.length === 1" class="polises__list--offer__content--info__property">
                    <img class="white-icon" src="/user-profile-front/images/polis-car.svg" alt="icon" />
                    <div class="polises__list--offer__content--info__property--texts">
                      <p class="text-white">{{ policy?.vehicles[0].mark }} {{ policy?.vehicles[0].model }}</p>
                      <p class="text-white">{{ policy?.vehicles[0].regNum }}</p>
                    </div>
                  </div>
                  <div v-if="policy.vehicles.length > 1" class="polises__list--offer__content--info__property">
                    <img class="white-icon" src="/user-profile-front/images/polis-car.svg" alt="icon" />
                    <div class="polises__list--offer__content--info__property--texts">
                      <p class="text-white">{{ $t("app.personalCabinetPage.severalVehicles") }}</p>
                    </div>
                  </div>

                  <template v-if="policy.installment === 'FFIN_Kasko'">
                    <div class="polises__list--offer__content--info__property">
                      <img class="white-icon" src="/user-profile-front/images/polis-card.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p class="text-white">
                          {{ thousandSeparator(policy.ins_premium) }}
                          {{ $t("form.formAbbreviations.inMonth") }}
                        </p>
                      </div>
                    </div>
                    <!-- <div class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-card.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>****3113</p>
                        <p>{{ $t("app.personalCabinetPage.nextPayment") }} 22.10.23</p>
                      </div>
                    </div> -->
                  </template>

                  <div v-else class="polises__list--offer__content--info__property">
                    <img class="white-icon" src="/user-profile-front/images/polis-card.svg" alt="icon" />
                    <div class="polises__list--offer__content--info__property--texts">
                      <p class="text-white">
                        {{ thousandSeparator(policy.ins_premium) }} {{ $t("form.formAbbreviations.tg") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <img class="card-image" src="/user-profile-front/images/polis-casco.png" alt="card" />
            </div>
          </template>

          <template
            v-if="groupedPolicies.cascoExpress.length !== 0"
            v-for="policy in groupedPolicies.cascoExpress"
            :key="policy.global_id">
            <div
              v-if="policy.status === isTerminated || policy.status === isDone"
              class="polises__list--offer green-card"
              :class="{
                'h-[280px]':
                  isMobileDevice && !openedCards.has(policy.global_id) && policy.installment !== 'FFIN_Kasko',
                'h-[300px]':
                  isMobileDevice && !openedCards.has(policy.global_id) && policy.installment === 'FFIN_Kasko',
                'h-[330px]': !isMobileDevice && !openedCards.has(policy.global_id),
                'max-h-[450px]':
                  openedCards.has(policy.global_id) && (policy.installment === null || policy.installment === ''),
                'max-h-[480px]': openedCards.has(policy.global_id) && policy.installment === 'FFIN_Kasko'
              }">
              <div class="polises__list--offer__content">
                <div class="polises__list--offer__content--number">
                  <p>№ {{ policy.global_id }}</p>
                  <p v-if="policy.active" class="!text-white">
                    {{
                      currentLocale !== "kk"
                        ? `${$t("form.formAbbreviations.till")} ${policy.till_date}`
                        : `${policy.till_date} ${$t("form.formAbbreviations.till")}`
                    }}
                  </p>
                  <p v-else class="!text-white">{{ $t("app.personalCabinetPage.hasnotEnteredInForce") }}</p>
                </div>

                <div v-if="policy.installment === 'FFIN_Kasko'">
                  <div class="polises__list--offer__content--title">
                    <div>
                      <p class="title-white">{{ $t("app.personalCabinetPage.casco") }}</p>
                      <p class="casco">Express</p>
                    </div>
                    <p class="polises__list--kasko-subcription">
                      {{ $t("app.personalCabinetPage.subscription") }}
                    </p>
                  </div>
                </div>
                <div v-else class="polises__list--offer__content--title">
                  <p class="title-white">{{ $t("app.personalCabinetPage.casco") }}</p>
                  <p class="casco">Express</p>
                </div>

                <div class="polises__list--offer__content--info">
                  <div v-if="policy.vehicles.length === 1" class="polises__list--offer__content--info__property">
                    <img class="white-icon" src="/user-profile-front/images/polis-car.svg" alt="icon" />
                    <div class="polises__list--offer__content--info__property--texts">
                      <p class="text-white">{{ policy?.vehicles[0].mark }} {{ policy?.vehicles[0].model }}</p>
                      <p class="text-white">{{ policy?.vehicles[0].regNum }}</p>
                    </div>
                  </div>
                  <div v-if="policy.vehicles.length > 1" class="polises__list--offer__content--info__property">
                    <img class="white-icon" src="/user-profile-front/images/polis-car.svg" alt="icon" />
                    <div class="polises__list--offer__content--info__property--texts">
                      <p class="text-white">{{ $t("app.personalCabinetPage.severalVehicles") }}</p>
                    </div>
                  </div>

                  <template v-if="policy.installment === 'FFIN_Kasko'">
                    <div class="polises__list--offer__content--info__property">
                      <img class="white-icon" src="/user-profile-front/images/polis-card.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p class="text-white">
                          {{ thousandSeparator(policy.ins_premium) }}
                          {{ $t("form.formAbbreviations.inMonth") }}
                        </p>
                      </div>
                    </div>
                    <!-- <div class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-card.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>****3113</p>
                        <p>{{ $t("app.personalCabinetPage.nextPayment") }} 22.10.23</p>
                      </div>
                    </div> -->
                  </template>

                  <div v-else class="polises__list--offer__content--info__property">
                    <img class="white-icon" src="/user-profile-front/images/polis-card.svg" alt="icon" />
                    <div class="polises__list--offer__content--info__property--texts">
                      <p class="text-white">
                        {{ thousandSeparator(policy.ins_premium) }} {{ $t("form.formAbbreviations.tg") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <img class="card-image" src="/user-profile-front/images/polis-casco.png" alt="card" />
            </div>
          </template>

          <template
            v-if="groupedPolicies.cascoVip.length !== 0"
            v-for="policy in groupedPolicies.cascoVip"
            :key="policy.global_id">
            <div
              v-if="policy.status === isTerminated || policy.status === isDone"
              class="polises__list--offer green-card"
              :class="{
                'h-[280px]':
                  isMobileDevice && !openedCards.has(policy.global_id) && policy.installment !== 'FFIN_Kasko',
                'h-[300px]':
                  isMobileDevice && !openedCards.has(policy.global_id) && policy.installment === 'FFIN_Kasko',
                'h-[330px]': !isMobileDevice && !openedCards.has(policy.global_id),
                'max-h-[450px]':
                  openedCards.has(policy.global_id) && (policy.installment === null || policy.installment === ''),
                'max-h-[480px]': openedCards.has(policy.global_id) && policy.installment === 'FFIN_Kasko'
              }">
              <div class="polises__list--offer__content">
                <div class="polises__list--offer__content--number">
                  <p>№ {{ policy.global_id }}</p>
                  <p v-if="policy.active" class="!text-white">
                    {{
                      currentLocale !== "kk"
                        ? `${$t("form.formAbbreviations.till")} ${policy.till_date}`
                        : `${policy.till_date} ${$t("form.formAbbreviations.till")}`
                    }}
                  </p>
                  <p v-else class="!text-white">{{ $t("app.personalCabinetPage.hasnotEnteredInForce") }}</p>
                </div>

                <div v-if="policy.installment === 'FFIN_Kasko'">
                  <div class="polises__list--offer__content--kasko-subcription-title">
                    <div class="polises__list--kasko-subcription-title">
                      <p class="title-white">{{ $t("app.personalCabinetPage.casco") }}</p>
                      <p class="casco">VIP</p>
                    </div>
                    <p class="polises__list--kasko-subcription">
                      {{ $t("app.personalCabinetPage.subscription") }}
                    </p>
                  </div>
                </div>
                <div v-else class="polises__list--offer__content--title">
                  <p class="title-white">{{ $t("app.personalCabinetPage.casco") }}</p>
                  <p class="casco">VIP</p>
                </div>

                <div class="polises__list--offer__content--info">
                  <div v-if="policy.vehicles.length === 1" class="polises__list--offer__content--info__property">
                    <img class="white-icon" src="/user-profile-front/images/polis-car.svg" alt="icon" />
                    <div class="polises__list--offer__content--info__property--texts">
                      <p class="text-white">{{ policy?.vehicles[0].mark }} {{ policy?.vehicles[0].model }}</p>
                      <p class="text-white">{{ policy?.vehicles[0].regNum }}</p>
                    </div>
                  </div>
                  <div v-if="policy.vehicles.length > 1" class="polises__list--offer__content--info__property">
                    <img class="white-icon" src="/user-profile-front/images/polis-car.svg" alt="icon" />
                    <div class="polises__list--offer__content--info__property--texts">
                      <p class="text-white">{{ $t("app.personalCabinetPage.severalVehicles") }}</p>
                    </div>
                  </div>

                  <template v-if="policy.installment === 'FFIN_Kasko'">
                    <div class="polises__list--offer__content--info__property">
                      <img class="white-icon" src="/user-profile-front/images/polis-card.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p class="text-white">
                          {{ thousandSeparator(policy.ins_premium) }}
                          {{ $t("form.formAbbreviations.inMonth") }}
                        </p>
                      </div>
                    </div>
                    <!-- <div class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-card.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>****3113</p>
                        <p>{{ $t("app.personalCabinetPage.nextPayment") }} 22.10.23</p>
                      </div>
                    </div> -->
                  </template>
                  <div v-else class="polises__list--offer__content--info__property">
                    <img class="white-icon" src="/user-profile-front/images/polis-card.svg" alt="icon" />
                    <div class="polises__list--offer__content--info__property--texts">
                      <p class="text-white">
                        {{ thousandSeparator(policy.ins_premium) }} {{ $t("form.formAbbreviations.tg") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <img class="card-image" src="/user-profile-front/images/polis-casco.png" alt="card" />
            </div>
          </template>

          <!-- MST -->
          <template
            v-if="groupedPolicies.mst.length !== 0"
            v-for="policy in groupedPolicies.mst"
            :key="policy.global_id">
            <div
              v-if="policy.status === isTerminated || policy.status === isDone"
              class="polises__list--offer green-card"
              :class="{
                'h-[300px]': isMobileDevice && !openedCards.has(policy.global_id),
                'h-[330px]': !isMobileDevice && !openedCards.has(policy.global_id),
                'max-h-[450px]': openedCards.has(policy.global_id)
              }">
              <div class="polises__list--offer__content">
                <div>
                  <div class="polises__list--offer__content--number">
                    <p>№ {{ policy.global_id }}</p>
                    <p v-if="policy.active">
                      {{
                        currentLocale !== "kk"
                          ? `${$t("form.formAbbreviations.till")} ${policy.till_date}`
                          : `${policy.till_date} ${$t("form.formAbbreviations.till")}`
                      }}
                    </p>
                    <p v-else>{{ $t("app.personalCabinetPage.hasnotEnteredInForce") }}</p>
                  </div>
                  <div class="polises__list--offer__content--title">
                    <p class="title-black">
                      {{ $t("app.personalCabinetPage.mst-1") }} <br />
                      <span>{{ $t("app.personalCabinetPage.mst-2") }}</span>
                    </p>
                  </div>
                  <div class="polises__list--offer__content--info">
                    <div class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-planet.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>
                          {{
                            policy.territories !== null && policy.territories.length === 1
                              ? policy.territories[0]?.country
                              : $t("app.personalCabinetPage.severalCountries")
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-compass.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--mst-texts">
                        <p>{{ $t("app.personalCabinetPage.travelType") }}</p>
                        <p>{{ policy.purpose !== null ? policy.purpose : "" }}</p>
                      </div>
                    </div>
                    <div class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-card.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>{{ thousandSeparator(policy.ins_premium) }} {{ $t("form.formAbbreviations.tg") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img class="card-image" src="/user-profile-front/images/polis-mst.png" alt="card" />
            </div>
          </template>

          <!--[FREEDOM COMFORT] 
            FREEDOM COMFORT, 
            FREEDOM COMFORT Subscription, 
          -->

          <template
            v-if="groupedPolicies.comfort.length !== 0"
            v-for="policy in groupedPolicies.comfort"
            :key="policy.global_id">
            <div
              v-if="policy.status === isTerminated || policy.status === isDone"
              class="polises__list--offer blue-card"
              :class="{
                'h-[280px]':
                  isMobileDevice && !openedCards.has(policy.global_id) && policy.installment !== 'FFIN_Comfort',
                'h-[300px]':
                  isMobileDevice && !openedCards.has(policy.global_id) && policy.installment === 'FFIN_Comfort',
                'h-[330px]': !isMobileDevice && !openedCards.has(policy.global_id),
                'max-h-[450px]':
                  openedCards.has(policy.global_id) && (policy.installment === null || policy.installment === ''),
                'max-h-[480px]': openedCards.has(policy.global_id) && policy.installment === 'FFIN_Comfort'
              }">
              <div class="polises__list--offer__content">
                <div>
                  <div class="polises__list--offer__content--number">
                    <p>№ {{ policy.global_id }}</p>
                    <p v-if="policy.active">
                      {{
                        currentLocale !== "kk"
                          ? `${$t("form.formAbbreviations.till")} ${policy.till_date}`
                          : `${policy.till_date} ${$t("form.formAbbreviations.till")}`
                      }}
                    </p>
                    <p v-else>{{ $t("app.personalCabinetPage.hasnotEnteredInForce") }}</p>
                  </div>

                  <div v-if="policy.installment === 'FFIN_Comfort'">
                    <div class="polises__list--offer__content--title">
                      <p class="title-black">
                        Freedom <span>Comfort</span> <br />
                        <span>{{ $t("app.personalCabinetPage.subscription") }}</span>
                      </p>
                    </div>
                  </div>
                  <div v-else class="polises__list--offer__content--title">
                    <p class="title-black">Freedom <span>Comfort</span></p>
                  </div>

                  <div class="polises__list--offer__content--info">
                    <div class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-home.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>
                          пр. {{ trimString(policy.apartment.street, 30) }}
                          {{ trimString(policy.apartment.number, 30) }}, кв
                          {{ policy.apartment.apartment }}
                        </p>
                      </div>
                    </div>

                    <template v-if="policy.installment === 'FFIN_Comfort'">
                      <div class="polises__list--offer__content--info__property">
                        <img src="/user-profile-front/images/polis-shield.svg" alt="icon" />
                        <div class="polises__list--offer__content--info__property--texts">
                          <p>
                            {{ thousandSeparator(policy.ins_premium) }}
                            {{ $t("form.formAbbreviations.inMonth") }}
                          </p>
                        </div>
                      </div>
                      <!-- <div class="polises__list--offer__content--info__property">
                        <img src="/user-profile-front/images/polis-card.svg" alt="icon" />
                        <div class="polises__list--offer__content--info__property--texts">
                          <p>****3113</p>
                          <p>{{ $t("app.personalCabinetPage.nextPayment") }} 22.10.23</p>
                        </div>
                      </div> -->
                    </template>
                    <div v-else class="polises__list--offer__content--info__property">
                      <img src="/user-profile-front/images/polis-card.svg" alt="icon" />
                      <div class="polises__list--offer__content--info__property--texts">
                        <p>{{ thousandSeparator(policy.ins_premium) }} {{ $t("form.formAbbreviations.tg") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img class="card-image" src="/user-profile-front/images/polis-comfort.png" alt="card" />
            </div>
          </template>

          <!-- [HOME PROTECT] -->
          <template v-if="groupedPolicies.homeProtect.length !== 0">
            <template v-for="policy in groupedPolicies.homeProtect" :key="policy.global_id">
              <div
                v-if="policy.status === isActive"
                class="polises__list--offer dark-green-card"
                :class="{
                  'h-[280px]':
                    isMobileDevice && !openedCards.has(policy.global_id) && policy.installment !== 'HOME_PROTECT',
                  'h-[300px]':
                    isMobileDevice && !openedCards.has(policy.global_id) && policy.installment === 'HOME_PROTECT',
                  'h-[330px]': !isMobileDevice && !openedCards.has(policy.global_id),
                  'max-h-[450px]':
                    openedCards.has(policy.global_id) && (policy.installment === null || policy.installment === ''),
                  'max-h-[480px]': openedCards.has(policy.global_id) && policy.installment === 'HOME_PROTECT'
                }">
                <div class="polises__list--offer__content">
                  <div>
                    <div class="polises__list--offer__content--number">
                      <p>№ {{ policy.global_id }}</p>
                      <p v-if="policy.active" class="!text-white">
                        {{
                          currentLocale !== "kk"
                            ? `${$t("form.formAbbreviations.till")} ${policy.till_date}`
                            : `${policy.till_date} ${$t("form.formAbbreviations.till")}`
                        }}
                      </p>
                      <p v-else class="!text-white">{{ $t("app.personalCabinetPage.hasnotEnteredInForce") }}</p>
                    </div>

                    <div class="polises__list--offer__content--title">
                      <p class="title-white">Home Protect</p>
                    </div>

                    <div class="polises__list--offer__content--info">
                      <div class="polises__list--offer__content--info__property">
                        <img src="/user-profile-front/images/polis-home-roof.svg" alt="icon" />
                        <div class="polises__list--offer__content--info__property--texts">
                          <p class="text-white">
                            пр. {{ trimString(policy.apartment.street, 30) }}
                            {{ trimString(policy.apartment.number, 30) }}, кв
                            {{ policy.apartment.apartment }}
                          </p>
                        </div>
                      </div>

                      <div class="polises__list--offer__content--info__property">
                        <img class="white-icon" src="/user-profile-front/images/polis-card.svg" alt="icon" />
                        <div class="polises__list--offer__content--info__property--texts">
                          <p class="text-white">
                            {{ thousandSeparator(policy.ins_premium) }} {{ $t("form.formAbbreviations.tg") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img class="card-image" src="/user-profile-front/images/polis-home-protect.png" alt="card" />
              </div>
            </template>
          </template>
        </div>
      </Transition>
    </div>
    <ProfileCardPopup v-if="cardPopup" />
    <DarkBackground @click="cardPopup = false" v-if="cardPopup" />
  </div>
</template>

<style scoped lang="scss">
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.polises {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 50px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--text {
      display: flex;
      align-items: center;
      gap: 5px;
      .title {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: all 0.3s ease;
      }
      .number {
        display: flex;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 3px 10px;
        border-radius: 100%;
        background: #f2994a;
        max-width: 24px;
        text-align: center;
        transition: all 0.3s ease;
      }
    }
  }
  &__header:hover {
    .title {
      color: lightgrey;
    }
    .number {
      background: coral;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    align-items: flex-start;

    &--offer {
      position: relative;
      background: white;
      border-radius: 10px;
      .card-image {
        position: absolute;
        right: 0;
        top: 48px;
        z-index: 100;
      }
      &__content {
        padding: 20px 16px 10px;
        height: 100%;
        &--number {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          margin-bottom: 20px;
          :nth-child(1) {
            border-radius: 10px;
            background: #525252;
            color: var(--White, #fff);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
            padding: 2px 8px;
            white-space: nowrap;
          }
          :nth-child(2) {
            color: var(--Gray-main, #404040);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.2px;
            white-space: nowrap;
          }
        }
        &--title {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 20px;
          .title {
            font-size: 35px;
            font-style: normal;
            font-weight: 900;
            line-height: 0.8;
            background: linear-gradient(200deg, #40bf7a 13.57%, #289112 98.35%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            span {
              font-size: 25px;
              font-style: normal;
              font-weight: 900;
              line-height: 100%;
              background: linear-gradient(200deg, #40bf7a 13.57%, #289112 98.35%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .title-white {
            font-size: 35px;
            font-style: normal;
            font-weight: 900;
            line-height: 0.8;
            color: #ffffff;
            span {
              font-size: 25px;
              font-style: normal;
              font-weight: 900;
              line-height: 100%;
              color: #ffffff;
            }
          }
          .title-black {
            font-size: 35px;
            font-style: normal;
            font-weight: 900;
            line-height: 0.8;
            color: #404040;
            span {
              font-size: 25px;
              font-style: normal;
              font-weight: 900;
              line-height: 100%;
              color: #404040;
            }
          }

          .casco {
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 1;
            padding: 6px 10px;
            border-radius: 4px;
            background: #f2994a;
          }
        }

        .polises__list--offer__content--kasko-subcription-title {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          .polises__list--kasko-subcription-title {
            display: flex;
            align-items: center;
            p:nth-child(1) {
              margin-right: 10px;
            }
          }
          .polises__list--kasko-subcription {
            font-size: 25px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            color: white;
          }
          .polises__list--kasko-deposit {
            font-size: 25px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            color: white;
          }
          .title-white {
            font-size: 35px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
            color: white;
          }

          .casco {
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 1;
            padding: 6px 10px;
            border-radius: 4px;
            background: #f2994a;
          }
        }

        &--info {
          display: flex;
          flex-direction: column;
          gap: 12px;
          &__property {
            display: flex;
            align-items: center;
            gap: 10px;
            &--texts {
              p:nth-child(1) {
                color: linear-gradient(132deg, #2f4d3b -0.69%, #162522 103.63%);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 0.2px;
              }
              p:nth-child(2) {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0.2px;
                opacity: 0.5;
              }
            }
            &--mst-texts {
              p:nth-child(1) {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0.2px;
                opacity: 0.5;
              }
              p:nth-child(2) {
                color: linear-gradient(132deg, #2f4d3b -0.69%, #162522 103.63%);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 0.2px;
              }
            }
          }
        }
        &--show {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 14px 0 10px 0;
          cursor: pointer;
          width: fit-content;
          transition: all 0.3s ease;
          margin: 0 auto 0;
          z-index: 100;
          p {
            color: #4faf3b;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
          }
        }
        &--show:hover {
          transform: scale(1.1);
        }
        &--show-white {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 10px 0 10px 0;
          cursor: pointer;
          width: fit-content;
          transition: all 0.3s ease;
          margin: auto auto 0;
          z-index: 100;
          p {
            color: #ffffff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
          }
        }
        &--show-white:hover {
          transform: scale(1.1);
        }
      }
    }
    .grey-card {
      background: #e9e9e9;
      opacity: 0.1;
    }
    .green-card {
      background: linear-gradient(200deg, #40bf7a 13.57%, #289112 98.35%);
      opacity: 0.1;
    }
    .blue-card {
      background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
      opacity: 0.1;
    }
  }
}

.white-icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%);
}

@media (max-width: $xxl) {
  .polises {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: $lg) {
  .polises {
    &__header:hover {
      .title {
        color: #fff;
      }
      .number {
        background: #f2994a;
      }
    }
    &__list {
      grid-template-columns: repeat(1, 1fr);
      &--offer {
        min-width: 100%;
      }
    }
  }
  .polises__list--offer__content--show:hover {
    transform: scale(1);
  }
  .polises__list--offer__content--show-white:hover {
    transform: scale(1);
  }
}

@media (max-width: $md) {
  .polises__list--offer {
    min-height: 310px;
  }
}
</style>
