<script setup lang="ts">
import {watchEffect} from "vue"
import {useHelperStore} from "@/stores/HelperStore"
import {storeToRefs} from "pinia"
import {useHomeProtectStore} from "@/stores/HomeProtectStore"
import {useI18n} from "vue-i18n"
import HProtectHero from "@/components/HomeProtect/HProtectHero.vue"
import HProtectHowWorks from "@/components/HomeProtect/HProtectHowWorks/HProtectHowWorks.vue"
import HProtectHowProgramWorks from "@/components/HomeProtect/HProtectHowProgramWorks/HProtectHowProgramWorks.vue"
import HProtectLimitedOffer from "@/components/HomeProtect/HProtectLimitedOffer.vue"
import HProtectInsuranceCase from "@/components/HomeProtect/HProtectInsuranceCase.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const homeProtectStore = useHomeProtectStore()
const {agreementLoaderHide, isCheckLoadingHide} = storeToRefs(homeProtectStore)
const {loading} = storeToRefs(homeProtectStore)
const helperStore = useHelperStore()
const {t} = useI18n()

watchEffect(() => {
  helperStore.pageTitle = t("app.headerPageTitles.homeProtect")
})
</script>

<template>
  <template v-if="isCheckLoadingHide"></template>
  <template v-else-if="agreementLoaderHide"> </template>
  <template v-else>
    <Modal style="z-index: 103" :isOpen="loading">
      <Loader />
    </Modal>
  </template>
  <div class="home-protect">
    <div class="home-protect__destructions-bg"></div>
    <HProtectHero />
    <HProtectHowWorks />
    <HProtectHowProgramWorks />
    <div class="home-protect__car-road-bg"></div>
    <HProtectLimitedOffer />
    <div class="home-protect__green-bg"></div>
    <HProtectInsuranceCase />
  </div>
</template>

<style scoped lang="scss">
.home-protect {
  position: relative;
  z-index: 0;
  .home-protect__destructions-bg {
    position: absolute;
    inset: 0;
    background: url("/home-protect-front/images/destructions-bg.webp") no-repeat center center / cover;
    height: 855px;

    @media (max-width: $lg) {
      height: 907px;
    }

    @media (max-width: $md) {
      height: 950px;
    }
  }

  .home-protect__first-green-bg {
    z-index: 0;
    width: 1224.714px;
    height: 1707.094px;
    transform: rotate(-90deg);
    position: absolute;
    left: -624px;
    top: 1283px;
    fill: radial-gradient(46.25% 69.68% at 38.69% 48.62%, rgba(90, 238, 53, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    filter: blur(97.5px);
  }

  .home-protect__car-road-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 2020px;
    background: url("/home-protect-front/images/car-rides-road.webp") no-repeat center center / cover;
    height: 1120px;
    z-index: 0;
  }

  .home-protect__green-bg {
    position: absolute;
    background-image: url("/home-protect-front/images/ellipse-1.webp");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    top: 2700px;
    width: 100%;
    height: 1707.094px;
    z-index: 0;
  }
}
</style>
