import {passwordRegex} from "@/constants/regex"
import zod from "zod"

export const registerSchema = zod
  .object({
    iin: zod.string().nonempty().length(12),
    phone: zod
      .string()
      .nonempty()
      .regex(/^\+7 \(\d{3}\) \d{3} \d{4}$/),
    email: zod.string().nonempty().email(),
    password: zod.string().nonempty().regex(passwordRegex),
    repeatPassword: zod.string().nonempty().min(8)
  })
  .refine((data) => data.password === data.repeatPassword, {
    path: ["repeatPassword"]
  })
