<script setup lang="ts">
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {kaskoUrl} from "@/constants/urls"
import FFButton from "@/components/FFUI/FFButton.vue"
import ArrowForBtn from "@/components/icons/ArrowForBtn.vue"

const router = useRouter()
const {currentLocale, openLink} = useHelpers()
</script>

<template>
  <div class="guarantee container">
    <div class="guarantee__first-row">
      <img class="guarantee-xxl" src="/main-front/images/guarantee-xxl.png" alt="guarantee" />
      <img class="guarantee-xl" src="/main-front/images/guarantee-xl.png" alt="guarantee" />
      <img class="guarantee-lg" src="/main-front/images/guarantee-lg.png" alt="guarantee" />
      <img class="guarantee-md" src="/main-front/images/guarantee-md.png" alt="guarantee" />
      <div class="guarantee__first-row__card">
        <div class="bg-[#F2994A] rounded-[5px] text-[16px] text-white w-fit px-[10px] py-[8px] leading-3">DTP.kz</div>
        <p class="guarantee__first-row__card--title">{{ $t("main.guarantee.online") }}</p>
        <p class="guarantee__first-row__card--subtitle">{{ $t("main.guarantee.insuranceThroughFiveDays") }}</p>
        <p class="guarantee__first-row__card--description">{{ $t("main.guarantee.crash") }}</p>
        <FFButton
          @click="openLink(`https://dtp.kz`, '_self')"
          class="guarantee__first-row__card--btn"
          :title="$t('main.guarantee.more')"
          :uppercase="true"
          color="white"
          background="#1e1e1e"
          paddingx="17px"
          paddingy="30px"
          width="100%" />
      </div>
    </div>
    <div class="guarantee__second-row">
      <div class="guarantee__second-row__card">
        <div class="bg-[#F2994A] rounded-[5px] text-[16px] text-white w-fit px-[10px] py-[8px] leading-3">
          {{ $t("main.guarantee.ogpo") }}
        </div>
        <p class="guarantee__second-row__card--title">{{ $t("main.guarantee.pay") }}</p>
        <div class="guarantee__second-row__card--btn" @click="router.push('pages/ogpo')">
          <p class="long">{{ $t("main.guarantee.calculate") }}</p>
          <p class="short">{{ $t("main.guarantee.calculateShort") }}</p>
          <ArrowForBtn class="arrow-svg" />
        </div>
      </div>
      <div class="guarantee__second-row__card">
        <div class="bg-[#F2994A] rounded-[5px] text-[16px] text-white w-fit px-[10px] py-[8px] leading-3">
          {{ $t("main.guarantee.casco") }}
        </div>
        <p class="guarantee__second-row__card--title">{{ $t("main.guarantee.cascoWithoutTricks") }}</p>
        <div class="guarantee__second-row__card--btn" @click="openLink(kaskoUrl, '_self')">
          <p class="long">{{ $t("main.guarantee.calculate") }}</p>
          <p class="short">{{ $t("main.guarantee.calculateShort") }}</p>
          <ArrowForBtn />
        </div>
      </div>
      <div class="guarantee__second-row__card">
        <div class="bg-[#F2994A] rounded-[5px] text-[16px] text-white w-fit px-[10px] py-[8px] leading-3">
          {{ $t("main.guarantee.callCenter") }}
        </div>
        <p class="guarantee__second-row__card--title">{{ $t("main.guarantee.support") }}</p>

        <a href="tel:5777"
          ><FFButton
            class="guarantee__second-row__card--ffbtn"
            :title="$t('main.guarantee.call5777')"
            :uppercase="false"
            color="white"
            background="#1e1e1e"
            paddingx="17px"
            paddingy="12px"
            width="100%"
        /></a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.guarantee {
  display: flex;
  flex-direction: column;
  gap: 30px;
  &__first-row {
    display: flex;
    gap: 30px;
    &__card {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #333333;
      background: #1e1e1e;
      &--title {
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 101%;
        background: var(--FF-gradient, linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 14px;
      }
      &--subtitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        color: #ffffff;
        margin-top: 14px;
      }
      &--description {
        color: #a9a9a9;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 645px;
        white-space: pre-wrap;
      }
      &--btn {
        margin-top: auto;
        border: 1px solid rgba(255, 255, 255, 0.7);
        max-width: 250px;
      }
    }
  }
  &__second-row {
    display: flex;
    gap: 30px;
    &__card {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 20px;
      border: 1px solid #333333;
      background: #1e1e1e;
      width: 100%;
      max-height: 209px;
      max-width: 350px;
      &--title {
        margin-top: 10px;
        margin-bottom: auto;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        background: var(--FF-gradient, linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &--btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: auto;
        p {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          transition: all 0.3s ease;
        }
        .short {
          display: none;
        }
      }
      &--btn:hover {
        p {
          color: #71c35a;
        }
        svg path {
          fill: #71c35a;
        }
      }
      &--ffbtn {
        margin-top: auto;
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.guarantee-xl {
  display: none;
}

.guarantee-lg {
  display: none;
}

.guarantee-md {
  display: none;
}

@media (max-width: $xl) {
  .guarantee-xxl {
    display: none;
  }

  .guarantee-xl {
    display: flex;
  }
  .guarantee {
    &__header {
      :nth-child(2) {
        font-size: 50px;
      }
    }
    &__first-row {
      flex-direction: column;
      &__card {
        &--btn {
          margin-top: 50px;
        }
      }
    }
    &__second-row {
      &__card {
        max-height: 225px;
        &--title {
          font-size: 16px;
        }
        &--btn {
          .long {
            display: none;
          }
          .short {
            display: block;
          }
        }
        &--ffbtn {
          text-transform: inherit;
          white-space: nowrap;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .guarantee-xl {
    display: none;
  }
  .guarantee-lg {
    display: flex;
  }
  .guarantee {
    &__header {
      :nth-child(1) {
        font-size: 30px;
      }
      :nth-child(2) {
        margin-top: -7px;
        font-size: 30px;
      }
    }
    &__first-row {
      &__card {
        &--title {
          margin-bottom: 0;
          font-size: 18px;
        }
        &--description {
          display: none;
        }
        &--btn {
          max-width: inherit;
        }
      }
    }
    &__second-row {
      flex-direction: column;
      &__card {
        height: 209px;
        &--title {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: $md) {
  .guarantee-lg {
    display: none;
  }
  .guarantee-md {
    display: flex;
  }

  .guarantee {
    &__second-row {
      &__card {
        max-width: 100%;
      }
    }
  }
}
</style>
