import {ref} from "vue"
import {defineStore} from "pinia"
import {useFetcher} from "@/composables/useFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {debounce} from "lodash-es"
import {type INewsData} from "@/interfaces/News/INews"
import {type INewsDetail} from "@/interfaces/News/INewsDetail"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher";

export const useNewsStore = defineStore("newsStore", () => {
  const {getData, loading} = useGatewayFetcher()
  const {currentLocale} = useHelpers()

  const news = ref<INewsData | null>(null)
  const newsDetail = ref<INewsDetail>()

  const getNews = async (perPage?: number, page?: number) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await getData(`/news?per_page=${perPage}&page=${page}`, config)
      if (response) {
        news.value = response.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getNewsDetails = async (id: number) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await getData(`/news/${id}`, config)
      if (response) {
        newsDetail.value = response.data
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
    }
  }
  return {
    news,
    newsDetail,
    getNews,
    getNewsDetails
  }
})
