<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <g filter="url(#filter0_i_3380_7451)">
      <path
        d="M20 4C11.2 4 4 11.2 4 20C4 28.8 11.2 36 20 36C28.8 36 36 28.8 36 20C36 11.2 28.8 4 20 4ZM20 12C22.72 12 24.8 14.08 24.8 16.8C24.8 19.52 22.72 21.6 20 21.6C17.28 21.6 15.2 19.52 15.2 16.8C15.2 14.08 17.28 12 20 12ZM20 32.8C16.48 32.8 13.12 31.36 10.72 28.8C14.24 23.68 21.12 22.4 26.24 25.92C27.36 26.72 28.32 27.68 29.12 28.8C26.88 31.36 23.52 32.8 20 32.8Z"
        fill="url(#paint0_linear_3380_7451)" />
    </g>
    <defs>
      <filter
        id="filter0_i_3380_7451"
        x="4"
        y="4"
        width="37"
        height="37"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3380_7451" />
      </filter>
      <linearGradient
        id="paint0_linear_3380_7451"
        x1="36"
        y1="-1.64706"
        x2="-4.95045"
        y2="19.6784"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
    </defs>
  </svg>
</template>
