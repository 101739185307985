<script setup lang="ts">
import {watchEffect} from "vue"
import {storeToRefs} from "pinia"
import {useHelperStore} from "@/stores/HelperStore"
import {useKaskoStore} from "@/stores/KaskoStore"
import {useI18n} from "vue-i18n"
import KaskoHero from "@/components/Kasko/KaskoHero.vue"
import KaskoCases from "@/components/Kasko/KaskoCases.vue"
import KaskoHowWorks from "@/components/Kasko/KaskoHowWorks.vue"
import KaskoWhatSubscriptionIs from "@/components/Kasko/KaskoWhatSubscriptionIs.vue"
import KaskoGuarantee from "@/components/Kasko/KaskoGuarantee.vue"
import KaskoArrangeCarinsurance from "@/components/Kasko/KaskoArrangeCarinsurance.vue"
import KaskoAnyQuestions from "@/components/Kasko/KaskoAnyQuestions.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const ogpoStore = useKaskoStore()
const helperStore = useHelperStore()
const {loading, agreementLoaderHide} = storeToRefs(ogpoStore)
const {t} = useI18n()

watchEffect(() => {
  helperStore.pageTitle = t("app.headerPageTitles.kaskoPage")
})
</script>

<template>
  <template v-if="agreementLoaderHide"> </template>
  <template v-else>
    <Modal style="z-index: 103" :isOpen="loading">
      <Loader />
    </Modal>
  </template>
  <div class="kasko">
    <div class="kasko__black-cool-car-hero"></div>
    <img class="kasko__comfort-salon" src="/kasko-front/images/comfort-salon.png" />
    <div class="kasko__wrapper container">
      <KaskoHero />
      <KaskoCases />
      <KaskoHowWorks />
      <KaskoWhatSubscriptionIs />
      <KaskoGuarantee />
      <KaskoArrangeCarinsurance />
      <KaskoAnyQuestions />
    </div>
  </div>
</template>

<style scoped lang="scss">
.kasko {
  position: relative;
  z-index: 0;

  .kasko__black-cool-car-hero {
    position: absolute;
    background-image: url("/kasko-front/images/black-cool-car-hero.png");
    background-repeat: no-repeat;
    background-position: center top;
    object-fit: cover;
    top: -262px;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 1080px;
    z-index: 0;
  }
  .kasko {
    position: relative;
    width: 100%;
  }
  .kasko__comfort-salon {
    position: absolute;
    object-fit: cover;
    top: 1100px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 1080px;
    z-index: 0;
  }
}

@media (max-width: $xxl) {
  .kasko {
    .kasko__black-cool-car-hero {
      top: -350px;
    }
  }
}

@media (max-width: $xl) {
  .kasko {
    .kasko__black-cool-car-hero {
      top: -280px;
    }
    .kasko__comfort-salon {
      top: 1350px;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      min-height: 750px;
    }
  }
}
</style>
