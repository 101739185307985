<script setup lang="ts">
import {computed} from 'vue'
import {useHelpers} from "@/composables/useHelpers"

const {currentLocale} = useHelpers()

const navLinks = computed(() => {
  return {
    lifeInsurance: {
      url: `https://ffin.life`,
      target: "_self"
    },
    investments: {
      url: `https://ffin.kz/${currentLocale.value}`,
      target: "_self"
    },
    bank: {
      url: `https://bankffin.kz/${currentLocale.value !== 'en' ? currentLocale.value : 'ru'}`,
      target: "_self"
    },
    credit: {
      url: `https://fastcash.credit/${currentLocale.value !== 'en' ? currentLocale.value : 'ru'}`,
      target: "_self"
    },
    partner: {
      url: "",
      target: "_self"
    }
  }
})
</script>

<template>
  <div class="navigation">
    <div class="nav-wrapper">
      <a class="nav-link" :href="navLinks.lifeInsurance.url" :target="navLinks.lifeInsurance.target">
        {{ $t("app.navigation.life") }}
      </a>
      <a class="nav-link" :href="navLinks.investments.url" :target="navLinks.investments.target">
        {{ $t("app.navigation.investments") }}
      </a>
      <a class="nav-link" :href="navLinks.bank.url" :target="navLinks.bank.target">
        {{ $t("app.navigation.bank") }}
      </a>
      <a class="nav-link" :href="navLinks.credit.url" :target="navLinks.credit.target">
        {{ $t("app.navigation.credit") }}
      </a>
      <a class="nav-link" :href="navLinks.partner.url" :target="navLinks.partner.target">{{
        $t("app.navigation.partner")
      }}</a>
    </div>
  </div>
</template>

<style scoped>
.navigation {
  width: 100%;
  border-top: 2px solid transparent;
}
.nav-wrapper {
  padding: 6px 0 6px 40px;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
}
.nav-link {
  color: #999;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: fit-content;
}

.nav-link:hover {
  transition: 0.2s;
  color: white;
}
</style>
