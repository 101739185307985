import {ref, reactive, type Component, computed, watch, type ComputedRef} from "vue"
import {defineStore} from "pinia"
import {useI18n} from "vue-i18n"
import {useRouter} from "vue-router"
import {useModalStore} from "@/stores/ModalStore"
import {useFormatters} from "@/utils/formatters"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {usePdfOpener} from "@/composables/usePdfOpener"
import {useHelpers} from "@/composables/useHelpers"
import {debounce} from "lodash-es"
import {useToast} from "vue-toastification"
import type {ICalculationResponse} from "@/interfaces/Comfort/ICalculationResponse"
import type {IHomeData} from "@/interfaces/Comfort/IHomeData"
import type {IClientCardData} from "@/interfaces/subscription/IClientCardData"
import ComfortForms from "@/components/Comfort/ComfortForms.vue"

export const useComfortStore = defineStore("comfortStore", () => {
  const modalStore = useModalStore()
  const {loading, getData, postData} = useGatewayFetcher()
  const {isDesktop} = useDeviceSize()
  const {phoneNumberFormatter, dateFormatter} = useFormatters()
  const {currentLocale, openLink} = useHelpers()
  const router = useRouter()
  const toast = useToast()
  const {openKdpAgreementPdf, openQuestionnairePdf} = usePdfOpener(postData)
  const {t} = useI18n()

  //Form steps
  const currentStep = ref<number>(1)

  const handleStepNext = () => {
    currentStep.value += 1
  }
  const handleStepBack = () => {
    currentStep.value -= 1
  }
  const resetStep = () => {
    currentStep.value = 1
  }

  //Calculation form
  const calculationForm = ref({
    phone: "",
    iin: "",
    promocode: ""
  })

  const date = ref(new Date(new Date().setDate(new Date().getDate() + 1)))
  const iin = ref<string>("")
  const iinName = ref<string>("")
  const isIinLoadingState = ref<boolean>(false)

  const iinCheck = debounce(async (data: string) => {
    if (!data) {
      iinName.value = ""
      return
    }

    isIinLoadingState.value = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (data.length === 12) {
        const verifyResponse = await postData(
          "/portal/mobile/verify-phone-info",
          {
            phone: phoneNumberFormatter(calculationForm.value.phone),
            iin: calculationForm.value.iin
          },
          config
        )

        if (verifyResponse && verifyResponse.status === 200) {
          const response = await postData(
            "/client/check",
            {
              iin: data,
              clientType: "Individual"
            },
            config
          )
          if (response && response.data && response.status === 200) {
            iinName.value = response.data.data.client
          } else {
            iinName.value = ""
            iin.value = ""
          }
        } else {
          iin.value = ""
        }
      } else {
        iinName.value = ""
      }
      isIinLoadingState.value = false
    } catch (error) {
      iin.value = ""
      iinName.value = ""
      isIinLoadingState.value = false
      console.error(error)
    }
  }, 10)

  const calculationObject = ref<ICalculationResponse[]>([])
  const calculateCost = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const verifyResponse = await postData(
        "/portal/mobile/verify-phone-info",
        {
          phone: phoneNumberFormatter(calculationForm.value.phone),
          iin: calculationForm.value.iin
        },
        config
      )
      if (verifyResponse && verifyResponse.status === 200) {
        const response = await postData(
          "/policy/comfort/calculate",
          {
            phone: phoneNumberFormatter(calculationForm.value.phone),
            iin: calculationForm.value.iin
          },
          config
        )
        if (response && response.status === 200) {
          calculationObject.value = response.data.data
          getCities()
          return true
        } else {
          return false
        }
      } else {
        iin.value = ""
        return false
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Agreement form
  const needKdp = ref<boolean>(true)
  const handleKdp = (): void => {
    needKdp.value = false
  }

  const verificationUsersData = ref<{fullName: string; iin: string; verifyClient: boolean}[]>([])
  const agreementLoaderHide = ref<boolean>(false)

  const fetchNotVerifiedIins = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const clients = [{iin: calculationForm.value.iin, clientType: "individual"}]
      const response = await postData("/portal/get-client-data", {clients}, config)
      if (response?.status === 200) {
        verificationUsersData.value = response.data.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const notVerificatedUsers = computed(() => {
    return verificationUsersData.value
  })

  const allUsersAreVerificated = computed(() => {
    return verificationUsersData.value.every((verficated) => verficated.verifyClient)
  })

  const intervalIds = ref<Record<number, number | undefined>>({})
  const clearAllIntervals = () => {
    Object.keys(intervalIds.value).forEach((index) => {
      clearInterval(intervalIds.value[Number(index)])
      delete intervalIds.value[Number(index)]
    })
  }

  const sendCode = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = notVerificatedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const openComfortAgreementPdf = async (fullName: string, iin: string) => {
    openKdpAgreementPdf(fullName, iin)
  }

  const openComfortQuestionnairePdf = async () => {
    const homeData = {
      iin: calculationForm.value.iin,
      upToInteriorDecorationAmount: currentSumOfCoverage.value.upToInteriorDecorationAmount,
      upToPledge: currentSumOfCoverage.value.upToPledge,
      coverageAmount: currentSumOfCoverage.value.coverageAmount,
      payoutToNeighbors: currentSumOfCoverage.value.payoutToNeighbors,
      city: homeDataObj.value.city.name,
      age: homeDataObj.value.homeAge,
      floor: homeDataObj.value.floor,
      numberOfFloors: homeDataObj.value.overalFloor,
      isRent: forRent.value,
      fromDate: date.value,
      period: 12,
      phone: phoneNumberFormatter(calculationForm.value.phone),
      email: homeDataObj.value.email
    }
    openQuestionnairePdf("/policy/comfort/form", homeData)
  }

  const handleNextStepKdpOrPrice = async () => {
    if (needKdp.value) {
      const notVerificatedUsersResponse = await fetchNotVerifiedIins()
      if (!notVerificatedUsersResponse) {
        return
      }
    }
    if (isDesktop.value) {
      currentStep.value = 2
      modalStore.openModal({
        component: ComfortForms
      })
    } else if (!isDesktop.value) {
      currentStep.value = 2
    } else {
      return
    }
  }

  //Price form
  const sliderSteps = ref<number>(4)
  const sliderStep = ref<number>(0)
  const amountOfCoverage = ref<number>()
  const forRent = ref<boolean>(false)
  const payMonthly = ref<boolean>(false)

  const currentSumOfCoverage = computed(() => {
    return calculationObject.value[sliderStep.value]
  })

  const handleAmountOfCoverageValChange = (event: Event) => {
    const value = Number((event.target as HTMLInputElement).value)
    sliderStep.value = value
  }

  const toggleCheckbox = (type: string) => {
    if (type === "forRent") {
      forRent.value = !forRent.value
    }
    if (type === "payMonthly") {
      payMonthly.value = !payMonthly.value
    }
  }

  const clientCardData = ref<IClientCardData | null>(null)
  const clientCardResponse = ref<IClientCardData[] | null>([])
  const selectedCardType = ref<number>(1)
  const getClientCardData = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData("/card/list", {iin: calculationForm.value.iin}, config)
      if (response?.status === 200 && Array.isArray(response.data)) {
        clientCardData.value = response.data[0]
        clientCardResponse.value = response.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  //Home data form
  const homeDataObj = ref<IHomeData>({
    city: "",
    street: "",
    home: "",
    apartmentNumber: "",
    floor: "",
    overalFloor: "",
    iAmFamiliar: false,
    email: "",
    homeAge: ""
  })

  const isHomeDataComplete = computed(() => {
    return Object.values(homeDataObj.value).every((value) => {
      if (typeof value === "boolean") {
        return value === true
      }
      return value !== ""
    })
  })

  const isHomeDataCompleteWithoutIAmFamiliar = computed(() => {
    const {iAmFamiliar, ...otherFields} = homeDataObj.value
    return Object.values(otherFields).every((value) => value !== "")
  })

  const cities = ref([])
  const getCities = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const response = await getData("/policy/comfort/cities", config)
      if (response && response.data) {
        cities.value = response.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Credit card form
  const creditCardObj = ref({
    cardNumber: "",
    expirationDate: {
      month: "",
      year: ""
    },
    cvv: "",
    cardNaming: ""
  })

  //Payment methods

  const selectedPayment = ref<string>("")

  const showKaspiForm = ref<boolean>(false)
  const showSuperAppBlock = ref<boolean>(false)

  const handleCurrentMethod = (type: string) => {
    selectedPayment.value = type
  }

  //Kaspi payment form
  const kaspiPayPhoneNumber = ref<string>("")

  //Final pay form
  const subscriptionData = ref<{action: string; addCardUrl: string | null} | null>(null)
  const paymentUrl = ref<string>("")

  const subscriptionFinalPay = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/policy/comfort/subscribe",
        {
          holderIin: calculationForm.value.iin,
          email: homeDataObj.value.email,
          clientType: "individual",
          fromDate: dateFormatter(date.value),
          period: 12,
          coverageType: currentSumOfCoverage.value.coverageType,
          cityCode: homeDataObj.value.city.code,
          street: homeDataObj.value.street,
          houseNumber: homeDataObj.value.home,
          numberOfFloors: homeDataObj.value.overalFloor,
          floor: homeDataObj.value.floor,
          flatNumber: homeDataObj.value.apartmentNumber,
          isRent: forRent.value,
          age: homeDataObj.value.homeAge,
          phone: phoneNumberFormatter(calculationForm.value.phone),
          isPayWithNewCard: payMonthly.value && selectedCardType.value === 1 ? false : true
        },
        config
      )
      if (response && response.status === 200) {
        subscriptionData.value = response.data
        if (subscriptionData.value && subscriptionData.value?.action === "add_card") {
          openLink(subscriptionData.value?.addCardUrl, "_self")
        } else {
          modalStore.closeModal()
          router.push(`/pages/subscription-activate-status?calcId=${response.data.orderId}&product=COMFORT`)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const finalPay = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + 1)
      const finalPayObject = {
        holderIin: calculationForm.value.iin,
        email: homeDataObj.value.email,
        clientType: "individual",
        payment_type: selectedPayment.value,
        kaspiPayNumber: kaspiPayPhoneNumber.value ? phoneNumberFormatter(kaspiPayPhoneNumber.value) : "",
        fromDate: dateFormatter(currentDate),
        period: 12,
        coverageType: currentSumOfCoverage.value.coverageType,
        cityCode: homeDataObj.value.city.code,
        street: homeDataObj.value.street,
        houseNumber: homeDataObj.value.home,
        numberOfFloors: homeDataObj.value.overalFloor,
        floor: homeDataObj.value.floor,
        flatNumber: homeDataObj.value.apartmentNumber,
        isRent: forRent.value,
        age: homeDataObj.value.homeAge,
        phone: phoneNumberFormatter(calculationForm.value.phone)
      }
      const response = await postData("/policy/comfort/create/order", finalPayObject, config)
      if (response && response.status === 200) {
        if (selectedPayment.value === "FreedomPay") {
          localStorage.removeItem("paymentType")

          paymentUrl.value = response.data?.payment_url
          window.location.href = paymentUrl.value
          calculationForm.value.iin = ""
          calculationForm.value.phone = ""
        }
        if (selectedPayment.value === "FreedomSuperApp") {
          localStorage.removeItem("paymentType")
          paymentUrl.value = response.data?.payment_url

          if (isDesktop.value) {
            showSuperAppBlock.value = true
          } else {
            openLink(paymentUrl.value, "_self")
            calculationForm.value.phone = ""
          }
        }
        if (selectedPayment.value === "KaspiPay") {
          localStorage.setItem("paymentType", "KaspiPay")
          modalStore.closeModal()
          router.replace("success-pay")
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    calculationForm,
    iin,
    iinName,
    isIinLoadingState,
    calculationObject,
    currentStep,
    sliderStep,
    sliderSteps,
    needKdp,
    verificationUsersData,
    agreementLoaderHide,
    notVerificatedUsers,
    allUsersAreVerificated,
    intervalIds,
    amountOfCoverage,
    forRent,
    payMonthly,
    currentSumOfCoverage,
    clientCardData,
    clientCardResponse,
    selectedCardType,
    homeDataObj,
    isHomeDataComplete,
    isHomeDataCompleteWithoutIAmFamiliar,
    cities,
    selectedPayment,
    paymentUrl,
    showKaspiForm,
    showSuperAppBlock,
    creditCardObj,
    kaspiPayPhoneNumber,
    calculateCost,
    fetchNotVerifiedIins,
    clearAllIntervals,
    iinCheck,
    sendCode,
    openComfortAgreementPdf,
    openComfortQuestionnairePdf,
    handleStepNext,
    handleStepBack,
    handleKdp,
    handleNextStepKdpOrPrice,
    getClientCardData,
    getCities,
    resetStep,
    handleAmountOfCoverageValChange,
    toggleCheckbox,
    handleCurrentMethod,
    subscriptionFinalPay,
    finalPay
  }
})
