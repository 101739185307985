<script setup lang="ts"></script>

<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <circle cx="14.5" cy="14.5" r="14" stroke="#333333" />
      <path d="M11 15H19" stroke="#333333" />
    </g>
  </svg>
</template>

<style scoped lang="scss"></style>
