<script setup lang="ts">
import {onUnmounted, ref} from "vue"
import {storeToRefs} from "pinia"
import {useRoute, useRouter} from "vue-router"
import {useAuthStore} from "@/stores/AuthStore"
import AuthLogin from "@/components/Auth/AuthLogin.vue"
import AuthRegister from "@/components/Auth/AuthRegister.vue"
import AuthResetPassword from "@/components/Auth/AuthResetPassword.vue"

const route = useRoute()
const authStore = useAuthStore()
const {activeTabIndex} = storeToRefs(authStore)

if (route.query.type === "register") {
  authStore.setActiveTabIndex(1)
}
</script>

<template>
  <div class="tabs" :class="{'active-register': activeTabIndex === 1}">
    <p v-if="activeTabIndex !== 3" class="tabs__title">{{ $t("app.auth.title") }}</p>
    <p v-else class="tabs__title">{{ $t("app.auth.resetPasswordTitle") }}</p>
    <div v-if="activeTabIndex !== 3" class="tabs__tab">
      <button :class="{'tabs__tab--active': activeTabIndex === 0}" @click="authStore.goLogin" type="button">
        {{ $t("app.auth.loginTitle") }}
      </button>
      <button :class="{'tabs__tab--active': activeTabIndex === 1}" @click="authStore.goRegister" type="button">
        {{ $t("app.auth.registerTitle") }}
      </button>
    </div>
    <AuthLogin v-if="activeTabIndex === 0" />
    <AuthRegister v-if="activeTabIndex === 1" />
    <AuthResetPassword v-if="activeTabIndex === 3"/>
  </div>
</template>

<style scoped lang="scss">
.tabs {
  z-index: 0;
  position: relative;
  display: flex;
  margin: 0 auto;
  align-items: center;
  gap: 30px;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 264px;
  width: 100%;
  max-width: 720px;
  .tabs__title {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .tabs__tab {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    background: rgba(66, 112, 46, 0.2);
    backdrop-filter: blur(5px);
    button {
      border-radius: 10px;
      width: 100%;
      padding: 17px 0;
      color: rgba(255, 255, 255, 0.5);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      transition: all 0.3s ease;
    }
    button:hover {
      color: #fff;
    }
    .tabs__tab--active {
      cursor: default;
      color: #fff !important;
      background: #404040;
      box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.25);
    }
  }
}

.active-register {
  padding-bottom: 289px;
}

@media (max-width: $xxl) {
  .tabs {
    padding-bottom: 100px;
  }
}

@media (max-width: $xl) {
  .tabs {
    max-width: 450px;
  }
}

@media (max-width: $lg) {
  .tabs {
    padding-top: 50px;
    max-width: 320px;
    &__title {
      font-size: 30px;
    }
  }
}
</style>
