<script setup lang="ts">
import {ref, watch, onBeforeMount} from "vue"
import {useRoute, useRouter} from "vue-router"
import {storeToRefs} from "pinia"
import {useNewsStore} from "@/stores/NewsStore"
import NewsHero from "@/components/News/NewsHero.vue"
import NewsList from "@/components/News/NewsList.vue"

const newsStore = useNewsStore()
const {news} = storeToRefs(newsStore)

const route = useRoute()
const router = useRouter()

const limit = ref(10)
const page = ref(parseInt(route.query.page as string) || 1)

const fetchNews = async () => {
  await newsStore.getNews(limit.value, page.value)
}

onBeforeMount(fetchNews)

watch(
  () => route.query.page,
  async (newPage) => {
    page.value = parseInt(newPage as string) || 1
    await fetchNews()
  }
)

const handlePageChange = (newPage: number) => {
  router.push({query: {...route.query, page: newPage}})
}
</script>

<template>
  <div class="news">
    <NewsHero />
    <NewsList
      :news="news?.data || []"
      :currentPage="page"
      :lastPage="news?.last_page"
      @page-change="handlePageChange" />
  </div>
</template>

<style>
.news {
  background: var(--color-white);
}
</style>
