import {ref} from "vue"
import {gatewayApi} from "@/api"
import type {AxiosError, AxiosRequestConfig} from "axios"
import {useToast} from "vue-toastification"
import jsonBeautify from "json-beautify"
import {useI18n} from "vue-i18n"
export function useGatewayFetcher<T>() {
  const data = ref<T>()
  const loading = ref(false)
  const error = ref<Error>()
  const toast = useToast()
  const {t} = useI18n()
  const getData = async (url: string, config?: AxiosRequestConfig) => {
    loading.value = true
    data.value = undefined
    error.value = undefined
    return gatewayApi
      .get<T>(url, config)
      .then((res) => {
        data.value = res.data
        return data.value
      })
      .catch((err: AxiosError) => {
        console.error(err)
        error.value = new Error(err.message)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const postData = async (url: string, dataToPost: any, config?: AxiosRequestConfig) => {
    loading.value = true
    data.value = undefined
    error.value = undefined
    return gatewayApi
      .post<T>(url, dataToPost, config)
      .then((res) => {
        data.value = res.data
        return res
      })
      .catch((err: AxiosError) => {
        const formattedJson = jsonBeautify(err?.response?.data, null as any, 2, 100)
        if (err?.response?.status === 422) {
          const validationErrors = err?.response?.data?.errors
          if (validationErrors) {
            toast.clear()
            if (Array.isArray(validationErrors)) {
              validationErrors.forEach((error: string) => {
                toast.error(error)
              })
            } else if (typeof validationErrors === "object") {
              Object.keys(validationErrors).forEach((key) => {
                validationErrors[key].forEach((message: string) => {
                  toast.error(message)
                })
              })
            } else {
              toast.error(validationErrors)
            }
            return
          }
        } else if (err?.response?.status === 400 || err?.response?.status === 401 || err?.response?.status === 429) {
          let errorString = err?.response?.data?.errors
          if (typeof errorString === "object" && errorString !== null && "message" in errorString) {
            errorString = errorString.message
          }
          toast.clear()
          toast.error(errorString)
        } else if (err?.response?.status === 500) {
          let errorString = err?.response?.data?.message
          toast.clear()
          toast.error(errorString)
        } else {
          toast.clear()
          toast.error(formattedJson)
          error.value = new Error(err.message)
        }
        throw err
      })
      .finally(() => {
        loading.value = false
      })
  }

  const putData = async (url: string, dataToPut: any, config?: AxiosRequestConfig) => {
    loading.value = true
    data.value = undefined
    error.value = undefined
    return gatewayApi
      .put<T>(url, dataToPut, config)
      .then((res) => {
        data.value = res.data
        return data.value
      })
      .catch((err: AxiosError) => {
        console.error(err)
        error.value = new Error(err.message)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const deleteData = async (url: string, config?: AxiosRequestConfig) => {
    loading.value = true
    data.value = undefined
    error.value = undefined
    return gatewayApi
      .delete(url, config)
      .then(() => {
        data.value = {} as T
        return data.value
      })
      .catch((err: AxiosError) => {
        console.error(err)
        error.value = new Error(err.message)
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {data, loading, error, getData, postData, putData, deleteData}
}
