<script setup lang="ts">
import {computed} from "vue"
import {vMaska} from "maska"
import {storeToRefs} from "pinia"
import {useComfortStore} from "@/stores/ComfortStore"
import {onPhoneFocus} from "@/utils/inputEvents"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFCheckerInput from "@/components/FFUI/FFCheckerInput.vue"

const comfortStore = useComfortStore()
const {calculationForm, iin, iinName, isIinLoadingState} = storeToRefs(comfortStore)

const emit = defineEmits(["nextStep"])

const isFormFilled = computed(() => {
  return calculationForm.value.phone.length !== 17 || calculationForm.value.iin.length !== 12 || iinName.value === ""
})

const submitLoginForm = async () => {
  const response = await comfortStore.calculateCost()
  if (response) {
    comfortStore.handleNextStepKdpOrPrice()
  } else {
    return
  }
}
</script>

<template>
  <span class="comfort-login__title">{{ $t("form.formHeaders.costCalculation") }}</span>
  <div class="comfort-login__form-row">
    <FFFieldset :title="$t('form.inputTitles.phone')">
      <FFInput
        v-maska
        id="phonenumber"
        name="phonenumber"
        type="tel"
        data-maska="+7 (###) ### ####"
        placeholder="+7 (___) ___ ____"
        v-model="calculationForm.phone"
        @focus="onPhoneFocus" />
    </FFFieldset>
  </div>
  <div class="comfort-login__form-row">
    <FFFieldset :title="$t('form.inputTitles.iin')">
      <FFCheckerInput
        dataMaska="############"
        :padding="
          !isIinLoadingState && Boolean(iinName) && calculationForm.iin.length === 12 ? '11px 5px 7px 14px' : '6px 5px 7px 14px'
        "
        :inputHeight="!isIinLoadingState && Boolean(iinName) && calculationForm.iin.length === 12 ? '16px' : '51px'"
        placeholder="123412545654"
        :needUnderText="!isIinLoadingState && Boolean(iinName)"
        :underText="!isIinLoadingState && Boolean(iinName) && calculationForm.iin.length === 12 ? iinName : ''"
        v-model="calculationForm.iin"
        id="iin"
        type="text"
        :loading="isIinLoadingState"
        inputmode="numeric"
        :func="
          () => {
            comfortStore.iinCheck(calculationForm.iin)
          }
        " />
    </FFFieldset>
  </div>
  <div class="comfort-login__form-row">
    <FFFieldset :title="$t('form.inputTitles.promocode')" borderType="dashed">
      <FFInput id="promocode" v-model="calculationForm.promocode" type="text" />
    </FFFieldset>
  </div>
  <FFButton @click="submitLoginForm" :disabled="isFormFilled" :title="$t('app.buttons.calculateCost')" />
</template>

<style scoped lang="scss">
.comfort-login__title {
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  width: 200px;
  margin: 0 auto 20px;
}
.comfort-login__form-row {
  margin-bottom: 20px;
}
.ff-checkbox {
  margin: 30px 0;
}
</style>
