<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <g filter="url(#filter0_i_3380_7479)">
      <path
        d="M8.72618 31.5502C15.0291 37.8531 25.2464 37.8531 31.5492 31.5502C37.8521 25.2473 37.8521 15.03 31.5492 8.72715C25.2464 2.42428 15.0291 2.42428 8.72618 8.72715C2.42461 15.03 2.42461 25.2473 8.72618 31.5502ZM15.885 10.1021L18.2864 12.5035C18.7666 12.9837 18.8686 13.7261 18.5355 14.3187L17.6331 15.9208C17.5247 16.1129 17.4823 16.3353 17.5124 16.5538C17.5425 16.7723 17.6434 16.9749 17.7996 17.1305L23.1458 22.4768C23.3017 22.6328 23.5043 22.7334 23.7228 22.7633C23.9413 22.7931 24.1636 22.7505 24.3555 22.642L25.9577 21.7396C26.246 21.5771 26.5795 21.5135 26.9073 21.5585C27.2351 21.6035 27.5391 21.7546 27.7729 21.9887L30.1756 24.3914C30.4594 24.6758 30.6188 25.0611 30.6188 25.4629C30.6188 25.8647 30.4594 26.2501 30.1756 26.5345L29.0382 27.6719C28.4072 28.3014 27.5861 28.705 26.7023 28.82C25.8185 28.9351 24.9214 28.7553 24.1503 28.3084L24.0418 28.2464C19.3283 25.5532 15.386 21.6939 12.5929 17.0389L12.0467 16.1274C11.5826 15.3551 11.39 14.4497 11.4997 13.5553C11.6095 12.661 12.0151 11.829 12.6522 11.1918L13.7419 10.1021C13.8825 9.96127 14.0496 9.84954 14.2334 9.7733C14.4173 9.69707 14.6144 9.65783 14.8134 9.65783C15.0125 9.65783 15.2096 9.69707 15.3935 9.7733C15.5773 9.84954 15.7444 9.96127 15.885 10.1021Z"
        fill="url(#paint0_linear_3380_7479)" />
    </g>
    <defs>
      <filter
        id="filter0_i_3380_7479"
        x="4"
        y="4"
        width="35.7439"
        height="35.7439"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="3.46658" dy="3.46658" />
        <feGaussianBlur stdDeviation="1.73329" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3380_7479" />
      </filter>
      <linearGradient
        id="paint0_linear_3380_7479"
        x1="36.2764"
        y1="-1.696"
        x2="-5.02828"
        y2="19.8133"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
    </defs>
  </svg>
</template>
