import {ref} from "vue"
import {defineStore} from "pinia"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import {useToast} from "vue-toastification"
import {useI18n} from "vue-i18n"

export const useHelperStore = defineStore("helperStore", () => {
  const {loading, postData} = useGatewayFetcher()
  const {currentLocale} = useHelpers()
  const toast = useToast()
  const {t} = useI18n()

  const {phoneNumberFormatter} = useFormatters()

  //page title
  let pageTitle = ref("")

  //input focus
  const inputIsFocused = ref<boolean>(false)
  const inputId = ref<string>("")

  //bottom sheet
  const isSheetVisible = ref(false)

  const toggleSheet = () => {
    isSheetVisible.value = !isSheetVisible.value
  }

  //ReCaptcha
  const isRecaptchaVerified = ref(false)
  const recaptchaToken = ref<string | null>(null)
  const widgetId = ref<number | null>(null)

  //Juridical pages form handling
  const juridicalFormData = ref<{
    companyName: string
    bin: string
    fio: string
    phone: string
    email: string
    iAgreeWithAgreement: boolean
  }>({
    companyName: "",
    bin: "",
    fio: "",
    phone: "",
    email: "",
    iAgreeWithAgreement: false
  })

  const handleJuridicalFormSend = async (policyName: string) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/policy/juridical/request/send",
        {
          policyName: policyName,
          companyName: juridicalFormData.value.companyName,
          bin: juridicalFormData.value.bin,
          fio: juridicalFormData.value.fio,
          phone: phoneNumberFormatter(juridicalFormData.value.phone),
          email: juridicalFormData.value.email
        },
        config
      )
      if (response?.status === 200) {
        toast.success(t("app.toasterMessages.weWillContactWithYouLater"))
        juridicalFormData.value = {
          companyName: "",
          bin: "",
          fio: "",
          phone: "",
          email: "",
          iAgreeWithAgreement: false
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    pageTitle,
    inputIsFocused,
    inputId,
    isSheetVisible,
    isRecaptchaVerified,
    recaptchaToken,
    widgetId,
    juridicalFormData,
    toggleSheet,
    handleJuridicalFormSend
  }
})
