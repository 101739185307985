import {ref, reactive, computed} from "vue"
import {defineStore, storeToRefs} from "pinia"
import type {IUser} from "@/interfaces/Auth/IUser"
import type {IRegisterData} from "@/interfaces/Auth/IRegisterData"
import {useToast} from "vue-toastification"
import {useModalStore} from "@/stores/ModalStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useFormatters} from "@/utils/formatters"
import {useHelpers} from "@/composables/useHelpers"
import {useRoute, useRouter} from "vue-router"
import {useI18n} from "vue-i18n"
import {throttle} from "lodash-es"
import {useRecaptcha} from "vue3-recaptcha-v2"
import {iinAndPhoneSchema, passwordsSchema} from "@/schemas"
import FFOtp from "@/components/FFUI/FFOtp.vue"
import VOtpInput from "vue3-otp-input"

interface ILoginResponse {
  token: string
  token_expired_at: number
  refresh_token: string
  refresh_token_expired_at: number
}

export const useAuthStore = defineStore("authStore", () => {
  const modalStore = useModalStore()
  const helperStore = useHelperStore()

  const {isRecaptchaVerified, recaptchaToken, widgetId} = storeToRefs(helperStore)
  const {postData, getData, loading} = useGatewayFetcher()
  const {handleReset} = useRecaptcha()
  const {phoneNumberFormatter} = useFormatters()
  const {currentLocale, scrollToTop} = useHelpers()
  const route = useRoute()
  const router = useRouter()
  const {t} = useI18n()
  const toast = useToast()

  const activeTabIndex = ref(0)

  const setActiveTabIndex = (index: number) => {
    activeTabIndex.value = index
  }

  const goLogin = () => {
    activeTabIndex.value = 0
    router.push({path: route.path})
  }

  const goRegister = () => {
    activeTabIndex.value = 1
    router.push({path: route.path, query: {type: "register"}})
  }

  const credentials = ref({
    phone: "",
    identifier: "",
    confirmationCode: ""
  })

  const loginData = reactive({
    phone: "",
    password: ""
  })
  const registerData = reactive<IRegisterData>({
    email: "",
    password: "",
    repeatPassword: "",
    phone: "",
    iin: ""
  })

  const isAuthenticated = ref(false)
  const accessToken = ref<string>("")
  const refreshToken = ref<string>("")
  const currentUser = ref()
  const localStorageAccessToken = localStorage.getItem("access-token") || ""

  const getUser = async (access_token: string) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value,
          Authorization: `Bearer ${access_token}`
        }
      }
      const response = await postData("/auth/user", {}, config)
      if (response?.status === 200) {
        localStorage.setItem("current-user", JSON.stringify(response?.data))
        currentUser.value = response?.data
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  //Login
  const handleLogin = throttle(async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      isAuthenticated.value = false
      const response = await postData(
        "/auth/login",
        {
          phone: phoneNumberFormatter(loginData.phone),
          password: loginData.password
          // recaptcha_token: recaptchaToken.value
        },
        config
      )
      if (response?.status === 200 && response?.data?.access_token && response?.data?.refresh_token) {
        localStorage.setItem("access_token", response?.data?.access_token)
        localStorage.setItem("refresh_token", response?.data?.refresh_token)
        accessToken.value = response?.data?.access_token
        refreshToken.value = response?.data?.refresh_token
        isAuthenticated.value = true
        handleReset(widgetId.value)
        isRecaptchaVerified.value = false

        const userResponse = await getUser(response?.data?.access_token)
        if (userResponse) {
          router.push("/pages/personal-profile")
        } else {
          logout()
        }
      }
    } catch (error) {
      logout()
      handleReset(widgetId.value)
      isRecaptchaVerified.value = false
      console.error(error)
    }
  }, 3000)

  //Registration
  const validateRegisterForm = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const response = await postData(
        "/auth/register/validate",
        {
          email: registerData.email,
          password: registerData.password,
          phone: phoneNumberFormatter(registerData.phone),
          confirmPassword: registerData.repeatPassword,
          iin: registerData.iin,
          recaptcha_token: recaptchaToken.value
        },
        config
      )
      if (response?.status === 200) {
        scrollToTop()
        handleReset(widgetId.value)
        isRecaptchaVerified.value = false
        if (response?.data?.sendOtp) {
          startTimer()
          otpCode.value = ""
          codeIsSent.value = true
          showOtp.value = true
        }
        return true
      } else {
        return false
      }
    } catch (error) {
      handleReset(widgetId.value)
      isRecaptchaVerified.value = false
      console.error(error)
      return false
    }
  }

  const handleRegister = throttle(async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const response = await postData(
        "/auth/register",
        {
          email: registerData.email,
          password: registerData.password,
          confirmPassword: registerData.repeatPassword,
          phone: phoneNumberFormatter(registerData.phone),
          iin: registerData.iin,
          otpCode: otpCode.value
        },
        config
      )
      if (response?.status === 200) {
        registerData.phone = ""
        registerData.email = ""
        registerData.iin = ""
        registerData.password = ""
        registerData.repeatPassword = ""
        clearRegisterOtpData()
        goLogin()
        toast.clear()
        toast.success(t("app.toasterMessages.auth.youHaveBeenRegistered"))
      } else {
        return
      }
    } catch (error) {
      console.error(error)
      return
    }
  }, 3000)

  const logout = () => {
    localStorage.clear()
    accessToken.value = ""
    refreshToken.value = ""
    isAuthenticated.value = false
  }

  //Register OTP code
  const otpCode = ref("")
  const showOtp = ref<boolean>(false)
  const codeIsSent = ref<boolean>(false)
  const sentOtpResponse = ref<{message: string; status: boolean}>()
  const timer = ref(0)

  const clearRegisterOtpData = () => {
    otpCode.value = ""
    showOtp.value = false
    timer.value = 0
    codeIsSent.value = false
    resetPasswordCurrentStep.value = 1
  }

  const buttonTitle = computed(() => {
    if (timer.value > 0) {
      const minutes = Math.floor(timer.value / 60)
      const seconds = timer.value % 60
      return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
    }
    return t("app.buttons.sendCode")
  })

  const startTimer = () => {
    timer.value = 90
    const interval = setInterval(() => {
      timer.value--
      if (timer.value <= 0) {
        clearInterval(interval)
        codeIsSent.value = false
      }
    }, 1000)
  }

  const handleSendOtp = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/auth/send-otp",
        {phone: phoneNumberFormatter(registerData.phone), type: "otp_registration"},
        config
      )
      if (response?.status === 200) {
        startTimer()
        otpCode.value = ""
        codeIsSent.value = true
        showOtp.value = true
        sentOtpResponse.value = response.data.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkOtpCode = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/auth/check-otp",
        {
          phone: phoneNumberFormatter(registerData.phone),
          otpCode: otpCode.value
        },
        config
      )

      if (response && response.status === 200) {
        await handleRegister()
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Reset password OTP code
  const resetPasswordOtpCode = ref("")
  const resetPasswordCodeIsSent = ref<boolean>(false)
  const resetPasswordTimer = ref(0)
  const resetPhoneObj = ref<{resetIin: string; resetPhoneNumber: string}>({
    resetIin: "",
    resetPhoneNumber: ""
  })
  const resetPasswordObj = ref<{email: string; password: string; confirmPassword: string}>({
    email: "",
    password: "",
    confirmPassword: ""
  })
  const resetPasswordCurrentStep = ref<number>(1)

  const resetPasswordButtonTitle = computed(() => {
    if (resetPasswordTimer.value > 0) {
      const minutes = Math.floor(resetPasswordTimer.value / 60)
      const seconds = resetPasswordTimer.value % 60
      return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
    }
    return t("app.buttons.sendCode")
  })

  const resetPasswordStartTimer = () => {
    resetPasswordTimer.value = 90
    const interval = setInterval(() => {
      resetPasswordTimer.value--
      if (resetPasswordTimer.value <= 0) {
        clearInterval(interval)
        resetPasswordCodeIsSent.value = false
      }
    }, 1000)
  }

  const resetPhoneNumberCheckResponse = ref()
  const resetPhoneNumberCheck = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const response = await postData(
        "/auth/check-user",
        {
          iin: resetPhoneObj.value.resetIin,
          phone: phoneNumberFormatter(resetPhoneObj.value.resetPhoneNumber),
          recaptcha_token: recaptchaToken.value
        },
        config
      )
      if (response?.status === 200) {
        isRecaptchaVerified.value = false
        resetPhoneNumberCheckResponse.value = response.data
        if (response.data?.isRepeatSend) {
          toast.warning(t("app.toasterMessages.auth.smsAlreadySent"))
        }
        if (response.data?.sendOtp) {
          resetPasswordCodeIsSent.value = true
          resetPasswordStartTimer()
          return true
        }
      } else {
        return false
      }
    } catch (error) {
      isRecaptchaVerified.value = false
      console.error(error)
      return false
    }
  }

  const resetPasswordSentOtpResponse = ref<{message: string; status: boolean}>()
  const resetPasswordSendOtp = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/auth/send-otp",
        {phone: phoneNumberFormatter(resetPhoneObj.value.resetPhoneNumber), type: "security_purposes_reset_password"},
        config
      )
      if (response?.status === 200) {
        if (response.data?.data?.isRepeatSend) {
          toast.warning(t("app.toasterMessages.auth.smsAlreadySent"))
        }
        resetPasswordStartTimer()
        resetPasswordCodeIsSent.value = true
        resetPasswordSentOtpResponse.value = response.data.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  const resetPasswordCheckOtpResponse = ref()
  const resetPasswordCheckOtpCode = async (code: string) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      resetPasswordOtpCode.value = code
      const response = await postData(
        "/auth/check-otp",
        {
          phone: phoneNumberFormatter(resetPhoneObj.value.resetPhoneNumber),
          otpCode: resetPasswordOtpCode.value
        },
        config
      )
      if (response?.status === 200 && response?.data?.data) {
        resetPasswordCheckOtpResponse.value = response?.data?.data
        if (resetPasswordCheckOtpResponse.value.status) {
          resetPasswordCurrentStep.value = 2
          modalStore.closeModal()
          otpCode.value = ""
          showOtp.value = false
          codeIsSent.value = false

          resetPasswordCodeIsSent.value = false
          scrollToTop()
        } else {
          toast.clear()
          toast.error(resetPasswordCheckOtpResponse.value.message)
          return
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const closeOtpForm = () => {
    resetPasswordOtpCode.value = ""
    resetPasswordCodeIsSent.value = false
    resetPasswordTimer.value = 0
    modalStore.closeModal()
  }

  const handleResetOtpFormOpen = async () => {
    const otpProps = {
      codeIsSent: resetPasswordCodeIsSent,
      buttonTitle: resetPasswordButtonTitle,

      sendOtp: resetPhoneNumberCheck,
      checkOtp: resetPasswordCheckOtpCode,
      closeOtp: closeOtpForm
    }
    modalStore.openModal({
      component: FFOtp,
      props: otpProps
    })
  }

  const clearResetPasswordData = () => {
    if (resetPasswordCurrentStep.value === 1) {
      goLogin()
      resetPasswordOtpCode.value = ""
      resetPasswordCodeIsSent.value = false
      resetPasswordTimer.value = 0
      resetPasswordObj.value = {
        password: "",
        confirmPassword: ""
      }
      resetPasswordCurrentStep.value = 1
    } else {
      resetPasswordOtpCode.value = ""
      resetPasswordCodeIsSent.value = false
      resetPasswordTimer.value = 0

      resetPasswordObj.value = {
        password: "",
        confirmPassword: ""
      }
      resetPasswordCurrentStep.value = 1
    }
  }

  const resetPassword = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const payload = {
        otpCode: resetPasswordOtpCode.value,
        email: resetPasswordObj.value.email,
        phone: phoneNumberFormatter(resetPhoneObj.value.resetPhoneNumber),
        password: resetPasswordObj.value.password,
        confirmPassword: resetPasswordObj.value.confirmPassword
      }
      const response = await postData("/auth/reset-password", payload, config)
      if (response?.status === 200) {
        goLogin()
        clearResetPasswordData()
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return {
    activeTabIndex,
    loading,
    credentials,
    loginData,
    registerData,
    isAuthenticated,
    accessToken,
    refreshToken,
    currentUser,
    localStorageAccessToken,
    otpCode,
    showOtp,
    codeIsSent,
    sentOtpResponse,
    buttonTitle,
    resetPasswordCodeIsSent,
    resetPasswordCheckOtpResponse,
    resetPhoneObj,
    resetPasswordObj,
    resetPasswordCurrentStep,
    resetPhoneNumberCheckResponse,
    handleSendOtp,
    checkOtpCode,
    setActiveTabIndex,
    goLogin,
    goRegister,
    handleLogin,
    getUser,
    validateRegisterForm,
    handleRegister,
    logout,
    handleResetOtpFormOpen,
    resetPhoneNumberCheck,
    clearResetPasswordData,
    resetPassword
  }
})
