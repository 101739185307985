<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import {externalUrls} from "@/constants/urls"

const {openLink} = useHelpers()

const detectDeviceOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS"
  }

  if (/android/i.test(userAgent)) {
    return "Android"
  }

  return "Unknown"
}

const redirectToAppStore = () => {
  const deviceOS = detectDeviceOS()

  if (deviceOS === "iOS") {
    openLink(externalUrls.dtpMobileAppAppStoreUrl, "_blank")
  } else if (deviceOS === "Android") {
    openLink(externalUrls.dtpMobileAppGoogleStoreUrl, "_blank")
  } else {
    openLink(externalUrls.dtpMobileAppGoogleStoreUrl, "_blank")
  }
}
</script>

<template>
  <section class="dtp-download-app container">
    <div class="dtp-download-app__wrapper">
      <div class="dtp-download-app__left-part">
        <p class="dtp-download-app__paragraph">{{ $t("app.dtpLandingPage.download.paragraph") }}</p>
      </div>
      <div class="dtp-download-app__right-part">
        <button @click="redirectToAppStore" class="dtp-download-app__download-button">
          {{ $t("app.dtpLandingPage.download.downloadApp") }}
          <img src="/icons-front/white-right-arrow-icon.svg" alt="" />
        </button>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.dtp-download-app {
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  background: radial-gradient(54.59% 70.24% at 0% 100%, rgba(38, 73, 52, 0.6) 0%, rgba(27, 27, 27, 0.6) 100%);
  backdrop-filter: blur(11.5px);
  margin-top: 150px;
  margin-bottom: 400px;
  z-index: 1;
  position: relative;

  .dtp-download-app__wrapper {
    @include row;

    @include max-responsive(xl) {
      @include column;
    }
    .dtp-download-app__left-part {
      max-width: 740px;
      padding: 30px 0;
      border-right: 1px solid rgba(255, 255, 255, 0.1);

      @include max-responsive(xl) {
        border-right: unset;
      }
      .dtp-download-app__paragraph {
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        @include main-green-gradient;
        max-width: 740px;
        padding-right: 50px;
      }
    }
    .dtp-download-app__right-part {
      margin: auto;

      @include max-responsive(xxl) {
        margin-left: auto;
        margin-right: 0;
      }

      @include max-responsive(xl) {
        margin-right: auto;
        margin-left: 0;
      }

      @include max-responsive(lg) {
        margin: 0;
        width: 100%;
      }

      .dtp-download-app__download-button {
        @include row;
        gap: 20px;
        color: var(--color-white);
        border-radius: 10px;
        border: 1px solid #fff;
        max-width: 210px;
        padding: 20px 24px;
        white-space: nowrap;

        @include max-responsive(xxl) {
          margin-left: 20px;
        }

        @include max-responsive(xl) {
          max-width: 300px;
          margin-left: 0;
        }

        @include max-responsive(lg) {
          width: 100%;
          max-width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
