<script setup>
import FFButton from "@/components/FFUI/FFButton.vue"
import {useHelpers} from "@/composables/useHelpers"

let data = "Расcчитать стоимость"
const list = [
  "Отдых за рубежом",
  "Гостевая поездка",
  "Бизнес-поездка",
  "Работа по найму",
  "Спорт",
  "Обучение",
  "Многократная поездка"
]

const {scrollToTop} = useHelpers()
</script>

<template>
  <section class="mst-banner">
    <div class="container mx-auto">
      <div class="mst-banner-wrapper">
        <div class="mst-banner-inner">
          <div class="mst-banner-content">
            <div class="mst-banner-title">{{ $t('app.mstPage.setUpInsuranceForYourself') }}</div>
            <div class="mst-banner-list">
              <ul>
                <li v-for="num in [1, 2, 3, 4, 5, 6, 7]" :key="num">{{ $t(`app.mstPage.setupInsurance-${num}`) }}</li>
              </ul>
            </div>
            <FFButton
              :title="$t('app.mstPage.getPrice')"
              :uppercase="true"
              width="fit-content"
              widthMobile="100%"
              colorMobile="#ffffff"
              backgroundMobile="transparent"
              borderPixelMobile="1px solid #ffffff"
              @click="scrollToTop" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.mst-banner {
  background: black;
  padding-bottom: 100px;
}

.mst-banner-wrapper {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border: none;
  background: url("/mst-front/images/mst-banner-bg.png") no-repeat center;
}

.mst-banner-inner {
  padding: 66px 75px 30px 75px;
}

.mst-banner-title {
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

.mst-banner-list {
  color: #dfdfdf;
  padding-top: 20px;
  padding-bottom: 50px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.mst-banner-list ul {
  padding-left: 15px;
  list-style-type: disc;
}

@media (max-width: $md) {
}

@media (max-width: $lg) {
  .mst-banner-list {
    ul {
      padding-left: 25px;
    }

    li {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

@media (max-width: $xl) {
  .mst-banner-inner {
    padding: 30px;
  }

  .mst-banner-title {
    font-size: 30px;
    font-weight: 900;
    max-width: 250px;
  }
}
</style>
