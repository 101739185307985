import "./assets/main.css"
import router from "@/router"
import {createApp} from "vue"
import {createPinia} from "pinia"
import {createHead} from "@unhead/vue"
import App from "./App.vue"
import i18n from "./i18n"
import Toast from "vue-toastification"
import VueGtag from "vue-gtag-next"
import PrimeVue from "primevue/config"
import Tooltip from "primevue/tooltip"
import {install} from "vue3-recaptcha-v2"
import "primevue/resources/primevue.min.css"
import "primevue/resources/themes/aura-light-green/theme.css"
import "vue-toastification/dist/index.css"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

const pinia = createPinia()
const app = createApp(App)
const head = createHead()

export const isDev = import.meta.env.MODE === "development"
export const isProd = import.meta.env.MODE === "production"

app.config.globalProperties.$isDev = isDev
app.config.globalProperties.$isProd = isProd

const options = {
  timeout: 10000,
  position: "top-right",
  rtl: false
}
if (isProd) {
  app.use(VueGtag, {
    property: {
      id: "G-PZCLX64FWX"
    }
  })
}

app.use(install, {
  sitekey: import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY
})
app.use(pinia)
app.use(PrimeVue)
app.use(Toast, options)
app.use(router)
app.use(i18n)
app.use(head)
app.directive("tooltip", Tooltip)

app.mount("#app")
