<script setup lang="ts"></script>

<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14.5" cy="14.5" r="14" stroke="#727272" />
    <path
      d="M17.7077 14.9572H14.9577V17.7072H14.041V14.9572H11.291V14.0405H14.041V11.2905H14.9577V14.0405H17.7077V14.9572Z"
      fill="#727272" />
  </svg>
</template>

<style scoped lang="scss"></style>
