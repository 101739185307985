<script setup lang="ts">
import {useRouter} from "vue-router"
import {storeToRefs} from "pinia"
import {useNewsStore} from "@/stores/NewsStore"
import {useHelpers} from "@/composables/useHelpers"

const newsStore = useNewsStore()
const {news} = storeToRefs(newsStore)
const {currentLocale, openLink} = useHelpers()

const router = useRouter()
</script>

<template>
  <div class="news container">
    <p class="news__title">{{ $t("main.last_news") }}</p>
    <div class="news__list">
      <div
        class="news__list--item"
        @click="router.push(`/pages/news/${i.news_id}`)"
        v-for="(i, index) in news?.data"
        :key="index">
        <img :src="`https://ffins.kz${i.news_image}`" :alt="i.news_name_ru" />
        <p v-if="currentLocale === 'ru'">{{ i.news_name_ru !== "" ? i.news_name_ru : i.news_name_ru }}</p>
        <p v-if="currentLocale === 'kk'">{{ i.news_name_kz !== "" ? i.news_name_kz : i.news_name_ru }}</p>
        <p v-if="currentLocale === 'en'">{{ i.news_name_en !== "" ? i.news_name_en : i.news_name_ru }}</p>
      </div>
    </div>
    <p class="news__more" @click="router.push('/pages/news')">
      {{ $t("main.more_news") }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.news {
  display: flex;
  flex-direction: column;
  gap: 50px;
  &__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__list {
    display: flex;
    gap: 30px;
    &--item {
      display: flex;
      flex-direction: column;
      max-width: 255px;
      gap: 20px;
      cursor: pointer;
      transition: all 0.3s ease;
      img {
        border-radius: 10px;
        object-fit: cover;
        width: 255px;
        height: 267px;
      }
      p {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    &--item:hover {
      transform: scale(1.05);
    }
  }
  &__more {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 101%;
    text-decoration-line: underline;
    transition: all 0.3s ease;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
  }
  &__more:hover {
    color: #71c35a;
    text-decoration-line: none;
  }
}

@media (max-width: $xxl) {
  .news {
    &__list {
      &--item {
        max-width: 210px;
      }
    }
  }
}

@media (max-width: $xl) {
  .news {
    position: relative;
    z-index: 1;
    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      &--item {
        max-width: 330px;
        img {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .news {
    &__title {
      font-size: 30px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 50px;
    }
  }
}

@media (max-width: $md) {
  .news {
    &__list {
      grid-row-gap: 30px;
    }
  }
}
</style>
