<script setup lang="ts">
import {ref, watch} from "vue"
import {useToast} from "vue-toastification"
import {vMaska} from "maska"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {filterCyrillicInput, filterSpecChars} from "@/utils/inputEvents"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import AddAutoIcon from "@/components/icons/AddAutoIcon.vue"

const toast = useToast()
const {t} = useI18n()
const addDriverAutoStore = useAddDriverAutoStore()

const {
  addDriverAutoByGlobalId,
  autoNumber,
  isDtRegCertGreaterThanContractDate,
  autoName,
  pushedVehicles,
  persons,
  personsForCalculation,
  vehicles,
  isAutonumtechpassFilled,
  isDriverAutoSumCalculated
} = storeToRefs(addDriverAutoStore)

const addAuto = async (autoNumb: string) => {
  isDriverAutoSumCalculated.value = false
  if (personsForCalculation?.value.length > 1 && vehicles.value.length === 1) {
    autoNumber.value = ""
    toast.clear()
    toast.error(t("app.toasterMessages.carNumCheck.cantAddMoreAuto"))
    return
  }
  const isDuplicateInArray = vehicles.value.some((item) => item.regNum === autoNumb)
  if (isDuplicateInArray) {
    toast.error(t("app.toasterMessages.carNumCheck.currentAutoNumisInList"))
    autoNumber.value = ""
    return
  }

  const response = await addDriverAutoStore.autonumtechpasCheck(autoNumb)
  if (response?.status) {
    pushedVehicles.value.push({
      regNum: autoNumb,
      carFullName: autoName.value || "",
      isNew: response.isNew
    })
    autoNumber.value = ""
  } else {
    return
  }
}

const deleteAuto = (regNum: string) => {
  isDriverAutoSumCalculated.value = false
  pushedVehicles.value = pushedVehicles.value.filter((vehicle) => vehicle.regNum !== regNum)

  if (addDriverAutoByGlobalId.value && addDriverAutoByGlobalId.value.insureds) {
    addDriverAutoByGlobalId.value.vehicles = addDriverAutoByGlobalId.value.vehicles.filter(
      (vehicle) => vehicle.regNum !== regNum
    )
  }
}

const preventAutoNumCyrillicInput = () => {
  autoNumber.value = filterCyrillicInput(autoNumber.value)
  autoNumber.value = filterSpecChars(autoNumber.value)
}
</script>
<template>
  <div class="add-auto">
    <p class="add-auto__transport-title">{{ $t("app.addDriverAuto.transportVehicle") }}</p>
    <hr />
    <div v-if="vehicles.length" class="add-auto__list">
      <div v-for="(auto, index) in vehicles" :key="auto.regNum">
        <div class="add-auto__list--auto">
          <div>
            <p v-if="!auto.carFullName" class="model">{{ auto.mark }} {{ auto.model }}</p>
            <p v-else class="model">{{ auto.carFullName }}</p>
            <p class="gos_num">{{ auto.regNum }}</p>
          </div>
          <img
            @click="deleteAuto(auto.regNum)"
            src="/auth-front/images/clear-input-big.svg"
            class="cursor-pointer"
            alt="icon" />
        </div>
      </div>
    </div>
    <div class="add-auto__form">
      <FFFieldset :title="$t('form.inputTitles.autoNumTechPass')">
        <FFInput
          v-model="autoNumber"
          @input="
            () => {
              autoNumber = autoNumber.toUpperCase()
              preventAutoNumCyrillicInput()
            }
          "
          :placeholder="$t('app.addDriverAuto.govNumberPlaceholder')"
          type="text" />
      </FFFieldset>
      <button class="add-mobile" @click="addAuto(autoNumber)">
        <AddAutoIcon :activeTab="1" />
      </button>
      <FFButton
        class="add-desktop"
        @click="addAuto(autoNumber)"
        :disabled="!isAutonumtechpassFilled"
        style="width: fit-content"
        :title="t('app.buttons.add')"
        :uppercase="false"
        color="#4F9D3A"
        paddingx="12.5px"
        paddingy="20px"
        width="100%"
        background="#fff"
        border="5px" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.add-auto {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);

  .add-auto__transport-title {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 10px;
  }

  .add-mobile {
    border-radius: 5px;
    border: 2px solid #fff;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
    padding: 7px 9px;
    display: none;
  }
  .add-auto__form {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    .add-desktop {
      margin-top: 30px;
    }
  }
  .add-auto__list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 30px 0 10px;
    .add-auto__list--auto {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        .model {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .gos_num {
          color: rgba(255, 255, 255, 0.5);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .add-auto {
    padding: 30px 16px;
    .add-desktop {
      display: none;
    }
    .add-mobile {
      display: block;
    }
    .add-auto__form {
      gap: 10px;
      margin-top: 20px;
    }
    .add-auto__list {
      .add-auto__list--auto {
        gap: 20px;
      }
    }
  }
}
</style>
