<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="66" viewBox="0 0 41 66" fill="none">
    <rect x="2.5" y="12.5" width="33" height="43" rx="2.5" stroke="url(#paint0_linear_152_3838)" stroke-width="5" />
    <rect x="2.5" y="12.5" width="33" height="43" rx="2.5" stroke="black" stroke-opacity="0.2" stroke-width="5" />
    <g filter="url(#filter0_bd_152_3838)">
      <path
        d="M35.726 10.0892L11.726 4.08917C11.2839 3.97889 10.8224 3.97079 10.3767 4.06548C9.93091 4.16016 9.51259 4.35515 9.15345 4.63564C8.79431 4.91613 8.50378 5.27474 8.30393 5.68427C8.10407 6.0938 8.00013 6.54348 8.00001 6.99917V60.9991C7.99896 61.4552 8.10213 61.9055 8.30163 62.3156C8.50112 62.7258 8.79169 63.0849 9.15113 63.3656C9.51058 63.6464 9.9294 63.8413 10.3757 63.9354C10.8219 64.0296 11.2838 64.0206 11.726 63.9091L35.726 57.9091C36.3753 57.7472 36.9518 57.3728 37.3638 56.8455C37.7759 56.3182 37.9998 55.6683 38 54.9991V12.9992C37.9998 12.33 37.7759 11.6801 37.3638 11.1528C36.9518 10.6255 36.3753 10.2511 35.726 10.0892Z"
        fill="#7DC851"
        fill-opacity="0.32" />
    </g>
    <ellipse cx="13.5" cy="36.5" rx="2.5" ry="3.5" fill="white" />
    <defs>
      <filter
        id="filter0_bd_152_3838"
        x="5"
        y="0"
        width="36"
        height="67"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_152_3838" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect1_backgroundBlur_152_3838" result="effect2_dropShadow_152_3838" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_152_3838" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_152_3838"
        x1="-0.798319"
        y1="10.4034"
        x2="49.0588"
        y2="46.2992"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#8DD979" />
        <stop offset="1" stop-color="#2A5704" />
      </linearGradient>
    </defs>
  </svg>
</template>
