<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="40" viewBox="0 0 36 40" fill="none">
    <path
      d="M12.9747 2.25697C14.8331 1.70388 16.808 1.20703 17.9898 1.20703C19.1717 1.20703 21.1466 1.70388 23.005 2.25697C24.9063 2.81943 26.8229 3.48501 27.95 3.88811C28.4212 4.05845 28.8389 4.37083 29.1553 4.78937C29.4716 5.20791 29.6736 5.71564 29.7382 6.25421C30.759 14.6481 28.3902 20.8689 25.516 24.9843C24.2973 26.7448 22.844 28.2951 21.2048 29.5834C20.638 30.0292 20.0375 30.4212 19.4098 30.7552C18.9302 31.0027 18.4146 31.2052 17.9898 31.2052C17.5651 31.2052 17.0512 31.0027 16.5699 30.7552C15.9422 30.4212 15.3416 30.0292 14.7749 29.5834C13.1357 28.295 11.6825 26.7447 10.4637 24.9843C7.58952 20.8689 5.22067 14.6481 6.24152 6.25421C6.30607 5.71564 6.50811 5.20791 6.82442 4.78937C7.14074 4.37083 7.5585 4.05845 8.02972 3.88811C9.6658 3.30092 11.3146 2.75704 12.9747 2.25697Z"
      fill="url(#paint0_linear_2949_6331)" />
    <g filter="url(#filter0_bi_2949_6331)">
      <path
        d="M17.616 4.23047C10.4058 4.23047 4.5392 10.1661 4.5392 17.453C4.50215 25.7834 12.2069 33.0017 15.8122 35.9285C16.3848 36.3933 16.671 36.6257 17.073 36.7372C17.3918 36.8257 17.8378 36.8241 18.156 36.7334C18.5572 36.619 18.8398 36.3862 19.405 35.9207C22.9941 32.9648 30.7292 25.6424 30.6928 17.4612C30.6928 10.1661 24.8262 4.23047 17.616 4.23047ZM17.616 24.0766C14.0035 24.0766 11.0776 21.1162 11.0776 17.4612C11.0776 13.8062 14.0035 10.8459 17.616 10.8459C21.2285 10.8459 24.1544 13.8062 24.1544 17.4612C24.1544 21.1162 21.2285 24.0766 17.616 24.0766Z"
        fill="url(#paint1_radial_2949_6331)"
        fill-opacity="0.8" />
    </g>
    <ellipse cx="17.6145" cy="17.3059" rx="6.92308" ry="6.92308" fill="white" />
    <defs>
      <filter
        id="filter0_bi_2949_6331"
        x="-0.460938"
        y="-0.769531"
        width="36.1543"
        height="42.5703"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2949_6331" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2949_6331" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="0.454965" dy="0.227482" />
        <feGaussianBlur stdDeviation="1.13741" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2949_6331" />
      </filter>
      <linearGradient
        id="paint0_linear_2949_6331"
        x1="29.9797"
        y1="1.20703"
        x2="0.985069"
        y2="18.247"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2949_6331"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(35.9246 -6.62978) rotate(119.941) scale(43.7077 39.736)">
        <stop stop-color="white" stop-opacity="0.700689" />
        <stop offset="1" stop-color="#B3EBDA" stop-opacity="0.254405" />
      </radialGradient>
    </defs>
  </svg>
</template>
