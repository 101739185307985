<script setup></script>

<template>
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1483 11.4082L12.3427 10.5751C12.1827 10.4864 12.0239 10.5576 11.8646 10.7526C11.6702 11.0182 11.5808 11.1245 11.3508 11.4082C11.2271 11.5676 11.1383 11.6739 10.9971 11.6914C10.8371 11.727 10.6252 11.6382 10.5896 11.6382C10.0227 11.4439 9.36769 10.9651 8.87206 10.522C8.39394 10.0795 7.86269 9.45948 7.63269 8.91011C7.59706 8.83948 7.52644 8.66198 7.63269 8.52073C7.84519 8.23698 7.88081 8.21948 8.11081 7.95386C8.27019 7.75886 8.39394 7.58198 8.34081 7.42198L7.54456 5.35011C7.47331 5.20823 7.26144 5.19073 7.01331 5.17261C6.80081 5.17261 6.57081 5.17261 6.41144 5.20823C6.05769 5.33261 5.33144 6.28886 5.24331 6.98011C5.04831 8.48573 6.41144 10.4876 7.77519 11.7276C9.13894 12.9857 11.2458 14.1726 12.7327 13.8357C13.4233 13.6764 14.3089 12.8795 14.3796 12.507C14.4152 12.3476 14.3971 12.1176 14.3621 11.9045C14.3439 11.6564 14.2908 11.4795 14.1483 11.4082Z"
      fill="white" />
    <path
      d="M9.63375 0C4.44625 0 0.25 4.19875 0.25 9.37125C0.25 11.1956 0.78125 12.8969 1.66625 14.3313L0.25 20L5.31375 17.6969C6.64719 18.3944 8.12952 18.7591 9.63437 18.76C14.8031 18.76 19 14.5613 19 9.37125C19 4.19875 14.8031 0 9.63375 0ZM9.63375 16.8819C7.96937 16.8819 6.44625 16.3506 5.2075 15.4294L2.92375 16.8819L3.95062 14.2775C2.77197 12.9147 2.12443 11.1724 2.12687 9.37062C2.12687 5.22563 5.49062 1.8775 9.63437 1.8775C10.6185 1.87659 11.5931 2.06982 12.5024 2.44611C13.4117 2.8224 14.2378 3.37436 14.9335 4.07039C15.6292 4.76643 16.1807 5.59285 16.5566 6.50234C16.9324 7.41184 17.1251 8.38654 17.1237 9.37062C17.1231 13.5162 13.7769 16.8819 9.63375 16.8819Z"
      fill="white" />
  </svg>
</template>

<style scoped></style>
