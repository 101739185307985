<script setup lang="ts">
import {ref, onMounted, watch} from "vue"
import {storeToRefs} from "pinia"
import {vMaska} from "maska"
import {useToast} from "vue-toastification"
import {useI18n} from "vue-i18n"
import zod, {type ZodIssue} from "zod"
import {useMSTRemasteredStore} from "@/stores/MSTRemastered"
import {useHelpers} from "@/composables/useHelpers"
import {useValidation} from "@/composables/useValidation"
import {insuredSchema} from "@/schemas/MST/insuredSchema"
import {onPhoneFocus} from "@/utils/inputEvents"
import {useFormatters} from "@/utils/formatters"
import {useRules} from "@/utils/rules"
import {filterEmailInput} from "@/utils/inputEvents"
import {mstDocsRules} from "@/constants/documents"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFCheckerInput from "@/components/FFUI/FFCheckerInput.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"
import VueDatePicker from "@vuepic/vue-datepicker"

import "@formatjs/intl-datetimeformat/polyfill-force"
import "@formatjs/intl-datetimeformat/locale-data/kk"
import "@formatjs/intl-datetimeformat/locale-data/ru"
import "@formatjs/intl-datetimeformat/locale-data/en"
import "@formatjs/intl-datetimeformat/add-all-tz"

const mstStore = useMSTRemasteredStore()
const {
  loading,
  authorizeForm,
  calculateForm,
  totalCategoryCount,
  delayedVisibility,
  policyHolderForm,
  personalDataCheckboxStates,
  insureds,
  currentForm,
  areAllValuesInPersonalDataFormFilled,
  notFoundClients
} = storeToRefs(mstStore)

const {currentLocale, openLink} = useHelpers()
const toast = useToast()
const {t} = useI18n()
const {phoneNumberFormatter} = useFormatters()
const {isLatinAndNoNumbersOrSpecialChars} = useRules()

//tracking current form

const setCurrentForm = (index: number) => {
  currentForm.value = index
}

//validation
const formErrors = ref<{[key: number]: ZodIssue[]}>({})

const policyHolderSchema = zod.object({
  email: zod.string().email("Invalid email format").nonempty()
})

const policyHolderValidation = useValidation(policyHolderSchema, policyHolderForm, {
  mode: "lazy"
})

const validateForms = async () => {
  let allFormsValid = true
  formErrors.value = {}

  await policyHolderValidation.validate()
  if (!policyHolderValidation.isValid.value) {
    allFormsValid = false
    currentForm.value = 0
    formErrors.value[0] = Array.isArray(policyHolderValidation.errors.value)
      ? policyHolderValidation.errors.value
      : (Object.keys(policyHolderValidation.errors.value || {}).map((key) => ({
          path: [key],
          message: policyHolderValidation.errors.value[key],
          code: "custom"
        })) as ZodIssue[])
    return
  }

  for (let i = 0; i < insureds.value.length; i++) {
    try {
      await insuredSchema.parseAsync(insureds.value[i])
    } catch (errors) {
      allFormsValid = false
      currentForm.value = i + 1
      formErrors.value[i + 1] = errors.errors
      break
    }
  }
  if (
    !personalDataCheckboxStates.value.notPublicOfficial ||
    !personalDataCheckboxStates.value.noBankAccount ||
    !personalDataCheckboxStates.value.iAgree
  ) {
    toast.error(t("app.toasterMessages.mstPage.agreeToTheTerms"))
  } else {
    if (allFormsValid) {
      await mstStore.setClientData()
    }
  }
}

const validateInsuredField = async (index: number) => {
  try {
    await insuredSchema.parseAsync(insureds.value[index])
    formErrors.value[index + 1] = []
  } catch (errors) {
    formErrors.value[index + 1] = errors.errors
  }
}

const hasError = (formIndex: number, fieldPath: string) => {
  return formErrors.value[formIndex]?.some((error) => error.path.includes(fieldPath)) ?? false
}

//setting up forms for insureds
const createPolicyHolder = () => {
  return {
    iin: "",
    phone: "",
    passportNumb: "",
    passportIssueDate: "",
    firstNameEng: "",
    lastNameEng: "",
    iinProcessed: false,
    isOlder18: null,
    fillFormByYourself: null,
    isClientNotFound: null
  }
}

const syncInsuredList = () => {
  const insuredListCount = insureds.value.length

  if (totalCategoryCount.value !== insuredListCount) {
    insureds.value = Array.from({length: totalCategoryCount.value}, createPolicyHolder)
  }
}

const onPhoneInput = async (index: number) => {
  if (insureds && insureds.value[index]?.phone && phoneNumberFormatter(insureds.value[index]?.phone).length === 11) {
    await mstStore.getKdpFromInsured(index)
  }
}

const shouldShowPhoneInput = (index: number) => {
  return (
    (!calculateForm.value.policyHolderIsTourist && insureds.value[index]?.isOlder18) || delayedVisibility.value[index]
  )
}

watch(
  insureds,
  (newVal) => {
    newVal.forEach((insured, index) => {
      if (insured.iin.length === 12 && insured.isOlder18) {
        setTimeout(() => {
          delayedVisibility.value[index] = true
        }, 10)
      } else {
        delayedVisibility.value[index] = false
      }
    })
  },
  {deep: true}
)

onMounted(() => {
  syncInsuredList()
})
</script>

<template>
  <div class="mst-personal-data__boxes">
    <div
      @click.prevent="setCurrentForm(index)"
      v-for="(item, index) in totalCategoryCount + 1"
      :key="index"
      :class="['mst-personal-data__box', {'mst-personal-data__box--active': index === currentForm}]">
      {{ index === 0 ? $t("app.mstPage.form.personalDataForm.policyHolder") : item - 1 }}
    </div>
  </div>
  <div v-for="(item, index) in totalCategoryCount + 1" :key="index" class="mst-personal-data__form">
    <template v-if="index === 0 && currentForm === index">
      <FFCheckbox
        v-model="policyHolderForm.isResidentRk"
        :title="$t('app.mstPage.form.personalDataForm.residentRk')"
        :locked="true" />
      <FFFieldset :title="$t('form.inputTitles.iin')">
        <FFInput v-model="authorizeForm.iin" :locked="true" />
      </FFFieldset>
      <div>
        <FFFieldset :title="$t('form.inputTitles.email')" :isError="policyHolderValidation.getError('email')">
          <FFInput id="email" name="email" type="email" inputmode="email" @input="filterEmailInput" v-model="policyHolderForm.email" />
        </FFFieldset>
        <FFErrorMessage
          v-if="policyHolderForm.email?.length === 0 && policyHolderValidation.getError('email')"
          :errorMessage="$t('form.validationErrors.emailFieldIsRequired')" />
        <FFErrorMessage
          v-if="policyHolderForm.email?.length > 0 && policyHolderValidation.getError('email')"
          :errorMessage="$t('form.validationErrors.emailIsNotValid')" />
      </div>
    </template>
    <template v-if="currentForm === index + 1">
      <p v-if="insureds[index].isOlder18 === false" class="mst-personal-data__tourist-is-child-text">
        {{ $t("app.mstPage.form.personalDataForm.touristIsChild") }}
      </p>
      <div class="mst-personal-data__form-row">
        <div>
          <FFFieldset :title="$t('form.inputTitles.iin')" :isError="hasError(index + 1, 'iin')">
            <FFInput
              v-maska
              data-maska="############"
              @input="
                () => {
                  validateInsuredField(index)
                  if (insureds && !insureds[index].iinProcessed) {
                    mstStore.checkInsuredAge(index)
                  }
                }
              "
              v-model="insureds[index].iin"
              :locked="
                (index === 0 && calculateForm.policyHolderIsTourist && !insureds[index].isClientNotFound) ||
                (index !== 0 &&
                  insureds &&
                  (insureds[index].fillFormByYourself ||
                    insureds[index].isClientNotFound ||
                    (insureds[index].isOlder18 !== null && !insureds[index].isOlder18) ||
                    insureds[index].iinProcessed))
              "
              type="text"
              inputmode="numeric" />
          </FFFieldset>
          <FFErrorMessage
            v-if="insureds[index].iin?.length === 0 && hasError(index + 1, 'iin')"
            :errorMessage="$t('form.validationErrors.iinFieldIsRequired')" />
          <FFErrorMessage
            v-if="insureds[index].iin?.length > 0 && insureds[index].iin?.length !== 12 && hasError(index + 1, 'iin')"
            :errorMessage="$t('form.validationErrors.iinIsNotValid')" />
        </div>
      </div>
      <div
        v-if="!(calculateForm.policyHolderIsTourist && currentForm === 1) && shouldShowPhoneInput(index)"
        class="mst-personal-data__form-row">
        <FFFieldset :title="$t('form.inputTitles.phone')">
          <FFInput
            v-maska
            data-maska="+7 (###) ### ####"
            placeholder="+7 (___) ___ ____"
            id="phonenumber"
            name="phonenumber"
            type="tel"
            v-model="insureds[index].phone"
            @focus="onPhoneFocus"
            @input="onPhoneInput(index)"
            :locked="
              (index === 0 && calculateForm.policyHolderIsTourist) ||
              insureds[index].iin?.length !== 12 ||
              insureds[index].iinProcessed
            " />
        </FFFieldset>
      </div>
      <div class="mst-personal-data__form-row">
        <FFFieldset :title="$t('form.inputTitles.passportNumber')" :isError="hasError(index + 1, 'passportNumb')">
          <FFInput
            v-model="insureds[index].passportNumb"
            v-maska
            data-maska="N########"
            @input="validateInsuredField(index)"
            placeholder="N754566582"
            type="text"
            inputmode="numeric"
            :locked="
              (index === 0 && insureds && calculateForm.policyHolderIsTourist && !insureds[index].isClientNotFound) ||
              (index !== 0 &&
                ((insureds[index].isOlder18 && !insureds[index].isClientNotFound) ||
                  (insureds[index].isOlder18 && !insureds[index].fillFormByYourself)))
            " />
        </FFFieldset>
        <FFErrorMessage
          v-if="insureds[index].passportNumb?.length === 0 && hasError(index + 1, 'passportNumb')"
          :errorMessage="$t('form.validationErrors.passportNumbRequired')" />
        <FFErrorMessage
          v-if="
            insureds[index].passportNumb?.length > 0 &&
            insureds[index].passportNumb?.length !== 9 &&
            hasError(index + 1, 'passportNumb')
          "
          :errorMessage="$t('form.validationErrors.passportNumbIncorrect')" />
      </div>
      <div class="mst-personal-data__form-row">
        <FFFieldset
          :title="$t('form.inputTitles.passportIssueDate')"
          :isError="hasError(index + 1, 'passportIssueDate')">
          <VueDatePicker
            :enable-time-picker="false"
            auto-apply
            format="dd.MM.yyyy"
            :locale="currentLocale"
            :max-date="new Date()"
            v-model="insureds[index].passportIssueDate"
            @update:modelValue="validateInsuredField(index)"
            :disabled="
              (index === 0 && insureds && calculateForm.policyHolderIsTourist && !insureds[index].isClientNotFound) ||
              (index !== 0 &&
                ((insureds[index].isOlder18 && !insureds[index].isClientNotFound) ||
                  (insureds[index].isOlder18 && !insureds[index].fillFormByYourself)))
            ">
            <template #clear-icon="{clear}"></template>
          </VueDatePicker>
        </FFFieldset>
        <FFErrorMessage
          v-if="insureds[index].passportIssueDate === '' && hasError(index + 1, 'passportIssueDate')"
          :errorMessage="$t('form.validationErrors.passportIssueDateRequired')" />
      </div>
      <div class="mst-personal-data__form-row">
        <FFFieldset :title="$t('form.inputTitles.surnameInLatin')" :isError="hasError(index + 1, 'lastNameEng')">
          <FFInput
            v-model="insureds[index].lastNameEng"
            @input="
              () => {
                validateInsuredField(index)
                insureds[index].lastNameEng = isLatinAndNoNumbersOrSpecialChars(
                  insureds[index].lastNameEng.toUpperCase()
                )
              }
            "
            :locked="
              (index === 0 && insureds && calculateForm.policyHolderIsTourist && !insureds[index].isClientNotFound) ||
              (index !== 0 &&
                ((insureds[index].isOlder18 && !insureds[index].isClientNotFound) ||
                  (insureds[index].isOlder18 && !insureds[index].fillFormByYourself)))
            " />
        </FFFieldset>
        <FFErrorMessage
          v-if="insureds[index].lastNameEng?.length === 0 && hasError(index + 1, 'lastNameEng')"
          :errorMessage="$t('form.validationErrors.surnameRequired')" />
      </div>
      <div class="mst-personal-data__form-row">
        <FFFieldset :title="$t('form.inputTitles.nameInLatin')" :isError="hasError(index + 1, 'firstNameEng')">
          <FFInput
            v-model="insureds[index].firstNameEng"
            @input="
              () => {
                validateInsuredField(index)
                insureds[index].firstNameEng = isLatinAndNoNumbersOrSpecialChars(
                  insureds[index].firstNameEng.toUpperCase()
                )
              }
            "
            :locked="
              (index === 0 && insureds && calculateForm.policyHolderIsTourist && !insureds[index].isClientNotFound) ||
              (index !== 0 &&
                ((insureds[index].isOlder18 && !insureds[index].isClientNotFound) ||
                  (insureds[index].isOlder18 && !insureds[index].fillFormByYourself)))
            " />
        </FFFieldset>
        <FFErrorMessage
          v-if="insureds[index].firstNameEng?.length === 0 && hasError(index + 1, 'firstNameEng')"
          :errorMessage="$t('form.validationErrors.nameRequired')" />
      </div>
      <p v-if="insureds[index].isClientNotFound" class="mst-personal-data__client-not-found">
        {{ $t("app.mstPage.form.personalDataForm.clientNotFound") }}
      </p>
      <FFCheckbox
        v-model="policyHolderForm.isResidentRk"
        :title="$t('app.mstPage.form.personalDataForm.residentRk')"
        :locked="true" />
    </template>
  </div>
  <div class="mst-personal-data__checkboxes">
    <FFCheckbox
      :title="$t('app.mstPage.form.personalDataForm.validationErrors.isPublicFace')"
      v-model="personalDataCheckboxStates.notPublicOfficial" />
    <FFCheckbox
      :title="$t('app.mstPage.form.personalDataForm.validationErrors.billsInBanksRegisteredInOff')"
      v-model="personalDataCheckboxStates.noBankAccount" />
    <FFCheckbox v-model="personalDataCheckboxStates.iAgree">
      <template #title>
        <p v-if="currentLocale === 'ru' || currentLocale === 'en'" class="anketa-rules">
          <span>
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.firstParagraph") }}
          </span>
          {{ " " }}
          <span
            @click="areAllValuesInPersonalDataFormFilled && mstStore.postQuestionnaireData()"
            :class="areAllValuesInPersonalDataFormFilled ? 'underline cursor-pointer' : ''">
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.secondParagraph") }}</span
          >
          {{ " " }}
          {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.subThirdParagraph") }}
          <span @click="openLink(mstDocsRules.mstDocRu, '_blank')" class="underline">
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.thirdParagraph") }}</span
          >
        </p>
        <p v-if="currentLocale === 'kk'" class="anketa-rules">
          <span
            @click="areAllValuesInPersonalDataFormFilled && mstStore.postQuestionnaireData()"
            :class="areAllValuesInPersonalDataFormFilled ? 'underline cursor-pointer' : ''">
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.firstParagraph") }}
          </span>
          {{ " " }}
          <span>
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.subSecondParagraph") }}
          </span>
          {{ " " }}
          <span @click="openLink(mstDocsRules.mstDocKk, '_blank')" class="underline">
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.secondParagraph") }}</span
          >
          {{ " " }}
          <span>{{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.thirdParagraph") }}</span>
        </p>
      </template>
    </FFCheckbox>
  </div>
  <FFButton @click="validateForms" :title="$t('app.buttons.proceedToPayment')" :uppercase="false" />
</template>

<style scoped lang="scss">
.mst-personal-data__boxes {
  @include flex;
  margin: 20px 0;

  .mst-personal-data__box {
    flex: 1 1 100%;
    padding: 12px;
    text-align: center;
    transition:
      flex 0.3s,
      background-color 0.3s,
      border-color 0.3s;
    color: var(--color-white);
    font-size: 13px;
    border-bottom: 2px solid var(--color-white);
    cursor: pointer;

    &--active {
      border-top: 2px solid var(--color-white);
      border-left: 2px solid var(--color-white);
      border-right: 2px solid var(--color-white);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: unset;
    }

    @include min-responsive(900px) {
      flex: 1 1 calc(25% - 8px);
    }

    @include min-responsive(600px) {
      flex: 1 1 calc(33.333% - 8px);
    }
  }
}

.mst-personal-data__tourist-is-child-text {
  color: var(--color-white);
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  list-style: normal;
  margin-bottom: -13px;
}

.mst-personal-data__client-not-found {
  color: var(--color-white);
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  list-style: normal;
}

.mst-personal-data__form {
  @include column;
  gap: 20px;
}

.mst-personal-data__checkboxes {
  @include column;
  gap: 10px;
  margin: 20px 0;
}
</style>
