<script setup lang="ts">
import {useTerminationAgreementStore} from "@/stores/TerminationAgreementStore"
import {useModalStore} from "@/stores/ModalStore"
import FFButton from "@/components/FFUI/FFButton.vue"
import CloseIcon from "@/components/icons/CloseIcon.vue"

const terminationAgreementStore = useTerminationAgreementStore()
const modalStore = useModalStore()

const handleTerminationAgreement = async () => {
  await terminationAgreementStore.terminateAgreement()
}
</script>

<template>
  <div class="termination-need-biometri-text">
    <CloseIcon @click="modalStore.closeModal" class="termination-need-biometri-text__close-icon" />
    <p class="termination-need-biometri-title">
      {{ $t("app.terminationAgreementPage.terminationNeedBiometriTextTitle") }}
    </p>
    <div>
      <p class="termination-need-biometri-description">
        {{ $t("app.terminationAgreementPage.terminationNeedBiometriTextDescription") }}
      </p>
    </div>
    <FFButton @click="handleTerminationAgreement" :title="$t('app.buttons.continue')" />
  </div>
</template>

<style scoped lang="scss">
.termination-need-biometri-text {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 18px;
  margin: 0 20px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  max-width: 500px;

  .termination-need-biometri-text__close-icon {
    position: absolute;
    top: 14px;
    right: 14px;
  }

  .termination-need-biometri-title {
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .termination-need-biometri-description {
    color: #fff;
    font-size: 19px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
</style>
