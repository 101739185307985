<script setup lang="ts">
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {useModalStore} from "@/stores/ModalStore"
import FFButton from "@/components/FFUI/FFButton.vue"
import CloseIcon from "@/components/icons/CloseIcon.vue"

const addDriverAutoStore = useAddDriverAutoStore()
const modalStore = useModalStore()
</script>

<template>
  <div class="add-welcome-text">
    <p class="add-welcome__title">
      {{ $t("app.addDriverAuto.badScoringSmsTitle") }}
    </p>
    <div>
      <p class="add-welcome__description">
        {{ $t("app.addDriverAuto.startPageWelcomeText") }}
      </p>
    </div>
    <FFButton @click="modalStore.closeModal" :title="$t('app.buttons.continue')" />
  </div>
</template>

<style scoped lang="scss">
.add-welcome-text {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 18px;
  margin: 0 20px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  max-width: 500px;

  .add-welcome__title {
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .add-welcome__description {
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
</style>
