<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="40" viewBox="0 0 36 40" fill="none">
    <path
      d="M12.9747 4.04993C14.8331 3.49684 16.808 3 17.9898 3C19.1717 3 21.1466 3.49684 23.005 4.04993C24.9063 4.6124 26.8229 5.27798 27.95 5.68108C28.4212 5.85142 28.8389 6.1638 29.1553 6.58234C29.4716 7.00088 29.6736 7.5086 29.7382 8.04718C30.759 16.441 28.3902 22.6619 25.516 26.7773C24.2973 28.5377 22.844 30.088 21.2048 31.3763C20.638 31.8222 20.0375 32.2142 19.4098 32.5481C18.9302 32.7956 18.4146 32.9981 17.9898 32.9981C17.5651 32.9981 17.0512 32.7956 16.5699 32.5481C15.9422 32.2142 15.3416 31.8222 14.7749 31.3763C13.1357 30.088 11.6825 28.5377 10.4637 26.7773C7.58952 22.6619 5.22067 16.441 6.24152 8.04718C6.30607 7.5086 6.50811 7.00088 6.82442 6.58234C7.14074 6.1638 7.5585 5.85142 8.02972 5.68108C9.6658 5.09389 11.3146 4.55001 12.9747 4.04993Z"
      fill="url(#paint0_linear_2949_6357)" />
    <g filter="url(#filter0_bi_2949_6357)">
      <path
        d="M18 9.79297C10.2804 9.79297 4 16.0734 4 23.793C4 31.5126 10.2804 37.793 18 37.793C25.7196 37.793 32 31.5126 32 23.793C32 16.0734 25.7196 9.79297 18 9.79297ZM23.6 29.393C23.0534 29.9396 22.1671 29.9396 21.6204 29.393L17.329 25.1015C17.0599 24.8325 16.9254 24.698 16.8292 24.541C16.7439 24.4018 16.6811 24.2501 16.643 24.0914C16.6 23.9123 16.6 23.7221 16.6 23.3416V16.793C16.6 16.0198 17.2268 15.393 18 15.393C18.7732 15.393 19.4 16.0198 19.4 16.793V22.1824C19.4 22.5629 19.4 22.7531 19.443 22.9322C19.4811 23.0909 19.5439 23.2426 19.6292 23.3818C19.7254 23.5388 19.8599 23.6733 20.129 23.9423L23.6 27.4134C24.1467 27.96 24.1467 28.8463 23.6 29.393Z"
        fill="url(#paint1_radial_2949_6357)"
        fill-opacity="0.8" />
    </g>
    <g filter="url(#filter1_d_2949_6357)">
      <path d="M16.4434 15.2383H19.5545V23.4049L24.61 28.4605L22.6656 30.7938L16.4434 24.5716V15.2383Z" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_bi_2949_6357"
        x="0.111111"
        y="5.90408"
        width="35.7778"
        height="35.7778"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.94444" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2949_6357" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2949_6357" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="0.353862" dy="0.176931" />
        <feGaussianBlur stdDeviation="0.884654" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2949_6357" />
      </filter>
      <filter
        id="filter1_d_2949_6357"
        x="13.3322"
        y="11.3494"
        width="14.3882"
        height="21.7769"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dy="-0.777778" />
        <feGaussianBlur stdDeviation="1.55556" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2949_6357" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2949_6357" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_2949_6357"
        x1="29.9797"
        y1="3"
        x2="0.985069"
        y2="20.04"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2949_6357"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(37.6009 0.599639) rotate(126.071) scale(39.6657 39.6811)">
        <stop stop-color="white" stop-opacity="0.700689" />
        <stop offset="1" stop-color="#B3EBDA" stop-opacity="0.254405" />
      </radialGradient>
    </defs>
  </svg>
</template>
