<script setup lang="ts">
import {computed} from "vue"
import {useHelpers} from "@/composables/useHelpers"
import {useDeviceSize} from "@/composables/useDeviceSize"
import FFButton from "@/components/FFUI/FFButton.vue"

const {scrollToPoint} = useHelpers()
const {isDesktop, isTablet} = useDeviceSize()

const scrollToForm = computed(() => {
  if(isDesktop.value) {
    return 2000
  } else if (isTablet.value){
    return 2800
  } else {
    return 3400
  }
})
</script>

<template>
  <section class="hotline-hero container">
    <div class="hotline-hero__left-part">
      <p class="hotline-hero__left-part-title">{{ $t("app.hotlinePage.title") }}</p>
      <p class="hotline-hero__left-part-subtitle">{{ $t("app.hotlinePage.subtitle") }}</p>
      <p class="hotline-hero__left-part-description">{{ $t("app.hotlinePage.description") }}</p>
      <FFButton
        @click="scrollToPoint(scrollToForm)"
        :title="$t('app.buttons.askQuestion')"
        :uppercase="true"
        maxWidth="300px"
        maxWidthMobile="100%"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        color="#ffffff"
        colorMobile="#ffffff" />
    </div>
    <div class="hotline-hero__right-part-desk">
      <img
        src="/hotline-front/images/hotline-iphone.webp"
        class="hotline-hero__right-part-img-desk"
        alt="hotline iphone" />
    </div>
    <div class="hotline-hero__right-part-mobile">
      <img
        src="/hotline-front/images/hotline-iphone.webp"
        class="hotline-hero__right-part-img-mobile"
        alt="hotline iphone" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.hotline-hero {
  @include flex;
  position: relative;
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(8px);
  margin-top: 170px;
  margin-bottom: 170px;
  z-index: 1;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: radial-gradient(46.25% 69.68% at 38.69% 48.62%, rgba(90, 238, 53, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    filter: blur(97.5px);
    position: absolute;
    z-index: -1;
  }

  @include max-responsive(wide) {
    margin-top: 152px;
    margin-bottom: 113px;
  }

  @include max-responsive(xxl) {
    margin-top: 162px;
    margin-bottom: 103px;
  }

  @include max-responsive(xl) {
    background: transparent;
    backdrop-filter: unset;
    @include column;
  }

  @include max-responsive(lg) {
    margin-top: 100px;
    margin-bottom: 100px;
    z-index: 1;
  }

  .hotline-hero__left-part {
    padding: 129px 0 129px 50px;
    max-width: 767px;

    @include max-responsive(xxl) {
      max-width: 596px;
    }

    @include max-responsive(xl) {
      padding: 0;
      margin-bottom: 70px;
    }

    @include max-responsive(lg) {
      margin-bottom: 100px;
    }
    .hotline-hero__left-part-title {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      @include main-green-gradient;

      @include max-responsive(lg) {
        max-width: 338px;
      }
    }
    .hotline-hero__left-part-subtitle {
      color: var(--color-white);
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;

      @include max-responsive(lg) {
        font-size: 18px;
      }
    }
    .hotline-hero__left-part-description {
      color: var(--color-white);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 50px 0;

      @include max-responsive(lg) {
        margin: 20px 0 40px;
      }
    }
  }
  .hotline-hero__right-part-desk {
    width: 331px;
    height: 660px;
    position: absolute;
    right: 0;
    top: -73px;
    bottom: 0;

    @include max-responsive(xl) {
      display: none;
    }
  }
  .hotline-hero__right-part-mobile {
    display: none;
    width: 331px;
    height: 660px;
    margin: auto;

    @include max-responsive(xl) {
      display: block;
      width: 234px;
      height: 478px;
    }
  }
}
</style>
