<script setup lang="ts">
import {useFormatters} from "@/utils/formatters"
import {NewsCategoryEnum} from "@/interfaces/News/NewsCategoryEnum"
import {type INewsItem} from "@/interfaces/News/INews"

interface IProps {
  category?: NewsCategoryEnum
  item: INewsItem
}

defineProps<IProps>()

const {convertISOToDate} = useFormatters()
</script>

<template>
  <router-link
    :to="{
      name: 'NewsDetails',
      params: {id: item.news_id},
      query: {}
    }">
    <div class="news-item">
      <div class="news-item__left-part">
        <span class="news-item__category"> Новости </span>
        <p class="news-item__title">{{ item.news_name_ru }}</p>
        <p class="news-item__description">
          {{ item.news_desc_ru }}
        </p>
        <p class="news-item__date">{{ convertISOToDate(item.created_at) }}</p>
      </div>
      <img class="news-item__poster" :src="`https://ffins.kz${item.news_image}`" loading="lazy" alt="" />
    </div>
  </router-link>
</template>

<style scoped lang="scss">
.news-item {
  @include between;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;

  @include max-responsive(lg) {
    @include column;
    gap: 20px;
  }

  .news-item__left-part {
    width: 100%;
    max-width: 717px;

    @include max-responsive(xxl) {
      max-width: 600px;
    }

    @include max-responsive(xl) {
      max-width: 368px;
    }

    @include max-responsive(lg) {
      max-width: 100%;
    }
    .news-item__category {
      padding: 4px 10px;
      border-radius: 5px;
      background: var(--color-orange);
      color: var(--color-white);

      text-shadow: 4px 4px 27px rgba(0, 0, 0, 0.25);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .news-item__title {
      color: var(--color-black);
      text-shadow: 4px 4px 27px rgba(0, 0, 0, 0.25);
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      margin: 10px 0;
    }
    .news-item__description {
      overflow: hidden;
      color: #606060;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 20px;
    }
    .news-item__date {
      color: #606060;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .news-item__poster {
    height: 170px;
    max-width: 343px;
    min-width: 320px;
    object-fit: cover;
    object-position: center;

    @include max-responsive(lg) {
      max-width: unset;
      min-width: unset;
      width: 100%;
    }
  }
}
</style>
