<script setup lang="ts">
import {ref} from "vue"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useModalStore} from "@/stores/ModalStore"
import {useOgpoPlusStore} from "@/stores/OgpoPlusStore"
import {useHelpers} from "@/composables/useHelpers"
import {pushGtagEvent} from "@/utils/pushGtagEvent"
import {whatsAppOgpoPlusMessageRu, whatsAppOgpoPlusMessageKk, whatsAppOgpoPlusMessageEn} from "@/constants/whatsAppMessages"
import FFForm from "@/components/FFUI/FFForm.vue"
import OgpoPlusCalculateForm from "@/components/OgpoPlus/Forms/OgpoPlusCalculateForm.vue"
import OgpoPlusAgreementForm from "@/components/OgpoPlus/Forms/OgpoPlusAgreementForm.vue"
import OgpoPlusExecutionForm from "@/components/OgpoPlus/Forms/OgpoPlusExecutionForm.vue"
import OgpoPlusPaymentMethodForm from "@/components/OgpoPlus/Forms/OgpoPlusPaymentMethodForm.vue"
import OgpoPlusKaspiPaymentForm from "@/components/OgpoPlus/Forms/OgpoPlusKaspiPaymentForm.vue"
import OgpoPlusIdentificationCardForm from "@/components/OgpoPlus/Forms/OgpoPlusIdentificationCardForm.vue"
import SuperAppQrBlock from "@/components/shared/SuperAppQrBlock.vue"
import CloseIcon from "@/components/icons/socials/CloseIcon.vue"
import ModalHeader from "@/components/FFUI/ModalHeader.vue"
import WhatsAppIcon from "@/components/icons/WhatsAppIcon.vue"

const modalStore = useModalStore()
const ogpoPlusStore = useOgpoPlusStore()

const {
  currentStep,
  phone,
  needKdp,
  calculationObject,
  driverLicenseNotFound,
  addOgpoPlus,
  identificationCardNotFound,
  allUsersAreVerificated,
  showKaspiForm,
  showSuperAppBlock,
  agreementLoaderHide,
  paymentUrl
} = storeToRefs(ogpoPlusStore)
const {isDesktop, isMobileDevice} = useDeviceSize()
const {currentLocale, openLink} = useHelpers()
const {t} = useI18n()

const cantScanQr = ref<boolean>(false)

const handleCloseModal = () => {
  modalStore.closeModal()
  ogpoPlusStore.resetStep()
  agreementLoaderHide.value = false
}

const handleStepBack = () => {
  if (currentStep.value === 3 && showKaspiForm.value && !showSuperAppBlock.value) {
    showKaspiForm.value = false
  } else if (currentStep.value === 3 && !showKaspiForm.value && showSuperAppBlock.value && !cantScanQr.value) {
    showSuperAppBlock.value = false
  } else if (currentStep.value === 3 && !showKaspiForm.value && showSuperAppBlock.value && cantScanQr.value) {
    cantScanQr.value = false
  } else if (currentStep.value === 2 && needKdp.value && isMobileDevice.value) {
    ogpoPlusStore.handleStepBack()
    agreementLoaderHide.value = false
    ogpoPlusStore.clearAllIntervals()
  } else if (currentStep.value === 2 && needKdp.value && isDesktop.value) {
    handleCloseModal()
    ogpoPlusStore.clearAllIntervals()
  } else if (currentStep.value === 2 && !needKdp.value && isDesktop.value) {
    modalStore.closeModal()
    ogpoPlusStore.handleStepBack()
    ogpoPlusStore.clearAllIntervals()
  } else if (currentStep.value === 1 && identificationCardNotFound.value && isDesktop.value) {
    currentStep.value = 1
    identificationCardNotFound.value = false
    modalStore.closeModal()
    ogpoPlusStore.clearAllIntervals()
  } else if (currentStep.value === 1 && identificationCardNotFound.value && !isDesktop.value) {
    currentStep.value = 1
    identificationCardNotFound.value = false
    ogpoPlusStore.clearAllIntervals()
  } else {
    ogpoPlusStore.handleStepBack()
  }
}

const handleProblemsWithScan = (value: boolean) => {
  cantScanQr.value = value
}
</script>

<template>
  <FFForm>
    <CloseIcon
      v-if="currentStep === 1 && !driverLicenseNotFound && !identificationCardNotFound"
      class="ff-form-close"
      @click="handleCloseModal" />
    <ModalHeader
      v-if="
        ((currentStep === 2 || currentStep === 3) && allUsersAreVerificated && needKdp) ||
        ((currentStep === 2 || currentStep === 3) && !needKdp)
      "
      :title="currentStep === 3 ? t('form.formHeaders.paymentMethod') : t('form.formHeaders.execution')"
      @stepBack="handleStepBack"
      :currentStep="currentStep"
      :totalSteps="3" />
    <OgpoPlusCalculateForm v-if="currentStep === 1 && !driverLicenseNotFound && !identificationCardNotFound" />
    <OgpoPlusIdentificationCardForm
      v-if="currentStep === 1 && identificationCardNotFound"
      @handleKdp="ogpoPlusStore.handleKdp"
      @stepBack="handleStepBack" />
    <OgpoPlusAgreementForm
      v-if="currentStep === 2 && needKdp && !allUsersAreVerificated"
      @handleKdp="ogpoPlusStore.handleKdp"
      @stepBack="handleStepBack" />
    <OgpoPlusExecutionForm
      v-if="(currentStep === 2 && needKdp && allUsersAreVerificated) || (currentStep === 2 && !needKdp)"
      @nextStep="ogpoPlusStore.handleStepNext" />
    <OgpoPlusPaymentMethodForm v-if="currentStep === 3 && !showKaspiForm && !showSuperAppBlock" />
    <OgpoPlusKaspiPaymentForm v-if="currentStep === 3 && showKaspiForm && !showSuperAppBlock" />
    <SuperAppQrBlock
      v-if="currentStep === 3 && showSuperAppBlock && !showKaspiForm"
      :url="paymentUrl"
      @cantScan="handleProblemsWithScan"
      :cantScanQr="cantScanQr"
      :orderId="calculationObject?.orderId"
      level="Q"
      >
      <template #super-app-qr-block__title-first-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-1") }}
      </template>
      <template #super-app-qr-block__title-second-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-2") }}
      </template>
    </SuperAppQrBlock>
    <div
      v-if="currentStep !== 1"
      class="data-ask"
      @click="
        openLink(
          `https://api.whatsapp.com/send?phone=77788700014&text=${
            currentLocale === 'ru'
              ? whatsAppOgpoPlusMessageRu
              : currentLocale === 'kk'
                ? whatsAppOgpoPlusMessageKk
                : whatsAppOgpoPlusMessageEn
          }`,
          '_blank'
        )
      ">
      <WhatsAppIcon />
      <p>{{ $t("app.paymentPage.askQuestion") }}</p>
    </div>
  </FFForm>
</template>

<style scoped lang="scss">
.ff-form {
  .data-ask {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 1;
    margin-top: 30px;
    cursor: pointer;
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #ffffff;
    }
  }
}
.ff-form-close {
  position: absolute;
  top: 28px;
  left: 21px;
}
</style>
