<script lang="ts" setup>
import {vMaska} from "maska"
import {useRouter} from "vue-router"
import {storeToRefs} from "pinia"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import { useModalStore } from "@/stores/ModalStore"
import {onPhoneFocus} from "@/utils/inputEvents"
import FFForm from "@/components/FFUI/FFForm.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import CloseIcon from "@/components/icons/CloseIcon.vue"

const addDriverAutoStore = useAddDriverAutoStore()
const modalStore = useModalStore()
const {kaspiPayPhoneNumber} = storeToRefs(addDriverAutoStore)
const router = useRouter()
</script>

<template>
  <div class="kaspi-payment-form">
    <FFForm>
      <CloseIcon @click="modalStore.closeModal" class="close-icon" />
      <FFFieldset :title="$t('form.inputTitles.linkedPhoneNumberToKaspi')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          placeholder="+7 (___) ___ ____"
          id="phone"
          v-model="kaspiPayPhoneNumber"
          name="phone"
          @focus="onPhoneFocus"
          type="tel" />
      </FFFieldset>
      <FFButton
        @click="addDriverAutoStore.handleKaspiPayment"
        :title="$t('app.buttons.sendBillToKaspi')"
        :uppercase="false" />
    </FFForm>
  </div>
</template>

<style scoped lang="scss">
.kaspi-payment-form {
  position: relative;
  margin-top: 20px;
  .close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .input-fieldset {
    margin-bottom: 20px;
  }
}
</style>
