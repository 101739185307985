<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import {externalUrls} from "@/constants/urls"
import YoutubeOldIcon from "@/components/icons/socials/YoutubeOldIcon.vue"
import InstagramOldIcon from "@/components/icons/socials/InstagramOldIcon.vue"

const {openLink} = useHelpers()
</script>

<template>
  <section class="hotline-chairman-info container">
    <div class="hotline-chairman-info__top-part">
      <div class="hotline-chairman-info__left-part">
        <img
          src="/hotline-front/images/chairman-portrait.webp"
          class="hotline-chairman-info__left-part-chairman-portrait"
          alt="" />
      </div>
      <div class="hotline-chairman-info__right-part">
        <div class="hotline-chairman-info__right-part-header">
          <p class="hotline-chairman-info__fio">{{ $t("app.hotlinePage.chairmanInfo.fio") }}</p>
          <p class="hotline-chairman-info__position">{{ $t("app.hotlinePage.chairmanInfo.position") }}</p>
        </div>
        <div class="hotline-chairman-info__right-part-body">
          <p class="hotline-chairman-info__list-title">{{ $t("app.hotlinePage.chairmanInfo.list.title") }}</p>
          <ul class="hotline-chairman-info__list">
            <li class="hotline-chairman-info__list-item">{{ $t("app.hotlinePage.chairmanInfo.list.item-1") }}</li>
            <li class="hotline-chairman-info__list-item">{{ $t("app.hotlinePage.chairmanInfo.list.item-2") }}</li>
            <li class="hotline-chairman-info__list-item">{{ $t("app.hotlinePage.chairmanInfo.list.item-3") }}</li>
          </ul>
        </div>
        <div class="hotline-chairman-info__right-part-footer">
          <p>{{ $t("app.hotlinePage.chairmanInfo.moreInfoInSocialMedias") }}</p>
          <div class="hotline-chairman-info__social-medias">
            <div @click="openLink(externalUrls.kerimbayevYoutubeChannel, '_blank')"><YoutubeOldIcon /></div>
            <div @click="openLink(externalUrls.kerimbayevInstagram, '_blank')"><InstagramOldIcon /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="hotline-chairman-info__bottom-part">
      <p class="hotline-chairman-info__bottom-part-text-1">
        {{ $t("app.hotlinePage.chairmanInfo.ceoSpeechAboutChairman") }}
      </p>
      <p class="hotline-chairman-info__bottom-part-text-2">{{ $t("app.hotlinePage.chairmanInfo.ceo") }}</p>
      <img
        src="/hotline-front/images/quotation-mark.webp"
        class="hotline-chairman-info__bottom-part-quotation-mark"
        alt="" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.hotline-chairman-info {
  @include column;

  @include max-responsive(lg) {
    margin-bottom: 57px;
  }
  .hotline-chairman-info__top-part {
    @include flex;
    flex-direction: row;
    gap: 37px;

    @include max-responsive(xl) {
      @include column-reverse;
      align-items: center;
    }

    .hotline-chairman-info__left-part {
      .hotline-chairman-info__left-part-chairman-portrait {
        max-width: 428px;
        height: fit-content;

        @include max-responsive(lg) {
          max-width: 100%;
          object-fit: contain;
          height: unset;
        }
      }
    }

    .hotline-chairman-info__right-part {
      .hotline-chairman-info__right-part-header {
        @include column;
        gap: 10px;
        .hotline-chairman-info__fio {
          font-size: 20px;
          font-style: normal;
          font-weight: 900;
          line-height: 20px;

          @include main-green-gradient;
        }
        .hotline-chairman-info__position {
          color: #a1a1a1;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.72px;
        }
      }

      .hotline-chairman-info__right-part-body {
        margin: 50px 0;
        @include column;
        gap: 30px;

        @include max-responsive(xl) {
          gap: 5px;
        }

        .hotline-chairman-info__list-title {
          color: #d2d2d2;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .hotline-chairman-info__list {
          .hotline-chairman-info__list-item {
            position: relative;
            color: #d2d2d2;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-left: 20px;
            &::before {
              content: "•";
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
            }
            &:not(:last-of-type) {
              margin-bottom: 20px;

              @include max-responsive(xl) {
                margin-bottom: 10px;
              }
            }
          }
        }
      }

      .hotline-chairman-info__right-part-footer {
        p {
          color: var(--color-white);
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          letter-spacing: 0.72px;
        }
        .hotline-chairman-info__social-medias {
          @include flex;
          gap: 15px;
          margin-top: 10px;
          svg {
            cursor: pointer;
          }
        }
      }
    }
  }
  .hotline-chairman-info__bottom-part {
    padding: 67px 74px 31px 58px;
    border-radius: 25px;
    border: 10px solid #79a162;
    background: linear-gradient(130deg, #79a162 0%, #1f3a07 100%);
    width: 80%;
    margin-right: 50px;
    position: relative;
    left: 200px;
    bottom: 150px;

    @include max-responsive(xl) {
      padding: 50px 30px;
      width: 100%;
      left: unset;
      bottom: unset;
      margin-top: 100px;
      margin-right: 0;
    }
    @include max-responsive(lg) {
      margin-top: 200px;
    }
    .hotline-chairman-info__bottom-part-text-1 {
      color: var(--color-white);
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 32px;
    }
    .hotline-chairman-info__bottom-part-text-2 {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: right;
    }
    .hotline-chairman-info__bottom-part-quotation-mark {
      position: absolute;
      top: -103px;
      left: 13px;
      width: 165px;
      height: 160px;
    }
  }
}
</style>
