<script setup lang="ts"></script>

<template>
  <section class="what-subscription-is">
    <p class="what-subscription-is__title">{{ $t("app.kaskoPage.whatKaskoSub.title") }}</p>
    <div class="what-subscription-is__list">
      <div class="what-subscription-is__box">
        <img src="/kasko-front/icons/green-light.svg" alt="green light icon" />
        <p>{{ $t("app.kaskoPage.whatKaskoSub.description-1") }}</p>
      </div>
      <div class="what-subscription-is__box">
        <img src="/kasko-front/icons/green-kid.svg" alt="green kid icon" />
        <p>{{ $t("app.kaskoPage.whatKaskoSub.description-2") }}</p>
      </div>
      <div class="what-subscription-is__box">
        <img src="/kasko-front/icons/green-first-aid-kit.svg" alt="green first aid kit icon" />
        <p>{{ $t("app.kaskoPage.whatKaskoSub.description-3") }}</p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.what-subscription-is {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 30px 30px 30px;
  margin-bottom: 150px;
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.4);
  backdrop-filter: blur(7.5px);
  .what-subscription-is__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 44px;
    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .what-subscription-is__list {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .what-subscription-is__box {
      display: flex;
      flex-direction: column;
      gap: 30px;
      img {
        width: 40px;
        height: 40px;
      }
      p {
        color: #cecece;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

@media (max-width: $lg) {
  .what-subscription-is {
    margin-bottom: 50px;
    .what-subscription-is__title {
      font-size: 30px;
      line-height: 30px;
    }
    .what-subscription-is__list {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
