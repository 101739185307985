import zod from "zod"

export const calculateSchemaWithoutSportType = zod.object({
  tourismGoal: zod.object({
    name: zod.string().nonempty(),
    value: zod.string().nonempty()
  }),
  isActiveTravel: zod.boolean().optional(),
  policyHolderIsTourist: zod.boolean().optional(),
  countries: zod.array(
    zod.object({
      id: zod.string().nonempty(),
      name: zod.string().nonempty()
    })
  ),
  dateStart: zod.date().refine((val) => val instanceof Date && !isNaN(val.getTime()), {
    message: "Invalid date"
  }),
  dateStop: zod.date().refine((val) => val instanceof Date && !isNaN(val.getTime()), {
    message: "Invalid date"
  }),
  ageTravels: zod.array(zod.string()).nonempty(),
  insuranceSumId: zod.object({
    id: zod.string().nonempty(),
    currency: zod.string().nonempty(),
    amount: zod.string().nonempty()
  })
})

export const calculateSchemaWithSportType = zod
  .object({
    tourismGoal: zod.object({
      name: zod.string().nonempty(),
      value: zod.string().nonempty()
    }),
    isActiveTravel: zod.boolean().optional(),
    policyHolderIsTourist: zod.boolean().optional(),
    sportType: zod.object({
      id: zod.string().nonempty(),
      name: zod.string().nonempty()
    }),
    countries: zod.array(
      zod.object({
        id: zod.string().nonempty(),
        name: zod.string().nonempty()
      })
    ),
    dateStart: zod.date().refine((val) => val instanceof Date && !isNaN(val.getTime()), {
      message: "Invalid date"
    }),
    dateStop: zod.date().refine((val) => val instanceof Date && !isNaN(val.getTime()), {
      message: "Invalid date"
    }),
    ageTravels: zod.array(zod.string()).nonempty(),
    insuranceSumId: zod.object({
      id: zod.string().nonempty(),
      currency: zod.string().nonempty(),
      amount: zod.string().nonempty()
    })
  })
