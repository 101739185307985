<script setup lang="ts">
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import {vMaska} from "maska"
import {ref} from "vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import {useRouter} from "vue-router"
import FFFileInput from "@/components/FFUI/FFFileInput.vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import FFSelect from "@/components/FFUI/FFSelect.vue"

const router = useRouter()
const name = ref<string>()
const lastName = ref<string>()
const phone = ref<string>()
const currentRequestType = ref()
const date = ref<string>()
const time = ref<string>()
const city = ref()
const culprit = ref<string>()

const avto1 = ref<string>()
const avto2 = ref<string>()
const avto3 = ref<string>()
const addedThirdCar = ref(false)
const description = ref<string>()
const file = ref()

const requestTypes = ref([
  {id: 1, name: "Первичное обращение", value: 1, checked: false},
  {id: 2, name: "Вторичное обращение", value: 2, checked: false}
])

const cities = ref([
  {id: 1, name: "Алматы", value: 1, checked: false},
  {id: 2, name: "Алматинская область", value: 2, checked: false},
  {id: 3, name: "Астана", value: 3, checked: false},
  {id: 4, name: "Актау", value: 4, checked: false},
  {id: 5, name: "Шымкент", value: 5, checked: false},
  {id: 6, name: "Тараз", value: 6, checked: false},
  {id: 7, name: "Костанай", value: 7, checked: false},
  {id: 8, name: "Кызылорда", value: 8, checked: false},
  {id: 9, name: "Караганда", value: 9, checked: false},
  {id: 10, name: "Петропавловск", value: 10, checked: false},
  {id: 11, name: "Атырау", value: 11, checked: false},
  {id: 12, name: "Уральск", value: 12, checked: false},
  {id: 13, name: "Семей", value: 13, checked: false},
  {id: 14, name: "Усть-Каменогорск", value: 14, checked: false},
  {id: 15, name: "Актобе", value: 15, checked: false},
  {id: 16, name: "Павлодар", value: 16, checked: false},
  {id: 17, name: "Кокшетау", value: 17, checked: false},
  {id: 18, name: "Жанаозен", value: 18, checked: false}
])

const onSubmit = () => {
  console.log("1")
}
</script>
<template>
  <form @submit.prevent="onSubmit" class="fill-declare">
    <p class="fill-declare__title">{{ $t("app.declareInsuredEvent.fillAllData") }}</p>
    <div class="fill-declare__personal">
      <FFFieldset :title="$t('app.declareInsuredEvent.name') + '*'">
        <FFInput id="name" v-model="name" name="name" type="text" />
      </FFFieldset>
      <FFFieldset :title="$t('app.declareInsuredEvent.surname') + '*'">
        <FFInput id="lastname" v-model="lastName" name="lastname" type="text" />
      </FFFieldset>
      <FFFieldset :title="$t('app.declareInsuredEvent.phone')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          id="phone"
          v-model="phone"
          name="phone"
          placeholder="+7 (___) ___ ____"
          type="tel" />
      </FFFieldset>
      <FFFieldset class="fixed-fieldset" :title="$t('app.declareInsuredEvent.type')">
        <FFSelect
          :multiple="false"
          :options="requestTypes"
          v-model="currentRequestType"
          :title="$t('app.declareInsuredEvent.choose')" />
      </FFFieldset>
      <FFFieldset class="fixed-fieldset" :title="$t('app.declareInsuredEvent.date') + '*'">
        <VueDatePicker
          locale="ru"
          class="dp-input-wrap"
          v-model="date"
          :enable-time-picker="false"
          auto-apply
          format="dd.MM.yyyy"
          :min-date="new Date()">
          <template #input-icon>
            <img class="date-time-picker-icon" src="/declare-insured-event/images/calendar-input.svg" alt="icon" />
          </template>
          <template #clear-icon> </template>
        </VueDatePicker>
      </FFFieldset>
      <FFFieldset class="fixed-fieldset" :title="$t('app.declareInsuredEvent.time') + '*'">
        <VueDatePicker class="dp-input-wrap" v-model="time" time-picker auto-apply>
          <template #input-icon>
            <img class="date-time-picker-icon" src="/declare-insured-event/images/time-input.svg" alt="icon" />
          </template>
          <template #clear-icon> </template>
        </VueDatePicker>
      </FFFieldset>
      <FFFieldset class="fixed-fieldset" :title="$t('app.declareInsuredEvent.place')">
        <FFSelect :multiple="false" :options="cities" v-model="city" :title="$t('app.declareInsuredEvent.choose')" />
      </FFFieldset>
      <FFFieldset :title="$t('app.declareInsuredEvent.culprit')">
        <FFInput
          id="culprit"
          v-model="culprit"
          name="culprit"
          type="text"
          :placeholder="$t('app.declareInsuredEvent.fullName')" />
      </FFFieldset>
    </div>
    <div class="fill-declare__members-dtp">
      <p class="fill-declare__members-dtp--title">{{ $t("app.declareInsuredEvent.membersTitle") }}</p>
      <div class="fill-declare__members-dtp--list">
        <FFFieldset :title="$t('app.declareInsuredEvent.auto') + ' 1'">
          <FFInput
            id="avto1"
            v-model="avto1"
            name="avto1"
            type="text"
            :placeholder="'111AAA02 ' + $t('app.declareInsuredEvent.or') + ' A000WWW'" />
        </FFFieldset>
        <FFFieldset :title="$t('app.declareInsuredEvent.auto') + ' 2'">
          <FFInput
            id="avto2"
            v-model="avto2"
            name="avto2"
            type="text"
            :placeholder="'111AAA02 ' + $t('app.declareInsuredEvent.or') + ' A000WWW'" />
        </FFFieldset>
        <Transition>
          <FFFieldset v-if="addedThirdCar" :title="$t('app.declareInsuredEvent.auto') + ' 3'">
            <FFInput
              id="avto3"
              v-model="avto3"
              name="avto3"
              type="text"
              :placeholder="'111AAA02 ' + $t('app.declareInsuredEvent.or') + ' A000WWW'" />
          </FFFieldset>
        </Transition>
      </div>
      <p class="fill-declare__members-dtp--add" @click="addedThirdCar = !addedThirdCar">
        {{ !addedThirdCar ? $t("app.declareInsuredEvent.addAuto") : $t("app.declareInsuredEvent.minusAuto") }}
      </p>
    </div>
    <div class="fill-declare__details">
      <FFFieldset :title="$t('app.declareInsuredEvent.shortDescription') + '*'">
        <FFInput
          :textarea="true"
          textarea-min-height="110px"
          v-model="description"
          :placeholder="$t('app.declareInsuredEvent.description')" />
      </FFFieldset>
      <div class="fill-declare__details--photo">
        <p class="fill-declare__details--photo__title">{{ $t("app.declareInsuredEvent.takePhoto") }}</p>
        <FFFileInput v-model="file" :title="$t('app.declareInsuredEvent.damage')" input-id="damage" />
      </div>
    </div>
    <div class="fill-declare__buttons">
      <FFButton
        @click="router.push('personal-profile')"
        :title="$t('app.declareInsuredEvent.back')"
        :uppercase="false"
        color="#FFFFFF"
        paddingx="15px"
        paddingy="10px"
        width="100%"
        border-pixel="1px solid #fff"
        background="transparent" />
      <FFButton
        type="submit"
        :title="$t('app.declareInsuredEvent.send')"
        :uppercase="false"
        color="#FFFFFF"
        paddingx="15px"
        paddingy="10px"
        width="100%"
        background="var(--Green-gardient, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%))" />
    </div>
  </form>
</template>
<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.fixed-fieldset {
  max-height: 52px;
  min-height: 52px;
}
.dp-input-wrap {
  margin-left: -20px;
  margin-top: -2px;
}
.date-time-picker-icon {
  margin-left: 250px;
  margin-bottom: 5px;
}
.fill-declare {
  display: flex;
  flex-direction: column;
  padding-top: 130px;
  padding-bottom: 130px;
  align-items: center;
  max-width: 547px;
  margin: 0 auto;
  &__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    background: var(--FF-gradient, linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__personal {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding-top: 50px;
    width: 100%;
  }
  &__members-dtp {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    &--title {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &--list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      width: 100%;
    }
    &--add {
      padding: 10px 16px;
      color: #4f9d3a;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      text-decoration-line: underline;
      cursor: pointer;
      transition: all 0.3s ease;
    }
    &--add:hover {
      color: #fff;
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 50px;
    width: 100%;
    &--photo {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__title {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
}

@media (max-width: $lg) {
  .date-time-picker-icon {
    margin-left: 308px;
  }
  .fill-declare {
    max-width: 320px;
    padding-top: 50px;
    padding-bottom: 50px;
    &__personal {
      grid-template-columns: repeat(1, 1fr);
    }
    &__members-dtp {
      &--add:hover {
        color: #4f9d3a;
      }
      &--list {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

@media (max-width: $md) {
  .date-time-picker-icon {
    margin-left: 275px;
  }
  .fill-declare {
    max-width: 288px;
  }
}
</style>
