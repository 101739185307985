import {isTokenExpired} from "@/utils/jwt"
import axios from "axios"

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json"
  }
})

const gatewayApi = axios.create({
  baseURL: import.meta.env.VITE_API_GATEWAY_URL,
  timeout: 90000,
  headers: {
    "Content-Type": "application/json"
  }
})

gatewayApi.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access_token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

gatewayApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config
    if ([401, 403].includes(error.response.status) && !originalConfig._retry) {
      originalConfig._retry = true
      try {
        const refreshToken = localStorage.getItem("refresh_token")
        if (refreshToken && isTokenExpired(refreshToken)) {
          const response = await axios.post("/auth/refresh-token", {refreshToken})
          const {token} = response.data

          localStorage.setItem("token", token)

          originalConfig.headers.Authorization = `Bearer ${token}`
          return axios(originalConfig)
        } else {
          localStorage.clear()
          window.location.href = "/pages/auth"
        }
      } catch (_error) {
        return Promise.reject(_error)
      }
    }
    return Promise.reject(error)
  }
)

export function isAuth(): boolean {
  return Boolean(localStorage.getItem("access-token"))
}

export {api, gatewayApi}
