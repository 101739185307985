<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <g filter="url(#filter0_i_3444_4193)">
      <circle cx="20" cy="20" r="16" fill="url(#paint0_linear_3444_4193)" />
    </g>
    <g filter="url(#filter1_d_3444_4193)">
      <path
        d="M12.8024 20.2224L19.2217 10.8423V20.2224H12.8024ZM20.4253 20.2224C21.5085 17.1557 21.3641 13.034 20.4253 10C24.2447 11.2594 27.1655 16.0517 27.2136 20.2224H20.4253ZM27.326 23.1501C26.997 23.6735 26.6118 24.1233 26.1625 24.5076C25.6409 24.2705 25.1755 23.9025 24.8064 23.4936C23.6108 25.0719 21.1956 25.0719 20 23.4936C18.8205 25.0719 16.3892 25.0719 15.2096 23.4936C14.8084 23.9025 14.3671 24.2705 13.8455 24.5076C12.9308 23.7389 12.2407 22.6431 12 21.4491H28C27.8753 22.0521 27.6472 22.628 27.326 23.1501ZM27.2136 27.9914C26.3631 27.9914 25.5607 27.787 24.8064 27.3781C23.33 28.1959 21.4764 28.1959 20 27.3781C18.5236 28.1959 16.67 28.1959 15.2096 27.3781C14.2227 27.9424 13.0913 28.0323 12 27.9914V26.3559C13.1314 26.3967 14.2227 26.2741 15.2096 25.5381C16.6058 26.5603 18.5878 26.5603 20 25.5381C21.4203 26.5603 23.3942 26.5603 24.8064 25.5381C25.7773 26.2741 26.8766 26.3967 28 26.3559V27.9914H27.2136Z"
        fill="#1F1F1F" />
    </g>
    <defs>
      <filter
        id="filter0_i_3444_4193"
        x="4"
        y="4"
        width="37"
        height="37"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3444_4193" />
      </filter>
      <filter
        id="filter1_d_3444_4193"
        x="12"
        y="10"
        width="25"
        height="27"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3444_4193" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3444_4193" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_3444_4193"
        x1="36"
        y1="-1.64706"
        x2="-4.95045"
        y2="19.6784"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
    </defs>
  </svg>
</template>
