<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="18" viewBox="0 0 60 18" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.07689 12.0213H8.70848C11.1496 12.0213 11.5998 10.6746 11.5998 9.02681V8.05228H6.07689V4.79205H11.5998C13.7812 4.75661 14.4911 3.44543 14.4911 1.7976V4.75662C14.4911 14.6613 7.63507 17.1065 7.63507 17.1065C7.63507 17.1065 6.97718 16.9116 6.07689 16.3269V12.0213ZM0 4.77434C0 10.338 1.99101 13.5982 3.65306 15.3524C5.48825 17.2837 7.34075 17.8684 7.42732 17.8861L7.6697 17.957L7.91208 17.8684C7.98133 17.833 9.81652 17.1774 11.6517 15.2106C13.3138 13.421 15.3048 10.1431 15.3048 4.77434V0.043457H0V4.77434Z"
      fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.957 8.85173V2.85156H24.6441V3.28636C24.6441 3.73854 24.2789 4.10377 23.8267 4.10377H22.4353V5.35598H24.2441V5.79077C24.2441 6.24296 23.8788 6.62558 23.4093 6.62558H22.4179V8.85173H20.957Z"
      fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.4609 8.85173V2.85156H27.548C28.0871 2.85156 28.4697 2.90374 28.7132 2.99069C29.061 3.12983 29.3393 3.33853 29.5306 3.63419C29.7219 3.92985 29.8263 4.29508 29.8263 4.72987C29.8263 5.06032 29.7393 5.39076 29.5828 5.68642C29.4263 5.99947 29.2002 6.22557 28.9219 6.36471V6.38209C28.9741 6.45166 29.061 6.5734 29.148 6.74732L30.3306 8.85173H28.6958L27.6349 6.83428H26.9392V8.85173H25.4609ZM26.9392 5.56468H27.5827C27.8262 5.56468 28.0175 5.49511 28.1393 5.37337C28.2784 5.25163 28.348 5.06032 28.348 4.83422C28.348 4.50378 28.2262 4.29508 28.0001 4.19073C27.8784 4.13856 27.6871 4.10377 27.4436 4.10377H26.9566V5.56468H26.9392Z"
      fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.9922 8.85203V2.83447H34.7488V4.10408H32.4531V5.19976H34.2966V6.45197H32.4531V7.58243H34.8706V8.85203H30.9922Z"
      fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.8086 8.85203V2.83447H39.5652V4.10408H37.2869V5.19976H39.113V6.45197H37.2869V7.58243H39.687V8.85203H35.8086Z"
      fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.625 8.85173V2.85156H42.7642C43.7207 2.85156 44.4686 3.11244 45.0251 3.65159C45.5817 4.17334 45.8599 4.92119 45.8599 5.84295C45.8599 6.78211 45.5817 7.52995 45.0251 8.0517C44.4686 8.59085 43.7207 8.85173 42.7642 8.85173H40.625ZM42.1033 7.58213H42.712C43.2338 7.58213 43.6338 7.4256 43.912 7.12994C44.2077 6.83428 44.3468 6.39949 44.3468 5.82556C44.3468 5.26902 44.2077 4.83423 43.912 4.53857C43.6164 4.24291 43.2164 4.08638 42.712 4.08638H42.1033V7.58213Z"
      fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.4883 5.80851C46.4883 4.93892 46.7839 4.20847 47.3753 3.61715C47.9666 3.02583 48.7144 2.74756 49.6362 2.74756C50.558 2.74756 51.3058 3.04322 51.8971 3.61715C52.4884 4.20847 52.7841 4.93892 52.7841 5.80851C52.7841 6.69549 52.4884 7.44334 51.8971 8.05205C51.3058 8.64337 50.558 8.95643 49.6362 8.95643C48.7144 8.95643 47.9666 8.66077 47.3753 8.05205C46.7839 7.44334 46.4883 6.69549 46.4883 5.80851ZM48.0188 5.80851C48.0188 6.33027 48.1753 6.74767 48.4709 7.0955C48.784 7.44334 49.1666 7.59987 49.6362 7.59987C50.1058 7.59987 50.4884 7.42595 50.8014 7.0955C51.1145 6.74767 51.2536 6.33027 51.2536 5.80851C51.2536 5.32154 51.0971 4.90414 50.8014 4.5737C50.4884 4.24325 50.1058 4.08673 49.6362 4.08673C49.1666 4.08673 48.784 4.24325 48.4709 4.5737C48.1753 4.90414 48.0188 5.32154 48.0188 5.80851Z"
      fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.4766 8.85173L53.9635 2.85156H55.5636L56.4332 5.39076L56.7288 6.38209H56.7462C56.8506 5.98208 56.9549 5.65164 57.0419 5.39076L57.9115 2.85156H59.5115L59.9985 8.85173H58.5376L58.3463 6.13861C58.3289 5.99947 58.3289 5.86034 58.3289 5.68642C58.3289 5.5125 58.3289 5.39076 58.3289 5.28641L58.3463 5.12989H58.3289C58.1897 5.54729 58.0854 5.87773 57.9984 6.13861L57.3723 7.87779H56.1201L55.494 6.13861L55.1636 5.12989H55.1462C55.1636 5.49511 55.1636 5.84295 55.1462 6.13861L54.9549 8.85173H53.4766Z"
      fill="white" />
    <path
      d="M20.957 15.2708V10.8594H22.789C23.2158 10.8594 23.5637 11.0007 23.8301 11.2861C24.0964 11.5688 24.2269 11.933 24.2269 12.3788C24.2269 12.8273 24.0937 13.1942 23.8301 13.4796C23.5637 13.765 23.2185 13.9091 22.789 13.9091H22.0361V15.2735H20.957V15.2708ZM22.0361 12.9822H22.5661C22.7455 12.9822 22.8869 12.9278 22.982 12.8164C23.0799 12.705 23.1261 12.5582 23.1261 12.3788C23.1261 12.1994 23.0798 12.0526 22.9847 11.9466C22.8896 11.8406 22.7564 11.7862 22.5824 11.7862H22.0334V12.9822H22.0361Z"
      fill="white" />
    <path
      d="M24.3516 15.2708L25.8492 10.8594H26.9854L28.4831 15.2708H27.3741L27.1023 14.3467H25.7269L25.4633 15.2708H24.3516ZM25.9797 13.4823H26.8495L26.6021 12.6261L26.4227 11.8678H26.4119C26.3466 12.1885 26.2868 12.4413 26.2325 12.6261L25.9797 13.4823Z"
      fill="white" />
    <path
      d="M29.8632 15.2708V13.4633L28.4062 10.8594H29.6348L30.135 11.9194L30.4013 12.5555H30.4122C30.4937 12.3326 30.5835 12.1206 30.6786 11.9194L31.1733 10.8594H32.4018L30.945 13.4633V15.2708H29.8632Z"
      fill="white" />
  </svg>
</template>
