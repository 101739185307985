<script setup lang="ts">
import WhiteLogo from "@/components/icons/WhiteLogo.vue"
import {useRouter} from "vue-router"

const router = useRouter()

const goMainPage = () => {
  router.push("/")
  window.scrollTo({top: 0, left: 0, behavior: "smooth"})
}
</script>

<template>
  <div class="about">
    <WhiteLogo @click="goMainPage" />
    <p class="about__about-text">
      {{ $t("app.footer.description") }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.about {
  display: flex;
  flex-direction: column;
  gap: 27px;

  &__about-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: fit-content;
  }
}

@media (max-width: 768px) {
  .about {
    display: grid;
    justify-content: center;
  }
  .about-text {
    width: fit-content;
    text-align: justify;
  }
}
</style>
