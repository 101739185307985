<script setup lang="ts">
import {ref} from "vue"
import {vMaska} from "maska"
import {storeToRefs} from "pinia"
import {useHomeProtectStore} from "@/stores/HomeProtectStore"
import {useHelpers} from "@/composables/useHelpers"
import {useValidation} from "@/composables/useValidation"
import {filterCyrillicInput, onPhoneFocus} from "@/utils/inputEvents"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFCheckerInput from "@/components/FFUI/FFCheckerInput.vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import Dropdown from "primevue/dropdown"

const homeProtectStore = useHomeProtectStore()
const {
  phone,
  iin,
  iinName,
  isIinLoadingState,
  cadastralNumber,
  date,
  month,
  monthes,
  isPhoneFilled,
  isFirstStepFormFilled
} = storeToRefs(homeProtectStore)

const {currentLocale} = useHelpers()
</script>

<template>
  <p class="calc-form__title">{{ $t("form.formHeaders.costCalculation") }}</p>
  <div class="calc-form__fields home-protect__calc-form">
    <div>
      <FFFieldset :title="$t('form.inputTitles.ownersPhone')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          placeholder="+7 (___) ___ ____"
          id="phonenumber"
          type="tel"
          v-model="phone"
          @focus="onPhoneFocus" />
      </FFFieldset>
    </div>
    <div>
      <div class="calc-form__form-row">
        <FFFieldset :title="$t('form.inputTitles.ownersIin')">
          <FFCheckerInput
            dataMaska="############"
            :padding="
              !isIinLoadingState && Boolean(iinName) && iin.length === 12 ? '11px 5px 7px 14px' : '6px 5px 7px 14px'
            "
            :inputHeight="!isIinLoadingState && Boolean(iinName) && iin.length === 12 ? '16px' : '51px'"
            placeholder="123412545654"
            :needUnderText="!isIinLoadingState && Boolean(iinName)"
            :underText="!isIinLoadingState && Boolean(iinName) && iin.length === 12 ? iinName : ''"
            v-model="iin"
            id="iin"
            type="text"
            :loading="isIinLoadingState"
            inputmode="numeric"
            :func="
              () => {
                homeProtectStore.iinCheck(iin)
              }
            "
            :lockedErrorText="$t('app.toasterMessages.inputPhoneNumber')"
            :locked="!isPhoneFilled" />
        </FFFieldset>
      </div>
    </div>
    <div class="calc-form__form-row">
      <FFFieldset :title="$t('form.inputTitles.dateStart')">
        <VueDatePicker
          :enable-time-picker="false"
          :teleport="true"
          auto-apply
          v-model="date"
          format="dd.MM.yyyy"
          :locale="currentLocale"
          :start-date="new Date(new Date().setDate(new Date().getDate() + 1))"
          :min-date="new Date(new Date().setDate(new Date().getDate() + 1))"
          :max-date="new Date(new Date().setDate(new Date().getDate() + 1) + 39 * 24 * 60 * 60 * 1000)">
          <template #input-icon>
            <img class="date-time-picker-icon" src="/declare-insured-event/images/calendar-input.svg" alt="icon" />
          </template>
          <template #clear-icon> </template>
        </VueDatePicker>
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.period')" minHeight="unset">
        <Dropdown v-model="month" :options="monthes" optionLabel="name" :disabled="true" dropdownIcon="no" />
      </FFFieldset>
    </div>
    <FFButton
      @click="homeProtectStore.getClientApartmentData"
      :title="$t('app.buttons.next')"
      :disabled="!isFirstStepFormFilled"
      :uppercase="false" />
  </div>
</template>

<style scoped lang="scss">
.calc-form__title {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.calc-form__fields {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 20px;
  .calc-form__form-row {
    display: flex;
    gap: 10px;

    .calc-form__icon-wrapper {
      cursor: pointer;
      border: 2px solid #ffffff;
      height: 46px;
      width: 60px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
    }
  }
  .form__ask {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #2e2e2e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin: 0 auto;
    transition: all 0.3s ease;
    padding: 10px 0;
  }
}
</style>
