<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

interface IProps {
  imagePath: string
  needDescription2?: boolean
}

withDefaults(defineProps<IProps>(), {
  needDescription2: false
})

const {scrollTo} = useHelpers()
</script>

<template>
  <section class="juridical-get-policy-be-calm__wrapper">
    <img :src="imagePath" alt="" />
    <div class="juridical-get-policy-be-calm container">
      <p class="juridical-get-policy-be-calm__title">
        <slot name="title" />
      </p>
      <p class="juridical-get-policy-be-calm__description">
        <slot name="description" />
      </p>
      <p v-if="needDescription2" class="juridical-get-policy-be-calm__description">
        <slot name="description-2" />
      </p>
      <FFButton
        @click="scrollTo('.juridical-form__wrapper')"
        :title="$t('app.buttons.sendRequest')"
        :uppercase="false"
        maxWidth="400px"
        maxWidthMobile="400px"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        color="var(--color-white)"
        colorMobile="var(--color-white)" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.juridical-get-policy-be-calm__wrapper {
  position: relative;
  padding-top: 500px;
  padding-bottom: 200px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .juridical-get-policy-be-calm {
    @include column;
    position: relative;
    z-index: 100;
    gap: 20px;
    .juridical-get-policy-be-calm__title {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      @include main-green-gradient;
    }
    .juridical-get-policy-be-calm__description {
      color: #dbdbdb;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;
      opacity: 0.8;
    }
  }
}
</style>
