import type {IAgreementData, IHomeData} from "@/interfaces/Comfort/IHomeData"
import {computed, ref, watch} from "vue"

import HProtectForms from "@/components/HomeProtect/HProtectForms.vue"
import FFOtp from "@/components/FFUI/FFOtp.vue"
import type {ICalculation} from "@/interfaces/HomeProtect/ICalculation"
import type {IApartmentClientData} from "@/interfaces/HomeProtect/IApartmentClientData"
import type {IPropertyStatus} from "@/interfaces/HomeProtect/IPropertyStatus"
import type {IPropertiesList} from "@/interfaces/HomeProtect/IPropertiesList"
import type {IProperty} from "@/interfaces/HomeProtect/IProperty"
import {debounce} from "lodash-es"
import {defineStore} from "pinia"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useFormatters} from "@/utils/formatters"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {useI18n} from "vue-i18n"
import {useModalStore} from "@/stores/ModalStore"
import {usePdfOpener} from "@/composables/usePdfOpener"
import {useRouter} from "vue-router"
import {useToast} from "vue-toastification"

export const useHomeProtectStore = defineStore("homeProtectStore", () => {
  const {getData, postData, loading} = useGatewayFetcher()

  const modalStore = useModalStore()

  const {currentLocale, openLink} = useHelpers()
  const {phoneNumberFormatter, dateFormatter} = useFormatters()
  const {isMobileDevice, isDesktop} = useDeviceSize()
  const {openKdpAgreementPdf, openQuestionnairePdf} = usePdfOpener(postData)
  const {t} = useI18n()
  const toast = useToast()
  const router = useRouter()

  const config = {
    headers: {
      "Accept-Language": currentLocale.value
    }
  }

  //Form steps
  const currentStep = ref<number>(1)

  const handleStepNext = () => {
    currentStep.value += 1
  }
  const handleStepBack = () => {
    currentStep.value -= 1
  }

  //Calculation form
  const phone = ref<string>()
  const iin = ref<string>("")
  const iinName = ref<string>("")
  const isIinLoadingState = ref<boolean>(false)
  const cadastralNumber = ref<string>("")
  const date = ref(new Date(new Date().setDate(new Date().getDate() + 1)))
  const month = ref({name: computed(() => t("form.dropdown.monthes.month-12")), value: 12})
  const monthes = ref([
    {name: computed(() => t("form.dropdown.monthes.month-12")), value: 12},
    {name: computed(() => t("form.dropdown.monthes.month-11")), value: 11},
    {name: computed(() => t("form.dropdown.monthes.month-10")), value: 10},
    {name: computed(() => t("form.dropdown.monthes.month-9")), value: 9},
    {name: computed(() => t("form.dropdown.monthes.month-8")), value: 8},
    {name: computed(() => t("form.dropdown.monthes.month-7")), value: 7},
    {name: computed(() => t("form.dropdown.monthes.month-6")), value: 6}
  ])
  const calculationObject = ref<ICalculation>()

  const isPhoneFilled = computed(() => {
    if (phone?.value?.length === 17) {
      return true
    } else {
      return false
    }
  })

  const isFirstStepFormFilled = ref<boolean>(false)
  let timeoutId: number | null = null

  watch([phone, iin], ([newPhone, newIin]) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    if (newPhone?.length === 17 && newIin?.length === 12) {
      timeoutId = setTimeout(() => {
        isFirstStepFormFilled.value = true
      }, 50)
    } else {
      isFirstStepFormFilled.value = false
    }
  })

  const iinCheck = debounce(async (data: string) => {
    if (!data) {
      iinName.value = ""
      return
    }

    isIinLoadingState.value = true
    try {
      if (data.length === 12) {
        const verifyResponse = await postData(
          "/portal/mobile/verify-phone-info",
          {
            phone: phoneNumberFormatter(phone.value),
            iin: iin.value
          },
          config
        )

        if (verifyResponse && verifyResponse.status === 200) {
          const response = await postData(
            "/client/check",
            {
              iin: data,
              clientType: "Individual",
              isDriver: true
            },
            config
          )
          if (response && response.data && response.status === 200) {
            iinName.value = response.data.data.client
          } else {
            iinName.value = ""
            iin.value = ""
          }
        } else {
          iin.value = ""
        }
      } else {
        iinName.value = ""
      }
      isIinLoadingState.value = false
    } catch (error) {
      iin.value = ""
      iinName.value = ""
      isIinLoadingState.value = false
      console.error(error)
    }
  }, 500)

  const handleNextStepKdpOrHomeForm = async () => {
    if (needKdp.value) {
      isShowExecutionFormWhileFetchNotVerifiedIins.value = false
      const response = await fetchNotVerifiedIins()
      if (response) {
        currentStep.value = 3
      } else {
        return
      }
    } else {
      isShowExecutionFormWhileFetchNotVerifiedIins.value = true
      currentStep.value = 3
    }
  }

  const calculateCost = async () => {
    try {
      const payload = {
        guid: propertyData.value?.guid,
        date_start: dateFormatter(date.value)
      }
      const response = await postData("/policy/home-protect/calculate", payload, config)
      if (response && response.status === 200) {
        calculationObject.value = response?.data?.data
        await handleNextStepKdpOrHomeForm()
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
    }
  }

  //OTP
  const otpCode = ref<string | number>()
  const showOtp = ref<boolean>(false)
  const codeIsSent = ref<boolean>(false)
  const sentOtpResponse = ref<{message: string; status: boolean}>()
  const timer = ref(0)
  const localStorageCurrentUser = JSON.parse(localStorage.getItem("current-user"))?.data || null
  const localStorageAccessToken = localStorage.getItem("access_token")

  const handleOtpFormOpen = async () => {
    const otpProps = {
      codeIsSent: codeIsSent,
      buttonTitle: buttonTitle,

      sendOtp: handleSendOtp,
      checkOtp: checkOtpCode,
      closeOtp: closeOtpForm
    }
    modalStore.openModal({
      component: FFOtp,
      props: otpProps
    })
  }

  const buttonTitle = computed(() => {
    if (checkOtpResponse.value?.status) {
      return t("app.buttons.sendCode")
    } else if (timer.value > 0) {
      const minutes = Math.floor(timer.value / 60)
      const seconds = timer.value % 60
      return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
    }
    return t("app.buttons.sendCode")
  })

  const startTimer = () => {
    timer.value = 90
    const interval = setInterval(() => {
      timer.value--
      if (timer.value <= 0) {
        clearInterval(interval)
        codeIsSent.value = false
      }
    }, 1000)
  }

  const handleSendOtp = async () => {
    try {
      const response = await postData(
        "/policy/home-protect/send-otp",
        {phone: phoneNumberFormatter(phone.value)},
        config
      )
      if (response?.status === 200) {
        startTimer()
        codeIsSent.value = true
        showOtp.value = true
        sentOtpResponse.value = response?.data?.data
        sessionStorage.setItem("request_id", response?.data?.requestId)
      } else {
        otpCode.value = ""
        codeIsSent.value = false
        showOtp.value = false
        sessionStorage.clear()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkOtpResponse = ref()
  const checkOtpCode = async (code: string | number) => {
    const requestId = sessionStorage.getItem("request_id")
    try {
      otpCode.value = code
      const response = await postData(
        "/policy/home-protect/check-otp",
        {
          phone: phoneNumberFormatter(phone.value),
          otpCode: otpCode.value,
          requestId
        },
        config
      )
      if (response?.status === 200 && response?.data) {
        checkOtpResponse.value = response.data
        if (checkOtpResponse.value.status) {
          const response = await getPropertiesList()
          if (response) {
            showOtp.value = false
            codeIsSent.value = false
            modalStore.closeModal()
            modalStore.openModal({
              component: HProtectForms
            })
            return true
          } else {
            modalStore.closeModal()
            currentStep.value = 1
          }
        }
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const closeOtpForm = () => {
    showOtp.value = false
    codeIsSent.value = false
    timer.value = 0
    currentStep.value = 1
    resetApartmentData()
    modalStore.closeModal()
  }

  //Bank apartment check form
  const isBankCheckModalNeed = ref<boolean>(true)
  const apartmentClientData = ref<IApartmentClientData | null>(null)
  const getClientApartmentData = async () => {
    const requestId = sessionStorage.getItem("request_id") || null

    try {
      const verifyResponse = await postData(
        "/portal/mobile/verify-phone-info",
        {
          phone: phoneNumberFormatter(phone.value),
          iin: iin.value
        },
        config
      )
      if (verifyResponse && verifyResponse.status === 200) {
        const response = await postData(
          "/policy/home-protect/get-client-data",
          {
            phone: phoneNumberFormatter(phone.value),
            iin: iin.value,
            requestId
          },
          config
        )
        if (response && response.status === 200 && response.data) {
          apartmentClientData.value = response.data.data
          if (apartmentClientData.value?.is_confirmed && !apartmentClientData.value?.otp_confirmed) {
            currentStep.value = 2
            handleOtpFormOpen()
            isBankCheckModalNeed.value = false
          } else if (apartmentClientData.value?.is_confirmed && apartmentClientData.value?.otp_confirmed) {
            const response = await getPropertiesList()
            if (response) {
              currentStep.value = 2
              modalStore.openModal({
                component: HProtectForms
              })
              isBankCheckModalNeed.value = false
            } else {
              return
            }
          } else {
            currentStep.value = 2
            modalStore.openModal({
              component: HProtectForms
            })
          }
        } else {
          return
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const isCheckLoadingHide = ref<boolean>(false)
  const propertyStatusData = ref<IPropertyStatus | null>(null)
  const checkPropertyStatus = async () => {
    try {
      const response = await postData(
        "/policy/home-protect/check-property-status",
        {
          phone: phoneNumberFormatter(phone.value),
          iin: iin.value
        },
        config
      )
      if (response && response.status === 200 && response.data) {
        isCheckLoadingHide.value = true
        propertyStatusData.value = response.data.data
        if (
          ((propertyStatusData.value?.status === 1 && !propertyStatusData.value.property) ||
            propertyStatusData.value?.status === 3 ||
            propertyStatusData.value?.status === 4 ||
            propertyStatusData.value?.status === 5) &&
          propertyStatusData.value?.message
        ) {
          isCheckLoadingHide.value = true
          currentStep.value = 1
          toast.error(propertyStatusData.value?.message)
          if (isDesktop.value) {
            modalStore.closeModal()
          }
        }
        if (propertyStatusData.value?.status === 1 && propertyStatusData.value.property) {
          const response = await getPropertiesList()
          if (response) {
            currentStep.value = 2
            isCheckLoadingHide.value = true
            isBankCheckModalNeed.value = false
            modalStore.closeModal()
            modalStore.openModal({
              component: HProtectForms
            })
            apartmentClientData.value.is_confirmed = true
          } else {
            if (isDesktop.value) {
              modalStore.closeModal()
            }
            currentStep.value = 1
            return
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const propertiesListData = ref<IPropertiesList | null>(null)
  const propertyData = ref<IProperty | null>(null)
  const apartmentCount = ref<number>(0)
  const isChoosedProperty = ref<boolean>(false)
  const choosedPropertyFromList = ref<{guid: string; address: string} | null>(null)

  const getPropertiesList = async () => {
    try {
      const response = await postData(
        "/policy/home-protect/get-properties",
        {
          phone: phoneNumberFormatter(phone.value),
          iin: iin.value
        },
        config
      )
      if (response && response.status === 200 && response.data) {
        apartmentCount.value = response.data?.count
        if (apartmentCount.value > 1) {
          propertiesListData.value = response.data
          isChoosedProperty.value = false
        } else {
          propertyData.value = response.data.data[0]
          isChoosedProperty.value = true
        }
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const getPropertyDetails = async () => {
    try {
      const response = await postData(
        "/policy/home-protect/get-property",
        {
          guid: choosedPropertyFromList.value?.guid
        },
        config
      )
      if (response && response.status === 200 && response.data) {
        propertyData.value = response.data.data
        isChoosedProperty.value = true
      }
    } catch (error) {
      console.error(error)
    }
  }

  const stopCheckingStatus = (statusInterval: number) => {
    if (statusInterval) {
      clearInterval(statusInterval)
    }
  }

  watch(choosedPropertyFromList, async (newVal) => {
    if (newVal) {
      await getPropertyDetails()
    }
  })

  const resetApartmentData = () => {
    propertiesListData.value = null
    propertyData.value = null
    apartmentCount.value = 0
    isChoosedProperty.value = false
    choosedPropertyFromList.value = null
  }

  //Agreement form
  const needKdp = ref<boolean>(true)
  const verificationUsersData = ref<{fullName: string; iin: string; verifyClient: boolean}[]>([])
  const agreementLoaderHide = ref<boolean>(false)

  const handleKdp = (): void => {
    needKdp.value = false
  }

  const isShowExecutionFormWhileFetchNotVerifiedIins = ref<boolean>(false)
  const fetchNotVerifiedIins = async () => {
    try {
      const firstClient = {iin: iin.value, clientType: "individual"}
      const clients = [firstClient]
      const response = await postData("/portal/get-client-data", {clients})
      if (response?.status === 200) {
        verificationUsersData.value = response.data.data
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const notVerificatedUsers = computed(() => {
    return verificationUsersData.value
  })

  const allUsersAreVerificated = computed(() => {
    return notVerificatedUsers.value.every((verficated) => verficated.verifyClient)
  })

  const intervalIds = ref<Record<number, number | undefined>>({})
  const clearAllIntervals = () => {
    Object.keys(intervalIds.value).forEach((index) => {
      clearInterval(intervalIds.value[Number(index)])
      delete intervalIds.value[Number(index)]
    })
  }

  const sendCode = async (index: number) => {
    try {
      const iin = notVerificatedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const openHomeProtectAgreementPdf = async (fullName: string, iin: string) => {
    openKdpAgreementPdf(fullName, iin)
  }

  const openHomeProtectQuestionnairePdf = async (calcId: string) => {
    openQuestionnairePdf("/policy/home-protect/form", {calcId})
  }

  //Home data form
  const agreementsObj = ref<IAgreementData>({
    iAmFamiliar: false
  })

  const isHomeDataComplete = computed(() => {
    return Object.values(agreementsObj.value).every((value) => {
      if (typeof value === "boolean") {
        return value === true
      }
      return value !== ""
    })
  })

  //Execution form
  const executionForm = ref({
    email: "",
    iAgreeWithAgreement: false
  })

  //Payment form
  const selectedPayment = ref<string>("")

  const handleCurrentMethod = (type: string): void => {
    selectedPayment.value = type
  }

  //Kaspi payment form
  const showKaspiForm = ref<boolean>(false)
  const showSuperAppBlock = ref<boolean>(false)
  const kaspiPayPhoneNumber = ref<string>("")

  //Final pay form
  const paymentUrl = ref<string>("")

  const finalPay = async () => {
    try {
      const payload = {
        calcId: calculationObject.value?.calcId,
        paymentType: selectedPayment.value,
        email: executionForm.value.email,
        gived_consent: true,
        kaspiPayNumber: kaspiPayPhoneNumber.value
      }
      const response = await postData("/policy/home-protect/create/order", payload, config)

      if (response && response.status === 200) {
        paymentUrl.value = response.data?.payment_url
        if (selectedPayment.value === "FreedomPay") {
          localStorage.removeItem("paymentType")

          window.location.href = paymentUrl.value
          phone.value = ""
        }
        if (selectedPayment.value === "FreedomSuperApp") {
          localStorage.removeItem("paymentType")
          paymentUrl.value = response.data?.payment_url

          if (isDesktop.value) {
            showSuperAppBlock.value = true
          } else {
            openLink(paymentUrl.value, "_self")
            phone.value = ""
          }
        }
        if (selectedPayment.value === "KaspiPay") {
          localStorage.removeItem("paymentType")
          localStorage.removeItem("cashbackBalance")
          localStorage.removeItem("isClientBank")
          localStorage.setItem("paymentType", "KaspiPay")
          modalStore.closeModal()
          router.replace("success-pay")
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    currentStep,
    needKdp,
    isShowExecutionFormWhileFetchNotVerifiedIins,
    phone,
    iin,
    iinName,
    isIinLoadingState,
    cadastralNumber,
    date,
    month,
    monthes,
    isPhoneFilled,
    isFirstStepFormFilled,
    apartmentClientData,
    propertyStatusData,
    propertiesListData,
    propertyData,
    apartmentCount,
    isCheckLoadingHide,
    isBankCheckModalNeed,
    choosedPropertyFromList,
    isChoosedProperty,
    showKaspiForm,
    showSuperAppBlock,
    kaspiPayPhoneNumber,
    verificationUsersData,
    agreementLoaderHide,
    notVerificatedUsers,
    allUsersAreVerificated,
    otpCode,
    showOtp,
    codeIsSent,
    checkOtpResponse,
    buttonTitle,
    intervalIds,
    agreementsObj,
    isHomeDataComplete,
    executionForm,
    calculationObject,
    selectedPayment,
    paymentUrl,
    fetchNotVerifiedIins,
    clearAllIntervals,
    sendCode,
    openHomeProtectAgreementPdf,
    handleKdp,
    handleStepBack,
    handleStepNext,
    handleNextStepKdpOrHomeForm,
    iinCheck,
    calculateCost,
    getClientApartmentData,
    checkPropertyStatus,
    handleOtpFormOpen,
    getPropertiesList,
    getPropertyDetails,
    resetApartmentData,
    finalPay,
    openHomeProtectQuestionnairePdf,
    handleCurrentMethod
  }
})
