<script setup lang="ts">
import {ref, computed, type ComputedRef} from "vue"
import {useI18n} from "vue-i18n"
import {useDeviceSize} from "@/composables/useDeviceSize"

const {t} = useI18n()
const {isMobileDevice} = useDeviceSize()

const boardOfDirectors = ref<{name: ComputedRef<string>; position: ComputedRef<string>; imgPath: string}[]>([
  {
    name: computed(() => t("app.ourTeam.turlov")),
    position: computed(() => t("app.ourTeam.chairmanBoardDirectors")),
    imgPath: "/our-team-front/images/Turlov.png"
  },
  {
    name: computed(() => t("app.ourTeam.grishin")),
    position: computed(() => t("app.ourTeam.memberBoardDirectors")),
    imgPath: "/our-team-front/images/Victorovich.png"
  },
  {
    name: computed(() => t("app.ourTeam.sadykova")),
    position: computed(() => t("app.ourTeam.independentDirector")),
    imgPath: "/our-team-front/images/Mashurovna.png"
  },
  {
    name: computed(() => t("app.ourTeam.anikina")),
    position: computed(() => t("app.ourTeam.independentDirector")),
    imgPath: "/our-team-front/images/Vladimirovna.png"
  },
  {
    name: computed(() => t("app.ourTeam.kerimbayev")),
    position: computed(() => t("app.ourTeam.memberBoardDirectors")),
    imgPath: "/our-team-front/images/Rashidovich.png"
  }
])

const managementBoard = ref<{name: ComputedRef<string>; position: ComputedRef<string>; imgPath: string}[]>([
  {
    name: computed(() => t("app.ourTeam.kerimbayev")),
    position: computed(() => t("app.ourTeam.chairmanBoard")),
    imgPath: "/our-team-front/images/Rashidovich.png"
  },
  {
    name: computed(() => t("app.ourTeam.rustanov")),
    position: computed(() => t("app.ourTeam.deputyChairman")),
    imgPath: "/our-team-front/images/Kibadullaevich.png"
  },
  {
    name: computed(() => t("app.ourTeam.don")),
    position: computed(() => t("app.ourTeam.financialOfficer")),
    imgPath: "/our-team-front/images/Ruslanovna.png"
  },
  {
    name: computed(() => t("app.ourTeam.tuleshov")),
    position: computed(() => t("app.ourTeam.deputyChairman")),
    imgPath: "/our-team-front/images/Sergeevich.png"
  },
  {
    name: computed(() => t("app.ourTeam.yesimkhanov")),
    position: computed(() => t("app.ourTeam.deputyChairman")),
    imgPath: "/our-team-front/images/Maulenovich.png"
  }
])
</script>

<template>
  <section class="our-team-members container">
    <div class="our-team-members__board-of-directors">
      <p class="our-team-members__board-of-directors-title">{{ $t("app.ourTeam.directorTitle") }}</p>
      <div class="our-team-members__board-of-directors-items">
        <div v-for="(data, index) in boardOfDirectors" :key="index" class="our-team-members__board-of-directors-item">
          <img :src="data.imgPath" class="our-team-members__board-of-directors-item-img" :alt="data.name" />
          <p class="our-team-members__board-of-directors-item-name">{{ data.name }}</p>
          <p class="our-team-members__board-of-directors-item-position">{{ data.position }}</p>
        </div>
      </div>
    </div>

    <div class="our-team-members__management-board">
      <p class="our-team-members__management-board-title">{{ $t("app.ourTeam.companyManagement") }}</p>
      <div class="our-team-members__management-board-items">
        <div v-for="(data, index) in managementBoard" :key="index" class="our-team-members__management-board-item">
          <img :src="data.imgPath" class="our-team-members__management-board-item-img" :alt="data.name" />
          <p class="our-team-members__management-board-item-name">{{ data.name }}</p>
          <p class="our-team-members__management-board-item-position">{{ data.position }}</p>
        </div>
      </div>
    </div>

    <div class="our-team-members__chief-accountant">
      <p class="our-team-members__chief-accountant-title">{{ $t("app.ourTeam.chiefAccountantTitle") }}</p>
      <div class="our-team-members__chief-accountant-items">
        <div class="our-team-members__chief-accountant-item">
          <img src="/our-team-front/images/Eduardovna.png" class="our-team-members__chief-accountant-item-img" alt="" />
          <p class="our-team-members__chief-accountant-item-name">{{ $t("app.ourTeam.hon") }}</p>
          <p class="our-team-members__chief-accountant-item-position">{{ $t("app.ourTeam.chiefAccountant") }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.our-team-members {
  @include column;
  padding-top: 100px;
  gap: 100px;

  .our-team-members__board-of-directors,
  .our-team-members__management-board,
  .our-team-members__chief-accountant {
    @include column;
    gap: 50px;

    .our-team-members__board-of-directors-title,
    .our-team-members__management-board-title,
    .our-team-members__chief-accountant-title {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      @include main-green-gradient;
      text-transform: uppercase;

      @include max-responsive(lg) {
        font-size: 30px;
      }
    }
    .our-team-members__board-of-directors-items,
    .our-team-members__management-board-items,
    .our-team-members__chief-accountant-items {
      @include flex;
      flex-wrap: wrap;
      row-gap: 50px;
      column-gap: 30px;

      @include max-responsive(lg) {
        flex-wrap: nowrap;
        overflow-x: scroll;
        height: fit-content;
      }

      .our-team-members__board-of-directors-item,
      .our-team-members__management-board-item,
      .our-team-members__chief-accountant-item {
        @include column;
        max-width: 350px;

        @include max-responsive(xxl) {
          max-width: 290px;
        }

        @include max-responsive(xl) {
          max-width: 210px;
        }

        .our-team-members__board-of-directors-item-img,
        .our-team-members__management-board-item-img,
        .our-team-members__chief-accountant-item-img {
          max-width: 350px;
          max-height: 350px;
          border-radius: 20px;

          @include max-responsive(xxl) {
            max-width: 290px;
          }

          @include max-responsive(xl) {
            max-width: 210px;
          }
        }
        .our-team-members__board-of-directors-item-name,
        .our-team-members__management-board-item-name,
        .our-team-members__chief-accountant-item-name {
          color: var(--color-white);
          text-align: center;
          text-shadow: 4px 4px 27px rgba(0, 0, 0, 0.25);
          font-size: 20px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          margin: 10px 0;
        }
        .our-team-members__board-of-directors-item-position,
        .our-team-members__management-board-item-position,
        .our-team-members__chief-accountant-item-position {
          height: 42px;
          color: #a5a5a5;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .our-team-members__board-of-directors-items {
      @include max-responsive(lg) {
        height: 336px;
      }
    }
    .our-team-members__management-board-items {
      @include max-responsive(lg) {
        height: 352px;
      }
    }
    .our-team-members__chief-accountant-items {
      @include max-responsive(lg) {
        height: 380px;
      }
    }
  }
}
</style>
