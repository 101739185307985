<script setup lang="ts">
import {ref, onMounted, computed} from "vue"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useHomeProtectStore} from "@/stores/HomeProtectStore"
import {useHelpers} from "@/composables/useHelpers"
import {constants} from "@/constants/kdp"
import FFButton from "@/components/FFUI/FFButton.vue"
import WhiteCheckIcon from "@/components/icons/button-states/WhiteCheckIcon.vue"
import WhiteCrossIcon from "@/components/icons/button-states/WhiteCrossIcon.vue"

const {t} = useI18n()
const homeProtectStore = useHomeProtectStore()
const {iin, notVerificatedUsers, agreementLoaderHide, intervalIds} = storeToRefs(homeProtectStore)
const {currentLocale} = useHelpers()

const emit = defineEmits(["stepBack", "handleKdp"])

const buttonStates = ref<Array<{id: number; status: string; requestInProgress: boolean}>>([])
const timers = ref<number[]>([])

onMounted(async () => {
  buttonStates.value = notVerificatedUsers.value.map((user) => ({
    id: user.verifyClient ? constants.AGREED : constants.DEFAULT,
    status: "",
    requestInProgress: false,
  }))
  timers.value = new Array(notVerificatedUsers.value.length).fill(constants.DEFAULT)
})

const handleIntervalKdpCheck = async (index: number) => {
  if (buttonStates.value[index].requestInProgress) return

  buttonStates.value[index].requestInProgress = true
  agreementLoaderHide.value = true
  const response = await homeProtectStore.sendCode(index)
  const responseData = response?.data?.data

  if (responseData?.id === 1) {
    clearInterval(intervalIds.value[index])
    delete intervalIds.value[index]
    agreementLoaderHide.value = false
    buttonStates.value[index].id = constants.AGREED
    buttonStates.value[index].status = responseData?.status
  } else if (responseData?.id === 2) {
    buttonStates.value[index].id = constants.PENDING
  } else if (responseData?.id === 3) {
    clearInterval(intervalIds.value[index])
    delete intervalIds.value[index]
    agreementLoaderHide.value = false
    buttonStates.value[index].id = constants.DENIED
    buttonStates.value[index].status = responseData?.status
  } else if (responseData?.id === 4) {
    clearInterval(intervalIds.value[index])
    delete intervalIds.value[index]
    agreementLoaderHide.value = false
    buttonStates.value[index].id = constants.ERROR
    buttonStates.value[index].status = responseData?.status
  }

  buttonStates.value[index].requestInProgress = false
}

const handleSendCode = async (index: number) => {
  buttonStates.value[index].id = constants.PENDING
  await handleIntervalKdpCheck(index)
  intervalIds.value[index] = setInterval(async () => {
    await handleIntervalKdpCheck(index)
  }, 5000)
}

const buttonTitle = (state: number, index: number) => {
  switch (state) {
    case constants.PENDING:
      if (currentLocale.value === "ru" || currentLocale.value === "en") {
        return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${timers.value[index]} ${t("app.buttons.sendCodeAgainThrough.text-2")}`
      } else {
        return `${timers.value[index]} ${t("app.buttons.sendCodeAgainThrough.text-2")}`
      }
    case constants.DENIED:
      return t("app.buttons.sendCodeAgain")
    default:
      return t("app.buttons.sendCode")
  }
}

const allUsersAreVerificated = computed(() => {
  return buttonStates.value.every((button) => button.id === 1)
})
</script>

<template>
  <p class="agreement-form__title">{{ $t("app.homeProtectPage.forms.agreementForm.agreementToGetAndProcessData") }}</p>
  <div class="agreement-form__content">
    <div class="agreement-form__content-first-part">
      <p>{{ $t("app.homeProtectPage.forms.agreementForm.smsFrom1414") }}</p>
      <p>{{ $t("app.homeProtectPage.forms.agreementForm.givePermission") }}</p>
    </div>
    <div class="agreement-form__content-second-part">
      <template v-if="Boolean(notVerificatedUsers.length)">
        <div v-for="(data, index) in notVerificatedUsers" :key="index" class="agreement-form__second-part-block">
          <p>{{ data.iin }}</p>
          <p>{{ data.fullName }}</p>
          <div
            v-if="buttonStates[index]?.id === constants.AGREED || data.verifyClient === true"
            class="agreement-form__second-part-state-block">
            <p>{{ $t("app.buttons.confirmed") }}</p>
            <WhiteCheckIcon />
          </div>
          <div
            v-if="buttonStates[index]?.id === constants.PENDING || buttonStates[index]?.id === constants.ERROR"
            class="agreement-form__second-part-state-block">
            <p>{{ $t("app.buttons.waitingConfirmation") }}</p>
            <img class="loading-icon" src="/main-front/images/loading-gif.gif" height="20" width="20" alt="loading" />
          </div>
          <div v-if="buttonStates[index]?.id === constants.DENIED" class="agreement-form__second-part-state-block">
            <p>{{ buttonStates[index]?.status }}</p>
            <WhiteCrossIcon />
          </div>
          <FFButton
            v-if="
              !data.verifyClient &&
              buttonStates[index]?.id !== constants.AGREED &&
              buttonStates[index]?.id !== constants.PENDING &&
              buttonStates[index]?.id !== constants.ERROR
            "
            @click="handleSendCode(index)"
            :disabled="buttonStates[index]?.id === constants.PENDING"
            :title="buttonTitle(buttonStates[index]?.id, index)"
            width="100%"
            :uppercase="false" />
          <p @click="homeProtectStore.openHomeProtectAgreementPdf(data.fullName, data.iin)">
            {{ $t("app.ogpoPage.forms.downloadConsent") }}
          </p>
        </div>
      </template>
    </div>
  </div>
  <div class="agreement-form__buttons">
    <FFButton @click="emit('stepBack')" :title="t('app.buttons.back')" width="100%" :uppercase="false" />
    <FFButton
      @click="emit('handleKdp')"
      :title="t('app.buttons.next')"
      width="100%"
      :uppercase="false"
      :disabled="!allUsersAreVerificated" />
  </div>
</template>

<style scoped lang="scss">
.agreement-form__title {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.agreement-form__content {
  display: flex;
  width: 100%;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 26px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  margin: 30px 0;

  .agreement-form__content-first-part {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 26px;
    p:first-of-type {
      color: #ffffff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p:last-of-type {
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  .agreement-form__content-second-part {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    .agreement-form__second-part-block {
      padding-top: 26px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      margin-bottom: 10px;
      .agreement-form__second-part-state-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 14px 0;
        p {
          color: #ffffff;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          text-decoration: unset !important;
        }
      }
      p {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
      p:nth-child(2) {
        margin-top: 3px;
      }
      p:last-of-type {
        text-decoration: underline;
        cursor: pointer;
      }
      .ff-button-wrapper {
        margin: 14px 0;
        width: 100%;
      }
    }
  }
}
.agreement-form__buttons {
  display: flex;
  width: 100%;
  gap: 10px;
}
</style>
