<script setup lang="ts">
import {computed} from "vue"
import {useI18n} from "vue-i18n"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useModalStore} from "@/stores/ModalStore"
import {useHelpers} from "@/composables/useHelpers"
import {externalUrls} from "@/constants/urls"
import {useRoute} from "vue-router"
import FFForm from "@/components/FFUI/FFForm.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import OgpoCalculateForm from "./Forms/OgpoCalculateForm.vue"
import OgpoForms from "@/components/Ogpo/OgpoForms.vue"

const modalStore = useModalStore()

const {isDesktop} = useDeviceSize()
const {currentLocale, openLink} = useHelpers()
const route = useRoute()
const {t} = useI18n()

const handleOpenFormModal = () => {
  modalStore.openModal({
    component: OgpoForms
  })
}

const isAdmitadSource = computed(() => {
  const requiredParams = ["admitad_uid", "utm_source", "utm_medium", "utm_company", "type"]

  return requiredParams.some((param) => {
    const value = route.query[param]
    return value !== undefined && value !== null && value.toString().trim() !== ""
  })
})
</script>

<template>
  <section class="ogpo-hero">
    <div class="ogpo-hero__left-part">
      <div class="ogpo-hero__box">{{ $t("app.ogpoPage.ogpo") }}</div>
      <h1 class="ogpo-hero__title">{{ $t("app.ogpoPage.mainTitle") }}</h1>
      <p class="ogpo-hero__subtitle">{{ $t("app.ogpoPage.subTitle") }}</p>
      <template v-if="currentLocale !== 'kk' && !isAdmitadSource">
        <p class="ogpo-hero__description">{{ $t("app.ogpoPage.description-1") }}</p>
        <p class="ogpo-hero__description">{{ $t("app.ogpoPage.description-2") }}</p>
      </template>
      <template v-else>
        <p v-if="!isAdmitadSource" class="ogpo-hero__description">
          {{ $t("app.ogpoPage.description-1") }} {{ $t("app.ogpoPage.description-2") }}
        </p></template
      >
      <FFButton
        v-if="!isDesktop"
        @click="handleOpenFormModal"
        class="calculate-btn"
        :title="t('app.buttons.findOutCostInsurance')"
        maxWidth="320px"
        :uppercase="false"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        color="#ffffff"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        colorMobile="#ffffff" />
      <FFButton
        v-if="!isAdmitadSource"
        @click="openLink(externalUrls.superAppUrl, '_self')"
        :title="t('app.buttons.downloadSuperApp')"
        maxWidth="320px"
        :uppercase="false"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        color="#ffffff"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        colorMobile="#ffffff"
        iconPath="/icons-front/white-right-arrow-icon.svg" />
    </div>
    <div class="ogpo-hero__right-part">
      <FFForm :style="{top: isAdmitadSource ? '-395px' : '-497px'}">
        <OgpoCalculateForm />
      </FFForm>
    </div>
  </section>
</template>

<style scoped lang="scss">
.ogpo-hero {
  position: relative;
  padding: 82px 52px 61px;
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 197px 0 263px;

  @include max-responsive(wide) {
    margin: 152px 0 113px;
  }

  @include max-responsive(xxl) {
    margin: 162px 0 103px;
  }

  @include max-responsive(xl) {
    padding: 0;
    background: none;
    border: 0;
  }

  @include max-responsive(768px) {
    background: none;
    backdrop-filter: unset;
    border: 0;
  }

  @include max-responsive(lg) {
    margin: 100px 0 50px;
  }

  .ogpo-hero__left-part {
    .ogpo-hero__box {
      display: inline-block;
      color: #ffffff;
      text-transform: uppercase;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 11.048px;
      text-transform: uppercase;

      border-radius: 2.053px;
      background: #f2994a;
      padding: 5px 10px;
    }
    .ogpo-hero__title {
      font-size: 60px;
      font-style: normal;
      font-weight: 900;
      line-height: 60px;
      max-width: 753px;
      @include main-green-gradient;
      margin: 20px 0px;

      @include max-responsive(lg) {
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
      }
    }
    .ogpo-hero__subtitle {
      color: #ffffff;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: 30px;
      margin-bottom: 20px;
      max-width: 500px;

      @include max-responsive(lg) {
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-bottom: 30px;
      }
    }
    .ogpo-hero__description {
      color: #ffffff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      opacity: 0.8;
      max-width: 589px;
    }
    .ogpo-hero__description:last-of-type {
      margin-bottom: 50px;
    }
    .calculate-btn {
      margin-bottom: 20px;
    }
  }

  .ogpo-hero__right-part {
    position: relative;

    @include max-responsive(xl) {
      display: none;
    }

    .ff-form {
      position: absolute;
      right: -53px;
      top: -497px;
      padding: 30px 8px 15px 15px;
    }
  }
}
</style>
