<script setup lang="ts">
import {ref, computed} from "vue"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useKaskoStore} from "@/stores/KaskoStore"
import {useModalStore} from "@/stores/ModalStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useHelpers} from "@/composables/useHelpers"
import {whatsAppKaskoMessageRu, whatsAppKaskoMessageKk, whatsAppKaskoMessageEn} from "@/constants/whatsAppMessages"
import {externalUrls} from "@/constants/urls"
import FFForm from "@/components/FFUI/FFForm.vue"
import KaskoAgreementForm from "@/components/Kasko/Forms/KaskoAgreementForm.vue"
import KaskoCalculateForm from "@/components/Kasko/Forms/KaskoCalculateForm.vue"
import KaskoTariffsForm from "@/components/Kasko/Forms/KaskoTariffsForm.vue"
import KaskoExecutationForm from "@/components/Kasko/Forms/KaskoExecutationForm.vue"
import KaskoPaymentMethodForm from "@/components/Kasko/Forms/KaskoPaymentMethodForm.vue"
import KaskoKaspiPaymentForm from "@/components/Kasko/Forms/KaskoKaspiPaymentForm.vue"
import SuperAppQrBlock from "@/components/shared/SuperAppQrBlock.vue"
import CloseIcon from "@/components/icons/socials/CloseIcon.vue"
import ModalHeader from "@/components/FFUI/ModalHeader.vue"
import WhatsAppIcon from "@/components/icons/WhatsAppIcon.vue"
import FFBackdropSheet from "@/components/FFUI/FFBackdropSheet.vue"

const kaskoStore = useKaskoStore()
const modalStore = useModalStore()
const helperStore = useHelperStore()
const {
  currentStep,
  sliderStep,
  needKdp,
  allUsersAreVerificated,
  showKaspiForm,
  showSuperAppBlock,
  agreementLoaderHide,
  paymentUrl,
  currentTariff
} = storeToRefs(kaskoStore)
const {isSheetVisible} = storeToRefs(helperStore)
const {isDesktop, isMobileDevice} = useDeviceSize()
const {openLink, currentLocale} = useHelpers()

const {t} = useI18n()

const cantScanQr = ref<boolean>(false)

const handleStepBack = () => {
  if (currentStep.value === 4 && showKaspiForm.value && !showSuperAppBlock.value) {
    showKaspiForm.value = false
  } else if (currentStep.value === 4 && !showKaspiForm.value && showSuperAppBlock.value && !cantScanQr.value) {
    showSuperAppBlock.value = false
  } else if (currentStep.value === 4 && !showKaspiForm.value && showSuperAppBlock.value && cantScanQr.value) {
    cantScanQr.value = false
  } else if (currentStep.value === 3) {
    kaskoStore.handleStepBack()
  } else if (currentStep.value === 2 && needKdp.value && isMobileDevice.value) {
    kaskoStore.handleStepBack()
    sliderStep.value = 0
    agreementLoaderHide.value = false
    kaskoStore.clearAllIntervals()
  } else if (currentStep.value === 2 && needKdp.value && isDesktop.value) {
    modalStore.closeModal()
    sliderStep.value = 0
    kaskoStore.clearAllIntervals()
  } else if (currentStep.value === 2 && !needKdp.value && isDesktop.value) {
    modalStore.closeModal()
    kaskoStore.handleStepBack()
    sliderStep.value = 0
    kaskoStore.clearAllIntervals()
  } else if (currentStep.value === 2 && !needKdp.value && !isDesktop.value) {
    kaskoStore.handleStepBack()
    sliderStep.value = 0
    kaskoStore.clearAllIntervals()
  } else {
    kaskoStore.handleStepBack()
  }
}

const handleProblemsWithScan = (value: boolean) => {
  cantScanQr.value = value
}

const descriptionNotBankClient = computed(() => {
  if (currentStep.value === 2) {
    if (currentLocale.value === "kk") {
      return `
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-3")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-4")}
  `
    } else {
      return `
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}
  `
    }
  } else {
    if (currentLocale.value === "kk") {
      return `
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}
    <span id="link" class="underline cursor-pointer">${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-3")}</span>
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-4")}
  `
    } else {
      return `
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
    <span id="link" class="underline cursor-pointer">${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}</span>
  `
    }
  }
})
</script>

<template>
  <FFForm class="calc-form">
    <CloseIcon v-if="currentStep === 1 && !isDesktop" class="ff-form-close" @click="modalStore.closeModal" />
    <ModalHeader
      v-if="
        ((currentStep === 2 || currentStep === 3 || currentStep === 4) && allUsersAreVerificated && needKdp) ||
        ((currentStep === 2 || currentStep === 3 || currentStep === 4) && !needKdp)
      "
      :title="
        currentStep === 3 || currentStep === 4 ? t('form.formHeaders.payment') : t('form.formHeaders.chooseTariff')
      "
      :currentStep="currentStep"
      @stepBack="handleStepBack"
      :totalSteps="4" />
    <KaskoCalculateForm v-if="currentStep === 1" />
    <KaskoAgreementForm
      v-if="currentStep === 2 && needKdp && !allUsersAreVerificated"
      @handleKdp="kaskoStore.handleKdp"
      @stepBack="handleStepBack" />
    <KaskoTariffsForm
      v-if="(currentStep === 2 && needKdp && allUsersAreVerificated) || (currentStep === 2 && !needKdp)" />
    <KaskoExecutationForm v-if="currentStep === 3" />
    <KaskoPaymentMethodForm v-if="currentStep === 4 && !showKaspiForm && !showSuperAppBlock" />
    <KaskoKaspiPaymentForm v-if="currentStep === 4 && showKaspiForm" />
    <SuperAppQrBlock
      v-if="currentStep === 4 && showSuperAppBlock && !showKaspiForm"
      :url="paymentUrl"
      @cantScan="handleProblemsWithScan"
      :cantScanQr="cantScanQr"
      :orderId="currentTariff.orderId"
      level="Q">
      <template #super-app-qr-block__title-first-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-1") }}
      </template>
      <template #super-app-qr-block__title-second-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-2") }}
      </template>
    </SuperAppQrBlock>
    <div
      v-if="currentStep !== 1 && !needKdp"
      class="data-ask"
      @click="
        openLink(
          `https://api.whatsapp.com/send?phone=77788700014&text=${currentLocale === 'ru' ? whatsAppKaskoMessageRu : currentLocale === 'kk' ? whatsAppKaskoMessageKk : whatsAppKaskoMessageEn}`,
          '_blank'
        )
      ">
      <WhatsAppIcon />
      <p>{{ $t("app.paymentPage.askQuestion") }}</p>
    </div>
    <FFBackdropSheet
      v-model:isVisible="isSheetVisible"
      :isSheet="!isDesktop"
      :descriptionNotBankClient="descriptionNotBankClient"
      :url="externalUrls.superAppUrl">
      <template #title>{{ $t("app.freedomBank.cashback.notBankClient.bottomSheet.title") }}</template>
    </FFBackdropSheet>
  </FFForm>
</template>

<style scoped lang="scss">
.ff-form {
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.63);
  }

  .data-ask {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 1;
    margin-top: 30px;
    cursor: pointer;
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #ffffff;
    }
  }
}
.ff-form-close {
  position: absolute;
  top: 30px;
  left: 30px;
}
</style>
