import {FreedomPay, FreedomSuperApp, KaspiPay} from "@/constants/payment"
import {computed, ref} from "vue"

import FreedomPayNoTextIcon from "@/components/icons/payment/FreedomPayNoTextIcon.vue"
import type {IPayment} from "@/interfaces/Payment/IPayment"
import KaspiIcon from "@/components/icons/payment/KaspiIcon.vue"
import {defineStore} from "pinia"
import {useI18n} from "vue-i18n"
import {useRoute} from "vue-router"

export const usePaymentStore = defineStore("usePaymentStore", () => {
  const {t} = useI18n()
  const selectedPayment = ref<string>("")
  const route = useRoute()

  const paymentMethodsWithSuperApp = ref([
    {
      id: 1,
      icon: FreedomPayNoTextIcon,
      type: FreedomSuperApp,
      name: computed(() => t("form.paymentMethods.freedomSuperApp")),
      subText: computed(() => t("form.paymentMethods.freedomSuperApp-sub"))
    },
    {
      id: 2,
      icon: FreedomPayNoTextIcon,
      type: FreedomPay,
      name: computed(() => t("form.paymentMethods.freedomPay")),
      subText: computed(() => t("form.paymentMethods.freedomPay-sub"))
    },
    {
      id: 3,
      icon: KaspiIcon,
      type: KaspiPay,
      name: computed(() => t("form.paymentMethods.kaspiKz"))
    }
  ])

  const paymentMethodsWithoutSuperApp = ref([
    {
      id: 1,
      icon: FreedomPayNoTextIcon,
      type: FreedomPay,
      name: computed(() => t("form.paymentMethods.freedomPay")),
      subText: computed(() => t("form.paymentMethods.freedomPay-sub"))
    },
    {
      id: 2,
      icon: KaspiIcon,
      type: KaspiPay,
      name: computed(() => t("form.paymentMethods.kaspiKz"))
    }
  ])

  const paymentMethodOnlyFreedomPay = ref([
    {
      id: 1,
      icon: FreedomPayNoTextIcon,
      type: FreedomPay,
      name: computed(() => t("form.paymentMethods.freedomPay")),
      subText: computed(() => t("form.paymentMethods.freedomPay-sub"))
    }
  ])

  const showKaspiForm = ref<boolean>(false)

  const handleCurrentMethod = (type: string): void => {
    selectedPayment.value = type
  }

  return {
    selectedPayment,
    paymentMethodsWithSuperApp,
    paymentMethodsWithoutSuperApp,
    paymentMethodOnlyFreedomPay,
    showKaspiForm,
    handleCurrentMethod
  }
})
