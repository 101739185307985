<script setup lang="ts">
import {ref, computed} from "vue"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useModalStore} from "@/stores/ModalStore"
import {useOgpoStore} from "@/stores/OgpoStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useHelpers} from "@/composables/useHelpers"
import {pushGtagEvent} from "@/utils/pushGtagEvent"
import {whatsAppOgpoMessageRu, whatsAppOgpoMessageKk, whatsAppOgpoMessageEn} from "@/constants/whatsAppMessages"
import {externalUrls} from "@/constants/urls"
import FFForm from "@/components/FFUI/FFForm.vue"
import CloseIcon from "@/components/icons/socials/CloseIcon.vue"
import FFBackdropSheet from "@/components/FFUI/FFBackdropSheet.vue"
import ModalHeader from "@/components/FFUI/ModalHeader.vue"
import WhatsAppIcon from "@/components/icons/WhatsAppIcon.vue"
import OgpoCalculateForm from "@/components/Ogpo/Forms/OgpoCalculateForm.vue"
import OgpoAgreementForm from "@/components/Ogpo/Forms/OgpoAgreementForm.vue"
import OgpoExecutionForm from "@/components/Ogpo/Forms/OgpoExecutionForm.vue"
import OgpoPaymentMethodForm from "@/components/Ogpo/Forms/OgpoPaymentMethodForm.vue"
import OgpoKaspiPaymentForm from "@/components/Ogpo/Forms/OgpoKaspiPaymentForm.vue"
import OgpoIdentificationCardForm from "@/components/Ogpo/Forms/OgpoIdentificationCardForm.vue"
import SuperAppQrBlock from "@/components/shared/SuperAppQrBlock.vue"

const helperStore = useHelperStore()
const modalStore = useModalStore()
const ogpoStore = useOgpoStore()

const {
  currentStep,
  phone,
  needKdp,
  driverLicenseNotFound,
  calculationObject,
  calculationObjectWhenOgpoPlus,
  addOgpoPlus,
  identificationCardNotFound,
  allUsersAreVerificated,
  showKaspiForm,
  showSuperAppBlock,
  agreementLoaderHide,
  paymentUrl,
  isScoring
} = storeToRefs(ogpoStore)
const {isSheetVisible} = storeToRefs(helperStore)
const {isDesktop, isMobileDevice} = useDeviceSize()
const {currentLocale, openLink} = useHelpers()
const {t} = useI18n()

const cantScanQr = ref<boolean>(false)

const handleCloseModal = () => {
  modalStore.closeModal()
  ogpoStore.resetStep()
  agreementLoaderHide.value = false
}

const handleStepBack = () => {
  if (currentStep.value === 3 && showKaspiForm.value && !showSuperAppBlock.value) {
    showKaspiForm.value = false
    isSheetVisible.value = false
  } else if (currentStep.value === 3 && !showKaspiForm.value && showSuperAppBlock.value && !cantScanQr.value) {
    showSuperAppBlock.value = false
    isSheetVisible.value = false
  } else if (currentStep.value === 3 && !showKaspiForm.value && showSuperAppBlock.value && cantScanQr.value) {
    cantScanQr.value = false
    isSheetVisible.value = false
  } else if (currentStep.value === 2 && needKdp.value && isMobileDevice.value) {
    ogpoStore.handleStepBack()
    agreementLoaderHide.value = false
    ogpoStore.clearAllIntervals()
    isSheetVisible.value = false
  } else if (currentStep.value === 2 && needKdp.value && isDesktop.value) {
    handleCloseModal()
    ogpoStore.clearAllIntervals()
    isSheetVisible.value = false
  } else if (currentStep.value === 2 && !needKdp.value && isDesktop.value) {
    modalStore.closeModal()
    ogpoStore.handleStepBack()
    ogpoStore.clearAllIntervals()
    isSheetVisible.value = false
  } else if (currentStep.value === 1 && identificationCardNotFound.value && isDesktop.value) {
    currentStep.value = 1
    identificationCardNotFound.value = false
    modalStore.closeModal()
    ogpoStore.clearAllIntervals()
    isSheetVisible.value = false
  } else if (currentStep.value === 1 && identificationCardNotFound.value && !isDesktop.value) {
    currentStep.value = 1
    identificationCardNotFound.value = false
    ogpoStore.clearAllIntervals()
    isSheetVisible.value = false
  } else {
    ogpoStore.handleStepBack()
    isSheetVisible.value = false
  }
}

const handleAskQuestion = () => {
  if (currentStep.value === 2) {
    pushGtagEvent("zadat_vopros_step2")
  } else {
    pushGtagEvent("zadat_vopros_step3")
  }
  openLink(
    `https://api.whatsapp.com/send?phone=77788700014&text=${
      currentLocale.value === "ru"
        ? whatsAppOgpoMessageRu
        : currentLocale.value === "kk"
          ? whatsAppOgpoMessageKk
          : whatsAppOgpoMessageEn
    }`,
    "_blank"
  )
}

const handleProblemsWithScan = (value: boolean) => {
  cantScanQr.value = value
}

const descriptionNotBankClient = computed(() => {
  if (currentStep.value === 2) {
    if (currentLocale.value === "kk") {
      return `
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-3")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-4")}
  `
    } else {
      return `
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}
  `
    }
  } else {
    if (currentLocale.value === "kk") {
      return `
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}
    <span id="link" class="underline cursor-pointer">${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-3")}</span>
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-4")}
  `
    } else {
      return `
    ${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-1")}
    <span id="link" class="underline cursor-pointer">${t("app.freedomBank.cashback.notBankClient.bottomSheet.description-2")}</span>
  `
    }
  }
})

const dynamicOrderId = computed(() => {
  return addOgpoPlus.value
    ? String(calculationObjectWhenOgpoPlus?.value?.orderId)
    : String(calculationObject?.value?.orderId)
})
</script>

<template>
  <FFForm>
    <CloseIcon
      v-if="currentStep === 1 && !driverLicenseNotFound && !identificationCardNotFound"
      class="ff-form-close"
      @click="handleCloseModal" />
    <ModalHeader
      v-if="
        ((currentStep === 2 || currentStep === 3) && allUsersAreVerificated && needKdp) ||
        ((currentStep === 2 || currentStep === 3) && !needKdp)
      "
      :title="currentStep === 3 ? t('form.formHeaders.paymentMethod') : t('form.formHeaders.execution')"
      @stepBack="handleStepBack"
      :currentStep="currentStep"
      :totalSteps="3" />
    <OgpoCalculateForm v-if="currentStep === 1 && !driverLicenseNotFound && !identificationCardNotFound" />
    <OgpoIdentificationCardForm
      v-if="currentStep === 1 && identificationCardNotFound"
      @handleKdp="ogpoStore.handleKdp"
      @stepBack="handleStepBack" />
    <OgpoAgreementForm
      v-if="currentStep === 2 && needKdp && !allUsersAreVerificated"
      @handleKdp="ogpoStore.handleKdp"
      @stepBack="handleStepBack" />
    <OgpoExecutionForm
      v-if="(currentStep === 2 && needKdp && allUsersAreVerificated) || (currentStep === 2 && !needKdp)"
      @nextStep="ogpoStore.handleStepNext" />
    <OgpoPaymentMethodForm v-if="currentStep === 3 && !showKaspiForm && !showSuperAppBlock" />
    <OgpoKaspiPaymentForm v-if="currentStep === 3 && showKaspiForm && !showSuperAppBlock" />
    <SuperAppQrBlock
      v-if="currentStep === 3 && showSuperAppBlock && !showKaspiForm"
      :url="paymentUrl"
      @cantScan="handleProblemsWithScan"
      :cantScanQr="cantScanQr"
      :orderId="dynamicOrderId"
      level="Q">
      <template #super-app-qr-block__title-first-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-1") }}
      </template>
      <template #super-app-qr-block__title-second-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-2") }}
      </template>
    </SuperAppQrBlock>
    <div v-if="currentStep !== 1" class="data-ask" @click="handleAskQuestion">
      <WhatsAppIcon />
      <p>{{ $t("app.paymentPage.askQuestion") }}</p>
    </div>
    <FFBackdropSheet
      v-model:isVisible="isSheetVisible"
      :isSheet="!isDesktop"
      :descriptionNotBankClient="descriptionNotBankClient"
      :url="externalUrls.superAppUrl">
      <template #title>{{ $t("app.freedomBank.cashback.notBankClient.bottomSheet.title") }}</template>
    </FFBackdropSheet>
  </FFForm>
</template>

<style scoped lang="scss">
.ff-form {
  position: relative;
  .data-ask {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    z-index: 1;
    cursor: pointer;
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #ffffff;
    }
  }
}
.ff-form-close {
  position: absolute;
  top: 28px;
  left: 21px;
}
</style>
