<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"

const {currentLocale} = useHelpers()
</script>

<template>
  <div class="ogpo-plus-statistics__images">
    <img
      class="ogpo-plus-statistics__statistics-first-image"
      src="/ogpo-front/images/statistics-first.png"
      data-src="/ogpo-front/images/statistics-first.png"
      alt=""
      draggable="false" />
    <img
      class="ogpo-plus-statistics__statistics-second-image"
      src="/ogpo-front/images/statistics-second.png"
      data-src="/ogpo-front/images/statistics-second.png"
      alt=""
      draggable="false" />
  </div>
  <section class="ogpo-plus-statistics">
    <p class="ogpo-plus-statistics__title">{{ $t("app.ogpoPage.statistics.mainTitle") }}</p>
    <div class="ogpo-plus-statistics__cards">
      <div class="ogpo-plus-statistics__card">
        <p>{{ $t("app.ogpoPage.statistics.title-1") }}</p>
        <p>202 307</p>
        <p>{{ $t("app.ogpoPage.statistics.subtitle-1") }}</p>
      </div>
      <div class="ogpo-plus-statistics__card">
        <p>{{ $t("app.ogpoPage.statistics.title-2") }}</p>
        <p>28 446</p>
        <p>{{ $t("app.ogpoPage.statistics.subtitle-2") }}</p>
      </div>
      <div class="ogpo-plus-statistics__card">
        <p>{{ $t("app.ogpoPage.statistics.title-3") }}</p>
        <div>
          <p>6,5</p>
          <div class="mlrd">
            <template v-if="currentLocale === 'en'">
              <p>{{ $t("app.ogpoPage.statistics.billion") }}</p>
              <p>{{ $t("app.ogpoPage.statistics.tenge") }}</p>
            </template>
            <template v-else>
              <p>млрд</p>
              <p>тг.</p>
            </template>
          </div>
        </div>
        <p>{{ $t("app.ogpoPage.statistics.subtitle-3") }}</p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.ogpo-plus-statistics {
  position: relative;
  background: rgba(74, 74, 74, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border-radius: 30px;
  margin-bottom: 588px;
  z-index: 2;

  @include min-responsive(xxlWide) {
    padding: 115px 0 60px;
  }

  @include max-responsive(xxlWide) {
    padding: 115px 0 60px;
  }

  @include max-responsive(wide) {
    padding: 87px 76px 87px 103px;
  }

  @include max-responsive(xxl) {
    padding: 50px 30px;
  }

  @include max-responsive(xl) {
    padding: 50px 0 40px;
  }

  @include max-responsive(lg) {
    padding: 50px 30px;
    margin-bottom: 511px;
  }

  @include max-responsive(md) {
    padding: 30px 5px;
    margin-bottom: 300px;
  }

  .ogpo-plus-statistics__title {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @include main-green-gradient;

    @include max-responsive(lg) {
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }

  .ogpo-plus-statistics__cards {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @include max-responsive(xl) {
      @include column;
    }

    .ogpo-plus-statistics__card {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 330px;

      @include max-responsive(xl) {
        width: 100%;
      }

      &:nth-child(1) {
        @include max-responsive(xl) {
          padding-bottom: 30px;
        }
      }

      &:nth-child(2) {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        padding: 0 30px;

        @include max-responsive(xl) {
          border-right: 0;
          border-left: 0;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          padding: 30px 0 30px;
        }
      }

      &:last-of-type {
        justify-content: start;

        @include max-responsive(xl) {
          padding-top: 30px;
        }
      }
      p:first-of-type {
        color: #ffffff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
      }
      p:nth-child(2) {
        text-align: center;
        font-size: 50px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        @include main-green-gradient;

        height: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p:last-of-type {
        color: #d6d6d6;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        height: fit-content;

        @include max-responsive(lg) {
          font-weight: 500;
        }
      }
      div {
        display: flex;
        align-items: center;
        margin: 0 auto;
        p:first-of-type {
          text-align: center;
          font-size: 70px !important;
          font-style: normal;
          font-weight: 900 !important;
          line-height: normal;
          @include main-green-gradient;
        }

        .mlrd {
          display: flex;
          flex-direction: column;
          p {
            font-size: 30px !important;
            font-style: normal;
            font-weight: 900 !important;
            line-height: normal;
            text-transform: lowercase;
          }
        }
      }
    }
  }
}

.ogpo-plus-statistics__images {
  position: relative;

  @include max-responsive(lg) {
    display: none;
  }

  .ogpo-plus-statistics__statistics-first-image {
    position: absolute;
    width: 279.446px;
    height: 270px;
    top: -90px;
    left: 320px;
    z-index: 0;

    @include max-responsive(xxl) {
      position: absolute;
      width: 179.446px;
      height: 170px;
      top: -58px;
      left: 337px;
      z-index: 0;
    }

    @include max-responsive(xl) {
      position: absolute;
      width: 179.446px;
      height: 170px;
      top: -58px;
      left: 205px;
      z-index: 0;
    }
  }
  .ogpo-plus-statistics__statistics-second-image {
    position: absolute;
    width: 261.726px;
    height: 253.382px;
    top: -88px;
    right: 323px;

    @include max-responsive(xxl) {
      position: absolute;
      width: 161.726px;
      height: 153.382px;
      top: -45px;
      right: 297px;
    }

    @include max-responsive(xl) {
      position: absolute;
      width: 161.726px;
      height: 153.382px;
      top: -45px;
      right: 189px;
    }
  }
}
</style>
