import {defineStore} from "pinia"
import {ref} from "vue"
import {useAuthStore} from "./AuthStore"
import {useFetcher} from "@/composables/useFetcher"
import {useFormatters} from "@/utils/formatters"
import {useHelpers} from "@/composables/useHelpers"

interface IInsuredList {
  iin: String
  passportNumb: String
  passportIssueDate: String
  isResident: Boolean
  firstName: String
  lastName: String
  middleName: String
  firstNameEng: String
  lastNameEng: String
  birthDate: String
  gender: String
  checked: Boolean
}

export const useMSTStore = defineStore("mstStore", () => {
  const authStore = useAuthStore()
  const {postData, loading} = useFetcher()
  const {phoneNumberFormatter} = useFormatters()
  const {openLink} = useHelpers()

  const calculateForm = ref({
    id: "random",
    dateStart: {},
    dateStop: {},
    aimTravel: {},
    amount: {},
    ageTravels: [],
    countries: [],
    sportId: {},
    insuranceSumId: {},
    activeTravel: false,
    manyTravel: false
  })
  const amountList = ref([])
  const calculatedAmount = ref(null)
  const insuredList = ref<IInsuredList[]>([])
  const email = ref("")
  const kaspiPayPhoneNumber = ref<string>("")
  const contractDate = ref()

  const updateAmountList = (newList: []) => {
    amountList.value = newList
  }

  const updateCalculatedAmount = (content: any) => {
    calculatedAmount.value = content
  }

  const updateCalculateForm = (form: any) => {
    calculateForm.value = form
  }

  const resetCalculationForm = () => {
    calculateForm.value = {
      id: "random",
      dateStart: {},
      dateStop: {},
      aimTravel: {},
      amount: {},
      ageTravels: [],
      countries: [],
      sportId: {},
      insuranceSumId: {},
      activeTravel: false,
      manyTravel: false
    }
  }

  const postQuestionnaireData = async () => {
    const formatedInsuredList = insuredList.value.map((insured) => ({
      ...insured.iin,
      insSumId: calculateForm.value.insuranceSumId
    }))

    const questionnaireDataObj = {
      holderIin: authStore.credentials.identifier,
      holderEmail: email.value,
      insSumId: calculateForm.value.insuranceSumId,
      holderPhoneNumb: phoneNumberFormatter(authStore.credentials.phone),
      fromDate: calculateForm.value.dateStart,
      tillDate: calculateForm.value.dateStop,
      isPublic: true,
      countries: calculateForm.value.countries,
      aimTravel: calculateForm.value.aimTravel,
      activeTravel: calculateForm.value.activeTravel,
      insureds: formatedInsuredList
    }

    try {
      const response = await postData("/core/mst/questionnaire", questionnaireDataObj)
      if (response && response.data && response.data.data) {
        const binaryData = atob(response.data.data)

        const arrayBuffer = new Uint8Array(binaryData.length)
        for (let i = 0; i < binaryData.length; i++) {
          arrayBuffer[i] = binaryData.charCodeAt(i)
        }

        const blob = new Blob([arrayBuffer], {type: "application/pdf"})

        const pdfUrl = URL.createObjectURL(blob)

        openLink(pdfUrl, "_blank")
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    calculateForm,
    amountList,
    calculatedAmount,
    insuredList,
    email,
    kaspiPayPhoneNumber,
    updateAmountList,
    updateCalculatedAmount,
    updateCalculateForm,
    resetCalculationForm,
    postQuestionnaireData
  }
})
