<script setup lang="ts">
import {ref, computed} from "vue"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useHelperStore} from "@/stores/HelperStore"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"
import JuridicalHero from "@/components/JuridicalPages/JuridicalHero.vue"
import JuridicalUniqueAbilities from "@/components/JuridicalPages/JuridicalUniqueAbilities.vue"
import JuridicalInsuranceConditions from "@/components/JuridicalPages/JuridicalInsuranceConditions.vue"
import AboutCompanyStatistics from "@/components/AboutCompany/AboutCompanyStatistics.vue"
import JuridicalGetPolicyBeCalm from "@/components/JuridicalPages/JuridicalGetPolicyBeCalm.vue"
import JuridicalForm from "@/components/JuridicalPages/JuridicalForm.vue"
import DarkGradientGreenUserProfileIcon from "@/components/icons/greens/DarkGradientGreenUserProfileIcon.vue"
import DarkGradientGreenStarShieldIcon from "@/components/icons/greens/DarkGradientGreenStarShieldIcon.vue"
import DarkGradientGreenStopwatch from "@/components/icons/greens/DarkGradientGreenStopwatch.vue"

const helperStore = useHelperStore()
const {loading} = storeToRefs(helperStore)
const {t} = useI18n()

const juridicalInsuranceConditions = ref([
  {
    name: computed(() =>
      t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.name1")
    ),
    text1: computed(() =>
      t(
        "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list1.list-title1"
      )
    ),
    text2: computed(() =>
      t(
        "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list1.list-title2"
      )
    )
  },
  {
    name: computed(() =>
      t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.name2")
    ),
    sectionList1: {
      name: computed(() =>
        t(
          "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-title1"
        )
      ),
      list: [
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item1"
            )
          )
        },
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item2"
            )
          )
        },
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item3"
            )
          )
        },
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item4"
            )
          )
        },
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item5"
            )
          )
        },
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item6"
            )
          )
        },
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item7"
            )
          )
        },
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item8"
            )
          )
        }
      ]
    },

    sectionList2: {
      name: computed(() =>
        t(
          "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-title2"
        )
      ),
      list: [
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item9"
            )
          )
        },
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item10"
            )
          )
        },
        {
          item: computed(() =>
            t(
              "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.list2.list-item11"
            )
          )
        }
      ]
    }
  },
  {
    name: computed(() =>
      t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.name3")
    ),
    text: computed(() =>
      t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.text3")
    )
  }
])
</script>

<template>
  <Modal style="z-index: 101" :isOpen="loading">
    <Loader />
  </Modal>

  <JuridicalHero
    heroBackgroundImage="url(/juridical-pages-front/images/specializirovannye-vidy-strakhovaniya/strakhovanie-neftyanykh-operatsiy-hero-bg.png)"
    heroBackgroundImageMobile="/juridical-pages-front/images/specializirovannye-vidy-strakhovaniya/strakhovanie-neftyanykh-operatsiy-hero-bg-mobile.png">
    <template #title>{{
      $t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.hero.title")
    }}</template>
    <template #description-1>{{
      $t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.hero.description")
    }}</template>
  </JuridicalHero>

  <JuridicalUniqueAbilities>
    <template #title>{{
      $t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.uniqueAbilities.title")
    }}</template>

    <template #icon1><DarkGradientGreenStarShieldIcon /></template>
    <template #name1>{{
      $t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.uniqueAbilities.name1")
    }}</template>
    <template #description1>{{
      $t(
        "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.uniqueAbilities.description1"
      )
    }}</template>

    <template #icon2><DarkGradientGreenUserProfileIcon /></template>
    <template #name2>{{
      $t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.uniqueAbilities.name2")
    }}</template>
    <template #description2>{{
      $t(
        "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.uniqueAbilities.description2"
      )
    }}</template>

    <template #icon3><DarkGradientGreenStopwatch /></template>
    <template #name3>{{
      $t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.uniqueAbilities.name3")
    }}</template>
    <template #description3>{{
      $t(
        "juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.uniqueAbilities.description3"
      )
    }}</template>
  </JuridicalUniqueAbilities>

  <JuridicalInsuranceConditions :items="juridicalInsuranceConditions">
    <template #title>{{
      $t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.insuranceConditions.title")
    }}</template>
    <template #content="{item, index}">
      <div v-if="item.list" class="list">
        <ul>
          <li v-for="(listItem, listIndex) in item.list" :key="listIndex">
            {{ listItem.item }}
          </li>
        </ul>
      </div>
      <div v-if="item.sectionList1" class="section-list">
        <p>{{ item.sectionList1.name }}</p>
        <ul>
          <li v-for="(listItem, listIndex) in item.sectionList1.list" :key="listIndex">
            {{ listItem.item }}
          </li>
        </ul>
      </div>
      <div v-if="item.sectionList2" class="section-list">
        <p>{{ item.sectionList2.name }}</p>
        <ul>
          <li v-for="(listItem, listIndex) in item.sectionList2.list" :key="listIndex">
            {{ listItem.item }}
          </li>
        </ul>
      </div>
      <div v-else class="texts">
        <p v-if="item.text">{{ item.text }}</p>
        <p v-if="item.text1">{{ item.text1 }}</p>
        <p v-if="item.text2">{{ item.text2 }}</p>
      </div>
    </template>
  </JuridicalInsuranceConditions>

  <div class="statistics">
    <AboutCompanyStatistics />
  </div>

  <JuridicalGetPolicyBeCalm
    :needDescription2="true"
    imagePath="/juridical-pages-front/images/specializirovannye-vidy-strakhovaniya/strakhovanie-neftyanykh-operatsiy-bottom-bg.png">
    <template #title>
      {{
        $t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.beCalm.title")
      }}</template
    >
    <template #description
      >{{ $t("juridical.specializirovannyeVidyStrakhovaniya.strakhovanieNeftyanykhOperatsiyPage.beCalm.description") }}
    </template>
  </JuridicalGetPolicyBeCalm>

  <JuridicalForm policyName="oilOperations" />
</template>

<style scoped lang="scss">
.statistics {
  margin: 150px 0 0;
  .about-company-statistics {
    margin-bottom: 0;
  }
}

.list {
  ul {
    li {
      position: relative;
      padding-left: 15px;
      color: #dbdbdb;
      font-weight: 300;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &::before {
        content: "•";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.section-list {
  p {
    color: #dbdbdb;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  ul {
    margin: 10px 20px;
    li {
      position: relative;
      padding-left: 15px;
      color: #dbdbdb;
      font-weight: 300;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &::before {
        content: "•";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.texts {
  @include column;
  gap: 10px;
  p {
    color: #dbdbdb;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.4px;
  }
}
</style>
