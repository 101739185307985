import {ref, watch, computed, shallowRef} from "vue"
import {useI18n} from "vue-i18n"
import {defineStore} from "pinia"
import {useToast} from "vue-toastification"
import {debounce} from "lodash-es"
import * as XLSX from "xlsx"
import zod from "zod"
import {useHelpers} from "@/composables/useHelpers"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useFormatters} from "@/utils/formatters"
import type {ICalcObj} from "@/interfaces/OgpoJuridical/ICalcObj"
import VOtpInput from "vue3-otp-input"

export const useOgpoJuridicalStore = defineStore("ogpoJuridicalStore", () => {
  const {loading, getData, postData} = useGatewayFetcher()
  const {currentLocale} = useHelpers()
  const {t} = useI18n()
  const toast = useToast()
  const {phoneNumberFormatter, dateFormatter} = useFormatters()

  const currentStep = ref<number>(1)

  //Calculation form
  const activeTab = ref<number>(1)

  const phone = ref<string>("")
  const bin = ref<string>("")
  const binName = ref<string>("")
  const isBinLoadingState = ref<boolean>(false)

  const autonumtechpass = ref<string>("")
  const autonumtechpassInputStates = ref<string[]>([])
  const autonumDuplicateMessage = ref<string>("")

  const carName = ref<string>("")
  const carNames = ref<string[]>([])
  const carsYearOfIssue = ref<{regNum: string; year: number}[]>([])

  const isAutoNumsLoadingState = ref<boolean>(false)
  const isAutoNumsLoadingStates = ref<boolean[]>([])

  const dateStart = ref(new Date(new Date().setDate(new Date().getDate() + 1)))
  const month = ref({name: computed(() => t("form.dropdown.monthes.month-12")), value: 12})
  const monthes = ref([{name: computed(() => t("form.dropdown.monthes.month-12")), value: 12}])

  const xlsxFile = ref<null>(null)

  const binCheck = debounce(async (data: string) => {
    if (!data) {
      binName.value = ""
      return
    }

    isBinLoadingState.value = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (data.length === 12) {
        const response = await postData(
          "/policy/juridical-ogpo/check/company-bin",
          {
            bin: data
          },
          config
        )
        if (response && response.data && response.status === 200) {
          binName.value = response.data
        } else {
          binName.value = ""
          bin.value = ""
        }
      } else {
        binName.value = ""
      }
      isBinLoadingState.value = false
    } catch (error) {
      bin.value = ""
      binName.value = ""
      isBinLoadingState.value = false
      console.error(error)
    }
  }, 500)

  const isCarNumberFilled = computed((): boolean => {
    if (autonumtechpass.value.length >= 3 && autonumtechpassInputStates.value.length === 0) {
      return true
    } else if (
      autonumtechpass.value.length >= 3 &&
      autonumtechpassInputStates.value.every((state) => state.length >= 3)
    ) {
      return true
    } else {
      return false
    }
  })

  const pushInputToAutonumtechpassArr = (): void => {
    if (autonumtechpass.value === "" && carName.value === "") {
      toast.clear()
      toast.error(t("app.toasterMessages.carNumCheck.currentNumberCannotBeEmpty"))
      return
    }

    if (!autoNumberMask.test(autonumtechpass.value)) {
      toast.clear()
      toast.error(t("app.toasterMessages.carNumCheck.wrongFormatOfCarNumber"))
      return
    }

    if (autonumtechpassInputStates.value.length >= 5) {
      toast.clear()
      toast.error(t("app.toasterMessages.carNumCheck.cannotAddMoreThanFiveNums"))
      return
    }

    if (
      autonumtechpassInputStates.value.length > 0 &&
      autonumtechpassInputStates.value[autonumtechpassInputStates.value.length - 1] === ""
    ) {
      toast.clear()
      toast.error(t("app.toasterMessages.carNumCheck.cannotAddNewFieldIfPrevNotFilled"))
      return
    }

    autonumtechpassInputStates.value.push("")
  }

  const removeAutonumtechpassState = (index: number, regNum?: string): void => {
    autonumtechpassInputStates.value = autonumtechpassInputStates.value.filter((_, idx) => idx !== index)
    carNames.value = carNames.value.filter((_, idx) => idx !== index)
    carsYearOfIssue.value = carsYearOfIssue.value.filter((_) => _.regNum !== regNum)
  }

  let isInputChanged = ref<boolean>(true)
  watch(autonumtechpass, (newValue) => {
    if (newValue.length < 3) {
      carName.value = ""
      isInputChanged.value = true
    }
  })

  const autoNumberMask = /^(?![A-Z]{3})[A-Z0-9]{6,7}$/

  const autonumtechpasCheck = async (bin: string, date?: string | null) => {
    if (!autoNumberMask.test(bin)) {
      toast.error(t("app.toasterMessages.carNumCheck.wrongFormatOfCarNumber"))
      return false
    }

    if (!bin) {
      carName.value = ""
      return
    }

    const isDuplicateInArray = autonumtechpassInputStates.value.some((item) => item === data)
    if (isDuplicateInArray) {
      toast.error("Этот номер уже был введён")
      autonumtechpass.value = ""
      carName.value = ""
      return
    }

    isAutoNumsLoadingState.value = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/policy/juridical-ogpo/check/car",
        {
          regNum: bin.toUpperCase(),
          fromDate: activeTab.value === 1 ? dateFormatter(date) : date,
          period: month.value.value
        },
        config
      )
      if (response && response.data && response.status === 200) {
        carName.value = response.data.data.car
        carsYearOfIssue.value.push({
          regNum: bin,
          year: response.data.data.year
        })
        return true
      } else {
        autonumtechpass.value = ""
        carName.value = ""
        return false
      }
    } catch (error) {
      autonumtechpass.value = ""
      carName.value = ""
      console.error(error)
    } finally {
      isAutoNumsLoadingState.value = false
    }
  }

  const handleAutonumBlur = () => {
    if (autoNumberMask.test(autonumtechpass.value)) {
      autonumtechpasCheck(autonumtechpass.value, dateStart.value)
    } else {
      toast.error(t("app.toasterMessages.carNumCheck.wrongFormatOfCarNumber"))
    }
  }

  watch(
    autonumtechpassInputStates,
    (newVal) => {
      newVal.forEach((data, index) => {
        if (data.length < 3) {
          carNames.value[index] = ""
        }
      })
    },
    {deep: true}
  )

  const autonumtechpassCheck = async (bin: string, index: number, date: string | null) => {
    if (!bin) {
      carNames.value[index] = ""
      return
    }

    const isDuplicateInArray = autonumtechpassInputStates.value.some((item, idx) => item === bin && idx !== index)
    const isDuplicateWithCurrent = autonumtechpass.value === bin
    if (isDuplicateInArray || isDuplicateWithCurrent) {
      autonumtechpassInputStates.value[index] = ""
      carNames.value[index] = ""
      return
    }

    isAutoNumsLoadingStates.value[index] = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/policy/juridical-ogpo/check/car",
        {
          regNum: bin.toUpperCase(),
          fromDate: dateFormatter(dateStart.value)
        },
        config
      )
      if (response && response.data && response.status === 200) {
        carNames.value[index] = response.data.data.car
        carsYearOfIssue.value.push({
          regNum: bin,
          year: response.data.data.year
        })
      } else {
        carNames.value[index] = ""
        autonumtechpassInputStates.value[index] = ""
      }
    } catch (error) {
      carNames.value[index] = ""
      autonumtechpassInputStates.value[index] = ""
      console.error(error)
    } finally {
      isAutoNumsLoadingStates.value[index] = false
    }
  }

  const handleArrayAutonumBlur = (index) => {
    return () => {
      if (autoNumberMask.test(autonumtechpassInputStates.value[index])) {
        autonumtechpassCheck(autonumtechpassInputStates.value[index], index, dateStart.value)
      } else {
        toast.error(t("app.toasterMessages.carNumCheck.wrongFormatOfCarNumber"))
      }
    }
  }

  const documentParsedCars = ref<{carNumber: string; year: number}[]>([])
  const parseXlsxFile = async (file: File) => {
    try {
      const data = await file.arrayBuffer()
      const workbook = XLSX.read(data, {type: "array"})
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const rows = XLSX.utils.sheet_to_json(worksheet, {header: 1}) as string[][]

      // for (let i = 0; i < rows.length; i++) {
      //   const [carNumber, date] = rows[i]

      //   if (!carNumber) continue

      //   const response = await autonumtechpasCheck(carNumber, date)
      //   if (response) {
      //     documentParsedCars.value.push({carNumber, date})
      //   } else {
      //     continue
      //   }
      // }

      const batchSize = 20
      for (let i = 0; i < rows.length; i += batchSize) {
        const batch = rows.slice(i, i + batchSize)

        const batchPromises = batch.map(async ([carNumber, date]) => {
          if (!carNumber) return null
          const response = await autonumtechpasCheck(carNumber, date)
          return response ? {carNumber, date} : null
        })

        const results = await Promise.all(batchPromises)

        results.forEach((result) => {
          if (result) {
            documentParsedCars.value.push(result)
          }
        })
      }

      toast.success("Файл успешно обработан!")
    } catch (error) {
      console.error("Ошибка при обработке файла:", error)
      toast.error("Ошибка при обработке файла")
    }
  }

  const resetAutoNumberStates = () => {
    autonumtechpassInputStates.value = []
    carNames.value = []
    isAutoNumsLoadingStates.value = []
  }

  const calculationResponse = shallowRef<ICalcObj>()
  const calculateCost = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const payload = {
        phone: phoneNumberFormatter(phone.value),
        bin: bin.value,
        cars:
          activeTab.value === 1
            ? [
                {regNum: autonumtechpass.value, fromDate: dateFormatter(dateStart.value), period: month.value.value},
                ...autonumtechpassInputStates.value.map((regNum) => ({
                  regNum,
                  fromDate: dateFormatter(dateStart.value),
                  period: month.value.value
                }))
              ]
            : [
                {
                  ...documentParsedCars.value.map((item) => ({
                    regNum: item.carNumber,
                    fromDate: dateFormatter(dateStart.value),
                    period: month.value.value
                  }))
                }
              ]
      }
      const response = await postData("/policy/juridical-ogpo/calculate", payload, config)
      if (response?.status === 200) {
        calculationResponse.value = response.data.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  //Agreement confirmation form
  const email = shallowRef<string>("")
  const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null)
  const bindModal = ref("")
  const showOtp = shallowRef<boolean>(false)
  const codeIsSent = shallowRef<boolean>(false)
  const sentOtpResponse = ref<{message: string; status: boolean}>()
  const timer = shallowRef<number>(0)

  const checkEmailOtpCodeResponse = ref<{success: boolean; message: boolean} | null>(null)

  const sendEmailOtpCode = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/policy/juridical-ogpo/email/send-code",
        {
          calcId: calculationResponse.value?.calcId,
          email: email.value
        },
        config
      )
      if (response?.status === 200) {
        codeIsSent.value = true
        showOtp.value = true
        startTimer()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkEmailOtpCode = async (code: string | number) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (bindModal.value.length === 6 && !checkEmailOtpCodeResponse.value?.success) {
        const response = await postData(
          "/policy/juridical-ogpo/email/confirm-code",
          {
            email: email.value,
            code: parseInt(code)
          },
          config
        )
        if (response?.status === 200) {
          checkEmailOtpCodeResponse.value = response.data?.data
          return true
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const buttonTitle = computed(() => {
    if (timer.value > 0) {
      const minutes = Math.floor(timer.value / 60)
      const seconds = timer.value % 60
      return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
    }
    return t("app.buttons.sendCode")
  })

  const startTimer = () => {
    timer.value = 90
    const interval = setInterval(() => {
      timer.value--
      if (timer.value <= 0) {
        clearInterval(interval)
        codeIsSent.value = false
      }
    }, 1000)
  }

  //Documents form
  const documents = ref<{
    registeredLegalEntityFile: File | null
    rightToSignFile: File | null
    articlesOfAssociationOrRegulationFile: File | null
    identityDocumentOfFirstHeadFile: File | null
  }>({
    registeredLegalEntityFile: null,
    rightToSignFile: null,
    articlesOfAssociationOrRegulationFile: null,
    identityDocumentOfFirstHeadFile: null
  })
  const documentTypes = ref<{guid: string; name: string}[]>([])

  const getDocumentTypes = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await getData("/policy/juridical-ogpo/document-types", config)
      if (response) {
        documentTypes.value = response.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const verificationStatuses = ref<{
    registeredLegalEntityFile: number
    rightToSignFile: number
    articlesOfAssociationOrRegulationFile: number
    identityDocumentOfFirstHeadFile: number
  }>({
    registeredLegalEntityFile: 0,
    rightToSignFile: 0,
    articlesOfAssociationOrRegulationFile: 0,
    identityDocumentOfFirstHeadFile: 0
  })
  const verifyDocumentResponse = shallowRef<{reg_number: string; verify_status: string} | null>(null)

  const verifyDocument = async (file: File | null, documentType: string, key: string) => {
    if (!file) return
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const formData = new FormData()
      formData.append("bin", bin.value)
      formData.append("file", file)
      formData.append("documentType", documentType)
      const response = await postData("/policy/juridical-ogpo/verify/document", formData, config)
      if (response?.status === 200 && response.data?.data?.verify_status) {
        verifyDocumentResponse.value = response.data.data
        const status = response.data.data.verify_status
        verificationStatuses.value[key] = status === "CONFIRMED" ? 1 : status === "PENDING" ? 2 : 3
      }
    } catch (error) {
      console.error(`Failed to verify document (${key}):`, error)
      verificationStatuses.value[key] = 3
    }
  }

  const getDocumentStatus = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/policy/juridical-ogpo/verify/get-document/status",
        {
          reg_number: verifyDocumentResponse.value?.reg_number
        },
        config
      )
      if (response?.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  const pollDocumentStatuses = () => {
    for (const [key, file] of Object.entries(documents.value)) {
      const documentType = documentTypes.value.find((doc) => doc.name === key)?.guid
      if (file && documentType) {
        verifyDocument(file, documentType, key)
      }
    }
  }

  //Work with owners forms
  const FORM_TYPE_1_FIELDS = ref([
    {
      key: "iin",
      label: computed(() => t("form.inputTitles.iin")),
      type: "text",
      inputType: "input"
    },
    {key: "documentType", label: computed(() => t("form.inputTitles.docType")), type: "text", inputType: "selector"},
    {key: "documentNumber", label: computed(() => t("form.inputTitles.docNumber")), type: "text", inputType: "input"},
    {
      key: "whoGaveDocument",
      label: computed(() => t("form.inputTitles.docIssuedByWhom")),
      type: "text",
      inputType: "input"
    },
    {key: "dateIssue", label: computed(() => t("form.inputTitles.issueDate")), type: "date", inputType: "date"},
    {key: "stopDate", label: computed(() => t("form.inputTitles.endDate")), type: "date", inputType: "date"}
  ])

  const FORM_TYPE_2_FIELDS = ref([
    {key: "name", label: computed(() => t("form.inputTitles.name")), type: "text", inputType: "input"},
    {key: "surname", label: computed(() => t("form.inputTitles.surname")), type: "text", inputType: "input"},
    {key: "patronomic", label: computed(() => t("form.inputTitles.patronomic")), type: "text", inputType: "input"},
    {key: "birthdayDate", label: computed(() => t("form.inputTitles.birthdayDate")), type: "date", inputType: "date"},
    {
      key: "homeCountry",
      label: computed(() => t("form.inputTitles.countryOfResidence")),
      type: "text",
      inputType: "selector"
    },
    {key: "documentType", label: computed(() => t("form.inputTitles.docType")), type: "text", inputType: "selector"},
    {key: "documentNumber", label: computed(() => t("form.inputTitles.docNumber")), type: "text", inputType: "input"},
    {
      key: "whoGaveDocument",
      label: computed(() => t("form.inputTitles.docIssuedByWhom")),
      type: "text",
      inputType: "input"
    },
    {key: "dateIssue", label: computed(() => t("form.inputTitles.issueDate")), type: "date", inputType: "date"},
    {key: "stopDate", label: computed(() => t("form.inputTitles.endDate")), type: "date", inputType: "date"}
  ])

  // Zod schema for validation
  const residentSchema = zod.object({
    iin: zod.string().length(12),
    documentType: zod
      .object({name: zod.string()})
      .or(zod.string())
      .transform((val) => (typeof val === "object" ? val.name : val))
      .refine((val) => val.trim().length > 0, {message: "Document type must not be empty"}),
    documentNumber: zod.string().nonempty(),
    whoGaveDocument: zod.string().nonempty(),
    dateIssue: zod
      .date()
      .or(zod.string())
      .transform((val) => (typeof val === "string" ? new Date(val) : val))
      .refine((date) => !isNaN(date.getTime()), {message: "Date must be valid and not empty"}),
    stopDate: zod
      .date()
      .or(zod.string())
      .optional()
      .transform((val) => (typeof val === "string" ? new Date(val) : val))
      .refine((date) => !isNaN(date.getTime()), {message: "Date must be valid"})
  })

  const nonResidentSchema = zod.object({
    name: zod.string().nonempty(),
    surname: zod.string().nonempty(),
    patronomic: zod.string().optional(),
    birthdayDate: zod
      .date()
      .or(zod.string())
      .transform((val) => (typeof val === "string" ? new Date(val) : val))
      .refine((date) => !isNaN(date.getTime()), {message: "Date must be valid and not empty"}),
    homeCountry: zod.object({
      ID: zod.string().nonempty(),
      Name: zod.string().nonempty()
    }),
    documentType: zod
      .object({name: zod.string()})
      .or(zod.string())
      .transform((val) => (typeof val === "object" ? val.name : val))
      .refine((val) => val.trim().length > 0, {message: "Document type must not be empty"}),
    documentNumber: zod.string().nonempty(),
    whoGaveDocument: zod.string().nonempty(),
    dateIssue: zod
      .date()
      .or(zod.string())
      .transform((val) => (typeof val === "string" ? new Date(val) : val))
      .refine((date) => !isNaN(date.getTime()), {message: "Date must be valid and not empty"}),
    stopDate: zod
      .date()
      .or(zod.string())
      .optional()
      .transform((val) => (typeof val === "string" ? new Date(val) : val))
      .refine((date) => !isNaN(date.getTime()), {message: "Date must be valid"})
  })

  const forms = ref([
    {
      id: 1,
      isResident: true,
      fields: initializeFields(true)
    }
  ])

  function initializeFields(isResident) {
    const fields = isResident ? FORM_TYPE_1_FIELDS.value : FORM_TYPE_2_FIELDS.value
    return fields.reduce((acc, field) => {
      acc[field.key] = ""
      return acc
    }, {})
  }

  const addForm = () => {
    const newFormId = forms.value.length + 1
    forms.value.push({
      id: newFormId,
      isResident: true,
      fields: initializeFields(true)
    })
  }

  const toggleResidency = (index) => {
    const form = forms.value[index]
    form.isResident = !form.isResident
    form.fields = initializeFields(form.isResident)
  }

  const removeForm = (index) => {
    if (forms.value.length > 1) {
      forms.value.splice(index, 1)
    }
  }

  function getFieldLabel(key, isResident) {
    const fields = isResident ? FORM_TYPE_1_FIELDS.value : FORM_TYPE_2_FIELDS.value
    const field = fields.find((f) => f.key === key)
    return field ? field.label : ""
  }

  function getFieldType(key, isResident) {
    const fields = isResident ? FORM_TYPE_1_FIELDS.value : FORM_TYPE_2_FIELDS.value
    const field = fields.find((f) => f.key === key)
    return field ? field.inputType : "input"
  }

  function getFieldKey(key, isResident) {
    const fields = isResident ? FORM_TYPE_1_FIELDS.value : FORM_TYPE_2_FIELDS.value
    const field = fields.find((f) => f.key === key)
    return field
  }

  const formErrors = ref([])

  const validateForm = (form) => {
    const schema = form.isResident ? residentSchema : nonResidentSchema
    const validation = schema.safeParse(form.fields)

    if (!validation.success) {
      formErrors.value[form.id] = validation.error.flatten().fieldErrors
    } else {
      formErrors.value[form.id] = {}
    }

    return validation.success
  }

  const validateAllForms = () => {
    const result = forms.value.every((form) => validateForm(form))
    return result
  }

  const formsValid = computed(() => validateAllForms())

  //Owner form
  const countries = ref()
  const getBeneficiariesCountries = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await getData("/policy/juridical-ogpo/countries", config)
      if (response) {
        countries.value = response.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const beneficiariesDocumentTypes = ref<{document_type: string; name: string}>()
  const getBeneficiariesDocumentTypes = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await getData("/policy/juridical-ogpo/beneficiary/document-types", config)
      if (response) {
        beneficiariesDocumentTypes.value = response.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const postBeneficiaries = async (beneficiaries: any[]) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const payload = {
        calcId: calculationResponse.value?.calcId,
        beneficiaries
      }
      const response = await postData("/policy/juridical-ogpo/beneficiaries", payload, config)
      if (response?.status === 200) {
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  //Payment form
  const paymentUrl = ref<string>("")
  const selectedPayment = ref<string>("")

  const handleCurrentMethod = (type: string) => {
    selectedPayment.value = type
  }

  const finalPay = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/juridical-ogpo/create/order",
        {
          calcId: calculationResponse.value?.calcId,
          paymentType: selectedPayment.value,
          email: email.value
        },
        config
      )
      if (response && response.status === 200) {
        if (selectedPayment.value === "FreedomPay") {
          paymentUrl.value = response.data?.payment_url
          window.location.href = paymentUrl.value
          phone.value = ""
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    activeTab,
    currentStep,
    phone,
    bin,
    autonumtechpass,
    autonumtechpassInputStates,
    autonumDuplicateMessage,
    carName,
    carNames,
    carsYearOfIssue,
    isAutoNumsLoadingState,
    isAutoNumsLoadingStates,
    dateStart,
    month,
    monthes,
    xlsxFile,
    isCarNumberFilled,
    documentParsedCars,
    email,
    documents,
    binCheck,
    calculationResponse,
    otpInput,
    bindModal,
    showOtp,
    codeIsSent,
    sentOtpResponse,
    checkEmailOtpCodeResponse,
    buttonTitle,
    timer,
    documentTypes,
    verificationStatuses,
    countries,
    beneficiariesDocumentTypes,
    selectedPayment,
    paymentUrl,
    FORM_TYPE_1_FIELDS,
    FORM_TYPE_2_FIELDS,
    residentSchema,
    nonResidentSchema,
    forms,
    formErrors,
    formsValid,
    pushInputToAutonumtechpassArr,
    autonumtechpasCheck,
    handleAutonumBlur,
    autonumtechpassCheck,
    removeAutonumtechpassState,
    handleArrayAutonumBlur,
    parseXlsxFile,
    resetAutoNumberStates,
    calculateCost,
    sendEmailOtpCode,
    checkEmailOtpCode,
    getDocumentTypes,
    verifyDocument,
    getDocumentStatus,
    pollDocumentStatuses,
    getBeneficiariesCountries,
    getBeneficiariesDocumentTypes,
    getFieldKey,
    postBeneficiaries,
    handleCurrentMethod,
    initializeFields,
    addForm,
    toggleResidency,
    removeForm,
    getFieldLabel,
    getFieldType,
    validateForm,
    validateAllForms,
    finalPay
  }
})
