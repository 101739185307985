<script setup lang="ts">
import {reactive} from "vue"
import {useToast} from "vue-toastification"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useHomeProtectStore} from "@/stores/HomeProtectStore"
import {useValidation} from "@/composables/useValidation"
import {useFormatters} from "@/utils/formatters"
import {useHelpers} from "@/composables/useHelpers"
import {filterEmailInput} from "@/utils/inputEvents"
import {homeProtectExecutionFormSchema} from "@/schemas"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"
import FFSlider from "@/components/FFUI/FFSlider.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import QuestionIcon from "@/components/icons/QuestionIcon.vue"

const toast = useToast()
const {t} = useI18n()
const homeProtectStore = useHomeProtectStore()
const {calculationObject, executionForm} = storeToRefs(homeProtectStore)
const {currentLocale, openLink} = useHelpers()
const {thousandSeparator} = useFormatters()

const emit = defineEmits(["nextStep"])

const {isValid, getError, validate} = useValidation(homeProtectExecutionFormSchema, executionForm.value, {
  mode: "lazy"
})

const handleNextStep = async () => {
  try {
    await validate()
    if (!executionForm.value.email && !executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTermsAndEmailAndPublicOffer"))
      return
    }
    if (!executionForm.value.email && executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTermsEmail"))
      return
    }
    if (executionForm.value.email && !executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTermsAndPublicOffer"))
      return
    }
    if (isValid.value) {
      emit("nextStep")
    }
  } catch (error) {
    console.error(error)
  }
}

// const showFranchiseTooltip = ref<boolean>(false)

// const handleMouseOver = () => {
//   showFranchiseTooltip.value = true
// }
// const handleMouseLeave = () => {
//   showFranchiseTooltip.value = false
// }

const tooltipsTypes = reactive<{franchise: boolean; insuranceSum: boolean}>({
  franchise: false,
  insuranceSum: false
})

const handleMouseOver = (type: keyof {franchise: boolean; insuranceSum: boolean}) => {
  tooltipsTypes[type] = true
}

const handleMouseLeave = (type: keyof {franchise: boolean; insuranceSum: boolean}) => {
  tooltipsTypes[type] = false
}

const handleTooltipClose = (type: keyof {franchise: boolean; insuranceSum: boolean}) => {
  tooltipsTypes[type] = false
}
</script>

<template>
  <div class="exec-form__email-iagree">
    <div>
      <FFFieldset :isError="!!getError('email')" :title="$t('form.inputTitles.email')">
        <FFInput
          id="email"
          name="email"
          type="email"
          v-model="executionForm.email"
          :placeholder="t('app.ogpoPage.forms.willSendPolisOnEmail')"
          inputmode="email"
          :required="true"
          @input="filterEmailInput" />
      </FFFieldset>
      <template v-if="!!getError('email')">
        <FFErrorMessage
          v-if="executionForm.email.length === 0"
          :darkMode="true"
          :errorMessage="t('form.validationErrors.emailFieldIsRequired')" />
        <FFErrorMessage
          v-if="executionForm.email.length > 0 && getError('email')"
          :darkMode="true"
          :errorMessage="t('form.validationErrors.emailIsNotValid')" />
      </template>
    </div>
    <FFCheckbox v-if="currentLocale === 'kk'" v-model="executionForm.iAgreeWithAgreement">
      <template #title>
        {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
        <span
          @click="() => homeProtectStore.openHomeProtectQuestionnairePdf(calculationObject?.calcId)"
          class="exec-form__link">
          {{ $t("form.checkboxTitles.iAgree.iAgree-2") }}</span
        >, {{ " " }}
        <span @click="openLink('https://ffins.kz/report-file/270', '_blank')" class="exec-form__link">
          {{ $t("form.checkboxTitles.iAgree.iAgree-2-1") }}</span
        >
        {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
        <span @click="openLink('https://ffins.kz/report-file/164', '_blank')" class="exec-form__link">
          {{ $t("form.checkboxTitles.iAgree.iAgree-4") }}
        </span>
        {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}
      </template>
    </FFCheckbox>
    <FFCheckbox v-else v-model="executionForm.iAgreeWithAgreement">
      <template #title>
        {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
        <span
          @click="() => homeProtectStore.openHomeProtectQuestionnairePdf(calculationObject?.calcId)"
          class="exec-form__link">
          {{ $t("form.checkboxTitles.iAgree.iAgree-2") }} </span
        >, {{ " " }}
        <span @click="openLink('https://ffins.kz/report-file/270', '_blank')" class="exec-form__link">
          {{ $t("form.checkboxTitles.iAgree.iAgree-2-1") }}</span
        >
        {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
        <span @click="openLink('https://ffins.kz/report-file/164', '_blank')" class="exec-form__link"
          >{{ $t("form.checkboxTitles.iAgree.iAgree-4") }} {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}</span
        >
      </template>
    </FFCheckbox>
  </div>
  <div class="relative">
    <div class="exec-form__dark-bg"></div>
    <div class="exec-form__payment-sum-wrapper">
      <div class="exec-form__payment-sum-wrapper--comfort">
        <div class="exec-form__payment-sum-title">
          <p>{{ $t("app.homeProtectPage.forms.executionForm.insuranceAmount") }}:</p>
          <svg @click="handleMouseOver('insuranceSum')" @mouseover="handleMouseOver('insuranceSum')" @mouseleave="handleMouseLeave('insuranceSum')" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_27088_38442)">
              <path
                d="M6 0.375C2.925 0.375 0.375 2.925 0.375 6C0.375 9.075 2.925 11.625 6 11.625C9.075 11.625 11.625 9.075 11.625 6C11.625 2.925 9.075 0.375 6 0.375ZM5.775 9.15C5.325 9.15 4.95 8.775 4.95 8.325C4.95 7.875 5.25 7.5 5.775 7.5C6.225 7.5 6.6 7.875 6.6 8.325C6.6 8.775 6.225 9.15 5.775 9.15ZM6.825 5.775C6.525 6.15 6.375 6.525 6.375 6.9V7.05H5.175V6.825C5.175 6.375 5.325 5.925 5.7 5.4C6 5.1 6.225 4.8 6.225 4.5C6.225 4.2 6 3.975 5.55 3.975C5.25 3.975 4.95 4.05 4.725 4.2L4.35 3.225C4.65 3 5.25 2.85 5.85 2.85C7.05 2.85 7.575 3.525 7.575 4.275C7.65 4.95 7.2 5.4 6.825 5.775Z"
                fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_27088_38442">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <p>{{ thousandSeparator(calculationObject?.insuranceAmount) }} {{ $t("form.formAbbreviations.tg") }}</p>
      </div>
      <div class="exec-form__payment-sum-wrapper--sum">
        <p>{{ $t("app.ogpoPage.forms.toPay") }}</p>
        <p>
          {{ thousandSeparator(Math.round(calculationObject?.premium)) }}
          <span class="tenge">{{ $t("form.formAbbreviations.tg") }}</span>
        </p>
      </div>
      <hr />
    </div>
    <FFTooltip
      v-show="tooltipsTypes.insuranceSum"
      :description="$t('app.homeProtectPage.forms.executionForm.marketCostOfYourApartment')"
      paddingXY="15px"
      fontSize="13px"
      fontWeight="500"
      top="-40px"
      left="0px" />
    <FFTooltip
      v-show="tooltipsTypes.franchise"
      :title="$t('app.homeProtectPage.forms.executionForm.franchise')"
      :description="$t('app.homeProtectPage.forms.executionForm.franchiseDescription')"
      paddingXY="15px"
      fontSize="13px"
      top="-85px"
      left="0px" />
    <div class="exec-form__franchise-wrapper">
      <template v-if="currentLocale === 'en'">
        <p class="exec-form__franchise-wrapper-text">
          {{ $t("app.homeProtectPage.forms.executionForm.franchise-1") }}
          <span @click="handleMouseOver('franchise')" @mouseover="handleMouseOver('franchise')" @mouseleave="handleMouseLeave('franchise')">{{
            $t("app.homeProtectPage.forms.executionForm.franchise-2")
          }}</span
          >{{ " " }}{{ $t("app.homeProtectPage.forms.executionForm.franchise-3") }}
        </p>
      </template>
      <template v-else>
        <p class="exec-form__franchise-wrapper-text">
          *<span @click="handleMouseOver('franchise')" @mouseover="handleMouseOver('franchise')" @mouseleave="handleMouseLeave('franchise')">{{
            $t("app.homeProtectPage.forms.executionForm.franchise-1")
          }}</span
          >{{ " " }}{{ $t("app.homeProtectPage.forms.executionForm.franchise-2") }}
        </p>
      </template>
    </div>

    <div class="exec-form__pay-button">
      <FFButton @click="handleNextStep" :title="$t('app.buttons.pay')" :uppercase="false" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.exec-form {
  border-radius: 10px;
  padding: 20px 16px;
  background-color: #1f1f1f;
  margin: 20px 0;

  .exec-form__coverage-tariff-wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    gap: 10px;

    .exec-form__coverage-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p:first-of-type {
        color: #ffffff;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border-bottom: 1px solid #ffffff;
        cursor: pointer;
      }
      p:last-of-type {
        color: #ffffff;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }
}

.exec-form__email-iagree {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;

  .exec-form__link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
  }
}

.exec-form__payment-sum-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  position: relative;
  z-index: 1;

  .exec-form__payment-sum-wrapper--notClientBank {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;

    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: none;

      span {
        text-decoration: underline;
      }
    }

    p:first-child {
      width: 165px;
    }
  }

  .exec-form__payment-sum-wrapper--clientBank {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    margin-top: 15px;

    span {
      text-decoration: underline;
    }
  }

  .exec-form__payment-sum-wrapper--comfort {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .exec-form__payment-sum-title {
      @include center;
      gap: 3px;
    }

    p {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      opacity: 0.8;
    }
  }

  .exec-form__payment-sum-wrapper--sum {
    display: flex;
    justify-content: space-between;

    &:last-of-type {
      margin-bottom: 16px;
    }

    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      opacity: 1;
    }
  }
}

.exec-form__franchise-wrapper {
  position: relative;
  z-index: 1;
  margin-top: 20px;
  .exec-form__franchise-wrapper-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    span {
      text-decoration: underline;
      cursor: default;
    }
  }
}

hr {
  z-index: 1;
  position: relative;
  border-color: white;
}

.exec-form__pay-button {
  position: relative;
  z-index: 1;
  padding-top: 20px;
}

.exec-form__pay-button--no-margin {
  margin-top: 0px;
}

.exec-form__dark-bg {
  background-color: rgba(0, 0, 0, 0.5);
  height: calc(100% + 30px);
  width: calc(100% + 60px);
  position: absolute;
  top: 0;
  left: -10%;
  right: 0;
  bottom: 0;
}

.tenge {
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
</style>
