<script setup lang="ts">
import {ref, watch, onMounted, onUnmounted} from "vue"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useRouter} from "vue-router"
import {useOgpoPlusStore} from "@/stores/OgpoPlusStore"
import {useHelpers} from "@/composables/useHelpers"
import {vMaska} from "maska"
import {onPhoneFocus, filterCyrillicInput, filterSpecChars} from "@/utils/inputEvents"
import {whatsAppOgpoMessageRu, whatsAppOgpoMessageKk, whatsAppOgpoMessageEn} from "@/constants/whatsAppMessages"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFSelect from "@/components/FFUI/FFSelect.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFCheckerInput from "@/components/FFUI/FFCheckerInput.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import AddDriverIcon from "@/components/icons/AddDriverIcon.vue"
import AddAutoIcon from "@/components/icons/AddAutoIcon.vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"
import YellowQuestionIcon from "@/components/icons/YellowQuestionIcon.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import Dropdown from "primevue/dropdown"
import FFFloatingTooltip from "@/components/FFUI/FFFloatingTooltip.vue"

//stores, composables
const ogpoPlusStore = useOgpoPlusStore()
const {
  phone,
  autonumtechpass,
  autonumDuplicateMessage,
  carName,
  iin,
  iinName,
  benefits,
  willManageCar,
  date,
  month,
  monthes,
  isSeasonalOperationVehicle,
  showSeasonalOperationVehicle,
  isInputChanged,
  isAutoNumsLoadingState,
  isPhoneFilled,
  isCarNumberFilled,
  isIinNumberFilled,
  isCalculationBtnDisabled,
  isIinLoadingState,
  addOgpoPlus
} = storeToRefs(ogpoPlusStore)

const router = useRouter()
const {currentLocale, openLink} = useHelpers()

const {t} = useI18n()

//tooltip and select
const benefitsTooltipShow = ref<boolean>(false)

const handleMouseOver = () => {
  benefitsTooltipShow.value = true
}
const handleMouseLeave = () => {
  benefitsTooltipShow.value = false
}

const fieldsWrapper = ref(null)
const isExceeded = ref<boolean>(false)

const preventAutoNumCyrillicInput = () => {
  autonumtechpass.value = filterCyrillicInput(autonumtechpass.value)
  autonumtechpass.value = filterSpecChars(autonumtechpass.value)
}
</script>

<template>
  <p class="calc-form__title">{{ $t("form.formHeaders.costCalculation") }}</p>
  <div class="calc-form__fields ogpo__calc-form" ref="fieldsWrapper" :class="{exceeded: isExceeded}">
    <div>
      <FFFieldset :title="$t('form.inputTitles.phone')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          placeholder="+7 (___) ___ ____"
          id="phonenumber"
          name="phonenumber"
          type="tel"
          v-model="phone"
          @focus="onPhoneFocus" />
      </FFFieldset>
    </div>
    <div class="relative">
      <div class="calc-form__form-row">
        <FFFieldset :title="$t('form.inputTitles.autoNumTechPass')" :locked="!isPhoneFilled">
          <FFCheckerInput
            :padding="
              !isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 3
                ? '11px 5px 7px 14px'
                : '6px 5px 7px 14px'
            "
            :inputHeight="!isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 3 ? '16px' : '51px'"
            placeholder="908LFA02 or A001OMA"
            :needUnderText="!isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 3"
            :underText="!isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 3 ? carName : ''"
            v-model="autonumtechpass"
            id="auto-num-or-tech-passport"
            type="text"
            :loading="isAutoNumsLoadingState"
            :func="
              () => {
                autonumtechpass = autonumtechpass.toUpperCase()
                preventAutoNumCyrillicInput()
              }
            "
            :blur="ogpoPlusStore.handleAutonumBlur"
            :lockedErrorText="$t('app.toasterMessages.inputPhoneNumber')"
            :locked="!isPhoneFilled" />
        </FFFieldset>
      </div>
    </div>

    <div>
      <div class="calc-form__form-row">
        <FFFieldset :title="$t('form.inputTitles.iin')">
          <FFCheckerInput
            dataMaska="############"
            :padding="
              !isIinLoadingState && Boolean(iinName) && iin.length === 12 ? '11px 5px 7px 14px' : '6px 5px 7px 14px'
            "
            :inputHeight="!isIinLoadingState && Boolean(iinName) && iin.length === 12 ? '16px' : '51px'"
            placeholder="123412545654"
            :needUnderText="!isIinLoadingState && Boolean(iinName)"
            :underText="!isIinLoadingState && Boolean(iinName) && iin.length === 12 ? iinName : ''"
            v-model="iin"
            id="iin"
            type="text"
            :loading="isIinLoadingState"
            inputmode="numeric"
            :func="
              () => {
                ogpoPlusStore.iinCheck(iin)
              }
            "
            :lockedErrorText="$t('app.toasterMessages.inputCarNumber')"
            :locked="!isCarNumberFilled" />
        </FFFieldset>
      </div>
    </div>
    <div class="calc-form__form-checkboxes-row">
      <FFCheckbox :title="t('app.ogpoPage.forms.willDriveCar')" v-model="willManageCar" :locked="true" />
    </div>

    <div class="calc-form__form-row">
      <FFFieldset :title="$t('form.inputTitles.dateStart')">
        <VueDatePicker
          :enable-time-picker="false"
          :teleport="true"
          auto-apply
          v-model="date"
          format="dd.MM.yyyy"
          :locale="currentLocale"
          :start-date="new Date(new Date().setDate(new Date().getDate() + 1))"
          :min-date="new Date(new Date().setDate(new Date().getDate() + 1))"
          :max-date="new Date(new Date().setDate(new Date().getDate() + 1) + 89 * 24 * 60 * 60 * 1000)">
          <template #input-icon>
            <img class="date-time-picker-icon" src="/declare-insured-event/images/calendar-input.svg" alt="icon" />
          </template>
          <template #clear-icon> </template>
        </VueDatePicker>
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.period')" minHeight="unset">
        <Dropdown v-model="month" :options="monthes" optionLabel="name" />
      </FFFieldset>
    </div>
    <FFCheckbox
      v-if="showSeasonalOperationVehicle"
      v-model="isSeasonalOperationVehicle"
      :title="$t('app.ogpoPage.forms.seasonalVehicle')"
      font-weight="500" />

    <FFButton
      :title="$t('app.buttons.calculateCost')"
      @click="ogpoPlusStore.calculateCost"
      :disabled="!isCalculationBtnDisabled" />
  </div>
</template>

<style scoped lang="scss">
.calc-form__title {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.calc-form__tabs {
  width: 100%;
  display: flex;
  margin: 30px 0;
  .calc-form__tab {
    cursor: pointer;
    padding: 10px 24px;
    width: 50%;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    background-color: transparent;
    white-space: nowrap;
    border-radius: 10px;
    color: #ffffff;
    opacity: 0.5;
  }
  .current {
    background-color: rgba(50, 50, 50, 0.57);
    color: #ffffff;
    opacity: 1;
  }
}

.calc-form__fields {
  display: flex;
  flex-direction: column;
  height: 390px;
  scrollbar-gutter: stable;
  overflow-x: hidden;
  padding-right: 5px;
  margin-top: 20px;
  gap: 20px;

  ::v-deep(.dp__input_icon) {
    inset-inline-start: unset;
    right: 0px;
    top: 45%;
  }
  ::v-deep(.ff-form .dp__input_icon) {
    inset-inline-start: unset;
    right: 0px;
    top: 45%;
  }
  ::v-deep(.date-time-picker-icon) {
    width: 27px;
    height: 27px;
  }
  ::v-deep(.dp__input_icon_pad) {
    padding-inline-start: unset;
    padding-left: 15px;
    font-size: 16px !important;
  }
  ::v-deep(.dp__pointer .db__input_readonly .dp__input_icon_pad) {
    padding-inline-start: unset !important;
  }
  
  .calc-form__form-row {
    display: flex;
    gap: 10px;

    .calc-form__icon-wrapper {
      cursor: pointer;
      border: 2px solid #ffffff;
      height: 46px;
      width: 60px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
    }
  }
  .form__ask {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #2e2e2e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin: 0 auto;
    transition: all 0.3s ease;
    padding: 10px 0;
  }
  .calc-form__form-checkboxes-row {
    width: 260px;
    display: flex;
    justify-content: space-between;
    .calc-form__form-subcheckbox-row {
      display: flex;
      align-items: center;
      gap: 3px;
      .calc-form__form-subcheckbox-row-tooltip {
        display: flex;
        align-items: center;
      }
    }
    .ff-checkbox:first-of-type {
      width: 94px;
    }
    .ff-checkbox:last-of-type {
      width: fit-content;
    }
  }
}
</style>
