<script setup lang="ts">
import {onMounted} from "vue"
import {storeToRefs} from "pinia"
import {useNewsStore} from "@/stores/NewsStore"
import {useChairmanStore} from "@/stores/ChairmanStore"
import HotlineHero from "@/components/Hotline/HotlineHero.vue"
import HotlineChairmanInfo from "@/components/Hotline/HotlineChairmanInfo.vue"
import HotlineFillForm from "@/components/Hotline/HotlineFillForm.vue"
import HotlineAboutCompany from "@/components/Hotline/HotlineAboutCompany.vue"
import HotlineAwards from "@/components/Hotline/HotlineAwards.vue"
import HotlineNews from "@/components/Hotline/HotlineNews.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const newsStore = useNewsStore()
const chairmanStore = useChairmanStore()
const {loading} = storeToRefs(chairmanStore)

onMounted(async () => {
  await newsStore.getNews(4, 1)
})
</script>

<template>
  <Modal style="z-index: 103" :isOpen="loading">
    <Loader />
  </Modal>
  <div class="hotline">
    <HotlineHero />
    <HotlineChairmanInfo />
    <HotlineFillForm />
    <HotlineAboutCompany />
    <HotlineAwards />
    <div class="hotline__autumn-road-bg"></div>
    <HotlineNews />
  </div>
</template>

<style scoped lang="scss">
.hotline {
  overflow: hidden;
  position: relative;
  z-index: 0;
  .hotline__green-bg {
    position: absolute;
    top: -565px;
    left: -300px;
    right: 0;
    width: 1224.714px;
    height: 1826.094px;
    z-index: 0;
    transform: rotate(-90deg);
    flex-shrink: 0;
    background: radial-gradient(46.25% 69.68% at 38.69% 48.62%, rgba(90, 238, 53, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    filter: blur(97.5px);
  }
  .hotline__autumn-road-bg {
    background-image: url("/hotline-front/images/autumn-road-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 820px;
    position: absolute;
    z-index: 0;
    bottom: -3357px;

    @include max-responsive(lg) {
      display: none;
    }
  }
}
</style>
