<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M9 18C4.05 18 0 13.95 0 9C0 4.05 4.05 0 9 0C13.95 0 18 4.05 18 9C18 13.95 13.95 18 9 18ZM9 1.875C5.1 1.875 1.875 5.1 1.875 9C1.875 12.9 5.1 16.125 9 16.125C12.9 16.125 16.125 12.9 16.125 9C16.125 5.1 12.9 1.875 9 1.875Z"
      fill="white" />
    <path
      d="M7.57344 12.5254C7.34844 12.5254 7.04844 12.4504 6.89844 12.2254L5.02344 10.3504C4.64844 9.97539 4.64844 9.37539 5.02344 9.00039C5.39844 8.62539 5.99844 8.62539 6.37344 9.00039L7.57344 10.2004L11.6234 6.15039C11.9984 5.77539 12.5984 5.77539 12.9734 6.15039C13.3484 6.52539 13.3484 7.12539 12.9734 7.50039L8.24844 12.2254C8.02344 12.4504 7.79844 12.5254 7.57344 12.5254Z"
      fill="white" />
  </svg>
</template>
