<script setup lang="ts">
import {computed} from "vue"
import {storeToRefs} from "pinia"
import {useMSTRemasteredStore} from "@/stores/MSTRemastered"
import {vMaska} from "maska"
import {onPhoneFocus} from "@/utils/inputEvents"
import {authorizeSchema} from "@/schemas/MST/authorizeSchema"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFCheckerInput from "@/components/FFUI/FFCheckerInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const mstStore = useMSTRemasteredStore()
const {authorizeForm, iinName, isIinLoadingState} = storeToRefs(mstStore)

const isAuthorizeFormFilled = computed(() => {
  return authorizeSchema.safeParse(authorizeForm.value).success
})
</script>

<template>
  <p class="authorize-form__title">{{ $t("form.formHeaders.costCalculation") }}</p>
  <div class="authorize-form__fields">
    <div>
      <FFFieldset :title="$t('form.inputTitles.phone')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          placeholder="+7 (___) ___ ____"
          id="phonenumber"
          name="phonenumber"
          type="tel"
          v-model="authorizeForm.phone"
          @focus="onPhoneFocus" />
      </FFFieldset>
    </div>
    <div>
      <FFFieldset :title="$t('form.inputTitles.iin')">
        <FFCheckerInput
          dataMaska="############"
          :padding="
            !isIinLoadingState && Boolean(iinName) && authorizeForm.iin.length === 12
              ? '11px 5px 7px 14px'
              : '6px 5px 7px 14px'
          "
          :inputHeight="!isIinLoadingState && Boolean(iinName) && authorizeForm.iin.length === 12 ? '16px' : '51px'"
          placeholder="123412545654"
          :needUnderText="!isIinLoadingState && Boolean(iinName)"
          :underText="!isIinLoadingState && Boolean(iinName) && authorizeForm.iin.length === 12 ? iinName : ''"
          v-model="authorizeForm.iin"
          id="iin"
          type="text"
          :loading="isIinLoadingState"
          inputmode="numeric"
          :func="
            () => {
              mstStore.iinCheck(authorizeForm.iin)
            }
          " />
      </FFFieldset>
    </div>
    <FFButton
      @click="mstStore.handleAuthorizeForm"
      :title="$t('app.buttons.next')"
      :disabled="!isAuthorizeFormFilled" />
  </div>
</template>

<style scoped lang="scss">
.authorize-form__title {
  color: white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.authorize-form__fields {
  @include column;
  gap: 20px;
}
</style>
