<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="59" viewBox="0 0 49 59" fill="none">
    <path
      d="M13.7684 59C1.81727 52.1872 -1.3292 45.7387 0.469731 36.9314C1.79778 30.4281 6.1464 25.137 6.56337 18.6462C8.42097 22.0655 9.19716 24.531 9.40472 28.1041C15.3128 20.7825 19.217 10.6468 19.4482 0C19.4482 0 34.8366 9.14549 35.8464 22.9599C37.1711 20.1125 37.838 15.5902 36.5132 12.6592C40.4874 15.5903 63.7486 41.6104 33.3625 59C39.0754 47.7488 34.8363 32.5675 24.9172 25.5559C25.5796 28.5708 24.4182 39.8153 20.0299 44.7563C21.2458 36.5004 18.873 33.0095 18.873 33.0095C18.873 33.0095 18.0586 37.6338 14.8989 42.3052C12.0134 46.571 10.0141 51.0985 13.7684 59Z"
      fill="url(#paint0_linear_152_3825)" />
    <path
      d="M13.7684 59C1.81727 52.1872 -1.3292 45.7387 0.469731 36.9314C1.79778 30.4281 6.1464 25.137 6.56337 18.6462C8.42097 22.0655 9.19716 24.531 9.40472 28.1041C15.3128 20.7825 19.217 10.6468 19.4482 0C19.4482 0 34.8366 9.14549 35.8464 22.9599C37.1711 20.1125 37.838 15.5902 36.5132 12.6592C40.4874 15.5903 63.7486 41.6104 33.3625 59C39.0754 47.7488 34.8363 32.5675 24.9172 25.5559C25.5796 28.5708 24.4182 39.8153 20.0299 44.7563C21.2458 36.5004 18.873 33.0095 18.873 33.0095C18.873 33.0095 18.0586 37.6338 14.8989 42.3052C12.0134 46.571 10.0141 51.0985 13.7684 59Z"
      fill="black"
      fill-opacity="0.2" />
    <g filter="url(#filter0_bd_152_3825)">
      <path
        d="M34.9191 33.4635C34.9191 34.666 34.727 35.776 34.3429 36.7935C33.9588 37.8111 33.4571 38.6782 32.8378 39.3951C32.2185 40.112 31.5326 40.7904 30.7801 41.4302C30.0275 42.07 29.275 42.6674 28.5224 43.2224C27.7699 43.7774 27.0839 44.3324 26.4646 44.8874C25.8454 45.4424 25.3437 46.0706 24.9595 46.7721C24.5754 47.4736 24.3834 48.2174 24.3834 49.0037C24.3834 50.4837 24.9086 52.2104 25.959 54.1837L25.865 54.1606L25.8885 54.1837C24.4774 53.5517 23.2232 52.9118 22.1257 52.2643C21.0282 51.6168 19.9425 50.846 18.8686 49.9518C17.7946 49.0576 16.9049 48.1134 16.1994 47.119C15.4939 46.1246 14.9255 44.9645 14.4944 43.6386C14.0632 42.3128 13.8477 40.8944 13.8477 39.3836C13.8477 38.1811 14.0397 37.071 14.4238 36.0535C14.8079 35.036 15.3096 34.1688 15.9289 33.4519C16.5482 32.7351 17.2341 32.0567 17.9867 31.4169C18.7392 30.7771 19.4918 30.1797 20.2443 29.6247C20.9969 29.0697 21.6828 28.5147 22.3021 27.9597C22.9214 27.4047 23.4231 26.7764 23.8072 26.075C24.1913 25.3735 24.3834 24.6296 24.3834 23.8434C24.3834 22.3942 23.866 20.6675 22.8312 18.6633L22.9018 18.6865L22.8783 18.6633C24.2893 19.2954 25.5436 19.9352 26.641 20.5827C27.7385 21.2302 28.8242 22.0011 29.8982 22.8953C30.9721 23.7894 31.8618 24.7337 32.5674 25.7281C33.2729 26.7225 33.8412 27.8826 34.2724 29.2085C34.7035 30.5343 34.9191 31.9526 34.9191 33.4635Z"
        fill="white"
        fill-opacity="0.32" />
    </g>
    <defs>
      <filter
        id="filter0_bd_152_3825"
        x="10.8477"
        y="15.6633"
        width="27.0723"
        height="41.5205"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_152_3825" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect1_backgroundBlur_152_3825" result="effect2_dropShadow_152_3825" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_152_3825" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_152_3825"
        x1="-1.02448"
        y1="0.495798"
        x2="61.0494"
        y2="47.1555"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#8DD979" />
        <stop offset="1" stop-color="#2A5704" />
      </linearGradient>
    </defs>
  </svg>
</template>
