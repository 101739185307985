<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useModalStore} from "@/stores/ModalStore"
import {useComfortStore} from "@/stores/ComfortStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import FFForm from "@/components/FFUI/FFForm.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import ComfortCalculateForm from "@/components/Comfort/Forms/ComfortCalculateForm.vue"
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue"
import ComfortForms from "@/components/Comfort/ComfortForms.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"

const modalStore = useModalStore()
const comfortStore = useComfortStore()

const {currentStep} = storeToRefs(comfortStore)

const {isMobileDevice, isTablet} = useDeviceSize()

const handleLoginFormOpen = async () => {
  modalStore.openModal({
    component: ComfortForms
  })
}
</script>

<template>
  <section class="comfort-hero">
    <div class="comfort-hero__left-part">
      <div class="comfort-hero__boxes">
        <div class="comfort-hero__box">{{ $t("app.comfortPage.withoutInspection") }}</div>
        <div class="comfort-hero__box">{{ $t("app.comfortPage.monthlyPayment") }}</div>
      </div>
      <h1 class="comfort-hero__title">{{ $t("app.comfortPage.mainTitle") }}</h1>
      <p class="comfort-hero__subtitle">
        {{ $t("app.comfortPage.description") }}
      </p>
      <div v-if="isMobileDevice || isTablet" class="comfort-hero__btn-container">
        <FFButton
          v-if="isMobileDevice"
          @click="handleLoginFormOpen"
          :title="$t('app.buttons.calculateCost')"
          color="#ffffff"
          size="18px"
          weight="900"
          :uppercase="false"
          background="transparent"
          backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          colorMobile="#ffffff" />
        <div v-else @click="handleLoginFormOpen" class="comfort-hero__calculate-price-btn">
          <span>{{ $t('app.buttons.calculateCost') }}</span>
          <ArrowRightIcon />
        </div>
      </div>
    </div>
    <div class="comfort-hero__right-part">
      <FFForm>
        <ComfortCalculateForm />
      </FFForm>
    </div>
  </section>
</template>

<style scoped lang="scss">
.comfort-hero {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 22px 22px 40px;
  margin-top: 150px;
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(8px);

  .first-green-shadow {
    position: absolute;
    z-index: -4;
    width: 1258.35px;
    height: 2889.799px;
    top: -164px;
    left: -490px;
  }

  .comfort-hero__left-part {
    .comfort-hero__boxes {
      display: flex;
      gap: 10px;

      .comfort-hero__box {
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 11.048px;
        text-transform: uppercase;

        border-radius: 5px;
        background: #f2994a;
        padding: 5px 20.5px;
      }
    }

    .comfort-hero__title {
      font-size: 50px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      max-width: 546px;
    }

    .comfort-hero__subtitle {
      color: #d1d1d1;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      max-width: 618px;
      margin-top: 20px;
    }

    .comfort-hero__btn-container {
      margin-top: 50px;
      .comfort-hero__calculate-price-btn {
        display: flex;
        align-items: center;
        gap: 30px;
        background-color: transparent;
        cursor: pointer;
        width: fit-content;
        transition: all 0.3s ease;

        &:hover {
          scale: 1.05;
          transition: scale 0.3s ease;
        }

        span {
          color: #ffffff;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }

  .comfort-hero__right-part {
    .ff-form {
      gap: 0;
      padding: 27px 30px 32px;
      min-width: 360px;

      .comfort-hero__right-part--title {
        color: #ffffff;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        width: 200px;

        margin: 0 auto 20px;
      }
      .comfort-hero__form-row {
        margin-bottom: 20px;
      }
      .ff-checkbox {
        margin: 30px 0;
      }
    }
  }
}

@media (max-width: $xl) {
  .comfort-hero {
    flex-direction: unset;
    padding: 0;
    border-radius: 0;
    background: none;
    backdrop-filter: unset;

    .comfort-hero__left-part {
      .comfort-hero__boxes {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 16px;

        .comfort-hero__box {
          width: fit-content;
        }
      }
      .comfort-hero__title {
        font-size: 30px;
      }
    }

    .comfort-hero__right-part {
      display: none;
    }
  }
}

@media (max-width: $lg) {
  .comfort-hero {
    margin-top: 20px;

    .comfort-hero__left-part {
      .comfort-hero__btn-container {
        margin-top: 20px;
      }
    }

    .comfort-hero__right-part {
      .ff-form {
        display: none;
      }
    }
  }
}
</style>
