<script setup lang="ts">
import {onMounted, onUnmounted} from "vue"
import {storeToRefs} from "pinia"
import {useModalStore} from "@/stores/ModalStore"
import {useHomeProtectStore} from "@/stores/HomeProtectStore"
import {useI18n} from "vue-i18n"
import HProtectForms from "@/components/HomeProtect/HProtectForms.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const modalStore = useModalStore()
const homeProtectStore = useHomeProtectStore()
const {currentStep, apartmentClientData, apartmentCount, isBankCheckModalNeed, isChoosedProperty, propertyStatusData} =
  storeToRefs(homeProtectStore)
const {t} = useI18n()

const emit = defineEmits(["stepBack"])

let statusInterval = null

const startCheckingStatus = () => {
  statusInterval = setInterval(async () => {
    await homeProtectStore.checkPropertyStatus()
    if (propertyStatusData.value?.status === 1) {
      clearInterval(statusInterval)
    } else if (propertyStatusData.value?.status === 3) {
      stopCheckingStatus()
      currentStep.value = 1
    }
  }, 10000)
}

const stopCheckingStatus = () => {
  if (statusInterval) {
    clearInterval(statusInterval)
  }
}

onMounted(() => {
  startCheckingStatus()
})

onUnmounted(() => {
  stopCheckingStatus()
})

const onBack = () => {
  emit("stepBack")
  stopCheckingStatus()
}
</script>

<template>
  <p class="agreement-form__title">
    {{ $t("app.homeProtectPage.forms.agreementForm.agreementToGetAndProcessApartmentData") }}
  </p>
  <div class="agreement-form__content-first-part">
    <p>{{ $t("app.homeProtectPage.forms.agreementForm.smsFrom1414GivePermission-1") }}</p>
    <p>{{ $t("app.homeProtectPage.forms.agreementForm.smsFrom1414GivePermission-2") }}</p>
  </div>
  <img
    v-if="propertyStatusData?.status !== 1"
    class="loading-icon"
    src="/main-front/images/loading-gif.gif"
    height="40"
    width="40"
    alt="loading" />
  <p class="agreement-form__please-wait-text">
    {{ $t("app.homeProtectPage.forms.agreementForm.youNeedToWait") }}
  </p>
  <div class="agreement-form__buttons">
    <FFButton @click="onBack" :title="t('app.buttons.back')" width="100%" :uppercase="false" />
  </div>
</template>

<style scoped lang="scss">
.agreement-form__title {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.agreement-form__content-first-part {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  p {
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  p:last-of-type {
    margin-bottom: 20px;
  }
}
.loading-icon {
  margin: 0 auto 8px;
}
.agreement-form__buttons {
  display: flex;
  width: 100%;
  gap: 10px;
}
.agreement-form__please-wait-text {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}
</style>
