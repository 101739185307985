import {ref, type Component, computed, watch, type ComputedRef, readonly} from "vue"
import {useI18n} from "vue-i18n"
import {useRouter} from "vue-router"
import {defineStore} from "pinia"
import {useRoute} from "vue-router"
import {useModalStore} from "@/stores/ModalStore"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {usePdfOpener} from "@/composables/usePdfOpener"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import {debounce, lowerCase} from "lodash-es"
import {useToast} from "vue-toastification"
import {driverLicense, identityCard} from "@/constants/documents"
import {pushGtagEvent} from "@/utils/pushGtagEvent"
import {usePaymentStore} from "@/stores/Payment"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {isDev} from "@/main"
import type {IInputIINState, ICalcObj, IClient, IFinalPay} from "@/interfaces/Ogpo/index"
import OgpoForms from "@/components/Ogpo/OgpoForms.vue"


export const useOgpoStore = defineStore("ogpoStore", () => {
  const {getData, postData, loading} = useGatewayFetcher()
  const {isDesktop} = useDeviceSize()
  const toast = useToast()
  const router = useRouter()
  const route = useRoute()

  const modalStore = useModalStore()
  const {paymentMethodsWithSuperApp} = usePaymentStore()

  const {phoneNumberFormatter, dateFormatter} = useFormatters()
  const {currentLocale, openLink} = useHelpers()
  const {openKdpAgreementPdf, openQuestionnairePdf} = usePdfOpener(postData)
  const {t} = useI18n()
  const isScoring = ref<boolean>(false)

  //Form steps
  const currentStep = ref<number>(1)

  const handleStepNext = (): void => {
    currentStep.value += 1
  }
  const handleStepBack = (): void => {
    currentStep.value -= 1
  }
  const resetStep = (): void => {
    currentStep.value = 1
  }

  //Calculation form
  const phone = ref<string>("")

  const autonumtechpass = ref<string>("")
  const autonumtechpassInputStates = ref<string[]>([])
  const autonumDuplicateMessage = ref<string>("")

  const carName = ref<string>("")
  const carNames = ref<string[]>([])
  const carsYearOfIssue = ref<{regNum: string; year: number}[]>([])

  const isAutoNumsLoadingState = ref<boolean>(false)
  const isAutoNumsLoadingStates = ref<boolean[]>([])

  const filteredCarsYearOfIssue = computed(() => {
    const allRegNums = [autonumtechpass.value, ...autonumtechpassInputStates.value].map((num) => num.toUpperCase())
    carsYearOfIssue.value = carsYearOfIssue.value.filter((car) => allRegNums.includes(car.regNum.toUpperCase()))
    return carsYearOfIssue.value
  })

  const isCarOlder = computed(() => {
    const currentYear = new Date().getFullYear()
    return filteredCarsYearOfIssue.value.some((car) => {
      const carAge = currentYear - car.year
      return carAge > 10
    })
  })

  const isPhoneFilled = computed((): boolean => {
    if (phone?.value.length === 17) {
      pushGtagEvent("ogpo_nomer_telefona")
      return true
    } else {
      return false
    }
  })

  const isCarNumberFilled = computed((): boolean => {
    if (autonumtechpass.value.length >= 3 && autonumtechpassInputStates.value.length === 0) {
      return true
    } else if (
      autonumtechpass.value.length >= 3 &&
      autonumtechpassInputStates.value.every((state) => state.length >= 3)
    ) {
      return true
    } else {
      return false
    }
  })

  const isIinNumberFilled = computed((): boolean => {
    if (iin.value.length === 12 && iinInputStates.value.length === 0) {
      return true
    } else if (iin.value.length === 12 && iinInputStates.value.every((state) => state.iin?.length === 12)) {
      return true
    } else {
      return false
    }
  })

  const pushInputToAutonumtechpassArr = (): void => {
    if (iinInputStates.value.length >= 1) {
      toast.clear()
      toast.error(t("app.toasterMessages.carNumCheck.cantAddMoreAuto"))
      return
    }

    if (autonumtechpass.value === "") {
      toast.clear()
      toast.error(t("app.toasterMessages.carNumCheck.currentNumberCannotBeEmpty"))
      return
    }

    if (autonumtechpassInputStates.value.length >= 9) {
      toast.clear()
      toast.error(t("app.toasterMessages.carNumCheck.cannotAddMoreThanTenNums"))
      return
    }

    if (
      autonumtechpassInputStates.value.length > 0 &&
      autonumtechpassInputStates.value[autonumtechpassInputStates.value.length - 1] === ""
    ) {
      toast.clear()
      toast.error(t("app.toasterMessages.carNumCheck.cannotAddNewFieldIfPrevNotFilled"))
      return
    }

    autonumtechpassInputStates.value.push("")
  }

  const removeAutonumtechpassState = (index: number, regNum?: string): void => {
    autonumtechpassInputStates.value = autonumtechpassInputStates.value.filter((_, idx) => idx !== index)
    carNames.value = carNames.value.filter((_, idx) => idx !== index)
    carsYearOfIssue.value = carsYearOfIssue.value.filter((_) => _.regNum !== regNum)
  }

  let isInputChanged = ref(true)
  watch(autonumtechpass, (newValue) => {
    if (newValue.length < 3) {
      carName.value = ""
      isInputChanged.value = true
    }
  })

  const autonumtechpasCheck = async (data) => {
    if (!data) {
      carName.value = ""
      return
    }

    const isDuplicateInArray = autonumtechpassInputStates.value.some((item) => item === data)
    if (isDuplicateInArray) {
      toast.error("Этот номер уже был введён")
      autonumtechpass.value = ""
      carName.value = ""
      return
    }

    isAutoNumsLoadingState.value = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/object/car/check",
        {
          regNum: data.toUpperCase()
        },
        config
      )
      if (response && response.data && response.status === 200) {
        pushGtagEvent("ogpo_nomer_avto")
        carName.value = response.data.data.car
        carsYearOfIssue.value.push({
          regNum: data,
          year: response.data.data.year
        })
      } else {
        carName.value = ""
        autonumtechpass.value = ""
      }
    } catch (error) {
      carName.value = ""
      autonumtechpass.value = ""
      console.error(error)
    } finally {
      isAutoNumsLoadingState.value = false
    }
  }

  const handleAutonumBlur = () => {
    autonumtechpasCheck(autonumtechpass.value)
  }

  watch(
    autonumtechpassInputStates,
    (newVal) => {
      newVal.forEach((data, index) => {
        if (data.length < 3) {
          carNames.value[index] = ""
        }
      })
    },
    {deep: true}
  )

  const autonumtechpassCheck = async (data, index) => {
    if (!data) {
      carNames.value[index] = ""
      return
    }

    const isDuplicateInArray = autonumtechpassInputStates.value.some((item, idx) => item === data && idx !== index)
    const isDuplicateWithCurrent = autonumtechpass.value === data
    if (isDuplicateInArray || isDuplicateWithCurrent) {
      autonumtechpassInputStates.value[index] = ""
      carNames.value[index] = ""
      return
    }

    isAutoNumsLoadingStates.value[index] = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/object/car/check",
        {
          regNum: data.toUpperCase()
        },
        config
      )
      if (response && response.data && response.status === 200) {
        pushGtagEvent("ogpo_nomer_avto")
        carNames.value[index] = response.data.data.car
        carsYearOfIssue.value.push({
          regNum: data,
          year: response.data.data.year
        })
      } else {
        carNames.value[index] = ""
        autonumtechpassInputStates.value[index] = ""
      }
    } catch (error) {
      carNames.value[index] = ""
      autonumtechpassInputStates.value[index] = ""
      console.error(error)
    } finally {
      isAutoNumsLoadingStates.value[index] = false
    }
  }

  const handleArrayAutonumBlur = (index) => {
    return () => {
      autonumtechpassCheck(autonumtechpassInputStates.value[index], index)
    }
  }

  //iin
  const iin = ref<string>("")
  const iinInputStates = ref<IInputIINState[]>([])

  const iinName = ref<string>("")
  const iinNames = ref<string[]>([])

  const isIinLoadingState = ref<boolean>(false)
  const isIinLoadingStates = ref<boolean[]>([false])

  const pushInputToIINArr = () => {
    if (autonumtechpassInputStates.value.length >= 1) {
      toast.clear()
      toast.error(t("app.toasterMessages.iinNumCheck.cantAddMoreIIN"))
      return
    }

    if (iin.value === "") {
      toast.clear()
      toast.error(t("app.toasterMessages.iinNumCheck.currentIINCannotBeEmpty"))
      return
    }

    if (iinInputStates.value.length >= 10) {
      toast.clear()
      toast.error(t("app.toasterMessages.iinNumCheck.cannotAddMoreThanTenIINs"))
      return
    }

    if (iinInputStates.value.some((state) => state.iin === "")) {
      toast.clear()
      toast.error(t("app.toasterMessages.iinNumCheck.cannotAddNewFieldIfPrevNotFilled"))
      return
    }

    iinInputStates.value.push({
      iin: "",
      perks: false
    })
  }

  const removeIINState = (index: number) => {
    iinInputStates.value = iinInputStates.value.filter((_, idx) => idx !== index)
  }

  //for single iin num check
  const iinCheck = debounce(async (data: string) => {
    if (!data) {
      iinName.value = ""
      return
    }

    const isDuplicateInArray = iinInputStates.value.some((item) => item.iin === data)
    if (isDuplicateInArray) {
      toast.clear()
      toast.error("Этот ИИН уже был введён")
      iin.value = ""
      iinName.value = ""
      return
    }
    isIinLoadingState.value = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (data.length === 12) {
        const verifyResponse = await postData(
          "/portal/mobile/verify-phone-info",
          {
            phone: phoneNumberFormatter(phone.value),
            iin: iin.value
          },
          config
        )

        if (verifyResponse && verifyResponse.status === 200) {
          const response = await postData(
            "/object/client/check",
            {
              iin: data,
              clientType: "Individual",
              isDriver: willManageCar.value
            },
            config
          )
          if (response && response.data && response.status === 200) {
            pushGtagEvent("pole_iin")
            iinName.value = response.data.data.client
          } else {
            iinName.value = ""
            iin.value = ""
          }
        } else {
          iin.value = ""
        }
      } else {
        iinName.value = ""
      }
      isIinLoadingState.value = false
    } catch (error) {
      iin.value = ""
      iinName.value = ""
      isIinLoadingState.value = false
      console.error(error)
    }
  }, 500)

  //for array states iin check
  const iinsCheck = debounce(async (data: string, index: number) => {
    if (!data) {
      iinNames.value[index] = ""
      return
    }

    const isDuplicateInArray = iinInputStates.value.some((item, idx) => item.iin === data && idx !== index)
    const isDuplicateWithCurrent = iin.value === data
    if (isDuplicateInArray || isDuplicateWithCurrent) {
      iinInputStates.value[index].iin = ""
      iinNames.value[index] = ""
      return
    }

    isIinLoadingStates.value[index] = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (data.length === 12) {
        const response = await postData(
          "/object/client/check",
          {
            iin: data,
            clientType: "Individual"
          },
          config
        )
        if (response && response.data && response.status === 200) {
          pushGtagEvent("pole_iin")
          iinNames.value[index] = response.data.data.client
        } else {
          iinNames.value[index] = ""
          iinInputStates.value[index].iin = ""
        }
      } else {
        iinNames.value[index] = ""
      }
      isIinLoadingStates.value[index] = false
    } catch (error) {
      iinInputStates.value[index].iin = ""
      iinNames.value[index] = ""
      isIinLoadingStates.value[index] = false
      console.error(error)
    }
  }, 500)

  //Individual value params
  const benefits = ref<boolean>(false)
  const willManageCar = ref<boolean>(true)

  const date = ref(new Date(new Date().setDate(new Date().getDate() + 1)))

  const month = ref({name: computed(() => t("app.ogpoPage.forms.month-12")), value: 12})
  const monthes = ref([
    {name: computed(() => t("app.ogpoPage.forms.month-12")), value: 12},
    {name: computed(() => t("app.ogpoPage.forms.month-11")), value: 11},
    {name: computed(() => t("app.ogpoPage.forms.month-10")), value: 10},
    {name: computed(() => t("app.ogpoPage.forms.month-9")), value: 9},
    {name: computed(() => t("app.ogpoPage.forms.month-8")), value: 8},
    {name: computed(() => t("app.ogpoPage.forms.month-7")), value: 7},
    {name: computed(() => t("app.ogpoPage.forms.month-6")), value: 6}
  ])

  const isSeasonalOperationVehicle = ref<boolean>(false)

  const showSeasonalOperationVehicle = computed(() => {
    return month.value.value !== 12
  })

  const isCalculationBtnDisabled = computed(() => {
    const commonConditions =
      isPhoneFilled.value &&
      isCarNumberFilled.value &&
      isIinNumberFilled.value &&
      date.value !== null &&
      month.value !== null &&
      isAutoNumsLoadingState.value === false &&
      isAutoNumsLoadingStates.value.every((state) => state === false) &&
      isIinLoadingState.value === false &&
      isIinLoadingStates.value.every((state) => state === false)

    if (showSeasonalOperationVehicle.value) {
      return commonConditions && isSeasonalOperationVehicle.value
    }

    return commonConditions
  })

  watch(month, (newVal) => {
    if (newVal.value === 12) {
      isSeasonalOperationVehicle.value = false
    }
  })

  //Admitad data from query params
  const admitadUid = route.query.admitad_uid ?? ""
  const utmSource = route.query.utm_source ?? ""
  const utmMedium = route.query.utm_medium ?? ""
  const utmCompany = route.query.utm_company ?? ""

  const createClient = () => {
    const firstClient = {
      iinBin: iin.value,
      isMain: true,
      isDriver: willManageCar.value,
      isPrivilege: benefits.value
    }
    const otherClients = iinInputStates.value.map((state, index) => ({
      iinBin: state.iin,
      isMain: false,
      isDriver: true,
      isPrivilege: state.perks
    }))
    return [firstClient, ...otherClients]
  }

  const calculationObject = ref<null | ICalcObj>(null)
  const driverLicenseNotFound = ref<boolean>(false)
  const driverLicenseNotFoundResponse = ref()
  const orderId = ref<number>()
  const calculateCost = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const verifyResponse = await postData(
        "/portal/mobile/verify-phone-info",
        {
          phone: phoneNumberFormatter(phone.value),
          iin: iin.value
        },
        config
      )
      if (verifyResponse && verifyResponse.status === 200) {
        const payload = {
          phone: phoneNumberFormatter(phone.value),
          cars: [{regNum: autonumtechpass.value}, ...autonumtechpassInputStates.value.map((regNum) => ({regNum}))],
          clients: createClient(),
          fromDate: dateFormatter(date.value),
          period: month.value.value,
          ogpoPlus: false,
          admitad_uid: admitadUid,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_company: utmCompany
        }

        const response = await postData("/policy/ogpo/calculate", payload, config)
        if (response && response.data && response.status === 200) {
          pushGtagEvent("ogpo_form_step1")
          addOgpoPlus.value = false
          identificationCardNotFound.value = false
          driverLicenseNotFound.value = false
          calculationObject.value = response.data
          orderId.value = calculationObject.value.orderId

          if (needKdp.value) {
            const notVerificatedUsersResponse = await fetchNotVerifiedIins()
            if (!notVerificatedUsersResponse) {
              return
            }
          }

          if (calculationObject.value.isScoring) {
            isScoring.value = true
          }

          modalStore.openModal({
            component: OgpoForms
          })
          handleStepNext()

          return true
        }
      }
    } catch (error) {
      if (error.response.status === 400 && error.response.data.errors?.type === driverLicense) {
        driverLicenseNotFound.value = true
        driverLicenseNotFoundResponse.value = error.response.data.errors
        return
      }
      if (error.response.status === 400 && error.response.data.errors?.type === identityCard) {
        const policyHolder = {iin: iin.value, clientType: "individual"}
        const otherClient = {iin: error.response.data.errors?.iin, clientType: "individual"}
        const clients = [policyHolder, otherClient]
        const response = await postData("/portal/get-client-data", {clients}, config)
        identificationCardNotFoundResponse.value = error.response.data.errors
        identificationCardNotFound.value = true

        if (response && response.status === 200) {
          identificationCardNotVerifiedUsers.value = response?.data?.data
          if (identificationCardNotFound.value) {
            modalStore.openModal({
              component: OgpoForms
            })
          }
        }
        return
      }
      console.error(error)
      return false
    }
  }

  //Identification card form
  const identificationCardNotFound = ref<boolean>(false)
  const identificationCardNotFoundResponse = ref()
  const identificationCardNotVerifiedUsers = ref<{iin: string; fullName: string; verifyClient: boolean}[]>([])

  const identificationCardObj = ref({
    iin: "",
    documentGivedDate: null,
    documentNumber: ""
  })

  const resetIdentificationCardObj = () => {
    identificationCardObj.value = {
      iin: "",
      documentGivedDate: null,
      documentNumber: ""
    }
  }

  const setIdentificationCardNewClientData = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const payload = {
        documentType: "IdentityCard",
        iin: identificationCardNotVerifiedUsers.value[index].iin
      }
      const response = await postData("/object/client/set", payload, config)
      if (response && response.status === 200) {
        await calculateCost()
      } else {
        return
      }
    } catch (error) {
      console.error(error)
    }
  }

  const sendIdentificationCard = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const response = await postData(
        "/object/client/set",
        {
          iin: identificationCardNotFoundResponse.value.iin,
          documentGivedDate: dateFormatter(identificationCardObj?.value?.documentGivedDate),
          documentNumber: driverLicenseObj.value.documentNumber,
          documentType: identificationCardNotFoundResponse.value.type
        },
        config
      )
      if (response?.status === 200) {
        identificationCardNotFound.value = false
      } else {
        return
      }
    } catch (error) {
      console.error(error)
      return
    }
  }

  //Agreement form
  const needKdp = ref<boolean>(true)
  const verificationUsersData = ref<{fullName: string; iin: string; verifyClient: boolean}[]>([])
  const agreementLoaderHide = ref<boolean>(false)

  const handleKdp = (): void => {
    needKdp.value = false
  }

  const fetchNotVerifiedIins = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const firstClient = {iin: iin.value, clientType: "individual"}
      const otherClients = iinInputStates.value.map((state) => ({iin: state.iin, clientType: "individual"}))
      const clients = [firstClient, ...otherClients]
      const response = await postData("/portal/get-client-data", {clients}, config)
      if (response?.status === 200) {
        verificationUsersData.value = response.data.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const notVerificatedUsers = computed(() => {
    if (calculationObject.value?.isScoring) {
      return verificationUsersData.value
    }

    const policyHolder = verificationUsersData.value.find((user) => user.iin === iin.value)

    if (policyHolder && !policyHolder.verifyClient) {
      return [
        {
          fullName: policyHolder.fullName,
          iin: policyHolder.iin,
          verifyClient: policyHolder.verifyClient
        }
      ]
    }

    return []
  })

  const allUsersAreVerificated = computed(() => {
    return notVerificatedUsers.value.every((verficated) => verficated.verifyClient)
  })

  const intervalIds = ref<Record<number, number | undefined>>({})
  const clearAllIntervals = () => {
    Object.keys(intervalIds.value).forEach((index) => {
      clearInterval(intervalIds.value[Number(index)])
      delete intervalIds.value[Number(index)]
    })
  }

  const sendCode = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = notVerificatedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const sendIdentificationCardCode = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = identificationCardNotVerifiedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const openOgpoAgreementPdf = async (fullName: string, iin: string) => {
    openKdpAgreementPdf(fullName, iin)
  }

  const openOgpoQuestionnairePdf = async (calcId: string) => {
    openQuestionnairePdf("/policy/ogpo/get-form", {calcId})
  }

  const openOgpoPlusQuestionnairePdf = async (calcId: string) => {
    openQuestionnairePdf("/policy/ogpo/get-form-ogpo-plus", {calcId})
  }

  //Ogpo form
  const addOgpoPlus = ref<boolean>(false)
  const nomadClubMember = ref<boolean>(false)
  const ogpoPlusSum = ref<null | number>(null)

  const executionForm = ref({
    email: "",
    iAgreeWithOgpoPlusAgreement: false,
    iAgreeWithAgreement: false,
    nomadCardNumber: ""
  })

  const calculationObjectWhenOgpoPlus = ref(null)
  const calculateCostWhenOgpoPlus = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const payload = {
        calcId: calculationObject.value?.calcId,
        phone: phoneNumberFormatter(phone.value),
        cars: [{regNum: autonumtechpass.value}, ...autonumtechpassInputStates.value.map((regNum) => ({regNum}))],
        clients: createClient(),
        fromDate: dateFormatter(date.value),
        period: month.value.value,
        ogpoPlus: addOgpoPlus.value
      }

      const response = await postData("/policy/ogpo/calculate", payload, config)
      if (response && response.data && response.status === 200) {
        if (addOgpoPlus.value) {
          pushGtagEvent("dobavit_k_stoimosti_step2")
        }
        calculationObjectWhenOgpoPlus.value = response.data
        orderId.value = calculationObjectWhenOgpoPlus.value.orderId
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const calculateCostWithoutOgpoPlus = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const payload = {
        phone: phoneNumberFormatter(phone.value),
        cars: [{regNum: autonumtechpass.value}, ...autonumtechpassInputStates.value.map((regNum) => ({regNum}))],
        clients: createClient(),
        fromDate: dateFormatter(date.value),
        period: month.value.value,
        ogpoPlus: addOgpoPlus.value
      }

      const response = await postData("/policy/ogpo/calculate", payload, config)
      if (response && response.data && response.status === 200) {
        calculationObject.value = response.data
        orderId.value = calculationObject.value.orderId
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const calculateSumWithOgpoPlusOrWithout = computed(() => {
    const premium = calculationObject.value?.premium ?? 0
    const insPremium = calculationObjectWhenOgpoPlus.value?.insPremium ?? 0

    if (addOgpoPlus.value) {
      return (ogpoPlusSum.value = premium + insPremium)
    } else {
      return (ogpoPlusSum.value = premium)
    }
  })

  const calculateCashbackWithOgpoPlusOrWithout = computed(() => {
    const firstCashbackAmount = calculationObject.value?.cashbackAmount ?? 0
    const secondCashbackAmount = calculationObjectWhenOgpoPlus.value?.cashbackAmount ?? 0

    // if (addOgpoPlus.value) {
    //   return secondCashbackAmount
    // } else {
    //   return firstCashbackAmount
    // }
    if (addOgpoPlus.value) {
      return firstCashbackAmount
    } else {
      return firstCashbackAmount
    }
  })

  const calculateCashbackPriceWithOgpoOrWithout = computed(() => {
    const firstCashbackPrice = calculationObject.value?.cashbackPrice ?? 0
    const secondCashbackPrice = calculationObjectWhenOgpoPlus.value?.cashbackPrice ?? 0
    // if (addOgpoPlus.value) {
    //   return secondCashbackPrice
    // } else {
    //   return firstCashbackPrice
    // }
    if (addOgpoPlus.value) {
      return firstCashbackPrice
    } else {
      return firstCashbackPrice
    }
  })

  //Payment methods

  const selectedPayment = ref<string>("")

  const showKaspiForm = ref<boolean>(false)
  const showSuperAppBlock = ref<boolean>(false)

  watch(
    () => calculationObject?.value?.isScoring,
    (newValue) => {
      if (newValue) {
        const freedomPayMethod = paymentMethodsWithSuperApp.find((method) => method.type === "FreedomPay")
        if (freedomPayMethod) {
          selectedPayment.value = freedomPayMethod.type
        }
      }
    },
    {immediate: true}
  )

  const handleCurrentMethod = (type: string) => {
    selectedPayment.value = type
  }

  //Kaspi payment form
  const kaspiPayPhoneNumber = ref<string>("")

  //Final pay form
  const paymentUrl = ref<string>("")

  const finalPay = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const firstClient: IClient = {
        iinBin: iin.value
      }
      const otherClients: IClient[] = iinInputStates.value.map((state, index) => ({
        iinBin: state.iin || ""
      }))
      const payload = {
        calc_id: calculationObject?.value?.calcId,
        orderId: addOgpoPlus.value
          ? String(calculationObjectWhenOgpoPlus?.value?.orderId)
          : String(calculationObject?.value?.orderId),
        premium: ogpoPlusSum.value,
        phone: phoneNumberFormatter(phone.value),
        iin: iin.value,
        clientType: "individual",
        email: executionForm.value.email,
        payment_title: selectedPayment.value,
        payment_type: selectedPayment.value,
        kaspiPayNumber: kaspiPayPhoneNumber.value ? phoneNumberFormatter(kaspiPayPhoneNumber.value) : "",
        insureds: [firstClient, ...otherClients],
        gived_consent: true
      }
      const response = await postData("/policy/ogpo/order/create", payload, config)
      if (response && response.status === 200) {
        if (!calculationObject.value?.isScoring) {
          localStorage.setItem("paymentType", selectedPayment.value)
          localStorage.setItem("cashbackAmount", JSON.stringify(calculationObject.value?.cashbackAmount))
          localStorage.setItem("cashbackBalance", JSON.stringify(calculationObject.value?.cashbackBalance))
          localStorage.setItem("isClientBank", JSON.stringify(calculationObject.value?.isClientBank))
          localStorage.setItem("isScoring", JSON.stringify(calculationObject.value?.isScoring))
          localStorage.setItem("productType", JSON.stringify("Ogpo"))
        }

        if (selectedPayment.value === "FreedomPay") {
          paymentUrl.value = response.data?.payment_url
          window.location.href = paymentUrl.value
          phone.value = ""
        }
        if (selectedPayment.value === "FreedomSuperApp") {
          paymentUrl.value = response.data?.payment_url

          if (isDesktop.value) {
            showSuperAppBlock.value = true
          } else {
            openLink(paymentUrl.value, "_self")
          }
        }
        if (selectedPayment.value === "KaspiPay") {
          localStorage.setItem("paymentType", "KaspiPay")
          modalStore.closeModal()
          router.replace("success-pay")
        }
      }
    } catch (error) {
      if (error.response.status === 400 && !error.response.data.errors?.is_valid) {
        resetStates()
        modalStore.closeModal()
        return
      }
      console.error(error)
    }
  }

  const resetStates = () => {
    currentStep.value = 1
    needKdp.value = false
    phone.value = ""
    autonumtechpass.value = ""
    autonumtechpassInputStates.value = []
    autonumDuplicateMessage.value = ""
    carName.value = ""
    carNames.value = []
    isAutoNumsLoadingState.value = false
    isAutoNumsLoadingStates.value = []
    isInputChanged.value = true
    iin.value = ""
    iinInputStates.value = []
    iinName.value = ""
    iinNames.value = []
    isIinLoadingState.value = false
    isIinLoadingStates.value = [false]
    benefits.value = false
    willManageCar.value = true
    date.value = new Date(new Date().setDate(new Date().getDate() + 1))
    month.value = {name: computed(() => t("app.ogpoPage.forms.month-12")), value: 12}
    isSeasonalOperationVehicle.value = false
    calculationObject.value = null
    driverLicenseNotFoundResponse.value = null
    driverLicenseNotFound.value = false
    identificationCardNotFound.value = false
    identificationCardNotFoundResponse.value = null
    identificationCardNotVerifiedUsers.value = []
    resetIdentificationCardObj()
    verificationUsersData.value = []
    agreementLoaderHide.value = false
    executionForm.value.email = ""
    executionForm.value.iAgreeWithOgpoPlusAgreement = false
    executionForm.value.iAgreeWithAgreement = false
    selectedPayment.value = ""
    showKaspiForm.value = false
    kaspiPayPhoneNumber.value = ""
    paymentUrl.value = ""
  }
  return {
    loading,
    currentStep,
    needKdp,
    phone,
    autonumtechpass,
    autonumtechpassInputStates,
    autonumDuplicateMessage,
    carName,
    carNames,
    iin,
    iinInputStates,
    iinName,
    iinNames,
    benefits,
    willManageCar,
    date,
    month,
    monthes,
    isSeasonalOperationVehicle,
    showSeasonalOperationVehicle,
    agreementLoaderHide,
    notVerificatedUsers,
    allUsersAreVerificated,
    intervalIds,
    addOgpoPlus,
    nomadClubMember,
    ogpoPlusSum,
    executionForm,
    calculateSumWithOgpoPlusOrWithout,
    calculateCashbackWithOgpoPlusOrWithout,
    calculateCashbackPriceWithOgpoOrWithout,
    selectedPayment,
    driverLicenseNotFoundResponse,
    identificationCardNotFound,
    identificationCardNotFoundResponse,
    identificationCardNotVerifiedUsers,
    identificationCardObj,
    showKaspiForm,
    showSuperAppBlock,
    kaspiPayPhoneNumber,
    isAutoNumsLoadingState,
    isAutoNumsLoadingStates,
    isInputChanged,
    isCarOlder,
    isPhoneFilled,
    isCarNumberFilled,
    isIinNumberFilled,
    isCalculationBtnDisabled,
    isIinLoadingState,
    isIinLoadingStates,
    calculationObject,
    calculationObjectWhenOgpoPlus,
    paymentUrl,
    isScoring,

    handleKdp,
    handleStepNext,
    handleStepBack,
    resetStep,
    resetIdentificationCardObj,
    sendIdentificationCard,
    fetchNotVerifiedIins,
    pushInputToAutonumtechpassArr,
    handleAutonumBlur,
    handleArrayAutonumBlur,
    removeAutonumtechpassState,
    autonumtechpasCheck,
    autonumtechpassCheck,
    pushInputToIINArr,
    removeIINState,
    calculateCostWhenOgpoPlus,
    calculateCostWithoutOgpoPlus,
    setIdentificationCardNewClientData,
    handleCurrentMethod,
    clearAllIntervals,
    sendCode,
    sendIdentificationCardCode,
    openOgpoAgreementPdf,
    openOgpoQuestionnairePdf,
    openOgpoPlusQuestionnairePdf,
    iinCheck,
    iinsCheck,
    calculateCost,
    finalPay,
    resetStates
  }
})
