<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

const {scrollToTop} = useHelpers()
</script>

<template>
  <section class="home-protect-limited-offer container">
    <div class="home-protect-limited-offer__texts">
      <p class="home-protect-limited-offer__title">{{ $t("app.homeProtectPage.limitedOffer.title") }}</p>
      <p class="home-protect-limited-offer__subtitle">{{ $t("app.homeProtectPage.limitedOffer.subtitle") }}</p>
      <p class="home-protect-limited-offer__description">{{ $t("app.homeProtectPage.limitedOffer.description") }}</p>
    </div>

    <FFButton @click="scrollToTop" :title="$t('app.buttons.getInsuranceNow')" maxWidth="290px" />
  </section>
</template>

<style scoped lang="scss">
.home-protect-limited-offer {
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: relative;
  z-index: 1;
  .home-protect-limited-offer__texts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .home-protect-limited-offer__title {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 40px;
      text-align: center;
      background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .home-protect-limited-offer__subtitle {
      color: #ffffff;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    .home-protect-limited-offer__description {
      color: #dfdfdf;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ff-button-wrapper {
    margin: auto;
  }
}
</style>
