<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <g filter="url(#filter0_i_2820_20168)">
      <path
        d="M4 28.4983C4 29.5592 4.42143 30.5766 5.17157 31.3268C5.92172 32.0769 6.93913 32.4983 8 32.4983H32C33.0609 32.4983 34.0783 32.0769 34.8284 31.3268C35.5786 30.5766 36 29.5592 36 28.4983V17.4983H4V28.4983ZM8.71429 23.0698C8.71429 22.5014 8.94005 21.9564 9.34191 21.5545C9.74378 21.1527 10.2888 20.9269 10.8571 20.9269H14.2857C14.854 20.9269 15.3991 21.1527 15.8009 21.5545C16.2028 21.9564 16.4286 22.5014 16.4286 23.0698V24.4983C16.4286 25.0666 16.2028 25.6117 15.8009 26.0136C15.3991 26.4154 14.854 26.6412 14.2857 26.6412H10.8571C10.2888 26.6412 9.74378 26.4154 9.34191 26.0136C8.94005 25.6117 8.71429 25.0666 8.71429 24.4983V23.0698ZM32 7.35547H8C6.93913 7.35547 5.92172 7.7769 5.17157 8.52704C4.42143 9.27719 4 10.2946 4 11.3555V13.2126H36V11.3555C36 10.2946 35.5786 9.27719 34.8284 8.52704C34.0783 7.7769 33.0609 7.35547 32 7.35547Z"
        fill="url(#paint0_linear_2820_20168)" />
    </g>
    <defs>
      <filter
        id="filter0_i_2820_20168"
        x="4"
        y="7.35547"
        width="35.4666"
        height="28.6111"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="3.46658" dy="3.46658" />
        <feGaussianBlur stdDeviation="1.73329" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2820_20168" />
      </filter>
      <linearGradient
        id="paint0_linear_2820_20168"
        x1="36"
        y1="2.9185"
        x2="-0.167816"
        y2="26.8902"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
    </defs>
  </svg>
</template>
