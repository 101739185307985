<script setup lang="ts">
import {ref, computed} from "vue"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useHelperStore} from "@/stores/HelperStore"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"
import JuridicalHero from "@/components/JuridicalPages/JuridicalHero.vue"
import JuridicalUniqueAbilities from "@/components/JuridicalPages/JuridicalUniqueAbilities.vue"
import JuridicalInsuranceConditions from "@/components/JuridicalPages/JuridicalInsuranceConditions.vue"
import AboutCompanyStatistics from "@/components/AboutCompany/AboutCompanyStatistics.vue"
import JuridicalGetPolicyBeCalm from "@/components/JuridicalPages/JuridicalGetPolicyBeCalm.vue"
import JuridicalForm from "@/components/JuridicalPages/JuridicalForm.vue"
import DarkGradientGreenUserProfileIcon from "@/components/icons/greens/DarkGradientGreenUserProfileIcon.vue"
import DarkGradientGreenStarShieldIcon from "@/components/icons/greens/DarkGradientGreenStarShieldIcon.vue"
import DarkGradientGreenLegislation from "@/components/icons/greens/DarkGradientGreenLegislationIcon.vue"

const helperStore = useHelperStore()
const {loading} = storeToRefs(helperStore)
const {t} = useI18n()

const juridicalInsuranceConditions = ref([
  {
    name: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.name1"
      )
    ),
    text: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.text1"
      )
    )
  },
  {
    name: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.name2"
      )
    ),
    text: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.text2"
      )
    )
  },
  {
    name: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.name3"
      )
    ),
    text: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.text3"
      )
    )
  },
  {
    name: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.name4"
      )
    ),
    text: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.text4"
      )
    )
  },
  {
    name: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.name5"
      )
    ),
    text: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.text5"
      )
    )
  },
  {
    name: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.name6"
      )
    ),
    text: computed(() =>
      t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.text6"
      )
    )
  }
])
</script>

<template>
  <Modal style="z-index: 101" :isOpen="loading">
    <Loader />
  </Modal>

  <JuridicalHero
    heroBackgroundImage="url(/juridical-pages-front/images/strakhovanie-otvetstvennosti/obyazatelnoe-strakhovanie-gpo-vladeltsev-opasnykh-obektov-hero-bg.png)"
    heroBackgroundImageMobile="/juridical-pages-front/images/strakhovanie-otvetstvennosti/obyazatelnoe-strakhovanie-gpo-vladeltsev-opasnykh-obektov-hero-bg-mobile.png">
    <template #title>{{
      $t("juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.hero.title")
    }}</template>
    <template #description-1>{{
      $t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.hero.description"
      )
    }}</template>
  </JuridicalHero>

  <JuridicalUniqueAbilities>
    <template #title>{{
      $t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.uniqueAbilities.title"
      )
    }}</template>

    <template #icon1><DarkGradientGreenLegislation /></template>
    <template #name1>{{
      $t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.uniqueAbilities.name1"
      )
    }}</template>
    <template #description1>{{
      $t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.uniqueAbilities.description1"
      )
    }}</template>

    <template #icon2><DarkGradientGreenUserProfileIcon /></template>
    <template #name2>{{
      $t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.uniqueAbilities.name2"
      )
    }}</template>
    <template #description2>{{
      $t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.uniqueAbilities.description2"
      )
    }}</template>

    <template #icon3><DarkGradientGreenStarShieldIcon /></template>
    <template #name3>{{
      $t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.uniqueAbilities.name3"
      )
    }}</template>
    <template #description3>{{
      $t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.uniqueAbilities.description3"
      )
    }}</template>
  </JuridicalUniqueAbilities>

  <JuridicalInsuranceConditions :items="juridicalInsuranceConditions">
    <template #title>{{
      $t(
        "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.insuranceConditions.title"
      )
    }}</template>
    <template #content="{item, index}">
      <div v-if="item.list" class="list">
        <ul>
          <li v-for="(listItem, listIndex) in item.list" :key="listIndex">
            {{ listItem.item }}
          </li>
        </ul>
      </div>
      <div v-else class="texts">
        <p>{{ item.text }}</p>
      </div>
    </template>
  </JuridicalInsuranceConditions>

  <div class="statistics">
    <AboutCompanyStatistics />
  </div>

  <JuridicalGetPolicyBeCalm
    :needDescription2="true"
    imagePath="/juridical-pages-front/images/strakhovanie-otvetstvennosti/ekologicheskoe-strakhovanie-bottom-bg.png">
    <template #title>
      {{
        $t(
          "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.beCalm.title"
        )
      }}</template
    >
    <template #description
      >{{
        $t(
          "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.beCalm.description1"
        )
      }}
    </template>
    <template #description-2>
      {{
        $t(
          "juridical.strakhovanieOtvetstvennosti.obyazatelnoeStrakhovanieGpoVladeltsevOpasnykhObektovPage.beCalm.description2"
        )
      }}
    </template>
  </JuridicalGetPolicyBeCalm>

  <JuridicalForm policyName="compulsoryLiabilityForHazardousFacilities" />
</template>

<style scoped lang="scss">
.statistics {
  margin: 150px 0 0;
  .about-company-statistics {
    margin-bottom: 0;
  }
}

.list {
  ul {
    li {
      position: relative;
      padding-left: 15px;
      color: #dbdbdb;
      font-weight: 300;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &::before {
        content: "•";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.texts {
  p {
    color: #dbdbdb;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.4px;
  }
}
</style>
