<script setup lang="ts">
const firstRowStatistics = [
  {
    title: "main.statistics.fifteenYears",
    description: "main.statistics.inTheInsuranceMarket"
  },
  {
    title: "main.statistics.302thousand",
    description: "main.statistics.trustedCustomer"
  },
  {
    title: "586",
    description: "main.statistics.employeesInTheCompany"
  }
]

const secondRowStatistics = [
  {
    title: "202 307",
    description: "main.statistics.polis2023"
  },
  {
    title: "main.statistics.billion",
    description: "main.statistics.insuranseCase"
  },
  {
    title: "kzA-",
    description: "main.statistics.maxPayment"
  }
]
</script>

<template>
  <section class="about-company-statistics container">
    <div class="about-company-statistics__banner">
      <p class="about-company-statistics__text">{{ $t("app.aboutCompanyPage.statistics.banner.text-1") }}</p>
      <p class="about-company-statistics__top-5">{{ $t("app.aboutCompanyPage.statistics.banner.text-2") }}</p>
      <p class="about-company-statistics__text">{{ $t("app.aboutCompanyPage.statistics.banner.text-3") }}</p>
    </div>
    <div class="about-company-statistics__row">
      <div v-for="(item, index) in firstRowStatistics" :key="index" class="about-company-statistics__row-column">
        <p class="about-company-statistics__row-column-stat">{{ $t(item.title) }}</p>
        <p class="about-company-statistics__row-column-description">{{ $t(item.description) }}</p>
      </div>
    </div>
    <div class="about-company-statistics__row">
      <div v-for="(item, index) in secondRowStatistics" :key="index" class="about-company-statistics__row-column">
        <p class="about-company-statistics__row-column-stat">{{ $t(item.title) }}</p>
        <p class="about-company-statistics__row-column-description">{{ $t(item.description) }}</p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.about-company-statistics {
  @include column;
  gap: 20px;
  margin-bottom: 100px;
  .about-company-statistics__banner {
    @include column;
    gap: 30px;
    padding: 50px 20px;
    border-radius: 20px;
    background-image: url("/about-company-front/images/about-company-top5-desktop.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include max-responsive(xl) {
      background-image: url("/about-company-front/images/about-company-top5-mobile.webp");
    }
    .about-company-statistics__text {
      color: var(--color-white);
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @include max-responsive(lg) {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .about-company-statistics__top-5 {
      font-size: 150px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-align: center;
      text-shadow: -10px 10px 30px rgba(0, 0, 0, 0.15);
      -webkit-text-stroke-width: 2;
      -webkit-text-stroke-color: var(--color-black);
      @include main-green-gradient;

      @include max-responsive(xl) {
        @include unset-main-green-gradient;
        color: var(--color-white);
      }

      @include max-responsive(lg) {
        font-size: 70px;
      }
    }
  }

  .about-company-statistics__row {
    padding: 30px;
    @include flex;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid rgba(13, 13, 13, 0.5);
    background: rgba(13, 13, 13, 0.5);

    @include max-responsive(xl) {
      background: rgba(114, 191, 68, 0.4);
    }

    @include max-responsive(lg) {
      @include column;
      align-items: center;
      padding: 10px 20px;
    }

    .about-company-statistics__row-column {
      padding: 20px 0;
      width: 33.3333%;
      @include column;
      gap: 10px;

      @include max-responsive(lg) {
        width: 100%;
      }

      &:nth-child(1) {
        padding-right: 20px;
        @include max-responsive(lg) {
          padding-bottom: 10px;
        }
      }
      &:nth-child(2) {
        padding: 20px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        border-right: 1px solid rgba(255, 255, 255, 0.2);

        @include max-responsive(lg) {
          padding: 10px 0;
          border: 0;
          border-top: 1px solid rgba(255, 255, 255, 0.2);
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
      &:nth-child(3) {
        padding-left: 20px;
        @include max-responsive(lg) {
          padding-left: 0;
          padding-top: 10px;
        }
      }

      .about-company-statistics__row-column-stat {
        font-size: 50px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-align: center;
        @include main-green-gradient;

        @include max-responsive(xl) {
          font-size: 40px;
          color: var(--color-white);
          @include unset-main-green-gradient;
          text-align: start;
        }

        @include max-responsive(lg) {
          font-size: 30px;
        }
      }
      .about-company-statistics__row-column-description {
        color: var(--color-white);
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: center;

        @include max-responsive(xl) {
          text-align: start;
        }

        @include max-responsive(lg) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
