<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

const {scrollTo} = useHelpers()

interface IProps {
  titleMaxWidth?: string
  subtitleMaxWidth?: string
  description1MaxWidth?: string
  description2MaxWidth?: string
  description3MaxWidth?: string

  needSubtitle?: boolean
  needDescription2?: boolean
  needDescription3?: boolean

  heroBackgroundImage: string
  heroBackgroundImageMobile: string
}

const props = withDefaults(defineProps<IProps>(), {
  titleMaxWidth: "920px",
  needSubtitle: false,
  needDescription2: false,
  needDescription3: false
})
</script>

<template>
  <section class="juridical-hero__bg">
    <img :src="heroBackgroundImageMobile" alt="" class="juridical-hero__hero-bg-mobile" />
    <div class="juridical-hero container">
      <h1 class="juridical-hero__title">
        <slot name="title" />
      </h1>
      <p v-if="needSubtitle" class="juridical-hero__subtitle">
        <slot name="subtitle" />
      </p>
      <div class="juridical-hero__description-wrapper">
        <p class="juridical-hero__description">
          <slot name="description-1" />
        </p>
        <p v-if="needDescription2" class="juridical-hero__description">
          <slot name="description-2" />
        </p>
        <p v-if="needDescription3" class="juridical-hero__description">
          <slot name="description-3" />
        </p>
      </div>
      <div class="juridical-hero__button-wrapper">
        <FFButton
          @click="scrollTo('.juridical-form__wrapper')"
          :title="$t('app.buttons.submitApplication')"
          :uppercase="false"
          iconPath="/icons-front/white-right-arrow-icon.svg"
          maxWidth="400px"
          maxWidthMobile="100%"
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          color="var(--color-white)"
          colorMobile="var(--color-white)"
          weight="500"
          weightMobile="500"
          justifyContent="space-between"
          justifyContentMobile="space-between" />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.juridical-hero__bg {
  background-image: v-bind(heroBackgroundImage);
  background-size: cover;
  width: 100%;

  @include max-responsive(lg) {
    background-image: unset;
  }

  .juridical-hero__hero-bg-mobile {
    display: none;

    @include max-responsive(lg) {
      display: flex;
      min-height: 250px;
      width: 100%;
    }
  }

  .juridical-hero {
    @include column;
    justify-content: center;
    min-height: 650px;

    @include max-responsive(lg) {
      min-height: 475px;
      margin-top: 20px;
    }

    .juridical-hero__title {
      font-size: 60px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      @include main-green-gradient;

      @include max-responsive(lg) {
        font-size: 36px;
        line-height: 43.2px;
      }
    }
    .juridical-hero__subtitle {
      color: var(--color-white);
      margin-top: 20px;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;

      @include max-responsive(lg) {
        font-size: 26px;
      }
    }
    .juridical-hero__description-wrapper {
      @include column;
      gap: 10px;
      margin: 20px 0;
      .juridical-hero__description {
        color: #dbdbdb;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
      }
    }
    .juridical-hero__button-wrapper {
      @include flex;
      gap: 20px;

      @include max-responsive(lg) {
        @include column;
      }
    }
  }
}
</style>
