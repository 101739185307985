<script setup lang="ts">
import AboutCard from "@/components/partials/AboutCard.vue"
import Contact from "@/components/partials/Contact.vue"
import ArrowUp from "@/components/icons/ArrowUp.vue"
import FacebookIcon from "@/components/icons/socials/FacebookIcon.vue"
import InstagramIcon from "@/components/icons/socials/InstagramIcon.vue"
import TiktokIcon from "@/components/icons/socials/TiktokIcon.vue"
import YoutubeIcon from "@/components/icons/socials/YoutubeIcon.vue"
import FFSearchToggle from "@/components/FFUI/FFSearchToggle.vue"
import {useHelpers} from "@/composables/useHelpers"

const {currentLocale, openLink} = useHelpers()

const scrollToTop = () => {
  window.scrollTo({top: 0, left: 0, behavior: "smooth"})
}
</script>

<template>
  <footer class="footer">
    <div class="container">
      <div class="upper grid justify-center md:grid-cols-3 gap-[30px] border-b border-b-[#ffffff80] pb-[50px]">
        <div class="about col-auto">
          <AboutCard />
        </div>
        <div class="col-auto">
          <Contact />
        </div>
        <div class="col-auto">
          <div class="footer-redirect">
            <button @click="scrollToTop" class="footer-redirect__up">
              <span>{{ $t("app.navigation.ontop") }}</span>
              <ArrowUp />
            </button>
          </div>
          <div>
            <p class="footer-redirect__problems-with-security">
              {{ $t("app.navigation.securityProblems") }}: <a href="mailto: security@ffins.kz" class="underline">security@ffins.kz</a>
            </p>
          </div>
          <div class="footer-socials-wrapper">
            <FacebookIcon />
            <InstagramIcon />
            <TiktokIcon />
            <YoutubeIcon />
          </div>
        </div>
      </div>
      <div class="footer-copyright pt-[22px] grid md:flex md:justify-between">
        <p>{{ $t("app.footer.license") }}</p>
        <p>Copyright © 2010 - 2025. АО «СК «Freedom Finance Insurance». Privacy Policy</p>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  padding: 100px 0;
  background: linear-gradient(132deg, #71c35a -0.7%, #254709 103.63%);
}

.footer-copyright {
  display: flex;
  gap: 20px;
  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;

    &:last-of-type {
      white-space: nowrap;

      @include max-responsive(lg) {
        white-space: unset;
      }
    }
  }
}

.footer-redirect {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  &__qna {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.2px;
    cursor: pointer;
  }
  &__up {
    display: flex;
    align-items: center;
    span {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.2px;
    }
  }
}

.footer-socials-wrapper {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-top: 95px;
}

.footer-redirect__problems-with-security {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-bottom: 3px;
}

@media (max-width: $xxl) {
  .footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: $xl) {
  .upper {
    grid-template-columns: 1fr 1fr;
  }
  .about {
    display: none;
  }
  .footer-socials-wrapper {
    margin-top: 160px;
  }
}

@media (max-width: $lg) {
  .footer {
    padding: 50px 0;
  }
  .upper {
    grid-template-columns: 1fr;
    padding-bottom: 25px;
  }
  .search {
    margin-top: 25px;
  }
  .footer-socials-wrapper {
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
  .footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
}

@media (max-width: $md) {
  .footer {
    padding: 40px 0;
  }
}
</style>
