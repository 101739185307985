<script setup lang="ts"></script>

<template>
  <section class="kasko-cases">
    <div class="kasko-cases__content">
      <p class="kasko-cases__title">{{ $t("app.kaskoPage.cases.title") }}</p>
      <p class="kasko-cases__description">{{ $t("app.kaskoPage.cases.description") }}</p>
    </div>
    <div class="kasko-cases__boxes-desktop">
      <div class="kasko-cases__box">
        <p class="kasko-cases__box-title">{{ $t("app.kaskoPage.cases.title-1") }}</p>
        <div>
          <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-1") }}</p>
          <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-1-2") }}</p>
        </div>
        <img src="/kasko-front/images/case-1.png" alt="car theft image" />
      </div>
      <div class="kasko-cases__box">
        <p class="kasko-cases__box-title">{{ $t("app.kaskoPage.cases.title-2") }}</p>
        <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-2") }}</p>
        <img src="/kasko-front/images/case-2.png" alt="car damage image" />
      </div>
      <div class="kasko-cases__box">
        <p class="kasko-cases__box-title">{{ $t("app.kaskoPage.cases.title-3") }}</p>
        <div>
          <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-3") }}</p>
          <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-3-2") }}</p>
        </div>
        <img src="/kasko-front/images/case-3.png" alt="theft of removable parts image" />
      </div>
      <div class="kasko-cases__box">
        <p class="kasko-cases__box-title">{{ $t("app.kaskoPage.cases.title-4") }}</p>
        <div>
          <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-4") }}</p>
          <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-4-2") }}</p>
        </div>
        <img src="/kasko-front/images/case-4.png" alt="the complete death of the car image" />
      </div>
    </div>

    <div class="kasko-cases__boxes-mobile">
      <div class="kasko-cases__boxes-content">
        <div class="kasko-cases__box">
          <p class="kasko-cases__box-title">{{ $t("app.kaskoPage.cases.title-1") }}</p>
          <div>
            <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-1") }}</p>
            <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-1-2") }}</p>
          </div>
          <img src="/kasko-front/images/case-1.png" alt="car theft image" />
        </div>
        <div class="kasko-cases__box">
          <p class="kasko-cases__box-title">{{ $t("app.kaskoPage.cases.title-2") }}</p>
          <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-2") }}</p>
          <img src="/kasko-front/images/case-2.png" alt="car damage image" />
        </div>
        <div class="kasko-cases__box">
          <p class="kasko-cases__box-title">{{ $t("app.kaskoPage.cases.title-3") }}</p>
          <div>
            <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-3") }}</p>
            <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-3-2") }}</p>
          </div>
          <img src="/kasko-front/images/case-3.png" alt="theft of removable parts image" />
        </div>
        <div class="kasko-cases__box">
          <p class="kasko-cases__box-title">{{ $t("app.kaskoPage.cases.title-4") }}</p>
          <div>
            <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-4") }}</p>
            <p class="kasko-cases__box-description">{{ $t("app.kaskoPage.cases.description-4-2") }}</p>
          </div>
          <img src="/kasko-front/images/case-4.png" alt="the complete death of the car image" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.kasko-cases {
  position: relative;
  z-index: 1;
  .kasko-cases__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;

    .kasko-cases__title {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 40px;

      background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .kasko-cases__description {
      color: #b8b8b8;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  .kasko-cases__boxes-desktop {
    display: grid;
    grid-gap: 24px 20px;
    grid-template-columns: 1fr 1fr;
    .kasko-cases__box {
      position: relative;
      min-height: 274px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 27px;
      overflow: hidden;

      border-radius: 30px;
      background: rgba(74, 74, 74, 0.4);
      backdrop-filter: blur(7.5px);
      .kasko-cases__box-title {
        color: #ffffff;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
      }
      .kasko-cases__box-description {
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        position: relative;
        z-index: 2;
      }
      img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        height: 230px;
        z-index: 1;
      }
    }
  }
  .kasko-cases__boxes-mobile {
    display: none;
  }
}

@media (max-width: $xl) {
  .kasko-cases {
    .kasko-cases__boxes-desktop {
      .kasko-cases__box {
        overflow: hidden;
        &:nth-child(1) {
          img {
            right: -140px;
          }
        }
        &:nth-child(2) {
          img {
            right: -5px;
          }
        }
        &:nth-child(3) {
          img {
            right: -100px;
          }
        }
        &:nth-child(4) {
          img {
            right: -90px;
          }
        }
      }
    }
    .kasko-cases__boxes-mobile {
      display: none;
    }
  }
}

@media (max-width: $lg) {
  .kasko-cases {
    overflow: hidden;
    .kasko-cases__boxes-desktop {
      display: none;
    }
    .kasko-cases__boxes-mobile {
      display: block;
      .kasko-cases__boxes-content {
        margin-top: 16px;
        margin-bottom: 16px;
        display: flex;
        overflow: auto;
        padding-bottom: 12px;

        .kasko-cases__box {
          position: relative;
          min-height: 274px;
          padding: 30px 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 83.333333%;
          flex-shrink: 0;
          overflow: hidden;

          border-radius: 30px;
          background: rgba(74, 74, 74, 0.4);
          backdrop-filter: blur(7.5px);

          &:not(:last-child) {
            margin-right: 16px;
          }
          .kasko-cases__box-title {
            color: #ffffff;
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-transform: uppercase;
          }
          .kasko-cases__box-description {
            color: #ffffff;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            position: relative;
            z-index: 2;
          }
          &:nth-child(1) {
            img {
              right: -140px;
            }
          }
          &:nth-child(2) {
            img {
              right: -5px;
            }
          }
          &:nth-child(3) {
            img {
              right: -100px;
            }
          }
          &:nth-child(4) {
            img {
              right: -90px;
            }
          }
          img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: auto;
            height: 230px;
          }
        }
      }
    }

    .kasko-cases__content {
      .kasko-cases__title {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
}
</style>
