<script setup lang="ts">
import {useModalStore} from "@/stores/ModalStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import OgpoJuridicalForms from "@/components/OgpoJuridical/OgpoJuridicalForms.vue"
import OgpoJuridicalCalculateForm from "@/components/OgpoJuridical/Forms/OgpoJuridicalCalculateForm.vue"
import FFForm from "@/components/FFUI/FFForm.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const {openModal} = useModalStore()
const {isDesktop} = useDeviceSize()

const handleOgpoJuridicalFormOpen = () => {
  openModal({
    component: OgpoJuridicalForms
  })
}
</script>

<template>
  <section class="ogpo-juridical-hero">
    <div class="ogpo-juridical-hero__wrapper container">
      <div class="ogpo-juridical-hero__left-part">
        <div class="ogpo-juridical-hero__box">{{ $t("app.ogpoJuridicalPage.carInsurance") }}</div>
        <p class="ogpo-juridical-hero__title">{{ $t("app.ogpoJuridicalPage.mainTitle") }}</p>
        <div class="ogpo-juridical-hero__descriptions">
          <p class="ogpo-juridical-hero__description-1">{{ $t("app.ogpoJuridicalPage.description1") }}</p>
          <p class="ogpo-juridical-hero__description-2">{{ $t("app.ogpoJuridicalPage.description2") }}</p>
        </div>
        <FFButton
          v-if="!isDesktop"
          @click="handleOgpoJuridicalFormOpen"
          :title="$t('app.buttons.calculateCost')"
          maxWidth="400px"
          maxWidthMobile="100%"
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          color="var(--color-white)"
          colorMobile="var(--color-white)"
          :uppercase="false"
          textPosition="start">
          <img src="" alt="" />
        </FFButton>
      </div>
      <div class="ogpo-juridical-hero__right-part">
        <FFForm>
          <OgpoJuridicalCalculateForm />
        </FFForm>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.ogpo-juridical-hero {
  background-image: url("/ogpo-juridical-front/images/black-car-hero-bg-desk.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 1920px;
  height: auto;
  .ogpo-juridical-hero__wrapper {
    @include between;
    position: relative;
    padding: 100px 0 150px;
    @include max-responsive(lg) {
      padding: 100px 15px;
    }

    .ogpo-juridical-hero__left-part {
      width: 100%;
      height: auto;
      .ogpo-juridical-hero__box {
        display: inline-block;
        color: var(--color-white);
        text-transform: uppercase;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 11.048px;
        text-transform: uppercase;

        border-radius: 2.053px;
        background: #f2994a;
        padding: 5px 10px;
      }
      .ogpo-juridical-hero__title {
        color: var(--color-white);
        font-size: 60px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        max-width: 550px;
        margin: 10px 0;
        @include main-green-gradient;

        @include max-responsive(xl) {
          font-size: 36px;
          font-style: normal;
          font-weight: 900;
          line-height: 43.2px;
        }
      }
      .ogpo-juridical-hero__descriptions {
        @include column;
        gap: 10px;
        p {
          color: #dbdbdb;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22.4px;
        }
      }
      .ff-button-wrapper {
        @include max-responsive(xl) {
          margin-top: 30px;
        }
      }
    }
    .ogpo-juridical-hero__right-part {
      .form {
        width: 350px;
        height: 400px;
        border: 1px solid red;
        border-radius: 20px;
      }

      @include max-responsive(xl) {
        display: none;
      }
    }
  }
}
</style>
