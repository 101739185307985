import {KASKO_CLASSIC, KASKO_EXPRESS, KASKO_VIP} from "@/constants/kasko"
import {computed, ref, watch} from "vue"
import {debounce} from "lodash-es"
import {defineStore} from "pinia"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useFormatters} from "@/utils/formatters"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {useI18n} from "vue-i18n"
import {useModalStore} from "@/stores/ModalStore"
import {usePdfOpener} from "@/composables/usePdfOpener"
import {useRouter} from "vue-router"
import {useToast} from "vue-toastification"
import type {IPayment} from "@/interfaces/Payment/IPayment"
import type {ICurrentTariff} from "@/interfaces/Kasko/ICurrentTariff"
import type {ICashbackData} from "@/interfaces/Kasko/ICashbackData"
import type {IClientCardData} from "@/interfaces/subscription/IClientCardData"
import CardIcon from "@/components/icons/payment/CardIcon.vue"
import KaskoForms from "@/components/Kasko/KaskoForms.vue"
import KaskoRisksModal from "@/components/Kasko/KaskoRisksModal.vue"
import KaskoTariffsForm from "@/components/Kasko/Forms/KaskoTariffsForm.vue"
import KaspiIcon from "@/components/icons/payment/KaspiIcon.vue"

export const useKaskoStore = defineStore("kaskoStore", () => {
  const {postData, loading} = useGatewayFetcher()
  const {currentLocale, openLink} = useHelpers()
  const {t} = useI18n()
  const toast = useToast()
  const {phoneNumberFormatter, dateFormatter, thousandSeparator} = useFormatters()
  const {isDesktop} = useDeviceSize()
  const {openKdpAgreementPdf, openQuestionnairePdf} = usePdfOpener(postData)
  const router = useRouter()

  const modalStore = useModalStore()

  const currentStep = ref<number>(1)
  const handleNextStep = () => {
    currentStep.value++
  }
  const handleStepBack = () => {
    currentStep.value--
  }

  //Calculation form
  const phone = ref<string>("")

  //Autonumtechpass
  const autonumtechpass = ref<string>("")
  const carName = ref<string>("")
  const isAutoNumsLoadingState = ref<boolean>(false)

  const isPhoneFilled = computed(() => {
    if (phone?.value.length === 17) {
      return true
    } else {
      return false
    }
  })

  const isCarNumberFilled = computed(() => {
    if (autonumtechpass.value.length >= 7) {
      return true
    } else {
      return false
    }
  })

  let isInputChanged = ref<boolean>(true)
  watch(autonumtechpass, (newValue) => {
    if (newValue.length < 7 || newValue.length < 8) {
      carName.value = ""
      isInputChanged.value = true
    }
  })

  const autonumtechpasCheck = async (data) => {
    if (!data) {
      carName.value = ""
      return
    }

    isAutoNumsLoadingState.value = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/object/car/check",
        {
          regNum: data.toUpperCase()
        },
        config
      )
      if (response && response.data && response.status === 200) {
        carName.value = response.data.data.car
      } else {
        carName.value = ""
        autonumtechpass.value = ""
      }
    } catch (error) {
      carName.value = ""
      autonumtechpass.value = ""
      console.error(error)
    } finally {
      isAutoNumsLoadingState.value = false
    }
  }
  const handleAutonumBlur = () => {
    autonumtechpasCheck(autonumtechpass.value)
  }

  //iin
  const iin = ref<string>("")
  const iinInputStates = ref([])

  const iinName = ref<string>("")
  const iinNames = ref<string[]>([])

  const isIinLoadingState = ref<boolean>(false)
  const isIinLoadingStates = ref<boolean[]>([false])

  const createClient = () => {
    const firstClient = {
      iinBin: iin.value,
      isMain: true,
      isDriver: true
    }
    const otherClients = iinInputStates.value.map((state, index) => ({
      iinBin: state.iin,
      isMain: false,
      isDriver: true
    }))
    return [firstClient, ...otherClients]
  }

  const isIinNumberFilled = computed(() => {
    if (iin.value.length === 12 && isIinLoadingState.value === false && iinInputStates.value.length === 0) {
      return true
    } else if (
      iin.value.length === 12 &&
      isIinLoadingStates.value.every((state) => state === false) &&
      iinInputStates.value.every((state) => state.iin?.length === 12)
    ) {
      return true
    } else {
      return false
    }
  })

  const pushInputToIINArr = () => {
    if (iin.value === "") {
      toast.clear()
      toast.error(t("app.toasterMessages.iinNumCheck.currentIINCannotBeEmpty"))
      return
    }

    if (iinInputStates.value.length >= 10) {
      toast.clear()
      toast.error(t("app.toasterMessages.iinNumCheck.cannotAddMoreThanTenIINs"))
      return
    }

    if (iinInputStates.value.some((state) => state.iin === "")) {
      toast.clear()
      toast.error(t("app.toasterMessages.iinNumCheck.cannotAddNewFieldIfPrevNotFilled"))
      return
    }

    iinInputStates.value.push({
      iin: ""
    })
  }

  const removeIINState = (index: number) => {
    iinInputStates.value = iinInputStates.value.filter((_, idx) => idx !== index)
  }

  //for single iin num check
  const iinCheck = debounce(async (data: string) => {
    if (!data) {
      iinName.value = ""
      return
    }

    const isDuplicateInArray = iinInputStates.value.some((item) => item.iin === data)
    if (isDuplicateInArray) {
      toast.error(t("app.toasterMessages.iinNumCheck.currentIINisInList"))
      iin.value = ""
      iinName.value = ""
      return
    }

    isIinLoadingState.value = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (data.length === 12) {
        const verifyResponse = await postData(
          "/portal/mobile/verify-phone-info",
          {
            phone: phoneNumberFormatter(phone.value),
            iin: iin.value
          },
          config
        )

        if (verifyResponse && verifyResponse.status === 200) {
          const response = await postData(
            "/object/client/check",
            {
              iin: data,
              clientType: "Individual"
            },
            config
          )
          if (response && response.data && response.status === 200) {
            iinName.value = response.data.data.client
          } else {
            iinName.value = ""
            iin.value = ""
          }
        } else {
          iin.value = ""
        }
      } else {
        iinName.value = ""
      }
      isIinLoadingState.value = false
    } catch (error) {
      iin.value = ""
      iinName.value = ""
      isIinLoadingState.value = false
      console.error(error)
    }
  }, 5)

  //for array states iin check
  const iinsCheck = debounce(async (data: string, index: number) => {
    if (!data) {
      iinNames.value[index] = ""
      return
    }

    const isDuplicateInArray = iinInputStates.value.some((item, idx) => item.iin === data && idx !== index)
    const isDuplicateWithCurrent = iin.value === data
    if (isDuplicateInArray || isDuplicateWithCurrent) {
      toast.clear()
      toast.error(t("app.toasterMessages.iinNumCheck.currentIINisInList"))
      iinInputStates.value[index].iin = ""
      iinNames.value[index] = ""
      return
    }

    isIinLoadingStates.value[index] = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (data.length === 12) {
        const response = await postData(
          "/object/client/check",
          {
            iin: data,
            clientType: "Individual"
          },
          config
        )
        if (response && response.data && response.status === 200) {
          iinNames.value[index] = response.data.data.client
        } else {
          iinNames.value[index] = ""
          iinInputStates.value[index].iin = ""
        }
      } else {
        iinNames.value[index] = ""
      }
      isIinLoadingStates.value[index] = false
    } catch (error) {
      iinNames.value[index] = ""
      iinInputStates.value[index].iin = ""
      isIinLoadingStates.value[index] = false
      console.error(error)
    }
  }, 5)

  const handleNextStepKdpOrTariffs = async () => {
    if (needKdp.value) {
      const notVerificatedUsersResponse = await fetchNotVerifiedIins()
      if (!notVerificatedUsersResponse) {
        return
      }
    }
    const response = await getInsuranceAmountOptions()
    if (response && isDesktop.value) {
      currentStep.value = 2
      modalStore.openModal({
        component: KaskoForms
      })
    } else if (response && !isDesktop.value) {
      currentStep.value = 2
    } else {
      return
    }
  }

  //Agreement form
  const needKdp = ref<boolean>(true)
  const verificationUsersData = ref<{fullName: string; iin: string; verifyClient: boolean}[]>([])
  const agreementLoaderHide = ref<boolean>(false)

  const handleKdp = (): void => {
    needKdp.value = false
  }

  const fetchNotVerifiedIins = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const firstClient = {iin: iin.value, clientType: "individual"}
      const clients = [firstClient]
      const response = await postData("/portal/get-client-data", {clients}, config)
      if (response?.status === 200) {
        verificationUsersData.value = response.data.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const notVerificatedUsers = computed(() => {
    return verificationUsersData.value
  })

  const allUsersAreVerificated = computed(() => {
    return notVerificatedUsers.value.every((verficated) => verficated.verifyClient)
  })

  const intervalIds = ref<Record<number, number | undefined>>({})
  const clearAllIntervals = () => {
    Object.keys(intervalIds.value).forEach((index) => {
      clearInterval(intervalIds.value[Number(index)])
      delete intervalIds.value[Number(index)]
    })
  }

  const sendCode = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = notVerificatedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const openKaskoAgreementPdf = async (fullName: string, iin: string) => {
    openKdpAgreementPdf(fullName, iin)
  }

  const openKaskoQuestionnairePdf = async (type: string, calcId: string) => {
    openQuestionnairePdf(`/policy/kasko/${type}/form`, {calcId})
  }

  const isCalculateButtonDisabled = computed(() => {
    return isPhoneFilled.value && isCarNumberFilled.value && isIinNumberFilled.value
  })

  //Kasko tarifs
  const sliderSteps = ref<number>(4)
  const sliderStep = ref<number>(0)
  const payMonthly = ref<boolean>(true)
  const kaskoTariffs = ref()

  const amountOfCoverage = ref<number>()
  const insSum = ref<number>()
  const currentTariffName = ref("")
  const orderedKeys = ["KASKO_VIP", "KASKO_CLASSIC", "KASKO_EXPRESS_3", "KASKO_EXPRESS_2", "KASKO_EXPRESS_1"]

  const computedAmountOfCoverage = computed(() => {
    return thousandSeparator(amountOfCoverage.value)
  })

  // const currentTariff = computed<ICurrentTariff | null>(() => {
  //   const tariffs = kaskoTariffs.value
  //   if (!tariffs) return null

  //   const orderedTariffs = orderedKeys.map((key) => tariffs[key])

  //   return orderedTariffs[sliderStep.value]
  // })

  const currentTariff = computed<ICurrentTariff | null>(() => {
    const tariffs = kaskoTariffs.value
    if (!tariffs) return null

    let filteredKeys

    if (tariffs["KASKO_VIP"]) {
      filteredKeys = orderedKeys
    } else if (tariffs["KASKO_CLASSIC"]) {
      filteredKeys = ["KASKO_CLASSIC", "KASKO_EXPRESS_3", "KASKO_EXPRESS_2", "KASKO_EXPRESS_1"]
    } else {
      filteredKeys = ["KASKO_EXPRESS_3", "KASKO_EXPRESS_2", "KASKO_EXPRESS_1"]
    }

    const orderedTariffs = filteredKeys.map((key) => tariffs[key]).filter(Boolean) 

    return orderedTariffs[sliderStep.value] || null
  })

  const getInsuranceAmountOptions = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + 1)
      const payload = {
        phone: phoneNumberFormatter(phone.value),
        cars: [
          {
            regNum: autonumtechpass.value
          }
        ],
        clients: createClient(),
        fromDate: dateFormatter(currentDate)
      }
      const verifyResponse = await postData(
        "/portal/mobile/verify-phone-info",
        {
          phone: phoneNumberFormatter(phone.value),
          iin: iin.value
        },
        config
      )
      if (verifyResponse && verifyResponse.status === 200) {
        const response = await postData("/policy/kasko/calculate", payload, config)
        if (response && response.status === 200) {
          kaskoTariffs.value = response?.data?.data
          amountOfCoverage.value = kaskoTariffs?.value?.KASKO_EXPRESS_1?.premium
          insSum.value = kaskoTariffs?.value?.KASKO_EXPRESS_1?.ins_sum
          currentTariffName.value = KASKO_EXPRESS
          return true
        } else {
          return
        }
      } else {
        return
      }
    } catch (error) {
      console.error(error)
      return
    }
  }

  const handleAmountOfCoverageValChange = (event: Event) => {
    const value = Number((event.target as HTMLInputElement).value)
    sliderStep.value = value

    switch (value) {
      case 0:
        amountOfCoverage.value = kaskoTariffs?.value?.KASKO_EXPRESS_1?.premium
        insSum.value = kaskoTariffs?.value?.KASKO_EXPRESS_1?.ins_sum
        currentTariffName.value = KASKO_EXPRESS
        break
      case 1:
        amountOfCoverage.value = kaskoTariffs?.value?.KASKO_EXPRESS_2?.premium
        insSum.value = kaskoTariffs?.value?.KASKO_EXPRESS_2?.ins_sum
        currentTariffName.value = KASKO_EXPRESS
        break
      case 2:
        amountOfCoverage.value = kaskoTariffs?.value?.KASKO_EXPRESS_3?.premium
        insSum.value = kaskoTariffs?.value?.KASKO_EXPRESS_3?.ins_sum
        currentTariffName.value = KASKO_EXPRESS
        break
      case 3:
        amountOfCoverage.value = kaskoTariffs?.value?.KASKO_CLASSIC?.premium
        insSum.value = kaskoTariffs?.value?.KASKO_CLASSIC?.ins_sum
        currentTariffName.value = KASKO_CLASSIC
        break
      case 4:
        amountOfCoverage.value = kaskoTariffs?.value?.KASKO_VIP?.premium
        insSum.value = kaskoTariffs?.value?.KASKO_VIP?.ins_sum
        currentTariffName.value = KASKO_VIP
        break
      default:
        amountOfCoverage.value = kaskoTariffs?.value?.KASKO_EXPRESS_1?.premium
        insSum.value = kaskoTariffs?.value?.KASKO_EXPRESS_1?.ins_sum
        currentTariffName.value = KASKO_EXPRESS
    }
  }

  const cashbackData = ref<ICashbackData | null>(null)
  const getCashbackData = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/policy/kasko/calculate/cashback",
        {
          calcId: currentTariff.value?.calcId,
          isSubscribe: payMonthly.value
        },
        config
      )
      if (response?.status === 200) {
        cashbackData.value = response.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const clientCardData = ref<IClientCardData | null>(null)
  const clientCardResponse = ref<IClientCardData[] | null>([])
  const selectedCardType = ref<number>(1)
  const getClientCardData = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData("/card/list", {iin: iin.value}, config)
      if (response?.status === 200 && Array.isArray(response.data)) {
        clientCardData.value = response.data[0]
        clientCardResponse.value = response.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const kasko_express_risks = computed(() => {
    return [`• ${t("app.kaskoPage.forms.tariffs.risks.onlyWhenDtp")}`]
  })
  const kasko_classic_risks = computed(() => {
    return [
      `• ${t("app.kaskoPage.forms.tariffs.risks.dtp")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.explosion")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.fire")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.lightning")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.hijacking")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.theft")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.robbery-1")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.robbery-2")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.disaster")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.sinkholeUnderTheIce")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.damagePlumb")}`
    ]
  })
  const kasko_vip_risks = computed(() => {
    return [
      `• ${t("app.kaskoPage.forms.tariffs.risks.dtp")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.explosion")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.fire")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.lightning")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.hijacking")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.theft")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.robbery-1")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.robbery-2")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.disaster")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.sinkholeUnderTheIce")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.damagePlumb")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.glassDamage")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.stealingMirrors")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.theftSpareWheel")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.theftWipers")}`,
      `• ${t("app.kaskoPage.forms.tariffs.risks.theftAntennas")}`
    ]
  })

  const handleOpenRiskModal = (tariff: string) => {
    if (tariff === KASKO_EXPRESS) {
      const risksProps = {
        title: computed(() => t("app.kaskoPage.forms.tariffs.risks.riskExpress")),
        risks: kasko_express_risks.value
      }
      modalStore.openModal({
        component: KaskoRisksModal,
        props: risksProps
      })
    }
    if (tariff === KASKO_CLASSIC) {
      const risksProps = {
        title: computed(() => t("app.kaskoPage.forms.tariffs.risks.riskClassic")),
        risks: kasko_classic_risks.value
      }
      modalStore.openModal({
        component: KaskoRisksModal,
        props: risksProps
      })
    }
    if (tariff === KASKO_VIP) {
      const risksProps = {
        title: computed(() => t("app.kaskoPage.forms.tariffs.risks.riskVip")),
        risks: kasko_vip_risks.value
      }
      modalStore.openModal({
        component: KaskoRisksModal,
        props: risksProps
      })
    }
  }

  const togglePayMonthlyCheckbox = (type: string) => {
    if (type === "payMonthly") {
      payMonthly.value = !payMonthly.value
    }
  }

  //Execution
  const executionForm = ref({
    inspectionDate: new Date(new Date().setDate(new Date().getDate())),
    email: "",
    iAgreeWithAgreement: false
  })

  //Payment methods
  const selectedPayment = ref<string>("")

  const showKaspiForm = ref<boolean>(false)
  const showSuperAppBlock = ref<boolean>(false)

  const handleCurrentMethod = (type: string) => {
    selectedPayment.value = type
  }

  //Kaspi payment form
  const kaspiPayPhoneNumber = ref<string>("")

  //Final pay form
  const subscriptionData = ref<{action: string; addCardUrl: string | null} | null>(null)
  const paymentUrl = ref<string>("")

  const subscriptionFinalPay = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/policy/kasko/subscribe",
        {
          calcId: currentTariff.value.calcId,
          email: executionForm.value.email,
          inspectionDate:
            currentTariff.value.kasko_type === "KASKO_EXPRESS"
              ? null
              : dateFormatter(executionForm.value?.inspectionDate),
          isPayWithNewCard: payMonthly.value && selectedCardType.value === 1 ? false : true
        },
        config
      )
      if (response && response.status === 200) {
        if (cashbackData.value) {
          localStorage.setItem("paymentType", selectedPayment.value)
          localStorage.setItem("cashbackAmount", JSON.stringify(cashbackData.value.cashbackAmount))
          localStorage.setItem("cashbackBalance", JSON.stringify(cashbackData.value.cashbackBalance))
          localStorage.setItem("isClientBank", JSON.stringify(cashbackData.value.isClientBank))
          localStorage.setItem("productType", JSON.stringify("Kasko"))
        }
        subscriptionData.value = response.data
        if (subscriptionData.value && subscriptionData.value?.action === "add_card") {
          openLink(subscriptionData.value?.addCardUrl, "_self")
        } else {
          modalStore.closeModal()
          router.push(`/pages/subscription-activate-status?calcId=${currentTariff.value?.calcId}&product=KASKO`)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const finalPay = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const firstClient = {
        iinBin: iin.value
      }
      const otherClients = iinInputStates.value.map((state, index) => ({
        iinBin: state.iin
      }))

      const finalPayObject = {
        calc_id: currentTariff.value.calcId,
        premium: currentTariff.value.premium,
        phone: phoneNumberFormatter(phone.value),
        iin: iin.value,
        clientType: "individual",
        email: executionForm.value.email,
        payment_title: selectedPayment.value,
        payment_type: selectedPayment.value,
        kasko_type: currentTariff.value.kasko_type,
        kaspiPayNumber: kaspiPayPhoneNumber.value ? phoneNumberFormatter(kaspiPayPhoneNumber.value) : "",
        insureds: [firstClient, ...otherClients],
        inspectionDate:
          currentTariff.value.kasko_type === "KASKO_EXPRESS" ? null : dateFormatter(executionForm.value.inspectionDate),
        order_id: String(currentTariff.value.orderId)
      }
      const response = await postData("/policy/kasko/create/order", finalPayObject, config)
      if (response && response.status === 200) {
        if (cashbackData.value) {
          localStorage.setItem("paymentType", selectedPayment.value)
          localStorage.setItem("cashbackAmount", JSON.stringify(cashbackData.value.cashbackAmount))
          localStorage.setItem("cashbackBalance", JSON.stringify(cashbackData.value.cashbackBalance))
          localStorage.setItem("isClientBank", JSON.stringify(cashbackData.value.isClientBank))
          localStorage.setItem("productType", JSON.stringify("Kasko"))
        }
        if (selectedPayment.value === "FreedomPay") {
          localStorage.removeItem("paymentType")

          paymentUrl.value = response.data?.payment_url
          window.location.href = paymentUrl.value
        }
        if (selectedPayment.value === "FreedomSuperApp") {
          localStorage.removeItem("paymentType")
          paymentUrl.value = response.data?.payment_url

          if (isDesktop.value) {
            showSuperAppBlock.value = true
          } else {
            openLink(paymentUrl.value, "_self")
            phone.value = ""
          }
        }
        if (selectedPayment.value === "KaspiPay") {
          localStorage.setItem("paymentType", "KaspiPay")
          modalStore.closeModal()
          router.replace("success-pay")
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    needKdp,
    intervalIds,
    allUsersAreVerificated,
    currentStep,
    phone,
    autonumtechpass,
    carName,
    isAutoNumsLoadingState,
    isPhoneFilled,
    isIinNumberFilled,
    isCarNumberFilled,
    iin,
    iinInputStates,
    iinName,
    iinNames,
    isIinLoadingState,
    isIinLoadingStates,
    iinCheck,
    iinsCheck,
    kaskoTariffs,
    amountOfCoverage,
    insSum,
    verificationUsersData,
    agreementLoaderHide,
    notVerificatedUsers,
    isCalculateButtonDisabled,
    currentTariffName,
    currentTariff,
    sliderSteps,
    sliderStep,
    payMonthly,
    cashbackData,
    clientCardData,
    clientCardResponse,
    selectedCardType,
    executionForm,
    selectedPayment,
    showKaspiForm,
    showSuperAppBlock,
    kaspiPayPhoneNumber,
    paymentUrl,
    pushInputToIINArr,
    removeIINState,
    handleKdp,
    fetchNotVerifiedIins,
    clearAllIntervals,
    sendCode,
    openKaskoAgreementPdf,
    openKaskoQuestionnairePdf,
    autonumtechpasCheck,
    handleAutonumBlur,
    handleNextStep,
    handleStepBack,
    handleNextStepKdpOrTariffs,
    togglePayMonthlyCheckbox,
    getCashbackData,
    getClientCardData,
    getInsuranceAmountOptions,
    handleAmountOfCoverageValChange,
    handleOpenRiskModal,
    handleCurrentMethod,
    subscriptionFinalPay,
    finalPay
  }
})
