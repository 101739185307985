<script setup lang="ts">
import {computed} from "vue"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useOgpoJuridicalStore} from "@/stores/OgpoJuridicalStore"
import {useModalStore} from "@/stores/ModalStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import FFForm from "@/components/FFUI/FFForm.vue"
import ModalHeader from "@/components/FFUI/ModalHeader.vue"
import OgpoJuridicalCalculateForm from "@/components/OgpoJuridical/Forms/OgpoJuridicalCalculateForm.vue"
import OgpoJuridicalAgreementConfirmationForm from "@/components/OgpoJuridical/Forms/OgpoJuridicalAgreementConfirmationForm.vue"
import OgpoJuridicalDocumentsForm from "@/components/OgpoJuridical/Forms/OgpoJuridicalDocumentsForm.vue"
import OgpoJuridicalOwnerDetails from "@/components/OgpoJuridical/Forms/OgpoJuridicalOwnerDetails.vue"
import OgpoJuridicalPaymentMethodForm from "./Forms/OgpoJuridicalPaymentMethodForm.vue"
import CloseIcon from "@/components/icons/socials/CloseIcon.vue"

const ogpoJuridicalStore = useOgpoJuridicalStore()
const {closeModal} = useModalStore()
const {currentStep} = storeToRefs(ogpoJuridicalStore)
const {t} = useI18n()
const {isDesktop} = useDeviceSize()

const handleStepBack = () => {
  if (currentStep.value === 3 || currentStep.value === 4) {
    currentStep.value = 2
  } else if (currentStep.value === 2) {
    if (isDesktop.value) {
      closeModal()
    }
    currentStep.value = 1
  } else if (currentStep.value === 5) {
    currentStep.value = 4
  }
}

const formHeaderTitle = computed(() => {
  if (currentStep.value === 2) {
    return t("form.formHeaders.confirmation")
  } else if (currentStep.value === 3) {
    return t("form.formHeaders.constituentDocuments")
  } else if (currentStep.value === 4) {
    return t("form.formHeaders.ownersDetails")
  } else {
    return t("form.formHeaders.paymentMethod")
  }
})
</script>

<template>
  <FFForm>
    <CloseIcon v-if="currentStep === 1 && !isDesktop" class="ff-form-close" @click="closeModal" />
    <ModalHeader
      v-if="currentStep !== 1"
      :title="formHeaderTitle"
      @stepBack="handleStepBack"
      :currentStep="currentStep"
      :totalSteps="5" />
    <OgpoJuridicalCalculateForm v-if="currentStep === 1" />
    <OgpoJuridicalAgreementConfirmationForm v-if="currentStep === 2" />
    <OgpoJuridicalDocumentsForm v-if="currentStep === 3" />
    <OgpoJuridicalOwnerDetails v-if="currentStep === 4" />
    <OgpoJuridicalPaymentMethodForm v-if="currentStep === 5" />
  </FFForm>
</template>

<style scoped lang="scss">
.ff-form-close {
  position: absolute;
  top: 28px;
  left: 21px;
}
</style>
