<script setup lang="ts">
import {useModalStore} from "@/stores/ModalStore"

const modalStore = useModalStore()
const {modalState, closeModal} = modalStore
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div v-if="modalState.isOpen" class="modal-wrapper">
        <component :is="modalState.component" v-bind="modalState.props" />
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped lang="scss">
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 100;
  background: rgba(0, 0, 0, 0.7);

  display: grid;
  place-items: center;

  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #d6dee1 transparent;

  .modal-wrapper__close-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* For Chrome, Safari, and Opera */
.modal-wrapper::-webkit-scrollbar {
  width: 12px;
}

.modal-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.modal-wrapper::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
