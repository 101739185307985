<script setup lang="ts">
import {useModalStore} from "@/stores/ModalStore"
import CloseIcon from "@/components/icons/CloseIcon.vue"

const {closeModal} = useModalStore()
</script>

<template>
  <div class="ogpo-upload-file-example-modal">
    <CloseIcon @click="closeModal" class="ogpo-upload-file-example-modal__close-icon" />
    <p class="ogpo-upload-file-example-modal__title">
      {{ $t("app.ogpoJuridicalPage.forms.calculateCost.downloadExampleModal.title") }}
    </p>
    <p class="ogpo-upload-file-example-modal__description">
      {{ $t("app.ogpoJuridicalPage.forms.calculateCost.downloadExampleModal.description1") }}
    </p>
    <p class="ogpo-upload-file-example-modal__important">
      {{ $t("app.ogpoJuridicalPage.forms.calculateCost.downloadExampleModal.important") }}:

      <span class="ogpo-upload-file-example-modal__description">
        {{ $t("app.ogpoJuridicalPage.forms.calculateCost.downloadExampleModal.description2") }}.
      </span>
    </p>
    <p class="ogpo-upload-file-example-modal__description">
      {{ $t("app.ogpoJuridicalPage.forms.calculateCost.downloadExampleModal.description3") }}:
    </p>
    <img src="https://ffins.kz/img/car_excel_test.jpg" alt="XLSX file example" />
  </div>
</template>

<style scoped lang="scss">
.ogpo-upload-file-example-modal {
  position: relative;
  background: #404040;
  padding: 20px;
  max-width: 400px;
  border-radius: 10px;
  margin: 0 20px;

  .ogpo-upload-file-example-modal__close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .ogpo-upload-file-example-modal__title {
    font-size: 25px;
    font-weight: 700;
    color: var(--color-white);
    font-style: normal;
    line-height: normal;
    margin-bottom: 20px;
  }

  .ogpo-upload-file-example-modal__description {
    font-size: 15px;
    font-weight: 500;
    color: var(--color-white);
    font-style: normal;
    line-height: normal;

    &:last-of-type {
      margin-bottom: 10px;
    }
  }

  .ogpo-upload-file-example-modal__important {
    font-size: 14px;
    font-weight: 900;
    color: var(--color-white);
    font-style: normal;
    line-height: normal;
    margin: 10px 0;
  }

  img {
    width: auto;
    height: auto;
    margin-bottom: 20px;
  }
}
</style>
