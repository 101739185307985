<script setup lang="ts">
import {ref} from "vue"
import {storeToRefs} from "pinia"
import {useComfortStore} from "@/stores/ComfortStore"
import {useModalStore} from "@/stores/ModalStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useHelpers} from "@/composables/useHelpers"
import {
  whatsAppComfortMessageRu,
  whatsAppComfortMessageKk,
  whatsAppComfortMessageEn
} from "@/constants/whatsAppMessages"
import ComfortAgreementForm from "@/components/Comfort/Forms/ComfortAgreementForm.vue"
import FFForm from "@/components/FFUI/FFForm.vue"
import ModalHeader from "@/components/FFUI/ModalHeader.vue"
import ComfortCalculateForm from "@/components/Comfort/Forms/ComfortCalculateForm.vue"
import ComfortPriceForm from "./Forms/ComfortPriceForm.vue"
import ComfortHomeDataForm from "@/components/Comfort/Forms/ComfortHomeDataForm.vue"
import ComfortPaymentMethodForm from "@/components/Comfort/Forms/ComfortPaymentMethodForm.vue"
import ComfortKaspiPaymentForm from "@/components/Comfort/Forms/ComfortKaspiPaymentForm.vue"
import SuperAppQrBlock from "@/components/shared/SuperAppQrBlock.vue"
import CloseIcon from "@/components/icons/socials/CloseIcon.vue"
import WhatsAppIcon from "@/components/icons/WhatsAppIcon.vue"

const comfortStore = useComfortStore()
const modalStore = useModalStore()
const {
  currentStep,
  needKdp,
  calculationObject,
  agreementLoaderHide,
  allUsersAreVerificated,
  showKaspiForm,
  showSuperAppBlock,
  paymentUrl
} = storeToRefs(comfortStore)
const {isDesktop, isMobileDevice} = useDeviceSize()
const {currentLocale, openLink} = useHelpers()

const cantScanQr = ref<boolean>(false)

const handleCloseModal = () => {
  modalStore.closeModal()
  comfortStore.resetStep()
}

const handleStepBack = () => {
  if (currentStep.value === 4 && showKaspiForm.value && !showSuperAppBlock.value) {
    showKaspiForm.value = false
  } else if (currentStep.value === 4 && !showKaspiForm.value && showSuperAppBlock.value && !cantScanQr.value) {
    showSuperAppBlock.value = false
  } else if (currentStep.value === 4 && !showKaspiForm.value && showSuperAppBlock.value && cantScanQr.value) {
    cantScanQr.value = false
  } else if (currentStep.value === 2 && needKdp.value && isMobileDevice.value) {
    comfortStore.handleStepBack()
    agreementLoaderHide.value = false
    comfortStore.clearAllIntervals()
  } else if (currentStep.value === 2 && needKdp.value && isDesktop.value) {
    handleCloseModal()
    comfortStore.clearAllIntervals()
  } else if (currentStep.value === 2 && !needKdp.value && isDesktop.value) {
    modalStore.closeModal()
    comfortStore.handleStepBack()
  } else if (currentStep.value === 2 && !needKdp.value && !isDesktop.value) {
    comfortStore.handleStepBack()
  } else {
    comfortStore.handleStepBack()
  }
}

const handleProblemsWithScan = (value: boolean) => {
  cantScanQr.value = value
}
</script>

<template>
  <FFForm :isFormComfort="true">
    <CloseIcon v-if="currentStep === 1" class="ff-form-close" @click="handleCloseModal" />
    <ModalHeader
      v-if="
        ((currentStep === 2 || currentStep === 3 || currentStep === 4) && allUsersAreVerificated && needKdp) ||
        ((currentStep === 2 || currentStep === 3 || currentStep === 4) && !needKdp)
      "
      :title="
        currentStep === 2
          ? $t('form.formHeaders.cost')
          : currentStep === 3
            ? $t('form.formHeaders.data')
            : $t('form.formHeaders.paymentMethod')
      "
      @stepBack="handleStepBack"
      :currentStep="currentStep"
      :totalSteps="4" />

    <ComfortCalculateForm v-if="currentStep === 1" />
    <ComfortAgreementForm
      v-if="currentStep === 2 && needKdp && !allUsersAreVerificated"
      @handleKdp="comfortStore.handleKdp"
      @stepBack="handleStepBack" />
    <ComfortPriceForm
      v-if="(currentStep === 2 && needKdp && allUsersAreVerificated) || (currentStep === 2 && !needKdp)"
      @nextStep="comfortStore.handleStepNext" />
    <ComfortHomeDataForm v-if="currentStep === 3" @nextStep="comfortStore.handleStepNext" />
    <ComfortPaymentMethodForm v-if="currentStep === 4 && !showKaspiForm" />
    <ComfortKaspiPaymentForm v-if="currentStep === 4 && showKaspiForm" />
    <SuperAppQrBlock
      v-if="currentStep === 4 && showSuperAppBlock && !showKaspiForm"
      :url="paymentUrl"
      @cantScan="handleProblemsWithScan"
      :cantScanQr="cantScanQr"
      :orderId="calculationObject?.orderId"
      level="Q">
      <template #super-app-qr-block__title-first-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-1") }}
      </template>
      <template #super-app-qr-block__title-second-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-2") }}
      </template>
    </SuperAppQrBlock>
    <div
      v-if="currentStep !== 1 && !needKdp"
      class="data-ask"
      @click="
        openLink(
          `https://api.whatsapp.com/send?phone=77788700014&text=${currentLocale === 'ru' ? whatsAppComfortMessageRu : currentLocale === 'kk' ? whatsAppComfortMessageKk : whatsAppComfortMessageEn}`,
          '_blank'
        )
      ">
      <WhatsAppIcon />
      <p>{{ $t("app.paymentPage.askQuestion") }}</p>
    </div>
  </FFForm>
</template>

<style scoped lang="scss">
.ff-form-comfort {
  .data-ask {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 1;
    margin-top: 30px;
    cursor: pointer;
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #ffffff;
    }
  }
}

.ff-form-close {
  position: absolute;
  top: 30px;
  left: 20px;
}
</style>
