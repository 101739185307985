<script setup lang="ts">
import {ref} from "vue"
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {personalProfileUrl} from "@/constants/urls"
import {useAuthStore} from "@/stores/AuthStore"
import FFButton from "@/components/FFUI/FFButton.vue"
import HoldingNavigation from "@/components/partials/HoldingNavigation.vue"
import Locale from "@/components/partials/Locale.vue"
import PersonIcon from "@/components/icons/PersonIcon.vue"
import FFCallCenterFull from "@/components/FFUI/FFCallCenterFull.vue"
import Menu from "@/components/partials/Menu.vue"
import WhatsAppIcon from "@/components/icons/WhatsAppIcon.vue"
import BurgerIcon from "@/components/icons/BurgerIcon.vue"
import PhoneIcon from "@/components/icons/PhoneIcon.vue"
import MobileMenu from "@/components/partials/MobileMenu.vue"
import {storeToRefs} from "pinia"

const authStore = useAuthStore()
const {resetPasswordCurrentStep, activeTabIndex} = storeToRefs(authStore)
const router = useRouter()
const mobileMenu = ref(false)

const {currentLocale, openLink} = useHelpers()

const closeMobileMenu = () => {
  window.scrollTo(0, 0)
  mobileMenu.value = false
}

const handlePersonalProfileRouting = () => {
  resetPasswordCurrentStep.value = 1
  activeTabIndex.value = 0
  router.push("/pages/personal-profile")
}
</script>

<template>
  <header class="header">
    <div class="navigation-top">
      <div class="header-container w-full flex lg:flex justify-between">
        <HoldingNavigation />
        <Locale />
      </div>
    </div>
    <div class="w-full bg-[#0f0f0f]">
      <div class="header-container navigation-top-wrapper">
        <img @click="router.push('/')" class="green-icon" src="/main-front/images/greenLogo.svg" alt="logo" />

        <Menu />

        <!-- Write to chairmanlink mobile -->
        <div @click="router.push('/pages/chairman')" class="nav-btn__write-to-chairman">
          {{ $t("app.writeToChairman") }}
        </div>

        <div class="xl-menu">
          <FFCallCenterFull class="xl-menu__call" />

          <a href="tel:5777" class="phone-icon-mobile"><PhoneIcon /></a>
          <PersonIcon class="xl-menu__profile-icon cursor-pointer" @click="router.push('/pages/personal-profile')" />
          <BurgerIcon class="burger" @click="mobileMenu = true" />
        </div>
        <FFButton
          @click="handlePersonalProfileRouting"
          class="profile-btn"
          style="white-space: nowrap"
          :title="$t('app.profileApp')"
          :uppercase="false"
          color="#FFFFFF"
          paddingx="20px"
          paddingy="35px"
          width="fit-content"
          type="submit"
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          icon-path="/contacts-front/images/profile.svg"
          icon-gap="11px" />
        <PersonIcon class="profile-btn-small" @click="router.push('/pages/personal-profile')" />
      </div>
    </div>
  </header>
  <Transition name="slide">
    <MobileMenu v-show="mobileMenu" @close="closeMobileMenu" />
  </Transition>
</template>

<style scoped lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.header {
  position: relative;
  z-index: 1;
}

.green-icon {
  cursor: pointer;
}

.nav-section {
  position: relative;
  display: inline-block;
}
.nav-btn__write-to-chairman {
  display: none;
}
.navigation-top {
  background-color: #2f2f2f;
}

.header-container {
  max-width: 1920px;
  margin: 0 auto;
}

.navigation-top-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0f0f0f;
  padding: 18px 20px;
}

.whatsapp,
.burger,
.profile-btn-small,
.phone-icon-mobile {
  display: none;
  cursor: pointer;
}

.xl-menu {
  display: none;
  align-items: center;
  gap: 20px;
}

@media (max-width: 1416px) {
  .profile-btn {
    display: none !important;
  }
  .profile-btn-small {
    display: flex;
  }
}

@media (max-width: 1275px) {
  .navigation-top,
  .call-center,
  .profile-btn-small {
    display: none;
  }
  .whatsapp,
  .burger,
  .xl-menu {
    display: flex;
  }
  .green-icon {
    width: 80px;
    height: 25px;
  }

  .nav-btn__write-to-chairman {
    display: block;
    border: 1px solid #2f4d3b;
    border-radius: 10px;
    background-color: transparent;
    background: var(--gradients-freedom-title, linear-gradient(85deg, #79c6e2 5.08%, #1cd491 79.1%, #3fed6e 136.66%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: auto;
    margin-right: 15px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 900;
    width: 150px;
    height: -moz-fit-content;
    height: fit-content;
    padding: 6px 4px;
    line-height: 14px;
    text-align: center;
  }
}

@media (max-width: $lg) {
  .xl-menu {
    &__call {
      display: none;
    }
    .phone-icon-mobile {
      display: flex;
    }
  }

  .nav-btn__write-to-chairman {
    margin: 0 22px 0 22px;
  }
}

@media (max-width: $md) {
  .nav-btn__write-to-chairman {
    width: 134px;
    font-size: 11px;
    margin: 0;
  }
  .xl-menu {
    gap: 15px;
  }
}

@media (max-width: 350px) {
  .xl-menu {
    gap: 25px;
  }
}
</style>
