<script setup lang="ts">
import {onMounted, computed} from "vue"
import {storeToRefs} from "pinia"
import router from "@/router"
import zod from "zod"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import {useAuthStore} from "@/stores/AuthStore"
import {vMaska} from "maska"
import {useI18n} from "vue-i18n"
import {useValidation} from "@/composables/useValidation"
import {filterCyrillicInput} from "@/utils/inputEvents"
import {passwordRegex} from "@/constants/regex"
import {useFormatters} from "@/utils/formatters"
import {throttle} from "lodash-es"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFileInput from "@/components/FFUI/FFFileInput.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"

const currentUser = JSON.parse(localStorage.getItem("current-user") || {})
const personalProfileStore = usePersonalProfileStore()
const authStore = useAuthStore()
const {
  originalEmail,
  originalPhone,
  emailPhoneProfileData,
  passwordProfileData,
  passwordType,
  repeatPasswordType,
  checkOtpResponse
} = storeToRefs(personalProfileStore)
const {phoneNumberFormatter} = useFormatters()

const {t} = useI18n()

const emailPhoneProfileSchema = zod
  .object({
    email: zod.string().email().nonempty(),
    phone: zod.string().min(17).nonempty()
  })
  .refine(
    (data) => {
      const emailChanged = data.email !== originalEmail.value
      const phoneChanged = phoneNumberFormatter(data.phone) !== originalPhone.value
      return emailChanged || phoneChanged
    },
    {
      message: "Either email or phone must be different from the original",
      path: ["email", "phone"]
    }
  )

const passwordProfileSchema = zod
  .object({
    password: zod.string().regex(passwordRegex).nonempty(),
    repeatPassword: zod.string().min(8).nonempty()
  })
  .refine(
    (data) => {
      if (data.password && data.repeatPassword) {
        return data.password === data.repeatPassword
      }
      return true
    },
    {
      path: ["repeatPassword"]
    }
  )

const emailPhoneValidation = useValidation(emailPhoneProfileSchema, emailPhoneProfileData, {
  mode: "eager"
})

const passwordValidation = useValidation(passwordProfileSchema, passwordProfileData, {
  mode: "eager"
})

const isEmailPhoneFormChanged = computed(() => {
  return (
    emailPhoneProfileData.value.email !== originalEmail.value ||
    phoneNumberFormatter(emailPhoneProfileData.value.phone) !== originalPhone.value
  )
})

const handleSubmitEmailPhone = throttle(async () => {
  const result = emailPhoneProfileSchema.safeParse(emailPhoneProfileData.value)
  if (result.success) {
    const {email, phone} = emailPhoneProfileData.value
    const response = await usePersonalProfileStore().changeEmailPhoneInUserProfile(email, phone)
    if (response) {
      router.push("personal-profile")
    } else {
      console.error("Failed to update profile")
    }
  } else {
    console.error(result.error.errors)
  }
}, 2000)

const handleSubmitPassword = async () => {
  await passwordValidation.validate()
  if (
    passwordValidation.isValid.value &&
    passwordProfileData.value.password !== "" &&
    passwordProfileData.value.repeatPassword !== "" &&
    passwordProfileData.value.password === passwordProfileData.value.repeatPassword
  ) {
    await personalProfileStore.handleOtpFormOpen()
  } else {
    return
  }
}

const preventPasswordCyrillicInput = () => {
  passwordProfileData.value.password = filterCyrillicInput(passwordProfileData.value.password)
}
const preventRepeatPasswordCyrillicInput = () => {
  passwordProfileData.value.repeatPassword = filterCyrillicInput(passwordProfileData.value.repeatPassword)
}
</script>
<template>
  <section class="user-info">
    <div class="user-info__block">
      <p class="user-info__block--title">{{ $t("app.profile.yourData") }}</p>
      <div class="user-info__block--grid">
        <FFFieldset :title="$t('app.profile.fio')">
          <FFInput
            id="fullName"
            v-model="currentUser.data.fullName"
            :placeholder="$t('app.profile.fullName')"
            name="fullName"
            type="text"
            :locked="true" />
        </FFFieldset>
        <FFFieldset :title="$t('app.auth.iin')">
          <FFInput
            id="iin"
            v-model="currentUser.data.iin"
            placeholder="900800700600"
            name="iin"
            type="text"
            :locked="true" />
        </FFFieldset>
        <div>
          <FFFieldset :isError="!!emailPhoneValidation.getError('email')" title="Email">
            <FFInput
              id="email"
              v-model="emailPhoneProfileData.email"
              placeholder="email@mail.ru"
              name="email"
              type="text" />
          </FFFieldset>
          <template v-if="!!emailPhoneValidation.getError('email')">
            <FFErrorMessage
              v-if="emailPhoneProfileData?.email?.length === 0"
              :errorMessage="t('form.validationErrors.emailFieldIsRequired')" />
            <FFErrorMessage
              v-if="emailPhoneProfileData?.email?.length > 0 && emailPhoneValidation.getError('email')"
              :errorMessage="t('form.validationErrors.emailIsNotValid')" />
          </template>
        </div>
        <div>
          <FFFieldset :isError="!!emailPhoneValidation.getError('phone')" :title="$t('form.inputTitles.phone')">
            <FFInput
              v-maska
              data-maska="+7 (###) ### ####"
              id="phone"
              v-model="emailPhoneProfileData.phone"
              name="phone"
              type="text"
              inputmode="tel" />
          </FFFieldset>
          <template v-if="!!emailPhoneValidation.getError('phone')">
            <FFErrorMessage
              v-if="emailPhoneProfileData?.phone?.length === 0"
              :errorMessage="t('form.validationErrors.phoneFieldIsRequired')" />
            <FFErrorMessage
              v-if="emailPhoneProfileData?.phone?.length > 0 && emailPhoneValidation.getError('phone')"
              :errorMessage="t('form.validationErrors.phoneIsNotValid')" />
          </template>
        </div>
      </div>
      <FFButton
        @click="handleSubmitEmailPhone"
        type="submit"
        :title="$t('app.profile.save')"
        :uppercase="false"
        color="#FFFFFF"
        paddingx="15px"
        paddingy="10px"
        width="100%"
        :disabled="!isEmailPhoneFormChanged"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)" />
    </div>
    <div class="user-info__block">
      <p class="user-info__block--title">{{ $t("app.auth.password") }}</p>
      <div class="user-info__block--grid">
        <div>
          <FFFieldset
            :isError="!!passwordValidation.getError('password')"
            :title="$t('app.auth.password')"
            :password="true"
            :eye-opened="passwordType === 'password'"
            @text="passwordType = 'text'"
            @password="passwordType = 'password'"
            @input="preventPasswordCyrillicInput">
            <FFInput id="password" v-model="passwordProfileData.password" name="password" :type="passwordType" />
          </FFFieldset>
          <template v-if="!!passwordValidation.getError('password')">
            <FFErrorMessage
              v-if="passwordProfileData?.password?.length > 0 && passwordValidation.getError('password')"
              :errorMessage="t('form.validationErrors.passwordIsNotValid')" />
          </template>
        </div>
        <div>
          <FFFieldset
            :isError="!!passwordValidation.getError('repeatPassword')"
            :title="$t('app.profile.repeatPassword')"
            :password="true"
            :eye-opened="repeatPasswordType === 'password'"
            @text="repeatPasswordType = 'text'"
            @password="repeatPasswordType = 'password'"
            @input="preventRepeatPasswordCyrillicInput">
            <FFInput
              id="repeatPassword"
              v-model="passwordProfileData.repeatPassword"
              name="repeatPassword"
              :type="repeatPasswordType" />
          </FFFieldset>
          <template v-if="!!passwordValidation.getError('repeatPassword')">
            <FFErrorMessage
              v-if="
                passwordProfileData?.repeatPassword?.length > 0 &&
                passwordProfileData?.password !== passwordProfileData?.repeatPassword
              "
              :errorMessage="t('form.validationErrors.repeatPasswordNotValid')" />
          </template>
        </div>
      </div>
      <div class="user-info__buttons">
        <FFButton
          @click="handleSubmitPassword"
          type="submit"
          :title="$t('app.buttons.change')"
          :uppercase="false"
          color="#FFFFFF"
          paddingx="15px"
          paddingy="10px"
          width="100%"
          :disabled="passwordProfileData?.password === null || passwordProfileData?.repeatPassword === null"
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)" />
      </div>
    </div>
  </section>
</template>
<style scoped lang="scss">
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.user-info {
  padding-top: 100px;
  padding-bottom: 100px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 0 auto;
  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &--title {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
    &--add {
      padding: 10px 16px;
      color: #4f9d3a;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      text-decoration-line: underline;
      cursor: pointer;
      transition: all 0.3s ease;
      width: fit-content;
    }
    &--add:hover {
      color: #fff;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
}

@media (max-width: $lg) {
  .user-info {
    max-width: 352px;
    padding: 50px 16px 50px 16px;
    &__block {
      width: 100%;
      &--grid {
        grid-template-columns: repeat(1, 1fr);
      }
      &--add:hover {
        color: #4f9d3a;
      }
    }
  }
}
</style>
