<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="38" viewBox="0 0 30 38" fill="none">
    <path
      d="M9.16602 24.0011H20.8327M9.16602 29.0011H14.9993M26.666 2.33447H3.33268C2.89065 2.33447 2.46673 2.51007 2.15417 2.82263C1.84161 3.13519 1.66602 3.55911 1.66602 4.00114V34.0011C1.66602 34.4432 1.84161 34.8671 2.15417 35.1797C2.46673 35.4922 2.89065 35.6678 3.33268 35.6678H26.666C27.108 35.6678 27.532 35.4922 27.8445 35.1797C28.1571 34.8671 28.3327 34.4432 28.3327 34.0011V4.00114C28.3327 3.55911 28.1571 3.13519 27.8445 2.82263C27.532 2.51007 27.108 2.33447 26.666 2.33447V2.33447Z"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M20 9.83447L13.3333 16.5011L10 13.1678"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
