<script setup lang="ts">
import {ref} from "vue"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useModalStore} from "@/stores/ModalStore"
import MSTRemasteredForms from "@/components/MSTRemastered/MSTForms.vue"
import MSTCalculateForm from "@/components/MSTRemastered/Forms/MSTAuthorizeForm.vue"
import FFForm from "@/components/FFUI/FFForm.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import GreenLogoIcon from "@/components/icons/greens/GreenLogoIcon.vue"

const modalStore = useModalStore()
const {isDesktop} = useDeviceSize()

const handleOpenFormModal = () => {
  modalStore.openModal({
    component: MSTRemasteredForms
  })
}
</script>

<template>
  <section class="mst-hero container">
    <div class="mst-hero__bag-bg"></div>
    <div class="mst-hero__container">
      <div class="mst-hero__content">
        <div class="mst-hero__badge">{{ $t("app.mstPage.fullyOnline") }}</div>
        <div class="mst-hero__title">{{ $t("app.mstPage.medInsuranceTouristsAbroadTitle") }}</div>
        <div class="mst-hero__description">{{ $t("app.mstPage.fastlyOrganizeMedHelp") }}</div>
        <div v-if="!isDesktop" class="mst-hero__calculate-button">
          <FFButton
            @click="handleOpenFormModal"
            background="#448723"
            backgroundMobile="#448723"
            color="#ffffff"
            colorMobile="#ffffff"
            :title="$t('app.mstPage.getPrice')" />
        </div>
        <div class="mst-hero__purpose">
          <div class="mst-hero__purpose-col" v-for="item in [1, 2, 3]" :key="item">
            <div class="mst-hero__purpose-item">
              <GreenLogoIcon />
              <div class="mst-hero__purpose-text">
                <div class="mst-hero__purpose-title">
                  {{ $t("app.mstPage.purpose-" + item) }}
                </div>
                <div class="mst-hero__purpose-description">
                  {{ $t("app.mstPage.purpose_note-" + item) }}
                </div>
              </div>
            </div>
          </div>
          <div class="mst-hero__purpose-col" v-for="item in [4, 5, 6]">
            <div class="mst-hero__purpose-item">
              <GreenLogoIcon />
              <div class="mst-hero__purpose-text">
                <div class="mst-hero__purpose-title">
                  {{ $t("app.mstPage.purpose-" + item) }}
                </div>
                <div class="mst-hero__purpose-description">
                  {{ $t("app.mstPage.purpose_note-" + item) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mst-hero__form">
        <FFForm>
          <MSTCalculateForm />
        </FFForm>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.mst-hero {
  position: relative;
  background-color: var(--color-black);
  background-image: url("/mst-front/images/mst-hero.png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 215px 0;

  @include max-responsive(lg) {
    padding: 30px 15px 0;
    background-image: none;
  }

  .mst-hero__container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @include max-responsive(lg) {
      padding-top: 256px;
    }
  }

  .mst-hero__content {
    flex: 2;
    margin-right: 20px;
  }

  .mst-hero__bag-bg {
    display: none;

    @include max-responsive(lg) {
      display: block;
      background-image: url("/mst-front/images/mst-hero-mobile.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      z-index: 0;
    }
  }

  .mst-hero__badge {
    width: fit-content;
    padding: 4px 12.5px;
    color: var(--color-white);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 2px;
    background: #f2994a;
    text-align: center;
  }

  .mst-hero__title {
    font-size: 50px;
    font-weight: 900;
    line-height: 55px;
    padding: 10px 0 50px;
    @include main-green-gradient;

    @include max-responsive(lg) {
      font-size: 30px;
      line-height: normal;
      padding-bottom: 30px;
    }
  }

  .mst-hero__description {
    color: var(--color-white);
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 50px;

    @include max-responsive(lg) {
      max-width: 300px;
    }
  }

  .mst-hero__calculate-button {
    display: none;

    @include max-responsive(xl) {
      display: block;
      margin-bottom: 50px;
    }
  }

  .mst-hero__purpose {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @include max-responsive(md) {
      grid-template-columns: 1fr;
    }
  }

  .mst-hero__purpose-col {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mst-hero__purpose-item {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  .mst-hero__purpose-title {
    color: var(--color-white);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;

    @include max-responsive(lg) {
      font-size: 16px;
      font-weight: 900;
    }
  }

  .mst-hero__purpose-description {
    color: var(--color-white);
    font-size: 12px;
    font-weight: 400;
    opacity: 0.6;

    @include max-responsive(lg) {
      font-weight: 300;
    }
  }

  .mst-hero__form {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;

    @include max-responsive(xl) {
      display: none;
    }
  }
}
</style>
