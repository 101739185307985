import {onBeforeUnmount} from "vue"

export function onPhoneFocus(event: FocusEvent): void {
  const input = event.target as HTMLInputElement

  event.preventDefault()

  if (!input.value.startsWith("+7 (")) {
    input.value = "+7 ("
  }

  requestAnimationFrame(() => {
    const inputLength = input.value.length
    input.setSelectionRange(inputLength, inputLength)
  })

  setTimeout(() => {
    input.focus()
  }, 0)

  input.addEventListener("input", onPhoneInput)
  input.addEventListener("click", onPhoneClick)
}

export function onPhoneInput(event: Event): void {
  const input = event.target as HTMLInputElement

  event.preventDefault()

  if (!input.value.startsWith("+7 (")) {
    input.value = "+7 (" + input.value.replace(/^\+7\s*/, "")
  }

  requestAnimationFrame(() => {
    const inputLength = input.value.length
    input.setSelectionRange(inputLength, inputLength)
  })

  setTimeout(() => {
    input.focus()
  }, 0)
}

export function onPhoneClick(event: MouseEvent): void {
  const input = event.target as HTMLInputElement

  event.preventDefault()

  requestAnimationFrame(() => {
    const inputLength = input.value.length
    input.setSelectionRange(inputLength, inputLength)
  })

  setTimeout(() => {
    input.focus()
  }, 0)
}

//Email
export function filterEmailInput(event: Event): void {
  const input = event.target as HTMLInputElement
  const filteredValue = input.value.replace(/[^a-zA-Z0-9._%+-@]/g, "")
  input.value = filteredValue
}

//Prevent cyrillic input
export const filterCyrillicInput = (text: string) => {
  return text.replace(/[\u0400-\u04FF]/g, "")
}

//Prevent latin input
export const filterLatinInput = (text: string) => {
  return text.replace(/[A-Za-z]/g, "")
}

//Prevent special characters
export const filterSpecChars = (text: string) => {
  return text.replace(/[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/, "")
}

//Allow Cyrillic characters, hyphen, and slash (for comfort street input)
export const filterCyrillicAndSpecialCharsInput = (text: string) => {
  return text.replace(/[^0-9\u0400-\u04FF\-\/\\ ]/g, "");
}

//Prevent input start with 0
export const preventTextStartWithZero = (text: string) => {
  return text.replace(/^0+/, "")
}
