<script lang="ts" setup>
import {defineProps, defineEmits, withDefaults} from "vue"

interface IProps {
  currentStep: number
  totalSteps: number
  title?: string
  isHandleStepBackNeeded?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  isHandleStepBackNeeded: true
})

const emit = defineEmits(["stepBack"])

function handleStepBack() {
  emit("stepBack")
}

let lang = localStorage.getItem("locale")
</script>

<template>
  <div class="ff-modal-header">
    <svg
      v-if="isHandleStepBackNeeded"
      @click="handleStepBack"
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M18 9V11H6L10 15L9 17L2 10L9 3L10 5L6 9H18Z" fill="white" />
    </svg>
    <div class="ff-modal-header-title">{{ props.title ? props.title : $t("app.mstPage.form.specifyTheData") }}</div>
    <div v-if="lang === 'ru' || lang === 'en'" class="ff-modal-steps">
      {{ $t("app.mstPage.form.step") }} {{ currentStep }} {{ $t("app.mstPage.form.from") }} {{ totalSteps }}
    </div>
    <div v-else class="ff-modal-steps">
      {{ totalSteps }}{{ $t("app.mstPage.form.of") }} {{ currentStep }}{{ $t("app.mstPage.form.step") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ff-modal {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
    }

    &-title {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      width: 119px;
    }
  }

  &-steps {
    padding: 9px 14px;
    border-radius: 20px;
    border: 1px solid #cacaca;
    background: rgba(217, 217, 217, 0.14);

    color: #fff;
    font-size: 13px;
    font-style: normal;
    text-wrap: nowrap;
    font-weight: 600;
    line-height: normal;
  }
}
</style>
