<script setup lang="ts">
import {computed} from "vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import {isActive, isActivePolicy} from "@/constants/policy"

interface Props {
  polisType: string
  ogpoDisabled?: boolean
  kaskoDisabled?: boolean
  comfortDisabled?: boolean
  isOgpoPolicyActive?: boolean
  isPolicyCancellable?: boolean
  subscriptionPolicyStatusCode?: string
}
const props = withDefaults(defineProps<Props>(), {
  ogpoDisabled: false,
  kaskoDisabled: false,
  comfortDisabled: false,
  isOgpoPolicyActive: false
})

const emit = defineEmits([
  "downloadPolis",
  "terminatePolis",
  "terminateSubscriptionPolis",
  "cancelSubscriptionPolis",
  "extendPolis",
  "cards",
  "addDriver",
  "editData",
  "insuranceCase",
  "insuranceCaseComfort"
])

const textColor = computed(() => {
  if (props.polisType === "casco" || props.polisType === "cascoSubscription") {
    return "#404040"
  } else {
    return "#FFFFFF"
  }
})

const backgroundColor = computed(() => {
  if (props.polisType === "casco" || props.polisType === "cascoSubscription") {
    return "#FFFFFF"
  } else {
    return "#404040"
  }
})
</script>

<template>
  <div class="action-buttons">
    <FFButton
      @click="emit('downloadPolis')"
      size="14px"
      :title="$t('app.personalCabinetPage.downloadPolis')"
      :uppercase="false"
      :color="textColor"
      :colorMobile="textColor"
      paddingx="17px"
      paddingy="20px"
      width="100%"
      :background="backgroundColor"
      :backgroundMobile="backgroundColor" />

    <FFButton
      v-if="
        polisType === 'ogpo' ||
        polisType === 'ogpoPlus' ||
        polisType === 'ogpoSubscription' ||
        polisType === 'casco' ||
        polisType === 'oldCascoSubscription' ||
        polisType === 'comfort' ||
        polisType === 'oldComfortSubscription' ||
        polisType === 'mst' ||
        polisType === 'homeProtect' ||
        ((polisType === 'cascoSubscription' || polisType === 'comfortSubscription') &&
          subscriptionPolicyStatusCode === isActivePolicy)
      "
      @click="emit('terminatePolis')"
      size="14px"
      :title="$t('app.personalCabinetPage.terminatePolis')"
      :uppercase="false"
      :color="textColor"
      :colorMobile="textColor"
      :disabled="isOgpoPolicyActive || kaskoDisabled || comfortDisabled"
      paddingx="17px"
      paddingy="20px"
      width="100%"
      :background="backgroundColor"
      :backgroundMobile="backgroundColor" />
    <FFButton
      v-if="
        subscriptionPolicyStatusCode === isActive &&
        (polisType === 'cascoSubscription' || polisType === 'comfortSubscription') &&
        !isPolicyCancellable
      "
      @click="emit('terminateSubscriptionPolis')"
      size="14px"
      :title="$t('app.personalCabinetPage.terminateSubscriptionPolis')"
      :uppercase="false"
      :color="textColor"
      :colorMobile="textColor"
      paddingx="17px"
      paddingy="20px"
      width="100%"
      :background="backgroundColor"
      :backgroundMobile="backgroundColor" />
    <FFButton
      v-if="
        subscriptionPolicyStatusCode === isActive &&
        (polisType === 'cascoSubscription' || polisType === 'comfortSubscription') &&
        isPolicyCancellable
      "
      @click="emit('cancelSubscriptionPolis')"
      size="14px"
      :title="$t('app.personalCabinetPage.cancelSubscriptionPolis')"
      :uppercase="false"
      :color="textColor"
      :colorMobile="textColor"
      paddingx="17px"
      paddingy="20px"
      width="100%"
      :background="backgroundColor"
      :backgroundMobile="backgroundColor" />
    <FFButton
      v-if="polisType === 'ogpo'"
      @click="emit('addDriver')"
      class="action-buttons__full"
      size="14px"
      :title="$t('app.personalCabinetPage.addDriver')"
      :uppercase="false"
      :color="textColor"
      :colorMobile="textColor"
      :disabled="ogpoDisabled"
      paddingx="17px"
      paddingy="20px"
      width="100%"
      :background="backgroundColor"
      :backgroundMobile="backgroundColor" />
    <FFButton
      v-if="polisType === 'cascoSubscription' || polisType === 'comfortSubscription'"
      @click="emit('cards')"
      size="14px"
      :title="$t('app.personalCabinetPage.cards')"
      :uppercase="false"
      :color="textColor"
      :colorMobile="textColor"
      paddingx="17px"
      paddingy="20px"
      width="100%"
      :background="backgroundColor"
      :backgroundMobile="backgroundColor"
      :disabled="subscriptionPolicyStatusCode === isActivePolicy" />
    <FFButton
      v-if="
        polisType === 'ogpo' ||
        polisType === 'ogpoPlus' ||
        polisType === 'ogpoSubscription' ||
        polisType === 'casco' ||
        polisType === 'oldCascoSubscription' ||
        polisType === 'cascoSubscription' ||
        polisType === 'homeProtect'
      "
      @click="emit('insuranceCase')"
      class="action-buttons__full"
      size="14px"
      :title="$t('app.personalCabinetPage.insuredEvent')"
      :uppercase="false"
      color="#FFFFFF"
      colorMobile="#FFFFFF"
      paddingx="17px"
      paddingy="20px"
      width="100%"
      background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #F00 -0.7%, #A80000 103.63%)"
      backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #F00 -0.7%, #A80000 103.63%)" />
    <FFButton
      v-if="polisType === 'comfort' || polisType === 'comfortSubscription'"
      @click="emit('insuranceCaseComfort')"
      class="action-buttons__full"
      size="14px"
      :title="$t('app.personalCabinetPage.insuredEvent')"
      :uppercase="false"
      color="#FFFFFF"
      colorMobile="#FFFFFF"
      paddingx="17px"
      paddingy="20px"
      width="100%"
      background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #F00 -0.7%, #A80000 103.63%)"
      backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #F00 -0.7%, #A80000 103.63%)" />
  </div>
</template>

<style scoped lang="scss">
.action-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 13px 0 10px;
  z-index: 100;
  button:hover {
    transform: scale(1.05);
    @include max-responsive(lg) {
      transform: scale(1);
    }
  }
}
</style>
