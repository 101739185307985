<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="42" viewBox="0 0 40 42" fill="none">
    <g filter="url(#filter0_i_3459_5102)">
      <circle cx="20" cy="20" r="16" fill="url(#paint0_linear_3459_5102)" />
    </g>
    <g filter="url(#filter1_d_3459_5102)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 28.3071V23H31V26.9314C29.3002 29.6232 26.6397 31.6478 23.5 32.5234V31H17V32.6521C14.2128 31.9938 11.7723 30.4382 10 28.3071ZM24.5195 17.977C25.54 18.82 26.1875 19.923 26.4745 21.1775C26.4745 21.1775 27.5245 21 27.5245 22H13.5395C13.5395 21 14.498 21.136 14.498 21.136C14.8055 19.881 15.4555 18.8495 16.4565 18.0055C17.174 17.4086 17.9924 17.1483 18.9026 16.8587C19.2451 16.7498 19.6005 16.6367 19.9685 16.5H20V9H21V9.661C21.5 9.3835 22.3585 9.233 22.995 9.233C23.187 9.233 23.3635 9.264 23.635 9.3285C23.9266 9.39156 24.2157 9.46595 24.5015 9.5515C24.822 9.6445 25.107 9.727 25.3835 9.78C25.6485 9.847 25.8755 9.875 26.059 9.875C26.3057 9.875 26.4231 9.84291 26.6429 9.78283C26.737 9.75709 26.85 9.7262 27 9.6905V13.3115C26.9027 13.3288 26.8187 13.3443 26.7444 13.358C26.4368 13.4147 26.2947 13.4409 26.0595 13.4405C25.876 13.4405 25.6545 13.4155 25.39 13.355C25.1125 13.2925 24.8265 13.219 24.506 13.122C24.1985 13.0375 23.896 12.963 23.612 12.894C23.333 12.833 23.0945 12.803 22.906 12.803C22.809 12.803 22.6675 12.8235 22.4635 12.872C22.2555 12.916 22.056 12.9785 21.8355 13.053L21.262 13.2655L21 13.336V16.5H21.005C21.3907 16.6422 21.7613 16.7565 22.117 16.8662C23.012 17.1423 23.8121 17.3891 24.5195 17.977Z"
        fill="#1F1F1F" />
    </g>
    <defs>
      <filter
        id="filter0_i_3459_5102"
        x="4"
        y="4"
        width="37"
        height="37"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3459_5102" />
      </filter>
      <filter
        id="filter1_d_3459_5102"
        x="10"
        y="9"
        width="30"
        height="32.6523"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3459_5102" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3459_5102" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_3459_5102"
        x1="36"
        y1="-1.64706"
        x2="-4.95045"
        y2="19.6784"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
    </defs>
  </svg>
</template>
