export const personalProfileUrl: string = "https://stage.ffins.kz/pages/auth"
export const kaskoUrl: string = "https://ffins.kz/kasko"
export const comfortUrl: string = "https://ffins.kz/comfort"

export const externalUrls = {
  superAppUrl: "https://freedombank.onelink.me/WNLd/insurance",
  kerimbayevYoutubeChannel: "https://www.youtube.com/@KerimbayevOnline",
  kerimbayevInstagram: "https://www.instagram.com/kerimbayev.online/?igshid=MzRlODBiNWFlZA%3D%3D",
  dtpMobileAppYouTubeUrl: "https://youtu.be/QJDJhQRHME0",
  freedomCurrencyUrl: "https://frhc.bankffin.kz/ru",
  dtpMobileAppGoogleStoreUrl: "https://play.google.com/store/apps/details?id=kz.ffins.dtpkz",
  dtpMobileAppAppStoreUrl: "https://apps.apple.com/kz/app/dtp-kz/id6572292213",
}