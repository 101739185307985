<script setup lang="ts">
import {ref, onUnmounted} from "vue"
import {storeToRefs} from "pinia"
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import {useModalStore} from "@/stores/ModalStore"
import FFButton from "@/components/FFUI/FFButton.vue"

const personalProfileStore = usePersonalProfileStore()
const modalStore = useModalStore()
const {closeModal} = useModalStore()
const {currentLocale} = useHelpers()
const {isSubscriptionEnded, currentOpenedPolicySubscription} = storeToRefs(personalProfileStore)
const router = useRouter()

const areYouSureToEndSubscription = ref(false)

const showConfirmation = () => {
  areYouSureToEndSubscription.value = true
}

const confirmEndSubscription = async () => {
  await personalProfileStore.subscriptionEnd()
  areYouSureToEndSubscription.value = false
}

const cancelEndSubscription = () => {
  areYouSureToEndSubscription.value = false
}

const handleEndSubscriptionAndTerminationAgreement = async () => {
  closeModal()
  await router.push(
    `/pages/termination-subscription-agreement/${currentOpenedPolicySubscription.value?.subscriptionRegNum}/${currentOpenedPolicySubscription.value?.productType}`
  )
}

onUnmounted(() => {
  isSubscriptionEnded.value = false
})
</script>

<template>
  <div class="profile-terminate-subscription-modal">
    <template v-if="!isSubscriptionEnded && !areYouSureToEndSubscription">
      <p class="profile-terminate-subscription-modal__title">
        {{ $t("app.personalCabinetPage.subscriptionTerminateModal.areYouSureToTerminateSubscription") }}
      </p>
      <div @click="showConfirmation" class="profile-terminate-subscription-modal__first-button">
        <p>{{ $t("app.personalCabinetPage.subscriptionTerminateModal.endSubscription") }}</p>
      </div>
      <div
        @click="handleEndSubscriptionAndTerminationAgreement"
        class="profile-terminate-subscription-modal__second-button">
        <p>{{ $t("app.personalCabinetPage.subscriptionTerminateModal.endSubscriptionAndTerminatePolicy") }}</p>
      </div>
      <div @click="closeModal" class="profile-terminate-subscription-modal__third-button">
        <p>{{ $t("app.buttons.back") }}</p>
      </div>
    </template>

    <template v-if="areYouSureToEndSubscription">
      <p class="profile-terminate-subscription-modal__confirmation-message">
        {{ $t("app.personalCabinetPage.subscriptionTerminateModal.confirmCancellation") }}
      </p>
      <div class="profile-terminate-subscription-modal__confirmation-buttons">
        <div @click="cancelEndSubscription">
          <p>{{ $t("app.buttons.no") }}</p>
        </div>
        <div @click="confirmEndSubscription">
          <p>{{ $t("app.buttons.yes") }}</p>
        </div>
      </div>
    </template>

    <template v-if="isSubscriptionEnded">
      <p class="profile-terminate-subscription-modal__success-title">
        {{ $t("app.personalCabinetPage.subscriptionTerminateModal.dearClientSubscriptionIsEnded") }}
      </p>
      <p class="profile-terminate-subscription-modal__description">
        <template v-if="currentLocale !== 'kk'">
          {{ $t("app.personalCabinetPage.subscriptionTerminateModal.yourPolicyTillDate-1") }}
          {{ currentOpenedPolicySubscription?.tillDate }}
        </template>
        <template v-else>
          {{ $t("app.personalCabinetPage.subscriptionTerminateModal.yourPolicyTillDate-1") }}
          {{ currentOpenedPolicySubscription?.tillDate }}
          {{ $t("app.personalCabinetPage.subscriptionTerminateModal.yourPolicyTillDate-2") }}
        </template>
      </p>
      <div @click="closeModal" class="profile-terminate-subscription-modal__close-wrapper">
        <p>{{ $t("app.buttons.close") }}</p>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.profile-terminate-subscription-modal {
  background-color: #2b2b2e;
  border-radius: 10px;
  max-width: 330px;

  .profile-terminate-subscription-modal__confirmation-message {
    color: var(--color-white);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 22px 28px;
  }

  .profile-terminate-subscription-modal__confirmation-buttons {
    @include flex;
    gap: 20px;
    padding: 0 28px 20px;

    div {
      padding: 20px;
      border: 1px solid #4ebc73;
      border-radius: 10px;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover {
        opacity: 0.8;
        transition: all ease 0.2s;
      }
      p {
        color: #4ebc73;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  .profile-terminate-subscription-modal__title {
    color: var(--color-white);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 22px 28px;
  }
  .profile-terminate-subscription-modal__success-title {
    color: var(--color-white);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 22px 30px 8px;
  }
  .profile-terminate-subscription-modal__description {
    color: #a7a7a7;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
    padding: 0 27px;
  }
  .profile-terminate-subscription-modal__close-wrapper {
    padding: 20px;
    @include center;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    p {
      color: #4ebc73;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .profile-terminate-subscription-modal__first-button {
    p {
      padding: 20px 0;
    }
  }
  .profile-terminate-subscription-modal__second-button {
    p {
      padding: 11px 0;
    }
  }
  .profile-terminate-subscription-modal__first-button,
  .profile-terminate-subscription-modal__second-button {
    padding: 0 79px;
    p {
      color: #4ebc73;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .profile-terminate-subscription-modal__third-button {
    padding: 0 40px;
    p {
      color: #7b7b7b;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 20px 0;
    }
  }
  .profile-terminate-subscription-modal__first-button,
  .profile-terminate-subscription-modal__second-button,
  .profile-terminate-subscription-modal__third-button {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .profile-terminate-subscription-modal__first-button {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .profile-terminate-subscription-modal__second-button {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
</style>
