<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <g filter="url(#filter0_i_3411_638)">
      <path
        d="M16.6681 6.0013H23.3348C24.2514 6.0013 25.0014 5.2513 25.0014 4.33464C25.0014 3.41797 24.2514 2.66797 23.3348 2.66797H16.6681C15.7514 2.66797 15.0014 3.41797 15.0014 4.33464C15.0014 5.2513 15.7514 6.0013 16.6681 6.0013ZM31.7181 13.318L32.9681 12.068C33.2763 11.7579 33.4493 11.3385 33.4493 10.9013C33.4493 10.4641 33.2763 10.0447 32.9681 9.73464L32.9514 9.71797C32.6414 9.40979 32.2219 9.23681 31.7848 9.23681C31.3476 9.23681 30.9282 9.40979 30.6181 9.71797L29.3681 10.968C26.7138 8.83161 23.4087 7.66718 20.0014 7.66797C12.0014 7.66797 5.20143 14.268 5.00143 22.268C4.94799 24.2712 5.29649 26.2648 6.02635 28.1311C6.75621 29.9974 7.85263 31.6986 9.25086 33.1341C10.6491 34.5696 12.3208 35.7104 14.1673 36.4891C16.0137 37.2678 17.9975 37.6687 20.0014 37.668C22.8253 37.6695 25.5921 36.8734 27.9833 35.3712C30.3745 33.8691 32.2928 31.7221 33.5172 29.1775C34.7416 26.6329 35.2223 23.7942 34.904 20.9884C34.5857 18.1825 33.4814 15.5237 31.7181 13.318ZM21.6681 22.668C21.6681 23.5846 20.9181 24.3346 20.0014 24.3346C19.0848 24.3346 18.3348 23.5846 18.3348 22.668V16.0013C18.3348 15.0846 19.0848 14.3346 20.0014 14.3346C20.9181 14.3346 21.6681 15.0846 21.6681 16.0013V22.668Z"
        fill="url(#paint0_linear_3411_638)" />
    </g>
    <defs>
      <filter
        id="filter0_i_3411_638"
        x="4.99609"
        y="2.66797"
        width="35.0039"
        height="40"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3411_638" />
      </filter>
      <linearGradient
        id="paint0_linear_3411_638"
        x1="34.9995"
        y1="-3.5085"
        x2="-5.69793"
        y2="14.6597"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
    </defs>
  </svg>
</template>
