<script setup lang="ts">
import {useFormatters} from "@/utils/formatters"
import type {IVehicleMileageResponse} from "@/interfaces/Calculator/IVehicleMileageResponse"

interface IProps {
  resultMileage: number
}

defineProps<IProps>()

const {thousandSeparator} = useFormatters()
</script>

<template>
  <section class="vehicle-mileage-calculator-result">
    <div class="vehicle-mileage-calculator-result__first-block">
      <p>{{ $t("app.calculatorPage.vehicleMileage.estimatedMileage") }}</p>
      <p>{{ thousandSeparator(resultMileage) }} {{ $t("form.formAbbreviations.km") }}</p>
    </div>
  </section>
</template>

<style scoped lang="scss">
.vehicle-mileage-calculator-result {
  @include column;
  gap: 50px;
  max-width: 540px;

  .vehicle-mileage-calculator-result__first-block {
    border-radius: 10px;
    background: #1f1f1f;
    padding: 24px 20px;

    @include between;

    p {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    .vehicle-mileage-calculator-result__row {
      @include column;

      .vehicle-mileage-calculator-result__row-title {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px;
        margin-bottom: 8px;
      }
      .vehicle-mileage-calculator-result__row-description {
        color: var(--color-white);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
      }
    }
  }

  .vehicle-mileage-calculator-result__second-block {
    border-radius: 10px;
    background: #1f1f1f;
    padding: 24px 20px;
    @include column;
    gap: 20px;

    p {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    .vehicle-mileage-calculator-result__row {
      @include column;

      .vehicle-mileage-calculator-result__row-title {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px;
        margin-bottom: 8px;
      }
      .vehicle-mileage-calculator-result__row-description {
        color: var(--color-white);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
      }
    }
  }
}
</style>
