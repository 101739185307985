<script setup lang="ts">
import {ref, computed, watch} from "vue"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useOgpoJuridicalStore} from "@/stores/OgpoJuridicalStore"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import VueDatePicker from "@vuepic/vue-datepicker"
import FFYesNoCheckbox from "@/components/FFUI/FFYesNoCheckbox.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import Dropdown from "primevue/dropdown"

const ogpoJuridicalStore = useOgpoJuridicalStore()
const {
  currentStep,
  countries,
  beneficiariesDocumentTypes,
  FORM_TYPE_1_FIELDS,
  FORM_TYPE_2_FIELDS,
  residentSchema,
  nonResidentSchema,
  forms,
  formErrors,
  initializeFields,
  addForm,
  toggleResidency,
  removeForm,
  getFieldLabel,
  getFieldType,
  validateForm,
  validateAllForms,
  formsValid
} = storeToRefs(ogpoJuridicalStore)
const {t} = useI18n()
const {currentLocale} = useHelpers()
const {dateFormatter} = useFormatters()

const handleNextStep = async () => {
  const result = ogpoJuridicalStore.validateAllForms()
  if (result) {
    const beneficiaries = forms.value.map((form) => {
      if (form.isResident) {
        return {
          resident: true,
          iin: form.fields.iin,
          documentType: form.fields.documentType.document_type,
          documentNumber: form.fields.documentNumber,
          issuedByWhom: form.fields.whoGaveDocument,
          documentIssueDate: dateFormatter(form.fields.dateIssue),
          documentTillDate: dateFormatter(form.fields.stopDate)
        }
      } else {
        return {
          resident: false,
          firstName: form.fields.name,
          lastName: form.fields.surname,
          middleName: form.fields.patronomic || null,
          birthdate: dateFormatter(form.fields.birthdayDate),
          issuedByWhom: form.fields.whoGaveDocument,
          documentType: form.fields.documentType.document_type,
          documentNumber: form.fields.documentNumber,
          documentIssueDate: dateFormatter(form.fields.dateIssue),
          documentTillDate: dateFormatter(form.fields.stopDate),
          countryName: form.fields.homeCountry?.Name
        }
      }
    })

    const response = await ogpoJuridicalStore.postBeneficiaries(beneficiaries)
    if (response) {
      currentStep.value = 5
    } else {
      return
    }
  }
}
</script>

<template>
  <div class="ogpo-juridical-owner-details__form-list">
    <div v-for="(form, index) in forms" :key="form.id" class="ogpo-juridical-owner-details__form">
      <div class="ogpo-juridical-owner-details__form-header">
        <p>{{ $t("app.ogpoJuridicalPage.forms.ownerDetails.isResidentRk") }}</p>
        <FFYesNoCheckbox
          :value="form.isResident"
          :toggleCheckbox="() => ogpoJuridicalStore.toggleResidency(index)"
          :isYesNoNeeded="true" />
      </div>

      <div v-for="(value, key) in form.fields" :key="key" class="ogpo-juridical-owner-details__form-field">
        <FFFieldset v-if="ogpoJuridicalStore.getFieldType(key, form.isResident) === 'date'" :title="ogpoJuridicalStore.getFieldLabel(key, form.isResident)">
          <VueDatePicker
            :enable-time-picker="false"
            :teleport="true"
            auto-apply
            :id="key"
            v-model="form.fields[key]"
            format="dd.MM.yyyy"
            :locale="currentLocale"
            :max-date="new Date()">
            <template #input-icon>
              <img class="date-time-picker-icon" src="/declare-insured-event/images/calendar-input.svg" alt="icon" />
            </template>
            <template #clear-icon> </template>
          </VueDatePicker>
        </FFFieldset>

        <FFFieldset
          v-else-if="ogpoJuridicalStore.getFieldType(key, form.isResident) === 'selector'"
          :title="ogpoJuridicalStore.getFieldLabel(key, form.isResident)">
          <Dropdown
            v-if="ogpoJuridicalStore.getFieldKey(key, form.isResident)?.key === 'homeCountry'"
            :id="key"
            v-model="form.fields[key]"
            :options="countries"
            optionLabel="Name"
            :panelStyle="{maxWidth: '265px', minWidth: '265px'}" />
          <Dropdown
            v-else-if="ogpoJuridicalStore.getFieldKey(key, form.isResident)?.key === 'documentType'"
            :id="key"
            v-model="form.fields[key]"
            :options="beneficiariesDocumentTypes"
            optionLabel="name"
            :panelStyle="{maxWidth: '265px', minWidth: '265px'}" />
        </FFFieldset>

        <FFFieldset v-else :title="ogpoJuridicalStore.getFieldLabel(key, form.isResident)">
          <FFInput :id="key" :type="ogpoJuridicalStore.getFieldType(key, form.isResident)" v-model="form.fields[key]" />
        </FFFieldset>
      </div>
      <button
        v-if="forms.length > 1"
        @click.prevent="ogpoJuridicalStore.removeForm(index)"
        class="delete-button"
        :disabled="forms.length === 1">
        {{ $t("app.buttons.deleteForm") }}
      </button>
    </div>
  </div>

  <div class="ogpo-juridical-owner-details__buttons">
    <FFButton
      @click="ogpoJuridicalStore.addForm"
      :title="$t('app.buttons.addOwner')"
      :uppercase="false"
      background="transparet"
      backgroundMobile="transparent"
      borderPixel="1px solid var(--color-white)"
      borderPixelMobile="1px solid var(--color-white)"
      color="var(--color-white)"
      colorMobile="var(--color-white)" />
    <FFButton @click="handleNextStep" :title="$t('app.buttons.continue')" :disabled="!formsValid" :uppercase="false" />
  </div>
</template>

<style scoped lang="scss">
.ogpo-juridical-owner-details__form-list {
  @include column;
  gap: 24px;
  margin-top: 24px;

  .ogpo-juridical-owner-details__form {
    width: 100%;

    .ogpo-juridical-owner-details__form-header {
      @include between;
      margin-bottom: 20px;

      p {
        color: var(--color-white);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px;
      }
    }
    .ogpo-juridical-owner-details__form-field {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      ::v-deep(.dp__input_icon) {
        inset-inline-start: unset;
        right: 0px;
        top: 45%;
      }
      ::v-deep(.ff-form .dp__input_icon) {
        inset-inline-start: unset;
        right: 0px;
        top: 45%;
      }
      ::v-deep(.date-time-picker-icon) {
        width: 27px;
        height: 27px;
      }
      ::v-deep(.dp__input_icon_pad) {
        padding-inline-start: unset;
        padding-left: 15px;
        font-size: 16px !important;
      }
      ::v-deep(.dp__pointer .db__input_readonly .dp__input_icon_pad) {
        padding-inline-start: unset !important;
      }
    }
    .delete-button {
      @include flex;
      font-size: 14px;
      font-weight: 400;
      border-bottom: 1px solid var(--color-white);
      color: var(--color-white);
      margin-left: auto;
      margin-top: 10px;
    }
  }
}

.ogpo-juridical-owner-details__buttons {
  @include column;
  gap: 15px;
  margin-top: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
