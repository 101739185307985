<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.177 25.4148H21.5842C25.6724 25.4148 26.4263 23.1596 26.4263 20.4V18.7679H17.177V13.308H26.4263C30.0796 13.2486 31.2683 11.0528 31.2683 8.29315V13.2486C31.2683 29.8362 19.7865 33.9311 19.7865 33.9311C19.7865 33.9311 18.6848 33.6047 17.177 32.6255V25.4148ZM7 13.2783C7 22.5958 10.3344 28.0558 13.1178 30.9935C16.1912 34.2279 19.2936 35.2071 19.4386 35.2368L19.8445 35.3555L20.2504 35.2071C20.3664 35.1478 23.4398 34.0498 26.5132 30.7561C29.2967 27.759 32.6311 22.2694 32.6311 13.2783V5.35547H7V13.2783Z"
      fill="url(#paint0_linear_2820_20174)" />
    <defs>
      <linearGradient
        id="paint0_linear_2820_20174"
        x1="6.10589"
        y1="4.10547"
        x2="30.7561"
        y2="5.98367"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#79B0E2" />
        <stop offset="0.5625" stop-color="#1CD491" />
        <stop offset="1" stop-color="#3FED6E" />
      </linearGradient>
    </defs>
  </svg>
</template>
