<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import {externalUrls} from "@/constants/urls"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"

const {openLink} = useHelpers()
</script>

<template>
  <section class="dtp-faq container">
    <p class="dtp-faq__title">{{ $t("app.dtpLandingPage.qna.title") }}</p>
    <Accordion :multiple="false" class="dtp-faq__accordion">
      <AccordionTab :header="$t('app.dtpLandingPage.qna.question-1')" headerClass="dtp-faq__content-accordion-tab">
        <p>
          {{ $t("app.dtpLandingPage.qna.answer-1") }}
        </p>
      </AccordionTab>
      <AccordionTab :header="$t('app.dtpLandingPage.qna.question-2')" headerClass="dtp-faq__content-accordion-tab">
        <p>
          {{ $t("app.dtpLandingPage.qna.answer-2-1") }}
        </p>
        <p class="ml-[5px]">
          {{ $t("app.dtpLandingPage.qna.answer-2-2") }}
        </p>
        <p class="ml-[5px]">
          {{ $t("app.dtpLandingPage.qna.answer-2-3") }}
        </p>
        <p>
          {{ $t("app.dtpLandingPage.qna.answer-2-4") }}
        </p>
      </AccordionTab>
      <AccordionTab :header="$t('app.dtpLandingPage.qna.question-3')" headerClass="dtp-faq__content-accordion-tab">
        <p>
          {{ $t("app.dtpLandingPage.qna.answer-3-1") }} {{ " " }}
          <span @click="openLink(externalUrls.freedomCurrencyUrl, '_blank')">
            {{ $t("app.dtpLandingPage.qna.answer-3-2") }}
          </span>
        </p>
      </AccordionTab>
      <AccordionTab :header="$t('app.dtpLandingPage.qna.question-4')" headerClass="dtp-faq__content-accordion-tab">
        <p>
          {{ $t("app.dtpLandingPage.qna.answer-4") }}
        </p>
      </AccordionTab>
      <AccordionTab :header="$t('app.dtpLandingPage.qna.question-5')" headerClass="dtp-faq__content-accordion-tab">
        <p>
          {{ $t("app.dtpLandingPage.qna.answer-5") }}
        </p>
      </AccordionTab>
      <AccordionTab :header="$t('app.dtpLandingPage.qna.question-6')" headerClass="dtp-faq__content-accordion-tab">
        <p>
          {{ $t("app.dtpLandingPage.qna.answer-6") }}
        </p>
      </AccordionTab>
    </Accordion>
  </section>
</template>

<style scoped lang="scss">
.dtp-faq {
  padding: 50px 30px;
  border-radius: 20px;
  background: radial-gradient(54.59% 70.24% at 0% 100%, rgba(38, 73, 52, 0.6) 0%, rgba(27, 27, 27, 0.6) 100%);
  backdrop-filter: blur(8px);
  @include column;
  gap: 50px;
  margin-bottom: 150px;
  .dtp-faq__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @include main-green-gradient;
  }
  .dtp-faq__accordion {
    @include column;
    gap: 26px;
    ::v-deep(.dtp-faq__content-accordion-tab) {
      padding-bottom: 10px;
    }
    ::v-deep(.p-accordion-tab) {
      &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-white);
      }
    }
    ::v-deep(.dtp-faq__content-accordion-tab a) {
      background: transparent;

      .p-icon {
        width: 22px;
        height: 22px;
        path {
          fill: var(--color-white);
        }
      }
      .p-accordion-header-text {
        color: var(--color-white);
        font-family: inherit;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
    }
    ::v-deep(.p-toggleable-content .p-accordion-content) {
      background: transparent;
      color: var(--color-white);
      padding-bottom: 10px;
      width: 100%;
      &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-white);
      }
    }
    ::v-deep(.p-toggleable-content .p-accordion-content p) {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      max-width: 1000px;

      span {
        color: rgba(100, 106, 237, 0.7);
        cursor: pointer;
      }
    }
  }
}
</style>
