<script setup lang="ts">
import {ref, computed, onUnmounted} from "vue"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {useFormatters} from "@/utils/formatters"
import {useHelpers} from "@/composables/useHelpers"
import FFPlainFieldset from "@/components/FFUI/FFPlainFieldset.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import Skeleton from "primevue/skeleton"

const addDriverAutoStore = useAddDriverAutoStore()
const {
  addDriverAutoByGlobalId,
  pushedVehicles,
  pushedPersons,
  isDtRegCertGreaterThanContractDate,
  persons,
  vehicles,
  initialPersonsState,
  initialVehiclesState
} = storeToRefs(addDriverAutoStore)
const {convertISOToDate} = useFormatters()
const {currentLocale} = useHelpers()
const {t} = useI18n()

const convertedFromDate = computed(() => {
  return convertISOToDate(addDriverAutoByGlobalId?.value?.from_date)
})

const reissueReason = computed(() => {
  const hasNewVehicle = pushedVehicles.value.some((pushedVehicle) => pushedVehicle.isNew)
  const hasNewPerson = pushedPersons.value.some((pushedPerson) => pushedPerson.isNew)
  const hasOnlyOldVehicles = !pushedVehicles.value.some((pushedVehicle) => pushedVehicle.isNew)
  const hasOnlyOldPersons = !pushedPersons.value.some((pushedPerson) => pushedPerson.isNew)
  const deletedPerson = initialPersonsState.value.some((initialPerson) => {
    return !persons.value?.some((person) => person.iin === initialPerson.iin)
  })
  const deletedVehicle = initialVehiclesState.value.some((initialVehicle) => {
    return !vehicles.value?.some((vehicle) => vehicle.regNum === initialVehicle.regNum)
  })

  if ((hasNewVehicle || deletedVehicle) && (hasNewPerson || deletedPerson)) {
    return t("app.addDriverAuto.changeInsuredsAllowedToDriveAndChangeListTs")
  }
  if (hasNewVehicle && hasOnlyOldPersons) {
    return t("app.addDriverAuto.changeListTs")
  }
  if (hasNewPerson && hasOnlyOldVehicles) {
    return t("app.addDriverAuto.changeInsuredsAllowedToDrive")
  }
  if (deletedVehicle) {
    return t("app.addDriverAuto.changeListTs")
  }
  if (deletedPerson) {
    return t("app.addDriverAuto.changeInsuredsAllowedToDrive")
  }
  return ""
})

const showReason = computed(() => {
  const deletedPerson = initialPersonsState.value.some((initialPerson) => {
    return !persons.value?.some((person) => person.iin === initialPerson.iin)
  })
  const deletedVehicle = initialVehiclesState.value.some((initialVehicle) => {
    return !vehicles.value?.some((vehicle) => vehicle.regNum === initialVehicle.regNum)
  })
  if (deletedPerson) {
    return true
  }
  if (deletedVehicle && vehicles.value.length !== 0) {
    return true
  }
  if (pushedPersons.value.length === 0 && pushedVehicles.value.length === 0) {
    return false
  }
  if (pushedPersons.value.some((pushedPerson) => pushedPerson.isNew) && vehicles.value.length !== 0) {
    return true
  }
  if (pushedVehicles.value.some((pushedVehicle) => pushedVehicle.isNew) && persons?.value?.length >= 1) {
    return true
  }

  return false
})
</script>

<template>
  <div class="add-reason">
    <p v-if="currentLocale === 'kk'" class="add-reason__title">
      {{ $t("app.addDriverAuto.iAskYouToTerminatePolicie-1") }}
      <Skeleton v-if="!addDriverAutoByGlobalId?.from_date" width="3rem" height="1.1rem" />
      <span v-if="convertedFromDate" class="add-reason__title">{{ convertedFromDate }} ж.</span>&nbsp;
      <Skeleton v-if="!addDriverAutoByGlobalId?.global_id" width="3rem" height="1.1rem" />№<span
        v-if="addDriverAutoByGlobalId?.global_id"
        class="add-reason__title"
        >{{ addDriverAutoByGlobalId?.global_id }}
      </span>
      {{ $t("app.addDriverAuto.iAskYouToTerminatePolicie-2") }}
    </p>
    <p v-else class="add-reason__title">
      {{ $t("app.addDriverAuto.iAskYouToTerminatePolicie-1") }} №
      <Skeleton v-if="!addDriverAutoByGlobalId?.global_id" width="3rem" height="1.1rem" />
      <span v-else class="add-reason__title">{{ addDriverAutoByGlobalId?.global_id }}</span>
      {{ $t("app.addDriverAuto.beginningFrom") }}
      <Skeleton v-if="!addDriverAutoByGlobalId?.from_date" width="5rem" height="1.1rem" />
      <span v-else class="add-reason__title">{{ convertedFromDate }}</span>
    </p>
    <FFPlainFieldset v-if="showReason" :title="$t('form.inputTitles.policyRenewalReson')" :reason="reissueReason" />
    <FFFieldset
      v-if="addDriverAutoByGlobalId?.holder_iin !== undefined"
      :title="$t('form.inputTitles.policyholderInformationIIN')">
      <FFInput id="iin" name="iin" v-model="addDriverAutoByGlobalId.holder_iin" type="text" :locked="true" />
    </FFFieldset>
  </div>
</template>

<style scoped lang="scss">
.add-reason {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
  margin-top: 50px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);

  .add-reason__reissue-reason {
    border: 2px solid #ffffff;
    border-radius: 5px;
    background: transparent;
    color: #ffffff;
    width: 100%;
    padding: 11px 5px 10px 14px;
    outline: none;
    font-size: 16px;
    position: relative;

    .add-reason__reissue-reason-title {
      position: absolute;
      top: -8px;
      left: 10px;
      color: white;
      padding: 0 4px;
      white-space: nowrap;
      font-weight: 600;
      font-size: 12px;
      width: fit-content;
      float: initial;
      background: #1c1c1c;
    }
  }

  .add-reason__title {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

@media (max-width: $lg) {
  .add-reason {
    padding: 30px 16px;
  }
}
</style>
