<script setup lang="ts">
import {onMounted} from "vue"
import {storeToRefs} from "pinia"
import {useTerminationAgreementStore} from "@/stores/TerminationAgreementStore"
import {useModalStore} from "@/stores/ModalStore"
import FFButton from "@/components/FFUI/FFButton.vue"
import CloseIcon from "@/components/icons/CloseIcon.vue"

const terminationAgreement = useTerminationAgreementStore()
const {terminationByGlobalId} = storeToRefs(terminationAgreement)
const modalStore = useModalStore()
</script>

<template>
  <div class="termination-welcome-text">
    <p class="termination-welcome__title">
      {{ $t("app.terminationAgreementPage.terminationNeedBiometriTextTitle") }}
    </p>
    <div>
      <p v-if="!terminationByGlobalId?.is_cancellable" class="termination-welcome__description">
        {{ $t("app.terminationAgreementPage.startPageWelcomeText") }}
      </p>
      <p v-else class="termination-welcome__description">
        {{ $t("app.terminationAgreementPage.startPageNotActiveWelcomeText") }}
      </p>
    </div>
    <FFButton @click="modalStore.closeModal" :title="$t('app.buttons.continue')" />
  </div>
</template>

<style scoped lang="scss">
.termination-welcome-text {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 18px;
  margin: 0 20px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  max-width: 500px;

  .termination-welcome__title {
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .termination-welcome__description {
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
</style>
