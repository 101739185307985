<script setup lang="ts">
import {useRouter} from "vue-router"
import ExclamationMarkRombIcon from "@/components/icons/ExclamationMarkRombIcon.vue"
import FFCallCenterFull from "@/components/FFUI/FFCallCenterFull.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const router = useRouter()
</script>

<template>
  <section class="home-protect-insurance-case container">
    <div class="home-protect-insurance-case__left-part">
      <p class="home-protect-insurance-case__left-part-title">{{ $t("app.homeProtectPage.insuranceCase.title") }}</p>
      <ol start="1" class="home-protect-insurance-case__left-part-list">
        <li v-for="i in 4" :key="i" class="home-protect-insurance-case__left-part-list-item">
          {{ $t(`app.homeProtectPage.insuranceCase.list-${i}`) }}
        </li>
      </ol>
    </div>
    <div class="home-protect-insurance-case__right-part">
      <ExclamationMarkRombIcon />
      <FFCallCenterFull />
      <FFButton
        @click="router.push('/pages/personal-profile')"
        class="profile-btn"
        style="white-space: nowrap"
        :title="$t('app.profileApp')"
        :uppercase="false"
        color="#FFFFFF"
        paddingx="20px"
        paddingy="35px"
        width="fit-content"
        type="submit"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        icon-path="/contacts-front/images/profile.svg"
        icon-gap="11px" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.home-protect-insurance-case {
  position: relative;
  display: flex;
  gap: 50px;
  margin-top: 150px;
  margin-bottom: 150px;
  z-index: 1;

  @media (max-width: $lg) {
    flex-direction: column;
  }

  .home-protect-insurance-case__left-part-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .home-protect-insurance-case__left-part {
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 716px;

    @media (max-width: $lg) {
      width: 100%;
      max-width: 100%;
    }
    .home-protect-insurance-case__left-part-list {
      padding-left: 20px;
      list-style-type: decimal;
      .home-protect-insurance-case__left-part-list-item {
        color: rgba(255, 255, 255, 0.8);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .home-protect-insurance-case__right-part {
    display: flex;
    padding: 50px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 30px;
    background: rgba(74, 74, 74, 0.2);
    backdrop-filter: blur(8px);
    width: 344px;

    @media (max-width: $lg) {
      max-width: 100%;
      width: 100%;
    }

    svg {
      width: 111.387px;
      height: 115px;
    }
  }
}
</style>
