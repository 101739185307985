export function useRules() {
  const isEmpty = (value: string | null | undefined): boolean => {
    if (value === null || value === undefined) {
      return true
    }
    return value.trim() === "" || false
  }

  const minLength = (value: string, minLength: number): boolean => {
    return value.length >= minLength
  }

  const maxLength = (value: string, maxLength: number): boolean => {
    return value.length <= maxLength
  }

  const isEmail = (value: string) => {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const isIIN = (value: string): boolean => {
    return value.length === 12
  }

  const isPassport = (value: string): boolean => {
    return value.length === 9
  }

  const isLatinAndNoNumbersOrSpecialChars = (value: string): string => {
    const filteredValue = value.replace(/[^a-zA-Z]/g, "")
    return filteredValue
  }

  return {
    isEmpty,
    minLength,
    maxLength,
    isEmail,
    isIIN,
    isPassport,
    isLatinAndNoNumbersOrSpecialChars
  }
}
