import {ref, watch, computed} from "vue"
import {defineStore} from "pinia"
import {debounce} from "lodash-es"
import {wearVehicleSchema, vehicleMileageSchema} from "@/schemas"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import type {IWearVehicleResponse} from "@/interfaces/Calculator/IWearVehicleResponse"

export const useWearAndMileageStore = defineStore("wearAndMileageStore", () => {
  const {getData, postData, loading} = useGatewayFetcher()
  const {currentLocale} = useHelpers()

  //Wear vehicle calculator
  const wearVehicleObject = ref({
    category: "",
    brand: "",
    yearOfRelease: null,
    actualMileage: null
  })

  const isWearVehicleObjectComplete = computed(() => {
    const validation = wearVehicleSchema.safeParse(wearVehicleObject.value)
    return validation.success
  })

  const categories = ref<{id: number; name: string}[]>([])

  const debouncedFetchCategories = debounce(async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await getData("/wear-vehicle/categories", config)
      if (response) {
        categories.value = response
      }
    } catch (error) {
      console.error("Error fetching categories:", error)
    }
  }, 500)

  const fetchCategories = () => {
    debouncedFetchCategories()
  }

  const brands = ref<{id: number; name: string}[]>([])

  const debouncedFetchBrands = debounce(async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/wear-vehicle/brands",
        {
          category_id: wearVehicleObject.value.category.id
        },
        config
      )
      if (response) {
        brands.value = response.data
      }
    } catch (error) {
      console.error("Error fetching marks:", error)
    }
  }, 500)

  const fetchBrands = () => {
    debouncedFetchBrands()
  }

  watch(
    currentLocale,
    () => {
      wearVehicleObject.value.category = ""
      wearVehicleObject.value.brand = ""
      fetchCategories()
    },
    {immediate: true}
  )

  watch(
    () => wearVehicleObject.value.category,
    (newCategory) => {
      if (newCategory) {
        fetchBrands()
        wearVehicleObject.value.brand = ""
      } else {
        brands.value = []
        wearVehicleObject.value.category = ""
      }
    }
  )

  watch(wearVehicleObject, () => {
    wearVehicleCalculateResult.value = null
  }, {
    deep: true
  })

  const wearVehicleCalculateResult = ref<IWearVehicleResponse | unknown | null>()
  const wearVehicleCalculate = debounce(async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/wear-vehicle/calculate",
        {
          brand_id: wearVehicleObject.value.brand.id,
          year: parseInt(wearVehicleObject.value.yearOfRelease),
          mileage: parseInt(wearVehicleObject.value.actualMileage)
        },
        config
      )
      if (response && response.status === 200) {
        wearVehicleCalculateResult.value = response.data
      }
    } catch (error) {
      console.error(error)
    }
  }, 500)

  //Vehicle mileage calculator
  const vehicleMileageObject = ref({
    category: null,
    yearOfRelease: null
  })

  const isVehicleMileageObjectComplete = computed(() => {
    const validation = vehicleMileageSchema.safeParse(vehicleMileageObject.value)
    return validation.success
  })

  const vehicleMileageCategories = ref<{name: string}[]>([])
  const debouncedFetchVehicleMileageCategories = debounce(async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await getData("/vehicle-mileage/categories", config)
      if (response) {
        vehicleMileageCategories.value = response.data
      }
    } catch (error) {
      console.error(error)
    }
  }, 500)

  const fetchVehicleMileageCategories = () => {
    debouncedFetchVehicleMileageCategories()
  }

  const vehicleMileageCalculateResult = ref()
  const vehicleMileageCalculate = debounce(async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/vehicle-mileage/calculate",
        {
          category_name: vehicleMileageObject?.value?.category?.name,
          year: parseInt(vehicleMileageObject?.value?.yearOfRelease)
        },
        config
      )
      if (response && response.status === 200) {
        vehicleMileageCalculateResult.value = response.data.data
      }
    } catch (error) {
      console.error(error)
    }
  }, 500)

  const resetStates = () => {
    wearVehicleObject.value = {
      category: "",
      brand: "",
      yearOfRelease: null,
      actualMileage: null
    }
    wearVehicleCalculateResult.value = null
    vehicleMileageObject.value = {
      category: null,
      yearOfRelease: null
    }
    vehicleMileageCalculateResult.value = null
  }

  return {
    loading,
    wearVehicleObject,
    isWearVehicleObjectComplete,
    categories,
    brands,
    wearVehicleCalculateResult,

    vehicleMileageObject,
    vehicleMileageCategories,
    vehicleMileageCalculateResult,
    isVehicleMileageObjectComplete,

    fetchCategories,
    fetchBrands,
    wearVehicleCalculate,
    fetchVehicleMileageCategories,
    vehicleMileageCalculate,

    resetStates
  }
})
