<script setup lang="ts">
import {ref, onUnmounted, computed} from "vue"
import {vMaska} from "maska"
import {storeToRefs} from "pinia"
import {useAuthStore} from "@/stores/AuthStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useValidation} from "@/composables/useValidation"
import {useToast} from "vue-toastification"
import {useI18n} from "vue-i18n"
import {onPhoneFocus} from "@/utils/inputEvents"
import {filterCyrillicInput} from "@/utils/inputEvents"
import {loginFormSchema} from "@/schemas"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFReCaptcha from "@/components/FFUI/FFReCaptcha.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"

const toast = useToast()
const {t} = useI18n()
const authStore = useAuthStore()
const helperStore = useHelperStore()

const {loginData, activeTabIndex, resetPhoneObj} = storeToRefs(authStore)
const {isRecaptchaVerified} = storeToRefs(helperStore)

const {isValid, getError, validate} = useValidation(loginFormSchema, loginData, {
  mode: "lazy"
})

const passwordType = ref<string>("password")

const handleLogin = async () => {
  try {
    await validate()
    if (isValid.value) {
      authStore.handleLogin()
    }
    return
  } catch (error) {
    console.error(error)
  }
}

const handleForgotPassword = () => {
  resetPhoneObj.value = {
    resetPhoneNumber: ""
  }
  activeTabIndex.value = 3
}

const preventPasswordCyrillicInput = () => {
  loginData.value.password = filterCyrillicInput(loginData.value.password)
}

const isLoginFormFilled = computed(() => {
  return loginFormSchema.safeParse(loginData.value).success
})

onUnmounted(() => {
  loginData.value.phone = ""
  loginData.value.password = ""
})
</script>

<template>
  <div class="form-wrapper">
    <form @submit.prevent="handleLogin" autocomplete="false" class="form-wrapper__login">
      <div class="form-wrapper__login-row">
        <FFFieldset :isError="!!getError('phone')" :title="$t('form.inputTitles.phone')">
          <FFInput
            v-maska
            data-maska="+7 (###) ### ####"
            placeholder="+7 (___) ___ ____"
            id="phone"
            v-model="loginData.phone"
            name="1"
            type="tel"
            @focus="onPhoneFocus" />
        </FFFieldset>
        <template v-if="!!getError('phone')">
          <FFErrorMessage
            v-if="loginData?.phone?.length === 0"
            :errorMessage="t('form.validationErrors.phoneFieldIsRequired')" />
          <FFErrorMessage
            v-if="loginData?.phone?.length > 0 && getError('phone')"
            :errorMessage="t('form.validationErrors.phoneIsNotValid')" />
        </template>
      </div>
      <div class="form-wrapper__login-row">
        <FFFieldset
          :isError="!!getError('password')"
          :title="$t('form.inputTitles.password')"
          :password="true"
          :eye-opened="passwordType === 'password'"
          @text="passwordType = 'text'"
          @password="passwordType = 'password'">
          <FFInput
            id="password"
            v-model="loginData.password"
            name="password"
            :type="passwordType"
            @input="preventPasswordCyrillicInput" />
        </FFFieldset>
        <template v-if="!!getError('password')">
          <FFErrorMessage
            v-if="loginData?.password?.length === 0"
            :errorMessage="t('form.validationErrors.passwordFieldIsRequired')" />
        </template>
      </div>
      <div class="recaptcha-wrapper">
        <FFReCaptcha />
      </div>
      <FFButton
        :title="$t('app.buttons.login')"
        :uppercase="false"
        :disabled="!isLoginFormFilled"
        color="#FFFFFF"
        colorMobile="#FFFFFF"
        paddingx="17px"
        paddingy="30px"
        width="100%"
        type="submit"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)" />
      <button @click="handleForgotPassword" type="button" class="form-wrapper__login--forgot">
        {{ $t("app.auth.forgotPassword") }}
      </button>
    </form>
  </div>
</template>

<style scoped lang="scss">
.form-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  .form-wrapper__login {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
    max-width: 264px;

    .form-wrapper__login-row {
      width: 100%;
    }

    .recaptcha-wrapper {
      transform: scale(0.87);
      transform-origin: 0 0;
      width: 263px;
    }

    .form-wrapper__login--forgot {
      color: #4f9d3a;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      text-decoration-line: underline;
      padding: 10px 0;
      transition: all 0.3s ease;
    }
    .form-wrapper__login--forgot:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>
