<script setup lang="ts">
import {onMounted, onUnmounted, computed} from "vue"
import {storeToRefs} from "pinia"
import {vMaska} from "maska"
import {useRouter} from "vue-router"
import {useHelperStore} from "@/stores/HelperStore"
import {useValidation} from "@/composables/useValidation"
import {useHelpers} from "@/composables/useHelpers"
import {onPhoneFocus} from "@/utils/inputEvents"
import {filterEmailInput} from "@/utils/inputEvents"
import {juridicalPageFormSchema} from "@/schemas/Juridical/juridicalPageFormSchema"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"

interface IProps {
  policyName?: string
}

const props = defineProps<IProps>()

const helperStore = useHelperStore()
const {juridicalFormData} = storeToRefs(helperStore)

const router = useRouter()
const {currentLocale, openLink} = useHelpers()
const {isValid, validate} = useValidation(juridicalPageFormSchema, juridicalFormData, {
  mode: "lazy"
})

const isJuridicalPageFormFilled = computed(() => {
  return juridicalPageFormSchema.safeParse(juridicalFormData.value).success
})

const handleSendForm = async () => {
  await validate()
  if (isValid.value) {
    helperStore.handleJuridicalFormSend(props.policyName)
  }
}

onMounted(() => {
  juridicalFormData.value = {
    companyName: "",
    bin: "",
    fio: "",
    phone: "",
    email: "",
    iAgreeWithAgreement: false
  }
})

onUnmounted(() => {
  juridicalFormData.value = {
    companyName: "",
    bin: "",
    fio: "",
    phone: "",
    email: "",
    iAgreeWithAgreement: false
  }
})
</script>

<template>
  <section class="juridical-form__wrapper container">
    <p class="juridical-form__title">{{ $t("juridical.submitApplication") }}</p>
    <div class="juridical-form">
      <FFFieldset :title="$t('form.inputTitles.company')">
        <FFInput v-model="juridicalFormData.companyName" />
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.bin')">
        <FFInput v-maska data-maska="############" id="bin" v-model="juridicalFormData.bin" name="bin" type="tel" />
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.fullName')">
        <FFInput v-model="juridicalFormData.fio" />
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.phone')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          placeholder="+7 (___) ___ ____"
          id="phone"
          v-model="juridicalFormData.phone"
          name="phone"
          @focus="onPhoneFocus"
          type="tel" />
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.email')">
        <FFInput
          id="email"
          v-model="juridicalFormData.email"
          name="email"
          type="email"
          inputmode="email"
          @input="filterEmailInput" />
      </FFFieldset>
      <FFCheckbox v-if="currentLocale === 'kk'" v-model="juridicalFormData.iAgreeWithAgreement">
        <template #title>
          {{ $t("form.checkboxTitles.iAgreeProcessPersonalData.iAgree-1") }}
          {{ $t("form.checkboxTitles.iAgreeProcessPersonalData.iAgree-2") }}
          <span
            @click="openLink('/pages/polodgenie-ob-obrabotke-personalnykh-dannykh', '_blank')"
            class="underline cursor-pointer">
            {{ $t("form.checkboxTitles.iAgreeProcessPersonalData.iAgree-3") }}
          </span>
        </template>
      </FFCheckbox>
      <FFCheckbox v-else v-model="juridicalFormData.iAgreeWithAgreement">
        <template #title>
          {{ $t("form.checkboxTitles.iAgreeProcessPersonalData.iAgree-1") }}
          <span
            @click="openLink('/pages/polodgenie-ob-obrabotke-personalnykh-dannykh', '_blank')"
            class="underline cursor-pointer">
            {{ $t("form.checkboxTitles.iAgreeProcessPersonalData.iAgree-2") }}
          </span>
          {{ $t("form.checkboxTitles.iAgreeProcessPersonalData.iAgree-3") }}
        </template>
      </FFCheckbox>
      <FFButton
        @click="handleSendForm"
        :disabled="!isJuridicalPageFormFilled"
        :uppercase="false"
        :title="$t('app.buttons.send')" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.juridical-form__wrapper {
  padding: 50px 30px;
  border-radius: 20px;
  background: rgba(74, 74, 74, 0.2);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  margin-bottom: 50px;

  .juridical-form__title {
    color: var(--color-white);
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    margin-left: 50px;

    @include max-responsive(lg) {
      margin-left: 0;
    }
  }

  .juridical-form {
    @include column;
    gap: 30px;
    max-width: 370px;
    margin-top: 20px;
    margin-left: 50px;

    @include max-responsive(lg) {
      max-width: 100%;
      margin-left: 0;
    }
  }
}
</style>
