<script setup lang="ts">
import {computed, reactive} from "vue"
import {useToast} from "vue-toastification"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useOgpoPlusStore} from "@/stores/OgpoPlusStore"
import {useValidation} from "@/composables/useValidation"
import {useFormatters} from "@/utils/formatters"
import {useHelpers} from "@/composables/useHelpers"
import {filterEmailInput} from "@/utils/inputEvents"
import {pushGtagEvent} from "@/utils/pushGtagEvent"
import {ogpoPlusExecutionFormSchema} from "@/schemas"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import QuestionIcon from "@/components/icons/QuestionIcon.vue"

const toast = useToast()
const {t} = useI18n()
const ogpoStore = useOgpoPlusStore()
const {calculationObject, addOgpoPlus, executionForm} = storeToRefs(ogpoStore)
const {currentLocale, openLink} = useHelpers()
const {thousandSeparator} = useFormatters()

const emit = defineEmits(["nextStep"])

const {isValid, getError, validate} = useValidation(ogpoPlusExecutionFormSchema, executionForm.value, {
  mode: "lazy"
})

const handleNextStep = async () => {
  try {
    await validate()
    if (!executionForm.value.email && !executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTermsAndEmail"))
      return
    }
    if (!executionForm.value.email && executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTermsEmail"))
      return
    }
    if (executionForm.value.email && !executionForm.value.iAgreeWithAgreement) {
      toast.clear()
      toast.error(t("app.toasterMessages.agreeToTheTerms"))
      return
    }
    if (isValid.value) {
      pushGtagEvent("valid_email_input")
      pushGtagEvent("vibor_oplata_step2")
      emit("nextStep")
    }
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <div class="exec-form__additional-refund-amount-wrapper">
    <p>{{ $t("app.ogpoPlusPage.forms.additionalRefundByOgpoPlus") }}</p>
    <p v-if="currentLocale !== 'kk'" class="exec-form__additional-refund-amount-right-text">
      {{ $t("form.formAbbreviations.till") }} 1 179 600 {{ $t("form.formAbbreviations.tg") }} ({{
        $t("form.formAbbreviations.till")
      }}
      300 МРП)
    </p>
    <p v-else class="exec-form__additional-refund-amount-right-text">
      1 179 600 {{ $t("form.formAbbreviations.tg") }} {{ $t("form.formAbbreviations.till") }} (300 МРП
      {{ $t("form.formAbbreviations.till") }})
    </p>
  </div>
  <div class="exec-form__opgo">
    <div>
      <FFFieldset :isError="!!getError('email')" :title="$t('form.inputTitles.email')">
        <FFInput
          id="email"
          name="email"
          type="email"
          v-model="executionForm.email"
          :placeholder="t('app.ogpoPlusPage.forms.willSendPolisOnEmail')"
          inputmode="email"
          :required="true"
          @input="filterEmailInput" />
      </FFFieldset>
      <template v-if="!!getError('email')">
        <FFErrorMessage
          v-if="executionForm.email.length === 0"
          :darkMode="true"
          :errorMessage="t('form.validationErrors.emailFieldIsRequired')" />
        <FFErrorMessage
          v-if="executionForm.email.length > 0 && getError('email')"
          :darkMode="true"
          :errorMessage="t('form.validationErrors.emailIsNotValid')" />
      </template>
    </div>
    <FFCheckbox v-if="currentLocale === 'kk'" v-model="executionForm.iAgreeWithAgreement">
      <template #title>
        {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
        <span class="exec-fort__ogpo-link" @click="() => ogpoStore.openOgpoQuestionnairePdf(calculationObject?.calcId)">
          {{ $t("form.checkboxTitles.iAgree.iAgree-2") }}</span
        >
        {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
        <span
          @click="
            openLink(
              'https://ffins.kz/kk/report-file/254',
              '_blank'
            )
          "
          class="exec-fort__ogpo-link">
          {{ $t("form.checkboxTitles.iAgree.iAgree-4") }}
        </span>
        {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}
      </template>
    </FFCheckbox>
    <FFCheckbox v-else v-model="executionForm.iAgreeWithAgreement">
      <template #title>
        {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
        <span @click="() => ogpoStore.openOgpoQuestionnairePdf(calculationObject?.calcId)" class="exec-fort__ogpo-link">
          {{ $t("form.checkboxTitles.iAgree.iAgree-2") }}
        </span>
        {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
        <span
          @click="
            openLink('https://ffins.kz/report-file/254', '_blank')
          "
          class="exec-fort__ogpo-link"
          >{{ $t("form.checkboxTitles.iAgree.iAgree-4") }} {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}</span
        >
      </template>
    </FFCheckbox>
  </div>
  <div class="relative">
    <div class="exec-form__dark-bg"></div>
    <div class="exec-form__payment-sum-wrapper">
      <div class="exec-form__payment-sum-wrapper--ogpo-plus">
        <p>{{ $t("app.ogpoPlusPage.forms.polisOgpoPlus") }}:</p>
        <p>
          {{ calculationObject && calculationObject.insPremium ? thousandSeparator(calculationObject.insPremium) : "" }}
          {{ $t("form.formAbbreviations.tg") }}
        </p>
      </div>
      <div class="exec-form__payment-sum-wrapper--sum">
        <p>{{ $t("app.ogpoPlusPage.forms.toPay") }}</p>
        <p>
          {{ thousandSeparator(calculationObject?.insPremium) }}
          <span class="tenge">{{ $t("form.formAbbreviations.tg") }}</span>
        </p>
      </div>
    </div>

    <div class="exec-form__pay-button" :class="{'exec-form__pay-button--no-margin': !addOgpoPlus}">
      <FFButton @click="handleNextStep" :uppercase="false" :title="$t('app.buttons.pay')" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.exec-form__additional-refund-amount-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  p {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .exec-form__additional-refund-amount-right-text {
    text-align: right;
  }
}

.exec-form__opgo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  .exec-fort__ogpo-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
  }
}

.exec-form__payment-sum-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  position: relative;
  z-index: 1;

  .exec-form__payment-sum-wrapper--notClientBank {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;

    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: none;

      span {
        text-decoration: underline;
      }
    }

    p:first-child {
      width: 165px;
    }
  }

  .exec-form__payment-sum-wrapper--clientBank {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    margin-top: 15px;

    span {
      text-decoration: underline;
    }
  }

  .exec-form__payment-sum-wrapper--ogpo-plus {
    display: flex;
    justify-content: space-between;

    p {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      opacity: 0.8;
    }

    p:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  .exec-form__payment-sum-wrapper--sum {
    display: flex;
    justify-content: space-between;

    p {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      opacity: 1;
    }
  }
}

hr {
  z-index: 1;
  position: relative;
  border-color: white;
}

.exec-form__pay-button {
  position: relative;
  z-index: 1;
  padding-top: 20px;
}

.exec-form__pay-button--no-margin {
  margin-top: 0px;
}

.exec-form__dark-bg {
  background-color: rgba(0, 0, 0, 0.5);
  height: calc(100% + 88px);
  width: calc(100% + 60px);
  position: absolute;
  top: 0;
  left: -10%;
  right: 0;
  bottom: 0;
}

.tenge {
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
</style>
