<script setup lang="ts">
import {useRouter} from "vue-router"

const router = useRouter()
</script>

<template>
  <section class="contacts">
    <h1>{{ $t("app.profile.title") }}</h1>
    <div class="contacts__breadcrumbs">
      <p @click="router.push('/')">{{ $t("app.declareInsuredEvent.main") }}</p>
      /
      <p @click="router.push('personal-profile')">{{ $t("app.declareInsuredEvent.personalCabinet") }}</p>
      /
      <span>{{ $t("app.profile.title") }}</span>
    </div>
  </section>
</template>

<style scoped lang="scss">
.contacts {
  top: 0;
  background: url("/user-profile-front/images/profile-hero.png") no-repeat center;
  background-size: cover;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 150px 0;
  h1 {
    color: var(--White, #fff);
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  &__breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #a1a1a1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    flex-wrap: wrap;
    p {
      cursor: pointer;
      transition: all 0.3s ease;
    }
    p:hover {
      color: #fff;
    }
    span {
      cursor: pointer;
      color: #fff;
    }
  }
}

@media (max-width: $xl) {
  .contacts {
    h1 {
      font-size: 40px;
    }
    &__breadcrumbs {
      font-size: 14px;
    }
  }
}

@media (max-width: $lg) {
  .contacts {
    border-radius: 0 0 50px 0;
    h1 {
      font-size: 30px;
    }
  }
}
</style>
