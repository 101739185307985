<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <g filter="url(#filter0_i_2820_20156)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 4.35547C9.87827 4.35547 7.84344 5.19832 6.34315 6.69861C4.84285 8.19891 4 10.2337 4 12.3555V28.3555C4 30.4772 4.84285 32.512 6.34315 34.0123C7.84344 35.5126 9.87827 36.3555 12 36.3555H28C30.1217 36.3555 32.1566 35.5126 33.6569 34.0123C35.1571 32.512 36 30.4772 36 28.3555V12.3555C36 10.2337 35.1571 8.19891 33.6569 6.69861C32.1566 5.19832 30.1217 4.35547 28 4.35547H12ZM25.968 18.2499C26.1117 18.0965 26.2238 17.9163 26.2979 17.7196C26.372 17.5229 26.4066 17.3135 26.3998 17.1034C26.3929 16.8933 26.3448 16.6867 26.2581 16.4952C26.1714 16.3037 26.0478 16.1312 25.8944 15.9875C25.741 15.8438 25.5608 15.7316 25.3641 15.6576C25.1674 15.5835 24.958 15.5489 24.748 15.5557C24.5379 15.5625 24.3312 15.6107 24.1397 15.6974C23.9482 15.7841 23.7757 15.9077 23.632 16.0611L18.6992 21.3251L16.2624 19.1603C15.9431 18.8949 15.5332 18.7639 15.1192 18.7952C14.7052 18.8264 14.3195 19.0174 14.0437 19.3277C13.7679 19.638 13.6235 20.0433 13.641 20.4582C13.6585 20.873 13.8366 21.2647 14.1376 21.5507L17.7376 24.7507C18.0491 25.0274 18.4562 25.1716 18.8725 25.1528C19.2887 25.134 19.6812 24.9536 19.9664 24.6499L25.968 18.2499Z"
        fill="url(#paint0_linear_2820_20156)" />
    </g>
    <defs>
      <filter
        id="filter0_i_2820_20156"
        x="4"
        y="4.35547"
        width="35.4666"
        height="35.4666"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="3.46658" dy="3.46658" />
        <feGaussianBlur stdDeviation="1.73329" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2820_20156" />
      </filter>
      <linearGradient
        id="paint0_linear_2820_20156"
        x1="36"
        y1="-1.29159"
        x2="-4.95045"
        y2="20.0339"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
    </defs>
  </svg>
</template>
