<script setup lang="ts">
import {ref, computed} from "vue"
import {useI18n} from "vue-i18n"
import type {IFromWhatSecureCards} from "@/interfaces/Comfort/IFromWhatSecureCards"
import GreenFireIcon from "@/components/icons/greens/GreenFireIcon.vue"
import GreenLiquidExposureIcon from "@/components/icons/greens/GreenLiquidExposureIcon.vue"
import GreenTheftIcon from "@/components/icons/greens/GreenTheftIcon.vue"
import GreenResponsibilityIcon from "@/components/icons/greens/GreenResponsibilityIcon.vue"

const {t} = useI18n()

const cards = ref<IFromWhatSecureCards[]>([
  {
    id: 1,
    icon: GreenFireIcon,
    name: computed(() => t("app.comfortPage.beProtectedAgainst-text-1"))
  },
  {
    id: 2,
    icon: GreenLiquidExposureIcon,
    name: computed(() => t("app.comfortPage.beProtectedAgainst-text-2"))
  },
  {
    id: 3,
    icon: GreenTheftIcon,
    name: computed(() => t("app.comfortPage.beProtectedAgainst-text-3"))
  },
  {
    id: 4,
    icon: GreenResponsibilityIcon,
    name: computed(() => t("app.comfortPage.beProtectedAgainst-text-4"))
  }
])
</script>

<template>
  <section class="comfort-fromwhatsecure">
    <h2 class="comfort-fromwhatsecure__title">{{ $t("app.comfortPage.beProtectedAgainst") }}</h2>
    <div class="comfort-fromwhatsecure__cards">
      <div v-for="card in cards" :key="card.id" class="comfort-fromwhatsecure__card">
        <component :is="card.icon" />
        <p>{{ card.name }}</p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.comfort-fromwhatsecure {
  margin-bottom: 150px;
  position: relative;
  z-index: 1;

  .comfort-fromwhatsecure__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;

    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .comfort-fromwhatsecure__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin-top: 56px;
    gap: 30px;
    .comfort-fromwhatsecure__card {
      padding: 51px 22px;
      border-radius: 20px;
      background: #272727;
      transition: 0.1s ease-out;

      &:hover {
        scale: 1.05;
        transition: 0.1s ease-in;
      }
      svg {
        height: 50px;
        width: 50px;
        margin-bottom: 30px;
      }
      p {
        color: #ffffff;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: default;
      }
    }
  }
}

@media (max-width: $lg) {
  .comfort-fromwhatsecure {
    margin-bottom: 100px;
    .comfort-fromwhatsecure__cards {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 15px;

      .comfort-fromwhatsecure__card {
        width: 100%;
        padding: 26.505px 11.434px;

        p {
          font-size: 11.434px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
