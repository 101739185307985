<script setup lang="ts">
import {ref, onMounted, computed} from "vue"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useRouter} from "vue-router"
import {vMaska} from "maska"
import {z} from "zod"
import {useOgpoJuridicalStore} from "@/stores/OgpoJuridicalStore"
import {useModalStore} from "@/stores/ModalStore"
import {useHelpers} from "@/composables/useHelpers"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useFormatters} from "@/utils/formatters"
import {onPhoneFocus, filterCyrillicInput, filterSpecChars} from "@/utils/inputEvents"
import OgpoJuridicalForms from "@/components/OgpoJuridical/OgpoJuridicalForms.vue"
import OgpoUploadFileExampleModal from "@/components/OgpoJuridical/OgpoUploadFileExampleModal.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFSelect from "@/components/FFUI/FFSelect.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFCheckerInput from "@/components/FFUI/FFCheckerInput.vue"
import FFFileInput from "@/components/FFUI/FFFileInput.vue"
import AddDriverIcon from "@/components/icons/AddDriverIcon.vue"
import AddAutoIcon from "@/components/icons/AddAutoIcon.vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"
import YellowQuestionIcon from "@/components/icons/YellowQuestionIcon.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import Dropdown from "primevue/dropdown"
import FFFloatingTooltip from "@/components/FFUI/FFFloatingTooltip.vue"
import OgpoJuridicalResetDataModal from "@/components/OgpoJuridical/Forms/OgpoJuridicalResetDataModal.vue"
import FFPlainFieldset from "@/components/FFUI/FFPlainFieldset.vue"

const ogpoJuridicalStore = useOgpoJuridicalStore()
const {
  activeTab,
  currentStep,
  phone,
  bin,
  autonumtechpass,
  autonumtechpassInputStates,
  isAutoNumsLoadingState,
  isAutoNumsLoadingStates,
  carName,
  carNames,
  dateStart,
  month,
  monthes,
  documentParsedCars,
  xlsxFile
} = storeToRefs(ogpoJuridicalStore)
const {openModal} = useModalStore()

const router = useRouter()
const {currentLocale, openLink} = useHelpers()
const {dateFormatter} = useFormatters()
const {isDesktop} = useDeviceSize()
const {t} = useI18n()

const preventAutoNumCyrillicInput = () => {
  autonumtechpass.value = filterCyrillicInput(autonumtechpass.value)
  autonumtechpass.value = filterSpecChars(autonumtechpass.value)
}
const preventAutoNumCyrillicInputs = (index: number) => {
  autonumtechpassInputStates.value[index] = filterCyrillicInput(autonumtechpassInputStates.value[index])
  autonumtechpassInputStates.value[index] = filterSpecChars(autonumtechpassInputStates.value[index])
}

const handleUploadFileExampleModalOpen = () => {
  openModal({
    component: OgpoUploadFileExampleModal
  })
}

const handleNextStep = async () => {
  const response = await ogpoJuridicalStore.calculateCost()

  if (response) {
    currentStep.value = 2

    if (isDesktop.value) {
      openModal({
        component: OgpoJuridicalForms
      })
    }
  } else {
    return
  }
}

const handleFileChange = async (event: Event) => {
  const target = event.target as HTMLInputElement
  if (!target.files?.length) return

  const file = target.files[0]
  if (file) {
    const results = await ogpoJuridicalStore.parseXlsxFile(file)
    console.log("Parsed Results:", results)
  }
}

const handleTabClick = (tab: number) => {
  if (tab === 1 && documentParsedCars.value.length !== 0) {
    return
  }
  if (tab === 2 && (autonumtechpass.value !== "" || autonumtechpassInputStates.value.length !== 0)) {
    return
  }
  activeTab.value = tab
}

const firstTabFormSchema = z.object({
  phone: z.string().regex(/^\+7 \(\d{3}\) \d{3} \d{4}$/, "Invalid phone format"),
  bin: z.string().length(12, "BIN must be 12 digits"),
  autonums: z.array(z.string().min(3, "Each autonum must have at least 3 characters"))
})

const secondTabFormSchema = z.object({
  phone: z.string().regex(/^\+7 \(\d{3}\) \d{3} \d{4}$/, "Invalid phone format"),
  bin: z.string().length(12, "BIN must be 12 digits"),
  documentParsedCars: z
    .array(z.object({carNumber: z.string().min(3, "Car number must have at least 3 characters")}))
    .nonempty("No cars parsed from document")
})

const allAutonums = computed(() => [autonumtechpass.value, ...autonumtechpassInputStates.value])

const firstTabBtnStatus = computed(() => {
  const result = firstTabFormSchema.safeParse({
    phone: phone.value,
    bin: bin.value,
    autonums: allAutonums.value
  })
  return !result.success
})

const secondTabBtnStatus = computed(() => {
  const result = secondTabFormSchema.safeParse({
    phone: phone.value,
    bin: bin.value,
    documentParsedCars: documentParsedCars.value
  })
  return !result.success
})
</script>

<template>
  <p class="calc-form__title">{{ $t("form.formHeaders.costCalculation") }}</p>
  <div class="calc-form__fields">
    <div class="calc-form__phone-and-bin-wrapper">
      <FFFieldset :title="$t('form.inputTitles.representativesPhone')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          placeholder="+7 (___) ___ ____"
          id="phone"
          v-model="phone"
          name="1"
          type="tel"
          @focus="onPhoneFocus" />
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.bin')">
        <FFInput
          v-maska
          data-maska="############"
          v-model="bin"
          id="bin"
          name="bin"
          type="tel"
          @input="ogpoJuridicalStore.binCheck(bin)" />
      </FFFieldset>
    </div>

    <div class="calc-form__tabs">
      <div
        @click="handleTabClick(1)"
        class="calc-form__tab"
        :class="{
          'calc-form__tab--active': activeTab === 1,
          'wrap-text': currentLocale === 'kk',
          'nowrap-text': currentLocale !== 'kk'
        }">
        <p>{{ $t("form.tabs.upToFiveCars") }}</p>
      </div>
      <div
        @click="handleTabClick(2)"
        class="calc-form__tab"
        :class="{
          'calc-form__tab--active': activeTab === 2,
          'wrap-text': currentLocale === 'kk',
          'nowrap-text': currentLocale !== 'kk'
        }">
        <p>{{ $t("form.tabs.uploadTheList") }}</p>
      </div>
    </div>

    <div class="calc-form__bottom-part">
      <template v-if="activeTab === 1">
        <div class="calc-form__tab-one-wrapper">
          <div class="calc-form__form-row">
            <FFFieldset :title="$t('form.inputTitles.autoNumTechPass')">
              <FFCheckerInput
                :padding="
                  !isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 3
                    ? '11px 5px 7px 14px'
                    : '6px 5px 7px 14px'
                "
                :inputHeight="
                  !isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 3 ? '16px' : '51px'
                "
                placeholder=""
                :needUnderText="!isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 3"
                :underText="!isAutoNumsLoadingState && Boolean(carName) && autonumtechpass.length >= 3 ? carName : ''"
                v-model="autonumtechpass"
                id="auto-num-or-tech-passport"
                type="text"
                :loading="isAutoNumsLoadingState"
                :func="
                  () => {
                    autonumtechpass = autonumtechpass.toUpperCase()
                    preventAutoNumCyrillicInput()
                  }
                "
                :blur="ogpoJuridicalStore.handleAutonumBlur"
                :lockedErrorText="$t('app.toasterMessages.inputPhoneNumber')" />
            </FFFieldset>
            <div @click="ogpoJuridicalStore.pushInputToAutonumtechpassArr" class="calc-form__icon-wrapper">
              <AddAutoIcon :activeTab="1" />
            </div>
          </div>

          <template v-for="(data, index) in autonumtechpassInputStates.length" :key="index">
            <div class="calc-form__form-row">
              <FFFieldset :title="$t('form.inputTitles.autoNumTechPass')">
                <FFCheckerInput
                  :padding="
                    !isAutoNumsLoadingStates[index] &&
                    Boolean(carNames[index]) &&
                    autonumtechpassInputStates[index].length >= 3
                      ? '11px 5px 7px 14px'
                      : '6px 5px 7px 14px'
                  "
                  :inputHeight="
                    !isAutoNumsLoadingStates[index] &&
                    Boolean(carNames[index]) &&
                    autonumtechpassInputStates[index].length >= 3
                      ? '16px'
                      : '51px'
                  "
                  placeholder="908LFA02 or A001OMA"
                  :needUnderText="
                    !isAutoNumsLoadingStates[index] &&
                    Boolean(carNames[index]) &&
                    autonumtechpassInputStates[index].length >= 3
                  "
                  :underText="
                    !isAutoNumsLoadingStates[index] &&
                    Boolean(carNames[index]) &&
                    autonumtechpassInputStates[index].length >= 3
                      ? carNames[index]
                      : ''
                  "
                  v-model="autonumtechpassInputStates[index]"
                  id="auto-num-or-tech-passport"
                  type="text"
                  :loading="isAutoNumsLoadingStates[index]"
                  :removeInputText="true"
                  :handleRemoveInput="
                    () => ogpoJuridicalStore.removeAutonumtechpassState(index, autonumtechpassInputStates[index])
                  "
                  :blur="ogpoJuridicalStore.handleArrayAutonumBlur(index)"
                  :func="
                    () => {
                      autonumtechpassInputStates[index] = autonumtechpassInputStates[index].toUpperCase()
                      preventAutoNumCyrillicInputs(index)
                    }
                  "
                  :lockedErrorText="$t('app.toasterMessages.inputPhoneNumber')" />
              </FFFieldset>
            </div>
          </template>

          <div class="calc-form__form-row">
            <FFFieldset :title="$t('form.inputTitles.dateStart')">
              <VueDatePicker
                :enable-time-picker="false"
                :teleport="true"
                auto-apply
                v-model="dateStart"
                format="dd.MM.yyyy"
                :locale="currentLocale"
                :start-date="new Date(new Date().setDate(new Date().getDate() + 1))"
                :min-date="new Date(new Date().setDate(new Date().getDate() + 1))"
                :max-date="new Date(new Date().setDate(new Date().getDate() + 1) + 89 * 24 * 60 * 60 * 1000)">
                <template #input-icon>
                  <img
                    class="date-time-picker-icon"
                    src="/declare-insured-event/images/calendar-input.svg"
                    alt="icon" />
                </template>
                <template #clear-icon> </template>
              </VueDatePicker>
            </FFFieldset>
            <FFFieldset :title="$t('form.inputTitles.period')" minHeight="unset">
              <Dropdown v-model="month" :options="monthes" optionLabel="name" :disabled="true" dropdownIcon="no" />
            </FFFieldset>
          </div>
        </div>
        <!-- handleNextStep -->
        <FFButton
          @click="currentStep = 4"
          :disabled="firstTabBtnStatus"
          :title="$t('app.buttons.calculateCost')"
          :uppercase="false" />
      </template>
      <template v-else>
        <div v-if="documentParsedCars.length === 0" class="calc-form__tab-two-wrapper">
          <p class="calc-form__xlsx-file-text">
            {{ $t("app.ogpoJuridicalPage.forms.calculateCost.uploadXlsxFileText") }}
          </p>
          <p @click="handleUploadFileExampleModalOpen" class="calc-form__download-example">
            {{ $t("app.ogpoJuridicalPage.forms.calculateCost.downloadExample") }}
          </p>

          <FFFileInput
            v-model="xlsxFile"
            fileTypes="*.xls, *.xlsx"
            fileAccepts=".xls, .xlsx"
            inputId="damage"
            @change="handleFileChange" />
        </div>
        <div v-else class="calc-form__tab-two-wrapper">
          <template v-for="(data, index) in documentParsedCars" :key="index">
            <div class="calc-form__form-row">
              <FFFieldset :title="$t('form.inputTitles.autoNumTechPass')">
                <FFInput v-model="data.carNumber" :value="data.carNumber" :locked="true" />
              </FFFieldset>
            </div>
          </template>
        </div>
        <FFButton
          @click="handleNextStep"
          :disabled="secondTabBtnStatus"
          :title="$t('app.buttons.calculateCost')"
          :uppercase="false" />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.calc-form__title {
  color: var(--color-white);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.calc-form__fields {
  @include column;
  margin-top: 24px;

  ::v-deep(.dp__input_icon) {
    inset-inline-start: unset;
    right: 0px;
    top: 45%;
  }
  ::v-deep(.ff-form .dp__input_icon) {
    inset-inline-start: unset;
    right: 0px;
    top: 45%;
  }
  ::v-deep(.date-time-picker-icon) {
    width: 27px;
    height: 27px;
  }
  ::v-deep(.dp__input_icon_pad) {
    padding-inline-start: unset;
    padding-left: 15px;
    font-size: 16px !important;
  }
  ::v-deep(.dp__pointer .db__input_readonly .dp__input_icon_pad) {
    padding-inline-start: unset !important;
  }

  .calc-form__phone-and-bin-wrapper {
    @include column;
    gap: 20px;
    margin-bottom: 30px;
  }
  .calc-form__tabs {
    @include flex;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 4px;

    .calc-form__tab {
      border-radius: 5px;
      width: 140px;
      cursor: pointer;
      transition: all 0.2s;

      &--active {
        border-radius: 5px;
        background: #363636;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
        transition: all 0.2s;
        p {
          opacity: 1 !important;
        }
      }

      p {
        color: var(--color-white);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 19.6px;
        opacity: 0.5;
        padding: 10px;
        text-align: center;
      }
    }
  }

  .calc-form__bottom-part {
    @include column;

    .calc-form__tab-one-wrapper {
      .calc-form__form-row {
        display: flex;
        gap: 10px;
        margin-top: 20px;

        &:last-of-type {
          margin-bottom: 20px;
        }

        .calc-form__icon-wrapper {
          cursor: pointer;
          border: 2px solid #ffffff;
          height: 46px;
          width: 60px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: auto;
        }
      }
    }

    .calc-form__tab-two-wrapper {
      margin-bottom: 20px;
      .calc-form__xlsx-file-text {
        color: var(--color-white);
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 19.6px;
        margin-top: 10px;
        opacity: 0.9;
      }

      .calc-form__download-example {
        color: var(--color-white);
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px;
        text-decoration: underline;
        opacity: 0.9;
        cursor: pointer;
      }

      .calc-form__form-row {
        display: flex;
        gap: 10px;
        margin-top: 20px;
      }
    }
  }
}

.wrap-text {
  white-space: normal !important;
}
.nowrap-text {
  white-space: nowrap !important;
}
.not-clickable {
  pointer-events: none;
}
</style>
